/* StickyButton.css */

.sticky-button-container {
    position: fixed;
    top: 50%;
    right: -3.7rem;
    transform: translateY(-50%);
    z-index: 1000;
  }
  
  .sticky-button {
    /* Add your button styles here */
    padding: 10px;
    background-color: #005e8c;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    outline: none;
    transform: rotate(-90deg);
    text-transform: uppercase;
    z-index: 100;
  }
  