.havellogo{
    width: 12rem;
  
}


.havellcenter-container{
    
        display: flex;
        justify-content: flex-start;
        align-items: center;
        /* margin-left: 14rem;s */
     
     
      
}

.havellcards-01 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 4rem;
  }
  
  .havellcard-link {
    /* width: calc(33.33% - 20px); */
    width: 329px;
    margin: 10px; 
  }
  
  @media screen and (max-width: 990px) {
    .havellcard-link {
      width: 100%;
      margin: 10px 0;
    }
    .havellcenter-container{
    
        display: flex;
        justify-content: left;
        align-items: center;
        margin-left: 0rem;
     
     
      
}

.havellogo{
    width: 9rem;
  
}
    
.havellcards-01 {
   
    margin-top: 0rem;
  }

  }


  .havellviewmore{
     
      position: absolute;
      right: 5rem;
      color: rgba(92, 92, 92, 0.80);
  }
 .orbitviewmore{
    color: rgba(92, 92, 92, 0.80);
 }

  .orbitimagesi{
      margin-top: 4rem;
  }
  

  .heading-style-h2._8vw-title.orbitheading{
      text-align: left;
      font-size: 20px;
      margin-top: 1rem;
  }
  @media screen and (max-width: 700px) {
    .orbitimagesi{
        margin-top: -4rem;
    }
    .heading-style-h2._8vw-title.orbitheading{
        /* text-align: center; */
        font-size: 1rem;
    }
  }

  @media screen and (max-width: 800px) {
    .heading-style-h2._8vw-title.orbitheading{
        text-align: left;
        font-size: 1.4rem;
    }
    .elmseauremainp .heading-style-h2{
        margin-top: 0rem;
    }
  }
  .elmeasureheading{
    /* color: red; */
    font-size: 1.7rem;
    /* text-align: center; */
  }

  /* css new elmaesure */
  /* <style> */
 





.snap-parent-y-mandatory {
    scroll-snap-type: y mandatory;
    overflow: scroll;
    height: 100%;
    width: 100%;
    -webkit-overflow-scrolling: touch
}

.overline_body1 {
    font-size: 1rem
}

.overline_body1,.overline_body2 {
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .3rem
}

.overline_body2 {
    font-size: .75rem
}

.m_overline_header {
    font-size: 1.125rem;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: .3rem
}

.m_overline_body1 {
    font-size: .625rem
}

.m_overline_body1,.m_overline_body2 {
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .3rem
}

.m_overline_body2 {
    font-size: .5rem
}

.v_headingCountdown {
    font-size: 100px
}

.heading2,.v_headingCountdown {
    font-weight: 900;
    line-height: .97em;
    letter-spacing: -.03rem
}

.heading2 {
    font-size: 3.75rem
}

.heading3 {
    font-size: 3rem;
    font-weight: 700;
    line-height: .97em;
    letter-spacing: -.03rem
}

.m_heading1 {
    font-size: 3.75rem
}

.m_heading1,.m_heading2 {
    font-weight: 900;
    line-height: .97em;
    letter-spacing: -.03rem
}

.m_heading2 {
    font-size: 1.875rem
}

.m_heading3 {
    font-size: 1.625rem;
    font-weight: 700;
    line-height: 1.05em;
    letter-spacing: -.03rem
}

.m_heading4_bold {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.15em
}

.m_heading4_regular {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.15em
}

.m_heading4_allcaps {
    font-size: 1.25rem;
    font-weight: 900;
    text-transform: uppercase;
    line-height: .97em
}

.heading5_bold {
    font-size: 1.5rem;
    font-weight: 700
}

.m_heading5_bold {
    font-size: 1.125rem;
    font-weight: 400
}

.m_heading5_regular {
    font-size: 1.125rem;
    font-weight: 400
}

.m_heading5_allcaps {
    font-size: 1.125rem;
    font-weight: 900;
    text-transform: uppercase;
    line-height: .85em
}

.m_heading6_bold {
    font-size: 1rem;
    font-weight: 700
}

.paragraph {
    font-size: 1.125rem
}

.m_paragraph,.paragraph {
    font-weight: 400;
    line-height: 1.61em
}

.m_paragraph {
    font-size: 1rem
}

.m_paragraph_mini {
    font-size: 12px;
    line-height: 1.61em
}

.m_paragraph_light {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.61em
}

.m_section_header {
    font-size: 1.625rem
}

.m_section_header,.m_section_header2 {
    font-weight: 900;
    text-transform: uppercase;
    line-height: .85em
}

.m_section_header2 {
    font-size: 30px
}

.m_launch_cta {
    font-size: 70px
}

.m_cta_links,.m_launch_cta {
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: .3rem
}

.m_cta_links {
    font-size: 1.125rem
}

.m_paragraph_header {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.2em
}

.m_paragraph_mini {
    font-size: .75rem;
    font-weight: 400;
    line-height: 1.15em
}

.elm_blue_gradient_l_d {
    --tw-gradient-from: #004ea2;
    --tw-gradient-to: rgba(0,78,162,0);
    --tw-gradient-stops: var(--tw-gradient-from),var(--tw-gradient-to);
    --tw-gradient-to: #003f83
}

.elm_blue_gradient_d_l,.elm_blue_gradient_l_d {
    background-image: linear-gradient(to bottom,var(--tw-gradient-stops))
}

.elm_blue_gradient_d_l {
    --tw-gradient-from: #003064;
    --tw-gradient-to: rgba(0,48,100,0);
    --tw-gradient-stops: var(--tw-gradient-from),var(--tw-gradient-to);
    --tw-gradient-to: #0059b8
}

.elm_blue_gradient_d_l_dark {
    --tw-gradient-from: #004c9e;
    --tw-gradient-to: rgba(0,76,158,0);
    --tw-gradient-stops: var(--tw-gradient-from),var(--tw-gradient-to);
    --tw-gradient-to: #001c3a
}

.elm_blue_gradient_d_l_dark,.elm_green_gradient_d_l {
    background-image: linear-gradient(to bottom,var(--tw-gradient-stops))
}

.elm_green_gradient_d_l {
    --tw-gradient-from: #1a8746;
    --tw-gradient-to: rgba(26,135,70,0);
    --tw-gradient-stops: var(--tw-gradient-from),var(--tw-gradient-to);
    --tw-gradient-to: #55ce77
}

.elm_grey_gradient_l_d {
    background-image: linear-gradient(to bottom,var(--tw-gradient-stops));
    --tw-gradient-from: #1e1e1e;
    --tw-gradient-to: rgba(30,30,30,0);
    --tw-gradient-stops: var(--tw-gradient-from),var(--tw-gradient-to);
    --tw-gradient-to: #434343
}

.elm_black_gradient_overlay {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from),var(--tw-gradient-to);
    --tw-gradient-to: transparent
}

.elm_black_gradient_overlay,.elm_black_gradient_overlay_light {
    background-image: linear-gradient(to right,var(--tw-gradient-stops))
}

.elm_black_gradient_overlay_light {
    --tw-gradient-from: rgba(0,0,0,.5);
    --tw-gradient-stops: var(--tw-gradient-from),var(--tw-gradient-to);
    --tw-gradient-to: transparent
}

.elm_black_gradient_overlay_medium {
    background-image: linear-gradient(to right,var(--tw-gradient-stops));
    --tw-gradient-from: rgba(0,0,0,.8);
    --tw-gradient-stops: var(--tw-gradient-from),var(--tw-gradient-to);
    --tw-gradient-to: transparent
}

.elm_black_gradient_overlay_medium_bottom {
    background-image: linear-gradient(to top,var(--tw-gradient-stops));
    --tw-gradient-from: rgba(0,0,0,.7);
    --tw-gradient-stops: var(--tw-gradient-from),var(--tw-gradient-to);
    --tw-gradient-to: transparent
}

.elm_blue_dark_to_transparent {
    --tw-gradient-from: #004c9e;
    --tw-gradient-to: rgba(0,76,158,0);
    --tw-gradient-stops: var(--tw-gradient-from),var(--tw-gradient-to);
    --tw-gradient-to: transparent
}

.elm_blue_dark_to_transparent,.elm_blue_to_transparent {
    background-image: linear-gradient(to right,var(--tw-gradient-stops))
}

.elm_blue_to_transparent {
    --tw-gradient-from: #0059b8;
    --tw-gradient-to: rgba(0,89,184,0);
    --tw-gradient-stops: var(--tw-gradient-from),var(--tw-gradient-to);
    --tw-gradient-to: transparent
}

.elm_white_to_transparent {
    background-image: linear-gradient(to bottom,var(--tw-gradient-stops));
    --tw-gradient-from: #fff;
    --tw-gradient-to: hsla(0,0%,100%,0);
    --tw-gradient-stops: var(--tw-gradient-from),var(--tw-gradient-to);
    --tw-gradient-to: transparent
}

.elm_transparent_to_blue {
    background-image: linear-gradient(to right,var(--tw-gradient-stops));
    --tw-gradient-from: transparent;
    --tw-gradient-to: transparent;
    --tw-gradient-stops: var(--tw-gradient-from),var(--tw-gradient-to);
    --tw-gradient-to: rgba(16,121,212,.5)
}

.elm_blue_shaded {
    background-image: linear-gradient(to bottom,var(--tw-gradient-stops));
    --tw-gradient-from: #0262c6;
    --tw-gradient-to: rgba(2,98,198,0);
    --tw-gradient-stops: var(--tw-gradient-from),var(--tw-gradient-to);
    --tw-gradient-to: #027bd6
}

.elm_white_to_transparent_l_r {
    background-image: linear-gradient(to right,var(--tw-gradient-stops));
    --tw-gradient-from: #fff;
    --tw-gradient-to: hsla(0,0%,100%,0);
    --tw-gradient-stops: var(--tw-gradient-from),var(--tw-gradient-to);
    --tw-gradient-to: transparent
}

.productBorder {
    border-top: 1px solid transparent;
    -o-border-image: linear-gradient(90deg,transparent,hsla(0,0%,100%,.2)) 2;
    border-image: linear-gradient(90deg,transparent,hsla(0,0%,100%,.2)) 2
}

.navbarBorder,.productBorder {
    border-bottom: 1px solid transparent;
    border-right: 1px solid transparent
}

.navbarBorder {
    border-left: 1px solid transparent;
    -o-border-image: linear-gradient(180deg,transparent,hsla(0,0%,100%,.3)) 2;
    border-image: linear-gradient(180deg,transparent,hsla(0,0%,100%,.3)) 2
}

.gradientHR {
    -o-border-image: radial-gradient(#0262c6 5%,hsla(0,0%,100%,.5) 60%) 1;
    border-image: radial-gradient(#0262c6 5%,hsla(0,0%,100%,.5) 60%) 1
}

.gradientHR,.gradientHRWhite {
    border-bottom: 1px solid transparent
}

.gradientHRWhite {
    -o-border-image: radial-gradient(#fff 5%,hsla(0,0%,100%,0) 60%) 1;
    border-image: radial-gradient(#fff 5%,hsla(0,0%,100%,0) 60%) 1
}

.pe-scrollbar-style::-webkit-scrollbar {
    width: 4px;
    /* background-color: #f5f5f5 */
}

.pe-scrollbar-style::-webkit-scrollbar-thumb {
    border-radius: 0;
    box-shadow: inset 0 0 2px rgba(0,0,0,.1);
    background-color: rgba(16,121,212,.5)
}

.pe-scrollbar-style::-moz-scrollbar {
    width: 4px;
    /* background-color: #f5f5f5 */
}

.pe-scrollbar-style::-moz-scrollbar-thumb {
    border-radius: 0;
    box-shadow: inset 0 0 2px rgba(0,0,0,.1);
    /* background-color: #1079d4 */
}

.pe-scrollbar-style::-ms-scrollbar {
    width: 4px;
    /* background-color: #f5f5f5 */
}

.pe-scrollbar-style::-ms-scrollbar-thumb {
    border-radius: 2px;
    box-shadow: inset 0 0 2px rgba(0,0,0,.1);
    /* background-color: #1079d4 */
}

.svg-filter-blue {
    filter: color(#004ea2)
}

.animatedGradient {
    width: 100wh;
    height: 90vh;
    color: #fff;
    /* background: linear-gradient(146deg,#004ea2,#39aaff,#55ce77,#004ea2); */
    background-size: 400% 400%;
    animation: Gradient 20s ease infinite
}

@keyframes Gradient {
    0% {
        background-position: 0 50%
    }

    50% {
        background-position: 100% 50%
    }

    to {
        background-position: 0 50%
    }
}

.pointer-events-none {
    pointer-events: none
}

.visible {
    visibility: visible
}

.collapse {
    visibility: collapse
}

.static {
    position: static
}

.fixed {
    position: fixed
}

.absolute {
    position: absolute
}

.relative {
    position: relative
}

.sticky {
    position: sticky
}

.inset-y-0 {
    top: 0;
    bottom: 0
}

.top-0 {
    top: 0
}

.left-0 {
    left: 0
}

.right-\[50\%\] {
    right: 50%
}

.left-\[15\%\] {
    left: 15%
}

.top-\[20\%\] {
    top: 20%
}

.top-\[40\%\] {
    top: 40%
}

.right-\[25\%\] {
    right: 25%
}

.bottom-16 {
    bottom: 4rem
}

.left-1\/2 {
    left: 50%
}

.top-\[-100vh\] {
    top: -100vh
}

.top-\[67px\] {
    top: 67px
}

.right-\[-70\%\] {
    right: -70%
}

.top-1\/3 {
    top: 33.333333%
}

.left-5 {
    left: 1.25rem
}

.right-\[0\%\] {
    right: 0
}

.top-\[-60\%\] {
    top: -60%
}

.top-3\.5 {
    top: .875rem
}

.left-2\.5 {
    left: .625rem
}

.top-3 {
    top: .75rem
}

.left-2 {
    left: .5rem
}

.right-0 {
    right: 0
}

.top-10 {
    top: 2.5rem
}

.bottom-0 {
    bottom: 0
}

.left-10 {
    left: 2.5rem
}

.top-\[-10\%\] {
    top: -10%
}

.top-5 {
    top: 1.25rem
}

.left-6 {
    left: 1.5rem
}

.top-2 {
    top: .5rem
}

.top-12 {
    top: 3rem
}

.left-3 {
    left: .75rem
}

.top-\[-50px\] {
    top: -50px
}

.top-\[-5px\] {
    top: -5px
}

.left-\[-100\%\] {
    left: -100%
}

.top-\[70\%\] {
    top: 70%
}

.left-\[30\%\] {
    left: 30%
}

.top-\[-30px\] {
    top: -30px
}

.right-2 {
    right: .5rem
}

.top-\[3px\] {
    top: 3px
}

.right-\[2px\] {
    right: 2px
}

.bottom-1 {
    bottom: .25rem
}

.z-20 {
    z-index: 20
}

.z-50 {
    z-index: 50
}

.z-0 {
    z-index: 0
}

.z-30 {
    z-index: 30
}

.z-10 {
    z-index: 10
}

.order-2 {
    order: 2
}

.order-1 {
    order: 1
}

.col-span-1 {
    grid-column: span 1/span 1
}

.row-span-1 {
    grid-row: span 1/span 1
}

.row-span-3 {
    grid-row: span 3/span 3
}

.m-1 {
    margin: .25rem
}

.mx-auto {
    margin-left: auto;
    margin-right: auto
}

.my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem
}

.my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem
}

.my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem
}

.my-2 {
    margin-top: .5rem;
    margin-bottom: .5rem
}

.my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem
}

.my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem
}

.my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem
}

.my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem
}

.my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem
}

.mx-4 {
    margin-left: 1rem;
    margin-right: 1rem
}

.my-1 {
    margin-top: .25rem;
    margin-bottom: .25rem
}

.my-\[4\%\] {
    margin-top: 4%;
    margin-bottom: 4%
}

.my-auto {
    margin-top: auto;
    margin-bottom: auto
}

.mt-\[-110px\] {
    margin-top: -110px
}

.mb-10 {
    margin-bottom: 2.5rem
}

.mb-5 {
    margin-bottom: 1.25rem
}

.mt-10 {
    margin-top: 2.5rem
}

.mt-5 {
    margin-top: 1.25rem
}

.mb-6 {
    margin-bottom: 1.5rem
}

.mb-2 {
    margin-bottom: .5rem
}

.mb-4 {
    margin-bottom: 1rem
}

.mt-\[-100px\] {
    margin-top: -100px
}

.mb-9 {
    margin-bottom: 2.25rem
}

.mt-14 {
    margin-top: 3.5rem
}

.mt-24 {
    margin-top: 6rem
}

.mb-20 {
    margin-bottom: 5rem
}

.mb-24 {
    margin-bottom: 6rem
}

.mt-12 {
    margin-top: 3rem
}

.ml-6 {
    margin-left: 1.5rem
}

.mt-8 {
    margin-top: 2rem
}

.ml-8 {
    margin-left: 2rem
}

.mt-\[-15px\] {
    margin-top: -15px
}

.ml-\[10\%\] {
    margin-left: 10%
}

.mt-6 {
    margin-top: 1.5rem
}

.mb-16 {
    margin-bottom: 4rem
}

.mt-2 {
    margin-top: .5rem
}

.mt-\[-6px\] {
    margin-top: -6px
}

.mt-3 {
    margin-top: .75rem
}

.mt-4 {
    margin-top: 1rem
}

.mt-20 {
    margin-top: 5rem
}

.mt-\[-4px\] {
    margin-top: -4px
}

.mb-8 {
    margin-bottom: 2rem
}

.mt-1 {
    margin-top: .25rem
}

.mt-16 {
    margin-top: 4rem
}

.mb-36 {
    margin-bottom: 9rem
}

.mt-\[-50px\] {
    margin-top: -50px
}

.mb-\[2px\] {
    margin-bottom: 2px
}

.mt-9 {
    margin-top: 2.25rem
}

.mb-1 {
    margin-bottom: .25rem
}

.mb-0 {
    margin-bottom: 0
}

.mt-0 {
    margin-top: 0
}

.mr-4 {
    margin-right: 1rem
}

.mt-\[-2px\] {
    margin-top: -2px
}

.ml-4 {
    margin-left: 1rem
}

.mb-3 {
    margin-bottom: .75rem
}

.mb-3\.5 {
    margin-bottom: .875rem
}

.ml-10 {
    margin-left: 2.5rem
}

.mb-\[12px\] {
    margin-bottom: 12px
}

.mb-\[-4px\] {
    margin-bottom: -4px
}

.ml-0 {
    margin-left: 0
}

.mb-\[-10px\] {
    margin-bottom: -10px
}

.mb-2\.5 {
    margin-bottom: .625rem
}

.ml-3 {
    margin-left: .75rem
}

.ml-2 {
    margin-left: .5rem
}

.mt-\[-67px\] {
    margin-top: -67px
}

.mb-40 {
    margin-bottom: 10rem
}

.mb-\[-6px\] {
    margin-bottom: -6px
}

.mt-\[6px\] {
    margin-top: 6px
}

.mr-3 {
    margin-right: .75rem
}

.block {
    display: block
}

.inline-block {
    display: inline-block
}

.flex {
    display: flex
}

.grid {
    display: grid
}

.hidden {
    display: none
}

.h-\[200vh\] {
    height: 200vh
}

.h-screen {
    height: 100vh
}

.h-full {
    height: 100%
}

.h-\[250px\] {
    height: 250px
}

.h-6 {
    height: 1.5rem
}

.h-16 {
    height: 4rem
}

.h-10 {
    height: 2.5rem
}

.h-\[544px\] {
    height: 544px
}

.h-\[645px\] {
    height: 645px
}

.h-56 {
    height: 14rem
}

.h-\[600px\] {
    height: 600px
}

.h-\[300px\] {
    height: 300px
}

.h-\[234px\] {
    height: 234px
}

.h-\[200px\] {
    height: 200px
}

.h-\[330px\] {
    height: 330px
}

.h-64 {
    height: 16rem
}

.h-4 {
    height: 1rem
}

.h-2\.5 {
    height: .625rem
}

.h-2 {
    height: .5rem
}

.h-3 {
    height: .75rem
}

.h-\[18px\] {
    height: 18px
}

.h-\[1px\] {
    height: 1px;
     background: linear-gradient(135deg, #5C5C5C 0%, rgba(92, 92, 92, 0.00) 100%);;
}

.h-\[325px\] {
    height: 325px
}

.h-\[201px\] {
    height: 201px
}

.h-\[148px\] {
    height: 148px
}

.h-\[50px\] {
    height: 50px
}

.h-\[265px\] {
    height: 265px
}

.h-\[630px\] {
    height: 630px
}

.h-\[188px\] {
    height: 188px
}

.h-\[52px\] {
    height: 52px
}

.h-\[8px\] {
    height: 8px
}

.h-\[22px\] {
    height: 22px
}

.h-\[15px\] {
    height: 15px
}

.h-\[19px\] {
    height: 19px
}

.h-\[60px\] {
    height: 60px
}

.h-8 {
    height: 2rem
}

.h-\[28px\] {
    height: 28px
}

.h-\[244px\] {
    height: 244px
}

.h-\[440px\] {
    height: 440px
}

.h-\[155px\] {
    height: 155px
}

.h-\[344px\] {
    height: 344px
}

.h-\[346px\] {
    height: 346px
}

.h-\[286px\] {
    height: 286px
}

.h-\[170px\] {
    height: 170px
}

.h-\[64px\] {
    height: 64px
}

.h-\[48px\] {
    height: 48px
}

.h-\[46px\] {
    height: 46px
}

.h-\[14px\] {
    height: 14px
}

.h-\[12px\] {
    height: 12px
}

.h-\[11px\] {
    height: 11px
}

.h-\[24px\] {
    height: 24px
}

.h-\[80px\] {
    height: 80px
}

.h-\[31px\] {
    height: 31px
}

.h-max {
    height: -moz-max-content;
    height: max-content
}

.h-\[20px\] {
    height: 20px
}

.h-5 {
    height: 1.25rem
}

.h-\[180px\] {
    height: 180px
}

.h-\[10px\] {
    height: 10px
}

.h-\[154px\] {
    height: 154px
}

.h-\[402px\] {
    height: 402px
}

.h-\[482px\] {
    height: 482px
}

.h-20 {
    height: 5rem
}

.h-24 {
    height: 6rem
}

.h-12 {
    height: 3rem
}

.h-\[42px\] {
    height: 42px
}

.h-\[120px\] {
    height: 120px
}

.h-\[100px\] {
    height: 100px
}

.h-\[35px\] {
    height: 35px
}

.h-1 {
    height: .25rem
}

.h-7 {
    height: 1.75rem
}

.h-14 {
    height: 3.5rem
}

.h-\[30px\] {
    height: 30px
}

.h-\[4px\] {
    height: 4px
}

.h-\[160px\] {
    height: 160px
}

.h-\[16px\] {
    height: 16px
}

.h-\[112px\] {
    height: 112px
}

.h-\[70px\] {
    height: 70px
}

.h-\[140px\] {
    height: 140px
}

.h-\[130px\] {
    height: 130px
}

.h-\[136px\] {
    height: 136px
}

.h-\[370px\] {
    height: 370px
}

.h-\[98px\] {
    height: 98px
}

.max-h-60 {
    max-height: 15rem
}

.w-screen {
    width: 100vw
}

.w-2\/3 {
    width: 66.666667%
}

.w-full {
    width: 100%
}

.w-3\/5 {
    width: 60%
}

.w-\[250px\] {
    width: 250px
}

.w-6 {
    width: 1.5rem
}

.w-11\/12 {
    width: 91.666667%
}

.w-24 {
    width: 6rem
}

.w-\[300px\] {
    width: 300px
}

.w-4\/5 {
    width: 80%
}

.w-\[1px\] {
    width: 1px
}

.w-\[18px\] {
    width: 18px
}

.w-\[117px\] {
    width: 117px
}

.w-\[148px\] {
    width: 148px
}

.w-\[50px\] {
    width: 50px
}

.w-\[188px\] {
    width: 188px
}

.w-\[223px\] {
    width: 223px
}

.w-\[8px\] {
    width: 8px
}

.w-\[22px\] {
    width: 22px
}

.w-\[15px\] {
    width: 15px
}

.w-\[19px\] {
    width: 19px
}

.w-\[200px\] {
    width: 200px
}

.w-8 {
    width: 2rem
}

.w-1\/4 {
    width: 25%
}

.w-10 {
    width: 2.5rem
}

.w-\[400px\] {
    width: 400px
}

.w-\[320px\] {
    width: 320px
}

.w-\[450px\] {
    width: 450px
}

.w-1\/2 {
    width: 50%
}

.w-\[170px\] {
    width: 170px
}

.w-\[275px\] {
    width: 275px
}

.w-\[48px\] {
    width: 48px
}

.w-\[46px\] {
    width: 46px
}

.w-\[16px\] {
    width: 16px
}

.w-\[11px\] {
    width: 11px
}

.w-\[10px\] {
    width: 10px
}

.w-1\/3 {
    width: 33.333333%
}

.w-\[24px\] {
    width: 24px
}

.w-\[80px\] {
    width: 80px
}

.w-\[45px\] {
    width: 45px
}

.w-\[20px\] {
    width: 20px
}

.w-10\/12 {
    width: 83.333333%
}

.w-2 {
    width: .5rem
}

.w-max {
    width: -moz-max-content;
    width: max-content
}

.w-2\.5 {
    width: .625rem
}

.w-5 {
    width: 1.25rem
}

.w-4 {
    width: 1rem
}

.w-\[330px\] {
    width: 330px
}

.w-\[420px\] {
    width: 420px
}

.w-\[152px\] {
    width: 152px
}

.w-3 {
    width: .75rem
}

.w-\[4px\] {
    width: 4px
}

.w-\[160px\] {
    width: 160px
}

.w-\[12px\] {
    width: 12px
}

.w-\[112px\] {
    width: 112px
}

.w-\[70px\] {
    width: 70px
}

.w-\[30px\] {
    width: 30px
}

.w-\[140px\] {
    width: 140px
}

.w-\[130px\] {
    width: 130px
}

.w-14 {
    width: 3.5rem
}

.w-\[136px\] {
    width: 136px
}

.w-\[180px\] {
    width: 180px
}

.w-\[370px\] {
    width: 370px
}

.w-3\/4 {
    width: 75%
}

.w-\[100px\] {
    width: 100px
}

.w-\[28px\] {
    width: 28px
}

.flex-1 {
    flex: 1 1 0%
}

.flex-shrink-0,.shrink-0 {
    flex-shrink: 0
}

.basis-full {
    flex-basis: 100%
}

.basis-1\/2 {
    flex-basis: 50%
}

.basis-3\/5 {
    flex-basis: 60%
}

.basis-5\/12 {
    flex-basis: 41.666667%
}

.basis-7\/12 {
    flex-basis: 58.333333%
}

.basis-1\/3 {
    flex-basis: 33.333333%
}

.basis-3\/4 {
    flex-basis: 75%
}

.basis-1\/4 {
    flex-basis: 25%
}

.basis-4\/5 {
    flex-basis: 80%
}

.-rotate-90 {
    --tw-rotate: -90deg
}

.-rotate-90,.rotate-180 {
    transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.rotate-180 {
    --tw-rotate: 180deg
}

.rotate-\[270deg\] {
    --tw-rotate: 270deg
}

.rotate-45,.rotate-\[270deg\] {
    transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.rotate-45 {
    --tw-rotate: 45deg
}

.transform {
    transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

@keyframes pulse {
    50% {
        opacity: .5
    }
}

.animate-pulse {
    animation: pulse 2s cubic-bezier(.4,0,.6,1) infinite
}

@keyframes bounce {
    0%,to {
        transform: translateY(-25%);
        animation-timing-function: cubic-bezier(.8,0,1,1)
    }

    50% {
        transform: none;
        animation-timing-function: cubic-bezier(0,0,.2,1)
    }
}

.animate-bounce {
    animation: bounce 1s infinite
}

.cursor-pointer {
    cursor: pointer
}

.cursor-default {
    cursor: default
}

.cursor-not-allowed {
    cursor: not-allowed
}

.select-none {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.resize-none {
    resize: none
}

.resize {
    resize: both
}

.list-disc {
    list-style-type: disc
}

.list-decimal {
    list-style-type: decimal
}

.appearance-none {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

.grid-flow-row {
    grid-auto-flow: row
}

.grid-cols-1 {
    grid-template-columns: repeat(1,minmax(0,1fr))
}

.grid-cols-2 {
    grid-template-columns: repeat(2,minmax(0,1fr))
}

.grid-cols-3 {
    grid-template-columns: repeat(3,minmax(0,1fr))
}

.grid-rows-2 {
    grid-template-rows: repeat(2,minmax(0,1fr))
}

.grid-rows-1 {
    grid-template-rows: repeat(1,minmax(0,1fr))
}

.grid-rows-4 {
    grid-template-rows: repeat(4,minmax(0,1fr))
}

.flex-row {
    flex-direction: row
}

.flex-col {
    flex-direction: column
}

.flex-col-reverse {
    flex-direction: column-reverse
}

.flex-wrap {
    flex-wrap: wrap
}

.items-start {
    align-items: flex-start
}

.items-end {
    align-items: flex-end
}

.items-center {
    align-items: center
}

.items-baseline {
    align-items: baseline
}

.items-stretch {
    align-items: stretch
}

.justify-start {
    justify-content: flex-start
}

.justify-end {
    justify-content: flex-end
}

.justify-center {
    justify-content: center
}

.justify-between {
    justify-content: space-between
}

.gap-10 {
    gap: 2.5rem
}

.gap-8 {
    gap: 2rem
}

.gap-4 {
    gap: 1rem
}

.gap-5 {
    gap: 1.25rem
}

.gap-2 {
    gap: .5rem
}

.gap-9 {
    gap: 2.25rem
}

.gap-6 {
    gap: 1.5rem
}

.gap-12 {
    gap: 3rem
}

.gap-20 {
    gap: 5rem
}

.gap-1 {
    gap: .25rem
}

.gap-3 {
    gap: .75rem
}

.gap-16 {
    gap: 4rem
}

.gap-\[12px\] {
    gap: 12px
}

.gap-32 {
    gap: 8rem
}

.gap-x-2 {
    -moz-column-gap: .5rem;
    column-gap: .5rem
}

.gap-x-10 {
    -moz-column-gap: 2.5rem;
    column-gap: 2.5rem
}

.gap-y-8 {
    row-gap: 2rem
}

.gap-x-5 {
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem
}

.gap-y-12 {
    row-gap: 3rem
}

.gap-x-8 {
    -moz-column-gap: 2rem;
    column-gap: 2rem
}

.gap-y-10 {
    row-gap: 2.5rem
}

.gap-y-6 {
    row-gap: 1.5rem
}

.gap-x-12 {
    -moz-column-gap: 3rem;
    column-gap: 3rem
}

.gap-x-4 {
    -moz-column-gap: 1rem;
    column-gap: 1rem;
    /* margin-bottom: 5rem; */
    /* margin-top: -3rem; */
}

.gap-y-1 {
    row-gap: .25rem
}

.space-y-4>:not([hidden])~:not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse))
}

.space-x-1>:not([hidden])~:not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.25rem * var(--tw-space-x-reverse));
    margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)))
}

.self-stretch {
    align-self: stretch
}

.overflow-hidden {
    overflow: hidden
}

.overflow-clip {
    overflow: clip
}

.overflow-y-auto {
    overflow-y: auto
}

.overflow-x-clip {
    overflow-x: clip
}

.overflow-y-scroll {
    overflow-y: scroll
}

.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.rounded-full {
    border-radius: 100%
}

.rounded-\[30px\] {
    border-radius: 30px
}

.rounded-\[2px\] {
    border-radius: 2px
}

.rounded-r-\[8px\] {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px
}

.rounded-l-\[8px\] {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px
}

.rounded-bl-\[20px\] {
    border-bottom-left-radius: 20px
}

.rounded-tl-\[25px\] {
    border-top-left-radius: 25px
}

.rounded-br-\[25px\] {
    border-bottom-right-radius: 25px
}

.rounded-br-\[10px\] {
    border-bottom-right-radius: 10px
}

.rounded-bl-\[8px\] {
    border-bottom-left-radius: 8px
}

.rounded-tr-\[8px\] {
    border-top-right-radius: 8px
}

.rounded-br-\[0px\] {
    border-bottom-right-radius: 0
}

.border-\[24px\] {
    border-width: 24px
}

.border-2 {
    border-width: 2px
}

.border,.border-\[1px\] {
    border-width: 1px
}

.border-0 {
    border-width: 0
}

.border-b,.border-b-\[1px\] {
    border-bottom-width: 1px
}

.border-t {
    border-top-width: 1px
}

.border-r {
    border-right-width: 1px
}

.border-l {
    border-left-width: 1px
}

.border-elm-green-light {
    --tw-border-opacity: 1;
    border-color: rgb(85 206 119/var(--tw-border-opacity))
}

.border-elm-blue {
    --tw-border-opacity: 1;
    border-color: rgb(57 170 255/var(--tw-border-opacity))
}

.border-elm-blue-dark {
    --tw-border-opacity: 1;
    border-color: rgb(0 78 162/var(--tw-border-opacity))
}

.border-elm-grey-light {
    --tw-border-opacity: 1;
    border-color: rgb(168 168 168/var(--tw-border-opacity))
}

.border-\[\#EDEDED\] {
    --tw-border-opacity: 1;
    border-color: rgb(237 237 237/var(--tw-border-opacity))
}

.border-elm-grey\/20 {
    border-color: rgba(68,68,68,.2)
}

.border-\[\#61A7FF\],.border-elm-blue-light {
    --tw-border-opacity: 1;
    border-color: rgb(97 167 255/var(--tw-border-opacity))
}

.border-b-elm-grey-light\/50 {
    border-bottom-color: hsla(0,0%,66%,.5)
}

.border-r-elm-white\/30 {
    border-right-color: hsla(0,0%,100%,.3)
}

.border-t-elm-grey\/20 {
    border-top-color: rgba(68,68,68,.2)
}

.bg-elm-white {
    --tw-bg-opacity: 1;
    /* background-color: rgb(255 255 255/var(--tw-bg-opacity)) */
}

.bg-elm-green-light {
    --tw-bg-opacity: 1;
    /* background-color: rgb(85 206 119/var(--tw-bg-opacity)) */
}

.bg-elm-black {
    --tw-bg-opacity: 1;
    /* background-color: rgb(0 0 0/var(--tw-bg-opacity)) */
}

.bg-elm-blue {
    --tw-bg-opacity: 1;
    /* background-color: rgb(57 170 255/var(--tw-bg-opacity)) */
}

.bg-\[\#F5F5F5\] {
    --tw-bg-opacity: 1;
    /* background-color: rgb(245 245 245/var(--tw-bg-opacity)) */
}

.bg-elm-blue-dark {
    --tw-bg-opacity: 1;
    /* background-color: rgb(0 78 162/var(--tw-bg-opacity)) */
}

.bg-\[\#1079D4\] {
    --tw-bg-opacity: 1;
    /* background-color: rgb(16 121 212/var(--tw-bg-opacity)) */
}

.bg-elm-blue-light\/20 {
    /* background-color: rgba(97,167,255,.2) */
}



.from-\[\#FFFFFF\] {
    --tw-gradient-from: #fff;
    --tw-gradient-to: hsla(0,0%,100%,0);
    --tw-gradient-stops: var(--tw-gradient-from),var(--tw-gradient-to)
}

.from-\[\#F3F3F3\] {
    --tw-gradient-from: #f3f3f3;
    --tw-gradient-to: hsla(0,0%,95%,0);
    --tw-gradient-stops: var(--tw-gradient-from),var(--tw-gradient-to)
}

.from-\[\#060606\] {
    --tw-gradient-from: #060606;
    --tw-gradient-to: rgba(6,6,6,0);
    --tw-gradient-stops: var(--tw-gradient-from),var(--tw-gradient-to)
}

.from-\[\#131313\] {
    --tw-gradient-from: #131313;
    --tw-gradient-to: hsla(0,0%,7%,0);
    --tw-gradient-stops: var(--tw-gradient-from),var(--tw-gradient-to)
}

.from-\[\#0059B8\] {
    --tw-gradient-from: #0059b8;
    --tw-gradient-to: rgba(0,89,184,0);
    --tw-gradient-stops: var(--tw-gradient-from),var(--tw-gradient-to)
}

.to-transparent {
    --tw-gradient-to: transparent
}

.to-\[\#F1F1F1\] {
    --tw-gradient-to: #f1f1f1
}

.to-\[\#111111\] {
    --tw-gradient-to: #111
}

.to-\[\#353535\] {
    --tw-gradient-to: #353535
}

.bg-cover {
    background-size: cover
}

.bg-left-bottom {
    background-position: 0 100%
}

.bg-right-top {
    background-position: 100% 0
}

.fill-elm-blue {
    fill: #39aaff
}

.fill-elm-white {
    fill: #fff
}

.fill-elm-blue-dark {
    fill: #004ea2
}

.stroke-elm-blue {
    stroke: #39aaff
}

.stroke-elm-white {
    stroke: #fff
}

.object-contain {
    -o-object-fit: contain;
    object-fit: contain
}

.object-cover {
    -o-object-fit: cover;
    object-fit: cover
}

.p-10 {
    padding: 2.5rem
}

.p-3 {
    padding: .75rem
}

.p-2 {
    padding: .5rem
}

.p-5 {
    padding: 1.25rem
}

.p-4 {
    padding: 1rem
}

.p-7 {
    padding: 1.75rem
}

.p-2\.5 {
    padding: .625rem
}

.py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem
}

.px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem
}

.px-4 {
    padding-left: 1rem;
    padding-right: 1rem
}

.py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem
}

.py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem
}

.px-3 {
    padding-left: .75rem;
    padding-right: .75rem
}

.py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem
}

.py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem
}

.py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem
}

.py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem
}

.py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem
}

.px-2 {
    padding-left: .5rem;
    padding-right: .5rem
}

.py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem
}

.py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem
}

.px-8 {
    padding-left: 2rem;
    padding-right: 2rem
}

.py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem
}

.px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem
}

.px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem
}

.py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem
}

.px-1 {
    padding-left: .25rem;
    padding-right: .25rem
}

.px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem
}

.py-1 {
    padding-top: .25rem;
    padding-bottom: .25rem
}

.py-2\.5 {
    padding-top: .625rem;
    padding-bottom: .625rem
}

.px-2\.5 {
    padding-left: .625rem;
    padding-right: .625rem
}

.py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem
}

.py-0 {
    padding-top: 0;
    padding-bottom: 0
}

.px-\[30px\] {
    padding-left: 30px;
    padding-right: 30px
}

.py-\[10px\] {
    padding-top: 10px;
    padding-bottom: 10px
}

.px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem
}

.py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem
}

.px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem
}

.pl-3 {
    padding-left: .75rem
}

.pb-6 {
    padding-bottom: 1.5rem
}

.pb-12 {
    padding-bottom: 3rem
}

.pl-4 {
    padding-left: 1rem
}

.pt-3 {
    padding-top: .75rem
}

.pr-8 {
    padding-right: 2rem
}

.pl-8 {
    padding-left: 2rem
}

.pt-24 {
    padding-top: 6rem
}

.pb-10 {
    padding-bottom: 2.5rem
}

.pt-8 {
    padding-top: 2rem
}

.pb-16 {
    padding-bottom: 4rem
}

.pl-14 {
    padding-left: 3.5rem
}

.pt-4 {
    padding-top: 1rem
}

.pb-2 {
    padding-bottom: .5rem
}

.pr-10 {
    padding-right: 2.5rem
}

.pr-2 {
    padding-right: .5rem
}

.pl-9 {
    padding-left: 2.25rem
}

.pl-10 {
    padding-left: 2.5rem
}

.pr-4 {
    padding-right: 1rem
}

.pl-2 {
    padding-left: .5rem
}

.pt-5 {
    padding-top: 1.25rem
}

.pt-36 {
    padding-top: 9rem
}

.pb-32 {
    padding-bottom: 8rem
}

.text-left {
    text-align: left
}

.text-center {
    text-align: center
}

.text-right {
    text-align: right
}

.text-\[150px\] {
    font-size: 150px
}

.text-\[18px\] {
    font-size: 18px
}

.text-\[10px\] {
    font-size: 10px
}

.text-lg {
    font-size: 1.125rem
}

.text-\[8px\] {
    font-size: 8px
}

.text-\[13px\] {
    font-size: 13px
}

.text-\[14px\] {
    /* font-size: 14px
     */
     font-size: 8px;
}

.text-xl {
    font-size: 1.25rem
}

.text-\[12px\] {
    font-size: 12px
}

.text-\[34px\] {
    font-size: 34px
}

.text-2xl {
    font-size: 1.5rem
}

.text-\[16px\] {
    font-size: 16px
}

.text-sm {
    font-size: .9375rem
}

.text-\[24px\] {
    font-size: 24px
}

.text-xs {
    font-size: .75rem
}

.text-\[15px\] {
    font-size: 15px
}

.text-\[39px\] {
    font-size: 39px
}

.font-black {
    font-weight: 900
}

.font-bold {
    font-weight: 500
}

.font-extrabold {
    font-weight: 800
}

.font-light {
    font-weight: 300
}

.font-\[14px\] {
    font-weight: 14px
}

.font-semibold {
    font-weight: 600
}

.font-normal {
    font-weight: 400
}

.font-medium {
    font-weight: 500
}

.font-\[12px\] {
    font-weight: 12px
}

.uppercase {
    text-transform: uppercase
}

.italic {
    font-style: italic
}

.leading-none {
    line-height: 1
}

.leading-5 {
    line-height: 1.25rem
}

.leading-\[97\%\] {
    line-height: 97%
}

.leading-\[130\%\] {
    line-height: 130%
}

.leading-\[1\.15em\] {
    line-height: 1.15em
}

.leading-tight {
    line-height: 1.25
}

.leading-3 {
    line-height: .75rem
}

.tracking-wide {
    letter-spacing: .15rem
}

.tracking-wider {
    letter-spacing: .3rem
}

.tracking-widest {
    letter-spacing: .5rem
}

.tracking-\[97\%\] {
    letter-spacing: 97%
}

.tracking-\[-3\%\] {
    letter-spacing: -3%
}

.tracking-\[-0\.2rem\] {
    letter-spacing: -.2rem
}

.text-elm-grey\/10 {
    color: rgba(68,68,68,.1)
}

.text-elm-blue-dark {
    --tw-text-opacity: 1;
    color: rgb(0 78 162/var(--tw-text-opacity))
}

.text-elm-blue {
    --tw-text-opacity: 1;
    color: rgb(57 170 255/var(--tw-text-opacity))
}

.text-elm-blue-light {
    --tw-text-opacity: 1;
    color: rgb(97 167 255/var(--tw-text-opacity))
}

.text-elm-green-light {
    --tw-text-opacity: 1;
    color: rgb(85 206 119/var(--tw-text-opacity))
}

.text-elm-grey {
    --tw-text-opacity: 1;
    color: rgb(68 68 68/var(--tw-text-opacity))
}

.text-elm-white {
    --tw-text-opacity: 1;
    /* color: rgb(255 255 255/var(--tw-text-opacity)) */
}

.text-elm-grey-medium {
    --tw-text-opacity: 1;
    color: rgb(104 104 104/var(--tw-text-opacity))
}

.text-\[\#B7B7B7\] {
    --tw-text-opacity: 1;
    color: rgb(183 183 183/var(--tw-text-opacity))
}

.text-elm-green {
    --tw-text-opacity: 1;
    color: rgb(41 163 90/var(--tw-text-opacity))
}

.text-elm-grey-light {
    --tw-text-opacity: 1;
    color: rgb(168 168 168/var(--tw-text-opacity))
}

.text-elm-white\/50 {
    color: hsla(0,0%,100%,.5)
}

.text-\[\#AB0200\] {
    --tw-text-opacity: 1;
    color: rgb(171 2 0/var(--tw-text-opacity))
}

.text-elm-grey-light\/50 {
    color: hsla(0,0%,66%,.5)
}

.text-elm-blue\/50 {
    color: rgba(57,170,255,.5)
}

.text-elm-blue-dark\/50 {
    color: rgba(0,78,162,.5)
}

.text-elm-green-dark {
    --tw-text-opacity: 1;
    color: rgb(28 112 62/var(--tw-text-opacity))
}

.text-elm-white\/70 {
    color: hsla(0,0%,100%,.7)
}

.text-\[\#ff0f0f\] {
    --tw-text-opacity: 1;
    color: rgb(255 15 15/var(--tw-text-opacity))
}

.text-\[\#8FA3BC\] {
    --tw-text-opacity: 1;
    color: rgb(143 163 188/var(--tw-text-opacity))
}

.underline {
    text-decoration-line: underline
}

.overline {
    text-decoration-line: overline
}

.opacity-75 {
    opacity: .75
}

.opacity-10 {
    opacity: .1
}

.opacity-100 {
    opacity: 1
}

.opacity-30 {
    opacity: .3
}

.opacity-25 {
    opacity: .25
}

.opacity-50 {
    opacity: .5
}

.opacity-80 {
    opacity: .8
}

.opacity-90 {
    opacity: .9
}

.opacity-20 {
    opacity: .2
}

.opacity-0 {
    opacity: 0
}

.opacity-5 {
    opacity: .05
}

.mix-blend-multiply {
    mix-blend-mode: multiply
}

.mix-blend-screen {
    mix-blend-mode: screen
}

.mix-blend-overlay {
    mix-blend-mode: overlay
}

.mix-blend-hard-light {
    mix-blend-mode: hard-light
}

.shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0,0,0,.05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color)
}

.shadow-lg,.shadow-sm {
    box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow)
}

.shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0,0,0,.1),0 4px 6px -4px rgba(0,0,0,.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),0 4px 6px -4px var(--tw-shadow-color)
}

.outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px
}

.outline {
    outline-style: solid
}

.outline-1 {
    outline-width: 1px
}

.outline-elm-blue-dark\/20 {
    outline-color: rgba(0,78,162,.2)
}

.ring-transparent {
    --tw-ring-color: transparent
}

.blur {
    --tw-blur: blur(8px)
}

.blur,.drop-shadow-lg {
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.drop-shadow-lg {
    --tw-drop-shadow: drop-shadow(0 10px 8px rgba(0,0,0,.04)) drop-shadow(0 4px 3px rgba(0,0,0,.1))
}

.grayscale {
    --tw-grayscale: grayscale(100%)
}

.filter,.grayscale {
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.transition {
    transition-property: color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,-webkit-backdrop-filter;
    transition-property: color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter;
    transition-property: color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .15s
}

.transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .15s
}

.transition-colors {
    transition-property: color,background-color,border-color,text-decoration-color,fill,stroke;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .15s
}

.delay-1000 {
    transition-delay: 1s
}

.delay-\[2000\] {
    transition-delay: 2000
}

.duration-150 {
    transition-duration: .15s
}

.duration-100 {
    transition-duration: .1s
}

.duration-200 {
    transition-duration: .2s
}

.duration-300 {
    transition-duration: .3s
}

.duration-500 {
    transition-duration: .5s
}

.duration-75 {
    transition-duration: 75ms
}

.ease-in-out {
    transition-timing-function: cubic-bezier(.4,0,.2,1)
}

.ease-in {
    transition-timing-function: cubic-bezier(.4,0,1,1)
}

@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,300;0,400;0,600;0,700;0,900;1,300;1,400;1,600;1,700;1,900&display=swap");*>* {
    font-family: Source Sans Pro,sans-serif
}

.placeholder\:m_overline_body1::-moz-placeholder {
    font-size: .625rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .3rem
}

.placeholder\:m_overline_body1::placeholder {
    font-size: .625rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .3rem
}

.placeholder\:text-elm-blue-dark\/30::-moz-placeholder {
    color: rgba(0,78,162,.3)
}

.placeholder\:text-elm-blue-dark\/30::placeholder {
    color: rgba(0,78,162,.3)
}

.placeholder\:text-elm-blue-light::-moz-placeholder {
    --tw-text-opacity: 1;
    color: rgb(97 167 255/var(--tw-text-opacity))
}

.placeholder\:text-elm-blue-light::placeholder {
    --tw-text-opacity: 1;
    color: rgb(97 167 255/var(--tw-text-opacity))
}

.hover\:ml-\[8\%\]:hover {
    margin-left: 8%
}

.hover\:scale-105:hover {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
    /* transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) */
}

.hover\:bg-elm-blue-dark:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(0 78 162/var(--tw-bg-opacity))
}

.hover\:bg-elm-blue:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(57 170 255/var(--tw-bg-opacity))
}

.hover\:bg-elm-green-light:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(85 206 119/var(--tw-bg-opacity))
}

.hover\:bg-elm-green-dark:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(28 112 62/var(--tw-bg-opacity))
}

.hover\:bg-\[\#F8F8F8\]:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(248 248 248/var(--tw-bg-opacity))
}

.hover\:bg-elm-white\/90:hover {
    background-color: hsla(0,0%,100%,.9)
}

.hover\:bg-elm-green:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(41 163 90/var(--tw-bg-opacity))
}

.hover\:bg-elm-green-light\/20:hover {
    background-color: rgba(85,206,119,.2)
}

.hover\:fill-elm-green-light:hover {
    fill: #55ce77
}

.hover\:text-elm-grey-medium:hover {
    --tw-text-opacity: 1;
    color: rgb(104 104 104/var(--tw-text-opacity))
}

.hover\:text-elm-green-light:hover {
    --tw-text-opacity: 1;
    color: rgb(85 206 119/var(--tw-text-opacity))
}

.hover\:text-elm-grey-light:hover {
    --tw-text-opacity: 1;
    color: rgb(168 168 168/var(--tw-text-opacity))
}

.hover\:text-elm-white:hover {
    --tw-text-opacity: 1;
    /* color: rgb(255 255 255/var(--tw-text-opacity)) */
}

.hover\:opacity-75:hover {
    /* opacity: .75 */
}

.hover\:opacity-90:hover {
    /* opacity: .9 */
}

.hover\:opacity-50:hover {
    /* opacity: .5 */
}

.hover\:opacity-100:hover {
    opacity: 1
}

.hover\:shadow-sm:hover {
    --tw-shadow: 0 1px 2px 0 rgba(0,0,0,.05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow)
}

.hover\:shadow-elm-grey-medium\/20:hover {
    --tw-shadow-color: hsla(0,0%,41%,.2);
    --tw-shadow: var(--tw-shadow-colored)
}

.hover\:grayscale-0:hover {
    --tw-grayscale: grayscale(0)
}

.hover\:grayscale-0:hover,.hover\:saturate-\[1\.3\]:hover {
    /* filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) */
}

.hover\:saturate-\[1\.3\]:hover {
    --tw-saturate: saturate(1.3)
}

.hover\:saturate-\[1\.2\]:hover {
    --tw-saturate: saturate(1.2);
    /* filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) */
}

.focus\:border-elm-blue:focus {
    --tw-border-opacity: 1;
    border-color: rgb(57 170 255/var(--tw-border-opacity))
}

.focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px
}

.focus\:ring-0:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow,0 0 #0000)
}

.focus\:ring-transparent:focus {
    --tw-ring-color: transparent
}

.focus-visible\:outline:focus-visible {
    outline-style: solid
}

.focus-visible\:outline-2:focus-visible {
    outline-width: 2px
}

.focus-visible\:outline-offset-2:focus-visible {
    outline-offset: 2px
}

.active\:text-elm-grey-medium:active {
    --tw-text-opacity: 1;
    color: rgb(104 104 104/var(--tw-text-opacity))
}

.active\:opacity-100:active {
    opacity: 1
}

@media (min-width: 640px) {
    .sm\:right-\[-20\%\] {
        right:-20%
    }

    .sm\:right-\[10\%\] {
        right: 10%
    }

    .sm\:top-\[-25\%\] {
        top: -25%
    }

    .sm\:overline_body1 {
        font-size: 1rem
    }

    .sm\:overline_body1,.sm\:overline_body2 {
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: .3rem
    }

    .sm\:overline_body2 {
        font-size: .75rem
    }

    .sm\:heading2 {
        font-size: 3.75rem;
        font-weight: 900;
        line-height: .97em;
        letter-spacing: -.03rem
    }

    .sm\:mb-5 {
        margin-bottom: 1.25rem
    }

    .sm\:heading3 {
        font-size: 3rem;
        font-weight: 700;
        line-height: .97em;
        letter-spacing: -.03rem
    }

    .sm\:ml-16 {
        margin-left: 4rem
    }

    .sm\:ml-10 {
        margin-left: 2.5rem
    }

    .sm\:mb-\[40px\] {
        margin-bottom: 40px
    }

    .sm\:mt-4 {
        margin-top: 1rem
    }

    .sm\:ml-4 {
        margin-left: 1rem
    }

    .sm\:mb-7 {
        margin-bottom: 1.75rem
    }

    .sm\:mb-6 {
        margin-bottom: 1.5rem
    }

    .sm\:heading4_bold {
        font-size: 1.875rem;
        font-weight: 700
    }

    .sm\:heading5_bold {
        font-size: 1.5rem;
        font-weight: 700
    }

    .sm\:h-full {
        height: 100%
    }

    .sm\:h-\[316px\] {
        height: 316px
    }

    .sm\:h-\[400px\] {
        height: 400px
    }

    .sm\:h-\[15px\] {
        height: 15px
    }

    .sm\:h-8 {
        height: 2rem
    }

    .sm\:h-7 {
        height: 1.75rem
    }

    .sm\:h-14 {
        height: 3.5rem
    }

    .sm\:h-\[256px\] {
        height: 256px
    }

    .sm\:paragraph {
        font-size: 1.125rem;
        font-weight: 400;
        line-height: 1.61em
    }

    .sm\:w-5\/12 {
        width: 41.666667%
    }

    .sm\:w-3\/5 {
        width: 60%
    }

    .sm\:w-1\/2 {
        width: 50%
    }

    .sm\:w-\[750px\] {
        width: 750px
    }

    .sm\:w-\[30px\] {
        width: 30px
    }

    .sm\:w-8 {
        width: 2rem
    }

    .sm\:w-7 {
        width: 1.75rem
    }

    .sm\:w-\[256px\] {
        width: 256px
    }

    .sm\:basis-1\/2 {
        flex-basis: 50%
    }

    .sm\:basis-2\/3 {
        flex-basis: 66.666667%
    }

    .sm\:cta_links {
        font-size: 1.125rem;
        font-weight: 900;
        text-transform: uppercase;
        letter-spacing: .3rem
    }

    .sm\:paragraph_header {
        font-size: 1.125rem;
        font-weight: 700;
        line-height: 1.3em
    }

    .sm\:grid-cols-2 {
        grid-template-columns: repeat(2,minmax(0,1fr))
    }

    .sm\:flex-row {
        flex-direction: row
    }

    .sm\:items-start {
        align-items: flex-start
    }

    .sm\:justify-between {
        justify-content: space-between
    }

    .sm\:gap-\[40px\] {
        gap: 40px
    }

    .sm\:gap-20 {
        gap: 5rem
    }

    .sm\:gap-0 {
        gap: 0
    }

    .sm\:gap-10 {
        gap: 2.5rem
    }

    .sm\:gap-8 {
        gap: 2rem
    }

    .sm\:gap-6 {
        gap: 1.5rem
    }

    .sm\:p-4 {
        padding: 1rem
    }

    .sm\:py-12 {
        padding-top: 3rem;
        padding-bottom: 3rem
    }

    .sm\:px-12 {
        padding-left: 3rem;
        padding-right: 3rem
    }

    .sm\:px-0 {
        padding-right: 0
    }

    .sm\:pl-0,.sm\:px-0 {
        padding-left: 0
    }

    .sm\:pl-14 {
        padding-left: 3.5rem
    }

    .sm\:text-lg {
        font-size: 1.125rem
    }

    .sm\:text-\[18px\] {
        font-size: 18px
    }

    .sm\:text-\[16px\] {
        font-size: 16px
    }

    .sm\:text-\[24px\] {
        font-size: 24px
    }

    .sm\:text-\[14px\] {
        font-size: 14px
    }
}

@media (min-width: 768px) {
    .md\:left-\[10\%\] {
        left:10%
    }

    .md\:top-\[37\%\] {
        top: 37%
    }

    .md\:top-\[28\%\] {
        top: 28%
    }

    .md\:right-\[10\%\] {
        right: 10%
    }

    .md\:top-\[84px\] {
        top: 84px
    }

    .md\:right-\[-30\%\] {
        right: -30%
    }

    .md\:right-\[0\%\] {
        right: 0
    }

    .md\:top-\[-200\%\] {
        top: -200%
    }

    .md\:bottom-8 {
        bottom: 2rem
    }

    .md\:top-\[-140px\] {
        top: -140px
    }

    .md\:order-1 {
        order: 1
    }

    .md\:order-2 {
        order: 2
    }

    .md\:overline_body1 {
        font-size: 1rem
    }

    .md\:overline_body1,.md\:overline_body2 {
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: .3rem
    }

    .md\:overline_body2 {
        font-size: .75rem
    }

    .md\:heading1 {
        font-size: 6rem;
        font-weight: 900;
        line-height: .97em;
        letter-spacing: -.03rem
    }

    .md\:my-28 {
        margin-top: 7rem;
        margin-bottom: 7rem
    }

    .md\:my-32 {
        margin-top: 8rem;
        margin-bottom: 8rem
    }

    .md\:my-10 {
        margin-top: 2.5rem;
        margin-bottom: 2.5rem
    }

    .md\:heading2 {
        font-size: 3.75rem;
        font-weight: 900;
        line-height: .97em;
        letter-spacing: -.03rem
    }

    .md\:my-16 {
        margin-top: 4rem;
        margin-bottom: 4rem
    }

    .md\:my-20 {
        margin-top: 5rem;
        margin-bottom: 5rem
    }

    .md\:heading3 {
        font-size: 3rem;
        font-weight: 700;
        line-height: .97em;
        letter-spacing: -.03rem
    }

    .md\:mb-8 {
        margin-bottom: 2rem
    }

    .md\:mb-11 {
        margin-bottom: 2.75rem
    }

    .md\:mb-32 {
        margin-bottom: 8rem
    }

    .md\:mt-24 {
        margin-top: 6rem
    }

    .md\:mt-4 {
        margin-top: 1rem
    }

    .md\:mt-0 {
        margin-top: 0
    }

    .md\:mt-44 {
        margin-top: 11rem
    }

    .md\:mt-40 {
        margin-top: 10rem
    }

    .md\:mt-6 {
        margin-top: 1.5rem
    }

    .md\:mt-16 {
        margin-top: 4rem
    }

    .md\:mt-20 {
        margin-top: 5rem
    }

    .md\:mt-7 {
        margin-top: 1.75rem
    }

    .md\:mt-9 {
        margin-top: 2.25rem
    }

    .md\:mb-2 {
        margin-bottom: .5rem
    }

    .md\:mt-32 {
        margin-top: 8rem
    }

    .md\:mt-10 {
        margin-top: 2.5rem
    }

    .md\:mt-2 {
        margin-top: .5rem
    }

    .md\:mb-12 {
        margin-bottom: 3rem
    }

    .md\:block {
        display: block
    }

    .md\:heading4_bold {
        font-size: 1.875rem;
        font-weight: 700
    }

    .md\:flex {
        display: flex
    }

    .md\:heading4_regular {
        font-size: 1.875rem;
        font-weight: 400;
        line-height: 1.5em
    }

    .md\:heading5_bold {
        font-size: 1.5rem;
        font-weight: 700
    }

    .md\:heading5_regular {
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 1.35em
    }

    .md\:heading5_allcaps {
        font-size: 1.5rem;
        font-weight: 900;
        text-transform: uppercase;
        line-height: .85em
    }

    .md\:heading6_bold {
        font-size: 1.125rem;
        font-weight: 700
    }

    .md\:heading6_allcaps {
        font-size: 1.125rem;
        font-weight: 900;
        text-transform: uppercase;
        line-height: .85em
    }

    .md\:hidden {
        display: none
    }

    .md\:h-\[350px\] {
        height: 350px
    }

    .md\:h-8 {
        height: 2rem
    }

    .md\:h-\[400px\] {
        height: 400px
    }

    .md\:h-14 {
        height: 3.5rem
    }

    .md\:h-\[768px\] {
        height: 768px
    }

    .md\:h-\[940px\] {
        height: 940px
    }

    .md\:h-\[1200px\] {
        height: 1200px
    }

    .md\:h-6 {
        height: 1.5rem
    }

    .md\:h-\[560px\] {
        height: 560px
    }

    .md\:h-\[445px\] {
        height: 445px
    }

    .md\:h-\[328px\] {
        height: 328px
    }

    .md\:h-\[256px\] {
        height: 256px
    }

    .md\:h-\[368px\] {
        height: 368px
    }

    .md\:h-\[25px\] {
        height: 25px
    }

    .md\:h-\[348px\] {
        height: 348px
    }

    .md\:h-\[528px\] {
        height: 528px
    }

    .md\:h-28 {
        height: 7rem
    }

    .md\:h-\[52px\] {
        height: 52px
    }

    .md\:h-\[50px\] {
        height: 50px
    }

    .md\:h-\[35px\] {
        height: 35px
    }

    .md\:h-\[179px\] {
        height: 179px
    }

    .md\:h-\[330px\] {
        height: 330px
    }

    .md\:h-\[170px\] {
        height: 170px
    }

    .md\:h-\[190px\] {
        height: 190px
    }

    .md\:h-\[62px\] {
        height: 62px
    }

    .md\:paragraph {
        font-size: 1.125rem;
        font-weight: 400;
        line-height: 1.61em
    }

    .md\:paragraph_mini {
        font-size: 14px;
        font-weight: 400;
        line-height: 1.61em
    }

    .md\:w-\[350px\] {
        width: 350px
    }

    .md\:w-8 {
        width: 2rem
    }

    .md\:w-4\/5 {
        width: 80%
    }

    .md\:w-1\/2 {
        width: 50%
    }

    .md\:w-1\/3 {
        width: 33.333333%
    }

    .md\:w-32 {
        width: 8rem
    }

    .md\:m_paragraph {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.61em
    }

    .md\:w-\[500px\] {
        width: 500px
    }

    .md\:w-2\/3 {
        width: 66.666667%
    }

    .md\:w-full {
        width: 100%
    }

    .md\:w-11\/12 {
        width: 91.666667%
    }

    .md\:w-\[530px\] {
        width: 530px
    }

    .md\:w-\[300px\] {
        width: 300px
    }

    .md\:w-5\/6 {
        width: 83.333333%
    }

    .md\:w-8\/12 {
        width: 66.666667%
    }

    .md\:w-\[25px\] {
        width: 25px
    }

    .md\:w-9\/12 {
        width: 75%
    }

    .md\:w-28 {
        width: 7rem
    }

    .md\:w-3\/5 {
        width: 60%
    }

    .md\:w-\[223px\] {
        width: 223px
    }

    .md\:w-6 {
        width: 1.5rem
    }

    .md\:w-max {
        width: -moz-max-content;
        width: max-content
    }

    .md\:w-\[179px\] {
        width: 179px
    }

    .md\:w-\[35px\] {
        width: 35px
    }

    .md\:w-\[330px\] {
        width: 330px
    }

    .md\:w-\[170px\] {
        width: 170px
    }

    .md\:w-\[190px\] {
        width: 190px
    }

    .md\:w-\[62px\] {
        width: 62px
    }

    .md\:section_header {
        font-size: 2.5rem;
        font-weight: 900;
        text-transform: uppercase;
        line-height: .85em
    }

    .md\:basis-2\/3 {
        flex-basis: 66.666667%
    }

    .md\:basis-1\/2 {
        flex-basis: 50%
    }

    .md\:basis-2\/5 {
        flex-basis: 40%
    }

    .md\:cta_links {
        font-size: 1.125rem;
        font-weight: 900;
        text-transform: uppercase;
        letter-spacing: .3rem
    }

    .md\:cursor-pointer {
        cursor: pointer
    }

    .md\:grid-cols-2 {
        grid-template-columns: repeat(2,minmax(0,1fr))
    }

    .md\:grid-cols-3 {
        grid-template-columns: repeat(3,minmax(0,1fr))
    }

    .md\:flex-row {
        flex-direction: row
    }

    .md\:flex-col {
        flex-direction: column
    }

    .md\:items-end {
        align-items: flex-end
    }

    .md\:items-center {
        align-items: center
    }

    .md\:items-stretch {
        align-items: stretch
    }

    .md\:justify-start {
        justify-content: flex-start
    }

    .md\:justify-center {
        justify-content: center
    }

    .md\:justify-between {
        justify-content: space-between
    }

    .md\:gap-20 {
        gap: 5rem
    }

    .md\:gap-8 {
        gap: 2rem
    }

    .md\:gap-32 {
        gap: 8rem
    }

    .md\:gap-4 {
        gap: 1rem
    }

    .md\:gap-5 {
        gap: 1.25rem
    }

    .md\:gap-2 {
        gap: .5rem
    }

    .md\:gap-10 {
        gap: 2.5rem
    }

    .md\:gap-x-12 {
        -moz-column-gap: 3rem;
        column-gap: 3rem
    }

    .md\:gap-y-5 {
        row-gap: 1.25rem
    }

    .md\:gap-x-10 {
        -moz-column-gap: 2.5rem;
        column-gap: 2.5rem
    }

    .md\:gap-y-20 {
        row-gap: 5rem
    }

    .md\:space-x-10>:not([hidden])~:not([hidden]) {
        --tw-space-x-reverse: 0;
        margin-right: calc(2.5rem * var(--tw-space-x-reverse));
        margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)))
    }

    .md\:rounded-tl-\[50px\] {
        border-top-left-radius: 50px
    }

    .md\:rounded-br-\[50px\] {
        border-bottom-right-radius: 50px
    }

    .md\:border-\[38px\] {
        border-width: 38px
    }

    .md\:p-8 {
        padding: 2rem
    }

    .md\:p-4 {
        padding: 1rem
    }

    .md\:p-32 {
        padding: 8rem
    }

    .md\:px-0 {
        padding-left: 0;
        padding-right: 0
    }

    .md\:py-0 {
        padding-top: 0;
        padding-bottom: 0
    }

    .md\:py-36 {
        padding-top: 9rem;
        padding-bottom: 9rem
    }

    .md\:px-8 {
        padding-left: 2rem;
        padding-right: 2rem
    }

    .md\:px-32 {
        padding-left: 8rem;
        padding-right: 8rem
    }

    .md\:py-3\.5 {
        padding-top: .875rem;
        padding-bottom: .875rem
    }

    .md\:px-3\.5 {
        padding-left: .875rem;
        padding-right: .875rem
    }

    .md\:py-3 {
        padding-top: .75rem;
        padding-bottom: .75rem
    }

    .md\:px-3 {
        padding-left: .75rem;
        padding-right: .75rem
    }

    .md\:py-40 {
        padding-top: 10rem;
        padding-bottom: 10rem
    }

    .md\:px-6 {
        padding-left: 1.5rem;
        padding-right: 1.5rem
    }

    .md\:py-8 {
        padding-top: 2rem;
        padding-bottom: 2rem
    }

    .md\:px-10 {
        padding-left: 2.5rem;
        padding-right: 2.5rem
    }

    .md\:py-2 {
        padding-top: .5rem;
        padding-bottom: .5rem
    }

    .md\:py-10 {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem
    }

    .md\:pt-20 {
        padding-top: 5rem
    }

    .md\:pl-2 {
        padding-left: .5rem
    }

    .md\:pl-0 {
        padding-left: 0
    }

    .md\:pl-\[350px\] {
        padding-left: 350px
    }

    .md\:text-left {
        text-align: left
    }

    .md\:text-\[18px\] {
        font-size: 18px
    }

    .md\:text-\[24px\] {
        font-size: 24px
    }

    .md\:text-\[14px\] {
        font-size: 14px
    }

    .md\:text-\[15px\] {
        font-size: 15px
    }

    .md\:text-\[16px\] {
        font-size: 16px
    }

    .md\:text-\[350px\] {
        font-size: 350px
    }

    .md\:text-\[84px\] {
        font-size: 84px
    }

    .md\:tracking-\[-1rem\] {
        letter-spacing: -1rem
    }

    .md\:opacity-0 {
        opacity: 0
    }

    .placeholder\:md\:overline_body1::-moz-placeholder {
        font-size: 1rem;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: .3rem
    }

    .placeholder\:md\:overline_body1::placeholder {
        font-size: 1rem;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: .3rem
    }
}

@media (min-width: 1024px) {
    .lg\:right-\[-15\%\] {
        right:-15%
    }

    .lg\:right-\[10\%\] {
        right: 10%
    }

    .lg\:top-\[-140\%\] {
        top: -140%
    }

    .lg\:top-3\.5 {
        top: .875rem
    }

    .lg\:left-5 {
        left: 1.25rem
    }

    .lg\:top-3 {
        top: .75rem
    }

    .lg\:top-\[-45\%\] {
        top: -45%
    }

    .lg\:top-\[84px\] {
        top: 84px
    }

    .lg\:top-4 {
        top: 1rem
    }

    .lg\:left-4 {
        left: 1rem
    }

    .lg\:left-3 {
        left: .75rem
    }

    .lg\:top-\[80\%\] {
        top: 80%
    }

    .lg\:order-1 {
        order: 1
    }

    .lg\:order-2 {
        order: 2
    }

    .lg\:overline_header {
        font-size: 1.5rem;
        font-weight: 900;
        text-transform: uppercase;
        letter-spacing: .3rem
    }

    .lg\:overline_body1 {
        font-size: 1rem
    }

    .lg\:overline_body1,.lg\:overline_body2 {
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: .3rem
    }

    .lg\:overline_body2 {
        font-size: .75rem
    }

    .lg\:headingCountdown {
        font-size: 220px;
        font-weight: 900;
        line-height: .97em;
        letter-spacing: -.03rem
    }

    .lg\:my-28 {
        margin-top: 7rem;
        margin-bottom: 7rem
    }

    .lg\:heading2 {
        font-size: 3.75rem;
        font-weight: 900;
        line-height: .97em;
        letter-spacing: -.03rem
    }

    .lg\:my-20 {
        margin-top: 5rem;
        margin-bottom: 5rem
    }

    .lg\:my-16 {
        margin-top: 4rem;
        margin-bottom: 4rem
    }

    .lg\:my-8 {
        margin-top: 2rem;
        margin-bottom: 2rem
    }

    .lg\:my-10 {
        margin-top: 2.5rem;
        margin-bottom: 2.5rem
    }

    .lg\:my-32 {
        margin-top: 8rem;
        margin-bottom: 8rem
    }

    .lg\:my-0 {
        margin-top: 0;
        margin-bottom: 0
    }

    .lg\:my-24 {
        margin-top: 6rem;
        margin-bottom: 6rem
    }

    .lg\:mt-\[-50px\] {
        margin-top: -50px
    }

    .lg\:heading3 {
        font-size: 3rem;
        font-weight: 700;
        line-height: .97em;
        letter-spacing: -.03rem
    }

    .lg\:mt-\[-110px\] {
        margin-top: -110px
    }

    .lg\:mb-7 {
        margin-bottom: 1.75rem
    }

    .lg\:mt-20 {
        margin-top: 5rem
    }

    .lg\:mb-32 {
        margin-bottom: 8rem
    }

    .lg\:mb-16 {
        margin-bottom: 4rem
    }

    .lg\:mb-12 {
        margin-bottom: 3rem
    }

    .lg\:mt-6 {
        margin-top: 1.5rem
    }

    .lg\:mt-36 {
        margin-top: 9rem
    }

    .lg\:mt-0 {
        margin-top: 0
    }

    .lg\:mt-16 {
        margin-top: 4rem
    }

    .lg\:mt-28 {
        margin-top: 7rem
    }

    .lg\:mb-2 {
        margin-bottom: .5rem
    }

    .lg\:mt-14 {
        margin-top: 3.5rem
    }

    .lg\:mt-\[-10px\] {
        margin-top: -10px
    }

    .lg\:mt-8 {
        margin-top: 2rem
    }

    .lg\:mt-4 {
        margin-top: 1rem
    }

    .lg\:mt-40 {
        margin-top: 10rem
    }

    .lg\:mt-3 {
        margin-top: .75rem
    }

    .lg\:mt-7 {
        margin-top: 1.75rem
    }

    .lg\:mb-\[-7px\] {
        margin-bottom: -7px
    }

    .lg\:ml-8 {
        margin-left: 2rem
    }

    .lg\:mt-1 {
        margin-top: .25rem
    }

    .lg\:mb-0 {
        margin-bottom: 0
    }

    .lg\:ml-6 {
        margin-left: 1.5rem
    }

    .lg\:mb-24 {
        margin-bottom: 6rem
    }

    .lg\:block {
        display: block
    }

    .lg\:heading4_bold {
        font-size: 1.875rem;
        font-weight: 700
    }

    .lg\:flex {
        display: flex
    }

    .lg\:heading4_regular {
        font-size: 1.875rem;
        font-weight: 400;
        line-height: 1.5em
    }

    .lg\:heading4_allcaps {
        font-size: 1.875rem;
        font-weight: 900;
        text-transform: uppercase;
        line-height: .85em
    }

    .lg\:heading5_bold {
        font-size: 1.5rem;
        font-weight: 700
    }

    .lg\:heading5_regular {
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 1.35em
    }

    .lg\:heading6_bold {
        font-size: 1.125rem;
        font-weight: 700
    }

    .lg\:hidden {
        display: none
    }

    .lg\:h-36 {
        height: 9rem
    }

    .lg\:h-14 {
        height: 3.5rem
    }

    .lg\:h-\[1436px\] {
        height: 1436px
    }

    .lg\:h-\[350px\] {
        height: 350px
    }

    .lg\:h-\[450px\] {
        height: 450px
    }

    .lg\:h-\[480px\] {
        height: 480px
    }

    .lg\:h-\[25px\] {
        height: 25px
    }

    .lg\:h-\[600px\] {
        height: 600px
    }

    .lg\:h-8 {
        height: 2rem
    }

    .lg\:h-24 {
        height: 6rem
    }

    .lg\:h-\[1133px\] {
        height: 1133px
    }

    .lg\:h-10 {
        height: 2.5rem
    }

    .lg\:h-\[445px\] {
        height: 445px
    }

    .lg\:h-\[170px\] {
        height: 170px
    }

    .lg\:h-\[55vh\] {
        height: 55vh
    }

    .lg\:h-\[42px\] {
        height: 42px
    }

    .lg\:h-\[100px\] {
        height: 100px
    }

    .lg\:h-\[150px\] {
        height: 150px
    }

    .lg\:h-\[56px\] {
        height: 56px
    }

    .lg\:h-6 {
        height: 1.5rem
    }

    .lg\:h-\[120px\] {
        height: 120px
    }

    .lg\:h-full {
        height: 100%
    }

    .lg\:h-20 {
        height: 5rem
    }

    .lg\:h-\[260px\] {
        height: 260px
    }

    .lg\:paragraph {
        font-size: 1.125rem;
        font-weight: 400;
        line-height: 1.61em
    }

    .lg\:paragraph_mini {
        font-size: 14px;
        font-weight: 400;
        line-height: 1.61em
    }

    .lg\:w-3\/12 {
        width: 25%
    }

    .lg\:w-1\/2 {
        width: 50%
    }

    .lg\:w-1\/3 {
        width: 33.333333%
    }

    .lg\:w-56 {
        width: 14rem
    }

    .lg\:w-40 {
        width: 10rem
    }

    .lg\:w-4\/5 {
        width: 80%
    }

    .lg\:w-10\/12,.lg\:w-5\/6 {
        width: 83.333333%
    }

    .lg\:w-\[450px\] {
        width: 450px
    }

    .lg\:w-full {
        width: 100%
    }

    .lg\:w-\[25px\] {
        width: 25px
    }

    .lg\:w-2\/3 {
        width: 66.666667%
    }

    .lg\:w-8 {
        width: 2rem
    }

    .lg\:w-24 {
        width: 6rem
    }

    .lg\:w-\[1000px\] {
        width: 1000px
    }

    .lg\:w-10 {
        width: 2.5rem
    }

    .lg\:w-\[700px\] {
        width: 700px
    }

    .lg\:w-\[170px\] {
        width: 170px
    }

    .lg\:w-\[50vw\] {
        width: 50vw
    }

    .lg\:w-9\/12 {
        width: 75%
    }

    .lg\:w-\[42px\] {
        width: 42px
    }

    .lg\:w-11\/12 {
        width: 91.666667%
    }

    .lg\:w-\[100px\] {
        width: 100px
    }

    .lg\:w-8\/12 {
        width: 66.666667%
    }

    .lg\:w-\[200px\] {
        width: 200px
    }

    .lg\:w-\[82px\] {
        width: 82px
    }

    .lg\:w-6 {
        width: 1.5rem
    }

    .lg\:w-\[420px\] {
        width: 420px
    }

    .lg\:w-\[260px\] {
        width: 260px
    }

    .lg\:w-3\/5 {
        width: 60%
    }

    .lg\:w-\[150px\] {
        width: 150px
    }

    .lg\:paragraph_light {
        font-size: 1.125rem;
        font-weight: 300;
        line-height: 1.61em
    }

    .lg\:basis-1\/2 {
        flex-basis: 50%
    }

    .lg\:basis-8\/12 {
        flex-basis: 66.666667%
    }

    .lg\:basis-4\/12 {
        flex-basis: 33.333333%
    }

    .lg\:basis-2\/3 {
        flex-basis: 66.666667%
    }

    .lg\:basis-1\/3 {
        flex-basis: 33.333333%
    }

    .lg\:cta_links {
        font-size: 1.125rem;
        font-weight: 900;
        text-transform: uppercase;
        letter-spacing: .3rem
    }

    .lg\:paragraph_header {
        font-size: 1.125rem;
        font-weight: 700;
        line-height: 1.3em
    }

    .lg\:elm_black_gradient_overlay_light {
        background-image: linear-gradient(to right,var(--tw-gradient-stops));
        --tw-gradient-from: rgba(0,0,0,.5);
        --tw-gradient-stops: var(--tw-gradient-from),var(--tw-gradient-to);
        --tw-gradient-to: transparent
    }

    .lg\:elm_black_gradient_overlay_medium {
        background-image: linear-gradient(to right,var(--tw-gradient-stops));
        --tw-gradient-from: rgba(0,0,0,.8);
        --tw-gradient-stops: var(--tw-gradient-from),var(--tw-gradient-to);
        --tw-gradient-to: transparent
    }

    .lg\:cursor-pointer {
        cursor: pointer
    }

    .lg\:grid-cols-2 {
        grid-template-columns: repeat(2,minmax(0,1fr))
    }

    .lg\:grid-cols-3 {
        grid-template-columns: repeat(3,minmax(0,1fr))
    }

    .lg\:grid-cols-4 {
        grid-template-columns: repeat(4,minmax(0,1fr))
    }

    .lg\:flex-row {
        flex-direction: row
    }

    .lg\:items-start {
        align-items: flex-start
    }

    .lg\:items-center {
        align-items: center
    }

    .lg\:justify-start {
        justify-content: flex-start
    }

    .lg\:justify-end {
        justify-content: flex-end
    }

    .lg\:justify-center {
        justify-content: center
    }

    .lg\:justify-between {
        justify-content: space-between
    }

    .lg\:gap-4 {
        gap: 1rem
    }

    .lg\:gap-14 {
        gap: 3.5rem
    }

    .lg\:gap-2 {
        gap: .5rem
    }

    .lg\:gap-6 {
        gap: 1.5rem
    }

    .lg\:gap-5 {
        gap: 1.25rem
    }

    .lg\:gap-20 {
        gap: 5rem
    }

    .lg\:gap-32 {
        gap: 8rem
    }

    .lg\:gap-8 {
        gap: 2rem
    }

    .lg\:gap-10 {
        gap: 2.5rem
    }

    .lg\:gap-24 {
        gap: 6rem
    }

    .lg\:gap-x-10 {
        -moz-column-gap: 2.5rem;
        column-gap: 2.5rem
    }

    .lg\:gap-y-20 {
        row-gap: 5rem
    }

    .lg\:rounded-bl-\[50px\] {
        border-bottom-left-radius: 50px
    }

    .lg\:rounded-tl-\[50px\] {
        border-top-left-radius: 50px
    }

    .lg\:rounded-br-\[20px\] {
        border-bottom-right-radius: 20px
    }

    .lg\:bg-elm-blue {
        --tw-bg-opacity: 1;
        background-color: rgb(57 170 255/var(--tw-bg-opacity))
    }

    .lg\:bg-elm-blue-dark {
        --tw-bg-opacity: 1;
        background-color: rgb(0 78 162/var(--tw-bg-opacity))
    }

    .lg\:p-36 {
        padding: 9rem
    }

    .lg\:p-10 {
        padding: 2.5rem
    }

    .lg\:p-4 {
        padding: 1rem
    }

    .lg\:p-16 {
        padding: 4rem
    }

    .lg\:px-6 {
        padding-left: 1.5rem;
        padding-right: 1.5rem
    }

    .lg\:py-6 {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem
    }

    .lg\:py-36 {
        padding-top: 9rem;
        padding-bottom: 9rem
    }

    .lg\:py-3 {
        padding-top: .75rem;
        padding-bottom: .75rem
    }

    .lg\:px-14 {
        padding-left: 3.5rem;
        padding-right: 3.5rem
    }

    .lg\:py-16 {
        padding-top: 4rem;
        padding-bottom: 4rem
    }

    .lg\:py-3\.5 {
        padding-top: .875rem;
        padding-bottom: .875rem
    }

    .lg\:px-3\.5 {
        padding-left: .875rem;
        padding-right: .875rem
    }

    .lg\:px-3 {
        padding-left: .75rem;
        padding-right: .75rem
    }

    .lg\:py-14 {
        padding-top: 3.5rem;
        padding-bottom: 3.5rem
    }

    .lg\:px-0 {
        padding-left: 0;
        padding-right: 0
    }

    .lg\:px-16 {
        padding-left: 4rem;
        padding-right: 4rem
    }

    .lg\:px-10 {
        padding-left: 2.5rem;
        padding-right: 2.5rem
    }

    .lg\:py-10 {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem
    }

    .lg\:px-20 {
        padding-left: 5rem;
        padding-right: 5rem
    }

    .lg\:py-4 {
        padding-top: 1rem;
        padding-bottom: 1rem
    }

    .lg\:py-5 {
        padding-top: 1.25rem;
        padding-bottom: 1.25rem
    }

    .lg\:px-8 {
        padding-left: 2rem;
        padding-right: 2rem
    }

    .lg\:py-32 {
        padding-top: 8rem;
        padding-bottom: 8rem
    }

    .lg\:py-2 {
        padding-top: .5rem;
        padding-bottom: .5rem
    }

    .lg\:pl-14 {
        padding-left: 3.5rem
    }

    .lg\:pr-8 {
        padding-right: 2rem
    }

    .lg\:text-left {
        text-align: left
    }

    .lg\:text-center {
        text-align: center
    }

    .lg\:text-right {
        text-align: right
    }

    .lg\:text-\[14px\] {
        font-size: 14px
    }

    .lg\:text-\[16px\] {
        font-size: 16px
    }

    .lg\:text-\[24px\] {
        font-size: 24px
    }

    .lg\:text-2xl {
        font-size: 1.5rem
    }

    .lg\:text-\[10px\] {
        font-size: 10px
    }

    .lg\:font-\[14px\] {
        font-weight: 14px
    }

    .lg\:leading-7 {
        line-height: 1.75rem
    }

    .lg\:leading-\[20px\] {
        line-height: 20px
    }

    .lg\:opacity-0 {
        opacity: 0
    }

    .placeholder\:lg\:overline_body2::-moz-placeholder {
        font-size: .75rem;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: .3rem
    }

    .placeholder\:lg\:overline_body2::placeholder {
        font-size: .75rem;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: .3rem
    }

    .hover\:lg\:block:hover {
        display: block
    }

    .lg\:hover\:translate-y-2:hover {
        --tw-translate-y: 0.5rem;
        /* transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) */
    }

    .group\/depthOne:hover .group-hover\/depthOne\:lg\:block,.group\/depthTwo:hover .group-hover\/depthTwo\:lg\:block {
        display: block
    }
}

.elmeasuresubhead{
  font-size: 2rem;
  text-align: center;

}


/* </style> */

.cursor-pointer:nth-child(2){
    background-color: red;
    margin-top: 3rem;
}

.elmeasureproduct{
    margin-top: 2rem;
}

.elmeasuresubheadingpr{
    color: #005E8C;

font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 2.5px;
/* text-decoration-line: underline; */
text-transform: uppercase;
margin-top: -5rem;
}

@media screen and (max-width: 990px) {
    .elmeasuresubheadingpr{
       
    margin-top: -2rem;
    }
    .havellviewmore{
      
        margin-top: -1rem;
        left: 1.3rem;
         }
         .havellogo.kei{
            margin-top: 2rem;

         }
  }


  .elmeasureallprod{
    margin-top: 4rem;
  }

  .kiewandcmp .max-width-medium{
  max-width: 42rem;
  }
  .orbitlomp .havellogo{
    width: 8rem;
  }
  .havelslomp .max-width-medium{
    max-width: 38rem;
  }