/* <style type="text/css" id="server-side-jss"> */
.button-0-3-39 {
  color: currentColor;
  /* border: 1px solid var(--foreground); */
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  padding: 7.5px 15px;
  overflow: hidden;
  position: relative;
  font-size: 10px;
  background: transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
  align-items: center;
  line-height: 1.6;
  border-radius: 80px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  justify-content: center;
  text-decoration: none;
}
.button-0-3-39:disabled {
  color: currentColor;
  cursor: inherit;
  opacity: 0.5;
  background: transparent;
}
.clone-0-3-40 {
  top: 0;
  left: 0;
  color: var(--background);
  right: 0;
  bottom: 0;
  overflow: hidden;
  position: absolute;
  transform: translateY(110%);
  background-color: var(--foreground);
}
.button-0-3-39.selected .clone-0-3-40 {
  transform: translateY(0%);
}
.cloneContent-0-3-41 {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: inline-flex;
  padding: 7.5px 15px;
  position: absolute;
  transform: translateY(-110%);
  align-items: center;
  justify-content: center;
}
.button-0-3-39.selected .cloneContent-0-3-41 {
  transform: translateY(0%);
}
.icon-0-3-42 {
  width: 18px;
  display: block;
}
.icon-0-3-42.backArrow {
  transform: rotate(180deg);
}
.iconContainer-0-3-43 {
  display: block;
  overflow: hidden;
  margin-top: 1px;
  margin-left: 5px;
}
.iconContainer-0-3-43.backArrow {
  margin-left: 0;
  margin-right: 5px;
}
.Layout-section-0-3-33.gutter {
  margin-left: 15px;
  margin-right: 15px;
}
.Layout-section-0-3-33.bottomMargin {
  /* margin-bottom: 75px; */
}
.Layout-section-0-3-33.grid {
  gap: 15px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
@media (min-width: 1024px) {
  .Layout-section-0-3-33.grid {
    gap: 25px;
    grid-template-columns: repeat(7, 1fr);
  }
}
@media (min-width: 1024px) {
  .Layout-section-0-3-33.bottomMargin {
    /* margin-bottom: 100px; */
  }
}
@media (min-width: 1024px) {
  .Layout-section-0-3-33.gutter {
    margin-left: 25px;
    margin-right: 25px;
  }
}
.caption-0-3-305 {
  font-size: 10px;
  font-family: "ABCMonumentGroteskMono", "Lucida Sans", Tahoma, sans-serif;
  line-height: 1.2;
  white-space: pre-wrap;
  text-transform: uppercase;
}
.imageContainer-0-3-92 {
  width: 100%;
  display: block;
  position: relative;
}
.imageContainer-0-3-92 picture::before {
  display: block;
}
@media (min-width: 1024px) {
  .imageContainer-0-3-92 {
  }
  .imageContainer-0-3-92 picture::before {
    display: block;
  }
}
.imageContainer-0-3-92.overflowHidden {
  /* overflow: hidden; */
}
.image-d257-0-3-931 {
    object-position: 50% 50%;
}
.picture-0-3-93 {
}
.image-0-3-94 {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  bottom: 0;
  height: 100%;
  position: absolute;
  object-fit: cover;
}
.image-0-3-94.fadeIn {
   opacity: 1;
}
.caption-0-3-95 {
  top: calc(100% + 10px);
  right: 0;
  position: absolute;
  font-size: 10px;
  font-family: "ABCMonumentGroteskMono", "Lucida Sans", Tahoma, sans-serif;
}
.imageContainer-d0-0-3-96 {
}
.imageContainer-d0-0-3-96 picture::before {
  /* content: ""; */
  padding-top: 66.74082313681869%;
}
@media (min-width: 1024px) {
  .imageContainer-d0-0-3-96 {
  }
  .imageContainer-d0-0-3-96 picture::before {
    padding-top: 66.74082313681869%;
  }
}
.image-d2-0-3-97 {
  object-position: 50% 50%;
}
.imageContainer-d3-0-3-306 {
}
.imageContainer-d3-0-3-306 picture::before {
  /* content: ""; */
  padding-top: 100%;
}
@media (min-width: 1024px) {
  .imageContainer-d3-0-3-306 {
  }
  .imageContainer-d3-0-3-306 picture::before {
    padding-top: 100%;
  }
}
.image-d5-0-3-307 {
  object-position: 50% 50%;
}
.imageContainer-d6-0-3-308 {
}
.imageContainer-d6-0-3-308 picture::before {
  /* content: ""; */
  padding-top: 100%;
}
@media (min-width: 1024px) {
  .imageContainer-d6-0-3-308 {
  }
  .imageContainer-d6-0-3-308 picture::before {
    padding-top: 100%;
  }
}
.image-d8-0-3-309 {
  object-position: 50% 50%;
}
.imageContainer-d9-0-3-310 {
}
.imageContainer-d9-0-3-310 picture::before {
  /* content: ""; */
  padding-top: 100%;
}
@media (min-width: 1024px) {
  .imageContainer-d9-0-3-310 {
  }
  .imageContainer-d9-0-3-310 picture::before {
    padding-top: 100%;
  }
}
.image-d11-0-3-311 {
  object-position: 50% 50%;
}
.imageContainer-d12-0-3-312 {
}
.imageContainer-d12-0-3-312 picture::before {
  /* content: ""; */
  padding-top: 100%;
}
@media (min-width: 1024px) {
  .imageContainer-d12-0-3-312 {
  }
  .imageContainer-d12-0-3-312 picture::before {
    padding-top: 100%;
  }
}
.image-d14-0-3-313 {
  object-position: 50% 50%;
}
.imageContainer-d15-0-3-314 {
}
.imageContainer-d15-0-3-314 picture::before {
  /* content: ""; */
  padding-top: 100%;
}
@media (min-width: 1024px) {
  .imageContainer-d15-0-3-314 {
  }
  .imageContainer-d15-0-3-314 picture::before {
    padding-top: 100%;
  }
}
.image-d17-0-3-315 {
  object-position: 50% 50%;
}
.imageContainer-d18-0-3-316 {
}
.imageContainer-d18-0-3-316 picture::before {
  /* content: ""; */
  padding-top: 100%;
}
@media (min-width: 1024px) {
  .imageContainer-d18-0-3-316 {
  }
  .imageContainer-d18-0-3-316 picture::before {
    padding-top: 100%;
  }
}
.image-d20-0-3-317 {
  object-position: 50% 50%;
}
.line-0-3-107 {
  background-color: var(--border);
}
.horizontal-0-3-108 {
  left: 0;
  right: 0;
  height: 1px;
  position: absolute;
  /* transform: scaleX(0); */
}
.horizontal-0-3-108.extend {
  left: -15px;
  right: -15px;
}
@media (min-width: 1024px) {
  .horizontal-0-3-108.extend {
    left: -25px;
    right: -25px;
  }
}
.vertical-0-3-109 {
  top: 0;
  width: 1px;
  bottom: 0;
  position: absolute;
  /* transform: scaley(0); */
}
.vertical-0-3-109.extend {
  top: -15px;
  bottom: -15px;
}
@media (min-width: 1024px) {
  .vertical-0-3-109.extend {
    top: -25px;
    bottom: -25px;
  }
}
.horizontal-d1-0-3-110 {
  transform-origin: 50% 50%;
}
.vertical-d2-0-3-111 {
  transform-origin: 50% 50%;
}
.horizontal-d3-0-3-112 {
  transform-origin: 50% 50%;
}
.vertical-d4-0-3-113 {
  transform-origin: 50% 50%;
}
.horizontal-d5-0-3-114 {
  transform-origin: 50% 50%;
}
.vertical-d6-0-3-115 {
  transform-origin: 50% 50%;
}
.horizontal-d7-0-3-116 {
  transform-origin: 50% 50%;
}
.vertical-d8-0-3-117 {
  transform-origin: 50% 50%;
}
.horizontal-d9-0-3-118 {
  transform-origin: 50% 50%;
}
.vertical-d10-0-3-119 {
  transform-origin: 50% 50%;
}
.horizontal-d11-0-3-120 {
  transform-origin: 50% 50%;
}
.vertical-d12-0-3-121 {
  transform-origin: 50% 50%;
}
.horizontal-d13-0-3-122 {
  transform-origin: 50% 50%;
}
.vertical-d14-0-3-123 {
  transform-origin: 50% 50%;
}
.horizontal-d15-0-3-124 {
  transform-origin: 50% 50%;
}
.vertical-d16-0-3-125 {
  transform-origin: 50% 50%;
}
.horizontal-d17-0-3-126 {
  transform-origin: 50% 50%;
}
.vertical-d18-0-3-127 {
  transform-origin: 50% 50%;
}
.horizontal-d19-0-3-128 {
  transform-origin: 50% 50%;
}
.vertical-d20-0-3-129 {
  transform-origin: 50% 50%;
}
.horizontal-d21-0-3-130 {
  transform-origin: 50% 50%;
}
.vertical-d22-0-3-131 {
  transform-origin: 50% 50%;
}
.horizontal-d23-0-3-132 {
  transform-origin: 50% 50%;
}
.vertical-d24-0-3-133 {
  transform-origin: 50% 50%;
}
.horizontal-d25-0-3-134 {
  transform-origin: 50% 50%;
}
.vertical-d26-0-3-135 {
  transform-origin: 50% 50%;
}
.horizontal-d27-0-3-136 {
  transform-origin: 50% 50%;
}
.vertical-d28-0-3-137 {
  transform-origin: 50% 50%;
}
.horizontal-d29-0-3-138 {
  transform-origin: 50% 50%;
}
.vertical-d30-0-3-139 {
  transform-origin: 50% 50%;
}
.horizontal-d31-0-3-140 {
  transform-origin: 50% 50%;
}
.vertical-d32-0-3-141 {
  transform-origin: 50% 50%;
}
.horizontal-d33-0-3-142 {
  transform-origin: 50% 50%;
}
.vertical-d34-0-3-143 {
  transform-origin: 50% 50%;
}
.horizontal-d35-0-3-144 {
  transform-origin: 50% 50%;
}
.vertical-d36-0-3-145 {
  transform-origin: 50% 50%;
}
.horizontal-d37-0-3-146 {
  transform-origin: 50% 50%;
}
.vertical-d38-0-3-147 {
  transform-origin: 50% 50%;
}
.horizontal-d39-0-3-148 {
  transform-origin: 50% 50%;
}
.vertical-d40-0-3-149 {
  transform-origin: 50% 50%;
}
.horizontal-d41-0-3-179 {
  transform-origin: 50% 50%;
}
.vertical-d42-0-3-180 {
  transform-origin: 50% 50%;
}
.horizontal-d43-0-3-181 {
  transform-origin: 50% 50%;
}
.vertical-d44-0-3-182 {
  transform-origin: 50% 50%;
}
.horizontal-d45-0-3-183 {
  transform-origin: 50% 50%;
}
.vertical-d46-0-3-184 {
  transform-origin: 50% 50%;
}
.horizontal-d47-0-3-185 {
  transform-origin: 50% 50%;
}
.vertical-d48-0-3-186 {
  transform-origin: 50% 50%;
}
.horizontal-d49-0-3-187 {
  transform-origin: 50% 50%;
}
.vertical-d50-0-3-188 {
  transform-origin: 50% 50%;
}
.horizontal-d51-0-3-189 {
  transform-origin: 50% 50%;
}
.vertical-d52-0-3-190 {
  transform-origin: 50% 50%;
}
.horizontal-d53-0-3-191 {
  transform-origin: 50% 50%;
}
.vertical-d54-0-3-192 {
  transform-origin: 50% 50%;
}
.horizontal-d55-0-3-193 {
  transform-origin: 50% 50%;
}
.vertical-d56-0-3-194 {
  transform-origin: 50% 50%;
}
.horizontal-d57-0-3-195 {
  transform-origin: 50% 50%;
}
.vertical-d58-0-3-196 {
  transform-origin: 50% 50%;
}
.horizontal-d59-0-3-197 {
  transform-origin: 50% 50%;
}
.vertical-d60-0-3-198 {
  transform-origin: 50% 50%;
}
.horizontal-d61-0-3-199 {
  transform-origin: 50% 50%;
}
.vertical-d62-0-3-200 {
  transform-origin: 50% 50%;
}
.horizontal-d63-0-3-201 {
  transform-origin: 50% 50%;
}
.vertical-d64-0-3-202 {
  transform-origin: 50% 50%;
}
.horizontal-d65-0-3-203 {
  transform-origin: 50% 50%;
}
.vertical-d66-0-3-204 {
  transform-origin: 50% 50%;
}
.horizontal-d67-0-3-205 {
  transform-origin: 50% 50%;
}
.vertical-d68-0-3-206 {
  transform-origin: 50% 50%;
}
.horizontal-d69-0-3-207 {
  transform-origin: 50% 50%;
}
.vertical-d70-0-3-208 {
  transform-origin: 50% 50%;
}
.horizontal-d71-0-3-209 {
  transform-origin: 50% 50%;
}
.vertical-d72-0-3-210 {
  transform-origin: 50% 50%;
}
.horizontal-d73-0-3-211 {
  transform-origin: 50% 50%;
}
.vertical-d74-0-3-212 {
  transform-origin: 50% 50%;
}
.horizontal-d75-0-3-213 {
  transform-origin: 50% 50%;
}
.vertical-d76-0-3-214 {
  transform-origin: 50% 50%;
}
.horizontal-d77-0-3-247 {
  transform-origin: 0% 50%;
}
.vertical-d78-0-3-248 {
  transform-origin: 50% 0%;
}
.horizontal-d79-0-3-249 {
  transform-origin: 50% 50%;
}
.vertical-d80-0-3-250 {
  transform-origin: 50% 50%;
}
.horizontal-d81-0-3-277 {
  transform-origin: 50% 50%;
}
.vertical-d82-0-3-278 {
  transform-origin: 50% 50%;
}
.horizontal-d83-0-3-279 {
  transform-origin: 50% 50%;
}
.vertical-d84-0-3-280 {
  transform-origin: 50% 50%;
}
.horizontal-d85-0-3-281 {
  transform-origin: 0 50%;
}
.vertical-d86-0-3-282 {
  transform-origin: 50% 0;
}
.horizontal-d87-0-3-283 {
  transform-origin: 50% 50%;
}
.vertical-d88-0-3-284 {
  transform-origin: 50% 50%;
}
.horizontal-d89-0-3-285 {
  transform-origin: 0 50%;
}
.vertical-d90-0-3-286 {
  transform-origin: 50% 0;
}
.horizontal-d91-0-3-287 {
  transform-origin: 50% 50%;
}
.vertical-d92-0-3-288 {
  transform-origin: 50% 50%;
}
.horizontal-d93-0-3-289 {
  transform-origin: 50% 50%;
}
.vertical-d94-0-3-290 {
  transform-origin: 50% 50%;
}
.horizontal-d95-0-3-291 {
  transform-origin: 50% 50%;
}
.vertical-d96-0-3-292 {
  transform-origin: 50% 50%;
}
.horizontal-d97-0-3-293 {
  transform-origin: 50% 50%;
}
.vertical-d98-0-3-294 {
  transform-origin: 50% 50%;
}
.horizontal-d99-0-3-295 {
  transform-origin: 50% 50%;
}
.vertical-d100-0-3-296 {
  transform-origin: 50% 50%;
}
.filters-0-3-237 {
  grid-column: 1 / span 4;
}
@media (min-width: 1024px) {
  .filters-0-3-237 {
    grid-column: 1 / span 3;
  }
}
.list-0-3-238 {
  grid-column: 1 / span 4;
}
@media (min-width: 1024px) {
  .list-0-3-238 {
    grid-column: 4 / span 4;
  }
}
.accordionItem-0-3-239 {
  border: 1px solid var(--border);
  overflow: hidden;
  margin-top: -1px;
  width: 36rem;
}
.accordionButton-0-3-240 {
  width: 100%;
  border: none;
  cursor: pointer;
  display: flex;
  opacity: 1;
  padding: 10px;
  font-size: 12px;
  transition: opacity 0.1s ease-in-out;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  text-transform: uppercase;
  font-weight: 600;
  color: #5C5C5CB2;
}
.accordionButton-0-3-240:hover,
.accordionButton-0-3-240.open
 {
  opacity: 1;
  border: 1px solid none;
}

.dropdownIcon-0-3-241 {
  transition: transform 0.15s ease-in-out;
}
.dropdownIcon-0-3-241.open {
  transform: rotate(180deg);
}
.optionsList-0-3-242 {
  height: 0;
}
.optionsListInner-0-3-243 {
  padding: 7.5px 0;
  /* border-top: 1px solid var(--border); */
}
.option-0-3-244 {
  width: 100%;
  border: none;
  cursor: pointer;
  margin: 0;
  display: block;
  opacity: 0.5;
  padding: 7.5px 10px;
  font-size: 12px;
  text-align: left;
  transition: opacity 0.1s ease-in-out;
  border-radius: 0;
  background-color: transparent;
}
.option-0-3-244:hover,
.option-0-3-244.selected {
  opacity: 1;
}
.buttonContainer-0-3-245 {
  /* border: 1px solid var(--border); */
  margin-top: -1px;
}
.clearButton-0-3-246 {
  width: 100%;
  border: none;
  cursor: pointer;
  display: block;
  opacity: 0.5;
  padding: 20px;
  font-size: 12px;
  transition: opacity 0.1s ease-in-out;
  border-radius: 0;
  background-color: transparent;
}
.clearButton-0-3-246:hover,
.clearButton-0-3-246.selected {
  opacity: 1;
}
.section-0-3-224 {
  overflow: hidden;
  position: relative;
  padding-bottom: 30px;
}
@media (min-width: 1024px) {
  .section-0-3-224 {
    /* min-height: calc(100vh - 72px); */
  }
}
.filters-0-3-225 {
  padding: 30vh 0 25px;
  grid-column: 1 / span 4;
}
@media (min-width: 1024px) {
  .filters-0-3-225 {
    /* top: 0; */
    /* bottom: 6rem; */
    width: 100%;
    height: calc(100vh - 46px);
    display: flex;
    padding: 50px 25px 25px 0;
    position: absolute;
    margin-top: -26px;
    grid-column: 1 / span 3;
    flex-direction: column;
    justify-content: space-between;
  }
}
.title-0-3-226 {
  font-size: 45px;
}
@media (min-width: 1024px) {
  .title-0-3-226 {
    font-size: 65px;
  }
}
.verticalLine-0-3-227 {
  opacity: 1;
}
@media (min-width: 1024px) {
  .verticalLine-0-3-227 {
    display: block;
  }
}
.listContainer-0-3-228 {
  grid-column: 1 / span 4;
}
@media (min-width: 1024px) {
  .listContainer-0-3-228 {
    grid-column: 4 / span 4;
    border: 1px solid  #5C5C5CCC;
    ;
    border-right: none;
    padding-left: 0.5rem;
  }
}
@media (min-width: 1024px) {
  .list-0-3-229 {
    display: grid;
    column-gap: 55px;
    grid-template-columns: repeat(2, 1fr);
  }
}
.tile-0-3-230 {
  display: flex;
  padding: 20px 0;
  position: relative;
  text-decoration: none;
}
@media (min-width: 1024px) {
  .tile-0-3-230 {
    grid-column: span 2;
  }
}
.tile-0-3-230:after {
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  content: "";
  display: block;
  opacity: 0.3;
  position: absolute;
  transition: opacity 0.1s ease-in-out;
  background-color: var(--border);
}
.tile-0-3-230:hover:after {
  opacity: 1;
}
.image-0-3-231 {
  width: 50%;
  flex-grow: 0;
  flex-shrink: 0;
}
@media (min-width: 1024px) {
  .image-0-3-231 {
    width: calc(50% - 12.5px);
    grid-column: span 2;
  }
}
.content-0-3-232 {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  margin-left: 15px;
  flex-direction: column;
  justify-content: space-between;
}
.tileTitle-0-3-233 {
  font-size: 20px;
}
.tileCategory-0-3-234 {
  display: block;
  opacity: 0.7;
  font-size: 10px;
}
.metaData-0-3-235 {
  display: flex;
  opacity: 0.5;
  align-items: center;
}
.emptyResults-0-3-236 {
  width: 100%;
  font-size: 20px;
  grid-column: 1 / span 4;
  padding-top: 25px;
}
.title-0-3-298 {
  display: block;
  opacity: 0.5;
  font-size: 12px;
  margin-bottom: 5px;
}
.input-0-3-299 {
  width: 100%;
  border: none;
  margin: -1px 0 0 0;
  outline: none;
  padding: 10px 30px 10px 0;
  font-size: 12px;
  appearance: none;
  border-top: 1px solid var(--border);
  box-shadow: none;
  border-bottom: 1px solid var(--border);
  border-radius: 0;
  background-color: transparent;
}
.inputContainer-0-3-300 {
  position: relative;
}
.button-0-3-301 {
  top: 50%;
  right: 0;
  width: 18px;
  border: none;
  cursor: pointer;
  height: 30px;
  margin: 0;
  display: flex;
  padding: 0;
  overflow: hidden;
  position: absolute;
  transform: translate(0, -50%);
  align-items: center;
  justify-content: flex-end;
  background-color: transparent;
}
.button-0-3-301:hover > .arrow-0-3-302 {
  transform: translate(200%, 0);
}
.button-0-3-301:hover > .arrow2-0-3-303 {
  transform: translate(200%, 0);
}
.arrow-0-3-302 {
  width: 18px;
  transition: transform 0.35s cubic-bezier(0.61, 1, 0.88, 1);
  flex-shrink: 0;
}
.arrow2-0-3-303 {
  right: 200%;
  width: 18px;
  position: absolute;
  transition: transform 0.35s cubic-bezier(0.61, 1, 0.88, 1);
}
.error-0-3-304 {
  color: #aa0000;
  margin-top: 5px;
}
.toolbarContainer-0-3-251 {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  z-index: 900;
  overflow: hidden;
  position: fixed;
  pointer-events: none;
}
@media (min-width: 1024px) {
  .toolbarContainer-0-3-251 {
    position: absolute;
  }
}
.toolbar-0-3-252 {
  left: 0;
  right: 0;
  bottom: 0;
  height: 50px;
  display: flex;
  position: absolute;
  transform: translate(0, 100%);
  border-top: 1px solid var(--border);
  align-items: center;
  pointer-events: all;
  justify-content: flex-end;
  background-color: var(--background);
}
.titleItem-0-3-253 {
  min-width: 220px;
}
.hideOnMobile-0-3-254 {
  opacity: 1;
}
@media (min-width: 1024px) {
  .hideOnMobile-0-3-254 {
    display: flex;
  }
}
.footer-0-3-255 {
  position: relative;
  font-size: 12px;
}
.section-0-3-256 {
  padding: 50px 0;
  row-gap: 50px !important;
}
@media (min-width: 1024px) {
  .section-0-3-256 {
    padding: 25px 0;
    row-gap: 25px !important;
  }
}
@media (min-width: 1024px) {
  .section-0-3-256.extraToolbarPadding {
    padding: 25px 0 75px;
  }
}
.logoNewsletterContainer-0-3-257 {
  grid-row: 1;
  grid-column: 1 / span 4;
}
@media (min-width: 1024px) {
  .logoNewsletterContainer-0-3-257 {
    grid-column: 1 / span 2;
    padding-right: 25px;
  }
}
.acknowledgeContainer-0-3-258 {
  margin: 0;
  grid-row: 7;
  font-size: 12px;
  text-align: center;
  grid-column: 1 / span 4;
  margin-bottom: 60px;
}
@media (min-width: 1024px) {
  .acknowledgeContainer-0-3-258 {
    grid-row: 2;
    margin-top: 280px;
    text-align: left;
    grid-column: 1 / span 2;
    margin-bottom: 0;
    padding-right: 50px;
  }
}
.socialLinksContainer-0-3-259 {
  grid-row: 5;
  grid-column: 1 / span 2;
}
@media (min-width: 1024px) {
  .socialLinksContainer-0-3-259 {
    grid-row: 1;
    grid-column: 3 / span 2;
  }
}
.copyrightContainer-0-3-260 {
  grid-row: 5;
  grid-column: 3 / span 2;
}
@media (min-width: 1024px) {
  .copyrightContainer-0-3-260 {
    grid-row: 2;
    align-self: end;
    grid-column: 3 / span 2;
  }
}
.productsContainer-0-3-261 {
  grid-row: 2;
  text-align: right;
  grid-column: 3 / span 2;
}
@media (min-width: 1024px) {
  .productsContainer-0-3-261 {
    grid-row: 1;
    text-align: left;
    grid-column: 5 / span 2;
  }
}
.projectsContainer-0-3-262 {
  grid-row: 2;
  grid-column: 1 / span 2;
}
@media (min-width: 1024px) {
  .projectsContainer-0-3-262 {
    grid-row: 1;
    text-align: right;
    grid-column: 7;
  }
}
.contactsContainer-0-3-263 {
  display: flex;
  grid-row: 3;
  grid-column: 1 / span 2;
  flex-direction: column;
}
@media (min-width: 1024px) {
  .contactsContainer-0-3-263 {
    grid-row: 2;
    align-self: end;
    grid-column: 5 / span 2;
  }
}
.resourcesContainer-0-3-264 {
  grid-row: 3;
  text-align: right;
  grid-column: 3 / span 2;
}
@media (min-width: 1024px) {
  .resourcesContainer-0-3-264 {
    grid-row: 2;
    align-self: end;
    grid-column: 7;
  }
}
.logo-0-3-265 {
  width: 64px;
  height: 64px;
  margin-bottom: 50px;
}
.link-0-3-266 {
  line-height: 1.8;
  text-decoration: none;
}
.link-0-3-266:hover {
  text-decoration: underline;
}
.caption-0-3-267 {
  display: block;
  opacity: 0.5;
  margin-bottom: 15px;
}
.partnerLogos-0-3-268 {
  margin: 0 -5px 15px;
  display: flex;
  flex-wrap: wrap;
  max-width: 20em;
}
@media (min-width: 1024px) {
  .partnerLogos-0-3-268 {
    margin: 0 -5px 30px;
  }
}
.partnerLogo-0-3-269 {
  width: 100%;
  margin: 5px 5px;
  max-width: 48px;
}
.partnerLogo-0-3-269 img {
  object-fit: contain;
}
.partnerLogo-0-3-269 > div {
  max-height: 100%;
}
.linkList-0-3-270 {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.verticalLineContainer-0-3-271 {
  opacity: 1;
  position: relative;
  pointer-events: none;
}
@media (min-width: 1024px) {
  .verticalLineContainer-0-3-271 {
    margin: -25px 0;
    display: block;
    grid-row: 1 / span 2;
  }
}
@media (min-width: 1024px) {
  .firstLine-0-3-272 {
    grid-column: 2;
  }
}
@media (min-width: 1024px) {
  .secondLine-0-3-273 {
    grid-column: 4;
  }
}
.horizontalLineContainer-0-3-274 {
  margin: 0 -15px;
  display: block;
  position: relative;
  grid-column: 1 / span 4;
  pointer-events: none;
}
@media (min-width: 1024px) {
  .horizontalLineContainer-0-3-274 {
    opacity: 1;
  }
}
.firstMobileLine-0-3-275 {
  grid-row: 4;
}
.secondMobileLine-0-3-276 {
  grid-row: 6;
}
.button-0-3-18 {
  color: currentColor;
  border: none;
  cursor: pointer;
  display: inline=block;
  padding: 0;
  position: relative;
  font-size: 12px;
  background: transparent;
  min-height: 42px;
  align-items: center;
  font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
  line-height: 1.2;
  border-radius: 0;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  text-decoration: none;
}
.labelContainer-0-3-19 {
  height: 1.2em;
  display: block;
  overflow: hidden;
  position: relative;
}
.menuLabel-0-3-20 {
  display: block;
  position: relative;
}
.closeLabel-0-3-21 {
  right: 0;
  display: block;
}
.count-0-3-17 {
  width: 20px;
  /* border: 1px solid currentColor; */
  height: 20px;
  display: inline-flex;
  font-size: 9px;
  align-items: center;
  font-family: "ABCMonumentGroteskMono", "Lucida Sans", Tahoma, sans-serif;
  border-radius: 50%;
  justify-content: center;
}
@media (min-width: 1024px) {
  .count-0-3-17 {
    width: 24px;
    height: 24px;
    font-size: 10px;
  }
}
.count-0-3-16 {
  margin-left: 5px;
}
.menuItem-0-3-14 {
  opacity: 1;
}
@media (min-width: 1024px) {
  .menuItem-0-3-14 {
    display: flex;
    align-items: center;
  }
}
.link-0-3-15 {
  color: currentColor;
  border: none;
  cursor: pointer;
  display: inline-flex;
  padding: 0;
  overflow: hidden;
  position: relative;
  font-size: 10px;
  background: transparent;
  min-height: 42px;
  align-items: center;
  font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
  line-height: 1.2;
  border-radius: 0;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  text-decoration: none;
}
.link-0-3-15:after {
  top: 50%;
  left: 0;
  right: 0;
  height: 1px;
  content: "";
  display: block;
  position: absolute;
  transform: translate(-110%, 1.2em);
  transition: transform 0.15s ease-in-out;
  background-color: currentColor;
  transform-origin: 0% 50%;
}
.link-0-3-15:hover:after,
.link-0-3-15.active:after,
.link-0-3-15.selected:after {
  transform: translate(0%, 1.2em);
}
.header-0-3-3 {
  top: 0;
  left: 0;
  right: 0;
  display: block;
  z-index: 1002;
  position: fixed;
  background-color: var(--background);
}
.line-0-3-4 {
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
   opacity: 1;
  position: absolute;
  background-color: var(--border);
}
.nav-0-3-5 {
  padding: 12px 15px;
}
@media (min-width: 1024px) {
  .nav-0-3-5 {
    padding: 12px 25px;
  }
}
.list-0-3-6 {
  margin: 0 -7.5px;
  display: flex;
  padding: 0;
  list-style: none;
  justify-content: space-between;
}
@media (min-width: 1024px) {
  .list-0-3-6 {
    margin: 0 -12.5px;
  }
}
.list-0-3-6 li {
  width: calc(100% / 7);
  margin: 0 7.5px;
   opacity: 1;
}
@media (min-width: 1024px) {
  .list-0-3-6 li {
    margin: 0 12.5px;
  }
}
.menuItem-0-3-7 {
  opacity: 1;
}
@media (min-width: 1024px) {
  .menuItem-0-3-7 {
    display: flex;
    align-items: center;
  }
}
.mobileMenu-0-3-8 {
  display: flex;
  justify-content: flex-end;
}
@media (min-width: 1024px) {
  .mobileMenu-0-3-8 {
    opacity: 1;
  }
}
.logoLink-0-3-9 {
  color: currentColor;
  display: inline-flex;
  min-height: 50px;
  align-items: center;
  text-decoration: none;
}
.logo-0-3-10 {
  width: 105px;
  height: 26px;
}
.link-0-3-11 {
  color: currentColor;
  border: none;
  cursor: pointer;
  display: inline-flex;
  padding: 0;
  overflow: hidden;
  position: relative;
  font-size: 10px;
  background: transparent;
  min-height: 42px;
  align-items: center;
  font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
  line-height: 1.2;
  border-radius: 0;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  text-decoration: none;
}
.link-0-3-11:after {
  top: 50%;
  left: 0;
  right: 0;
  height: 1px;
  content: "";
  display: block;
  position: absolute;
  transform: translate(-110%, 1.2em);
  transition: transform 0.15s ease-in-out;
  background-color: currentColor;
  transform-origin: 0% 50%;
}
.link-0-3-11:hover:after,
.link-0-3-11.active:after {
  transform: translate(0%, 1.2em);
}
.cartLink-0-3-12:after {
  right: 22px;
}
@media (min-width: 1024px) {
  .cartCount-0-3-13 {
    width: 20px;
    height: 20px;
  }
}
.MobileMenu-container-0-3-22 {
  top: 0;
  left: 0;
  color: currentColor;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  position: fixed;
  transform: translate(100%, 0);
  pointer-events: none;
  background-color: var(--background);
}
.MobileMenu-container-0-3-22.open {
  pointer-events: all;
}
.MobileMenu-inner-0-3-23 {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  display: flex;
  position: absolute;
  padding-top: 42px;
  flex-direction: column;
}
.MobileMenu-header-0-3-24 {
  padding: 30px 15px;
}
.MobileMenu-breadcrumb-0-3-25 {
  font-size: 12px;
  visibility: hidden;
  align-items: center;
  line-height: 1.4;
  margin-bottom: 30px;
  text-transform: uppercase;
}
.MobileMenu-breadcrumb-0-3-25.show {
  visibility: visible;
}
.MobileMenu-title-0-3-26 {
  font-size: 20px;
  line-height: 1.4;
}
.MobileMenu-menuItems-0-3-27 {
  margin: 0;
  display: block;
  padding: 0;
  overflow: hidden;
  list-style: none;
  padding-bottom: 1px;
}
.MobileMenu-menuItem-0-3-28 {
  display: block;
  border-top: 1px solid var(--border);
  border-bottom: 1px solid var(--border);
  margin-bottom: -1px;
}
.MobileMenu-link-0-3-29 {
  width: 100%;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 15px;
  font-size: 15px;
  background: transparent;
  text-align: left;
  align-items: center;
  font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
  letter-spacing: 0.03em;
  text-transform: none;
  justify-content: space-between;
  text-decoration: none;
}
.MobileMenu-count-0-3-30 {
  font-size: 9px;
}
.panel-0-3-44 {
  height: 100vh;
  opacity: 1;
  overflow: auto;
  position: relative;
  transform: translate(110%, 0);
  background-color: var(--background);
}
@media (min-width: 1024px) {
  .panel-0-3-44 {
    transform: translate(-110%, 0);
  }
}
.panel-0-3-44.padding {
  padding: 20px 15px 15px;
}
.panel-0-3-44.flex {
  display: flex;
  flex-direction: column;
}
.panel-0-3-44::-webkit-scrollbar {
  width: 5px;
}
.panel-0-3-44::-webkit-scrollbar-track {
  background: transparent;
}
.panel-0-3-44::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: var(--border);
}
@media (min-width: 1024px) {
  .panel-0-3-44.padding {
    padding: 30px 25px 25px;
  }
}
.breadcrumb-0-3-46 {
  cursor: pointer;
  display: flex;
  font-size: 12px;
  min-height: 42px;
  visibility: hidden;
  align-items: center;
  line-height: 1.4;
  margin-bottom: 30px;
  text-transform: uppercase;
}
@media (max-width: 1023.98px) {
  .breadcrumb-0-3-46.show {
    visibility: visible;
  }
}
.title-0-3-47 {
  display: flex;
  font-size: 20px;
  align-items: center;
  line-height: 1.4;
  margin-bottom: 30px;
}
@media (min-width: 1024px) {
  .title-0-3-47 {
    margin-bottom: 50px;
  }
}
.titleLink-0-3-48 {
  transition: opacity 0.15s ease-in-out;
  text-decoration: none;
}
.titleLink-0-3-48:hover {
  opacity: 0.5;
}
.count-0-3-49 {
  margin-left: 5px;
}
.closeButton-0-3-50 {
  top: 24px;
  right: 15px;
  width: 26px;
  border: none;
  cursor: pointer;
  height: 26px;
  margin: 0;
  display: flex;
   opacity: 1;
  padding: 0;
  z-index: 1;
  position: absolute;
  transition: opacity 0.5s ease-in-out;
  align-items: center;
  border-radius: 50%;
  pointer-events: none;
  justify-content: center;
  background-color: transparent;
}
@media (min-width: 1024px) {
  .closeButton-0-3-50 {
    top: 24px;
    right: 24px;
    display: flex;
  }
}
.closeButton-0-3-50:hover,
.closeButton-0-3-50.fill {
  background-color: #f2f2f2;
}
.closeButton-0-3-50.show {
  opacity: 1;
  pointer-events: all;
}
.backIcon-0-3-51 {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}
.container-0-3-52 {
  height: 100%;
  display: flex;
  font-size: 12px;
  flex-direction: column;
}
.imageContainer-0-3-53 {
  width: 100%;
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
}
.image-0-3-54 {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  bottom: 0;
  height: 100%;
  position: absolute;
  object-fit: cover;
}
.product-0-3-55 {
  display: flex;
  padding: 15px;
  flex-grow: 0;
  min-height: 280px;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: space-between;
}
@media (min-width: 1024px) {
  .product-0-3-55 {
    padding: 25px;
  }
}
.title-0-3-56 {
  display: block;
  font-size: 15px;
  margin-bottom: 25px;
}
.productDetails-0-3-57 {
  display: flex;
  margin-bottom: 25px;
}
.productDetails-0-3-57 > * {
  width: 50%;
}
.locationDetails-0-3-58 {
  margin-bottom: 25px;
}
.caption-0-3-59 {
  display: block;
  opacity: 0.5;
}
.detailsPanel-0-3-60 {
  overflow: hidden;
}
.container-0-3-215 {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1003;
  overflow: hidden;
  position: fixed;
  pointer-events: none;
}
.overlay-0-3-216 {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
   opacity: 1;
  position: absolute;
  background-color: #000;
}
.drawerWrapper-0-3-217 {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  transform: translate(-100%, 0);
  background-color: var(--background);
}
@media (min-width: 1024px) {
  .drawerWrapper-0-3-217 {
    width: 480px;
    bottom: 0;
    height: auto;
  }
}
.drawerContainer-0-3-218 {
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
}
.header-0-3-219 {
  margin: 25px 15px 0;
  display: flex;
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: space-between;
}
@media (min-width: 1024px) {
  .header-0-3-219 {
    margin: 25px 25px 0;
  }
}
.header-0-3-219:before {
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  /* content: ""; */
  opacity: 0.3;
  position: absolute;
  background-color: var(--border);
}
.headerTop-0-3-220 {
  display: flex;
  align-items: start;
  margin-bottom: 25px;
  justify-content: space-between;
}
@media (min-width: 1024px) {
  .headerTop-0-3-220 {
    margin-bottom: 25px;
  }
}
.drawerBody-0-3-221 {
  display: flex;
  padding: 15px;
  overflow: auto;
  position: relative;
  flex-grow: 1;
  margin-top: -8px;
  flex-shrink: 1;
  padding-top: 0;
  flex-direction: column;
}
.drawerBody-0-3-221.empty:after {
  opacity: 1;
}
@media (min-width: 1024px) {
  .drawerBody-0-3-221 {
    padding: 25px;
    padding-top: 0;
  }
}
.title-0-3-222 {
  display: flex;
  font-size: 20px;
  align-items: center;
  font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
  text-transform: none;
}
.closeButton-0-3-223 {
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  margin-left: auto;
  border-radius: 0;
  background-color: transparent;
}
.container-0-3-34 {
  opacity: 1;
  padding: 15px;
  position: relative;
  transform: translateX(-110%);
  grid-column: 1 / span 4;
  border-right: 1px solid var(--border);
  justify-content: space-between;
  background-color: var(--background);
}
@media (min-width: 1024px) {
  .container-0-3-34 {
    display: flex;
    padding: 23px 25px 25px;
    z-index: 4;
    grid-column: 1;
    margin-left: -25px;
    flex-direction: column;
  }
}
.list-0-3-35 {
  margin: 40px 0 0;
  padding: 0;
  list-style: none;
}
.item-0-3-36 {
  opacity: 0.5;
  font-size: 15px;
  transition: opacity 0.25s ease-in-out;
  font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
  text-transform: none;
}
.item-0-3-36:after {
  top: 1.2em;
}
.item-0-3-36:hover,
.item-0-3-36.selected {
  opacity: 1;
}
.button-0-3-37 {
  align-self: start;
}
.buttonLabel-0-3-38 {
  opacity: 0.5;
  margin-left: 5px;
  white-space: nowrap;
}
.container-0-3-31 {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0;
  z-index: 1003;
  overflow: hidden;
  position: fixed;
  pointer-events: none;
}
@media (min-width: 1024px) {
  .container-0-3-31 {
    padding: 0 25px;
  }
}
.container-0-3-31 > .panel1 {
  grid-row: 1;
  grid-column: 1 / span 4;
}
.container-0-3-31 > .panel2 {
  grid-row: 1;
  grid-column: 1 / span 4;
}
.container-0-3-31 > .panel3 {
  grid-row: 1;
  grid-column: 1 / span 4;
  margin-right: 15px;
}
@media (min-width: 1024px) {
  .container-0-3-31 > .panel3 {
    z-index: 1;
    grid-column: 6 / span 2;
    margin-left: -25px;
    border-right: 1px solid var(--border);
    margin-right: -26px;
  }
}
@media (min-width: 1024px) {
  .container-0-3-31 > .panel2 {
    z-index: 2;
    grid-column: 4 / span 2;
    margin-left: -25px;
    border-right: 1px solid var(--border);
  }
}
@media (min-width: 1024px) {
  .container-0-3-31 > .panel1 {
    z-index: 3;
    grid-column: 2 / span 2;
    margin-left: -25px;
    border-right: 1px solid var(--border);
  }
}
.overlay-0-3-32 {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
   opacity: 1;
  position: absolute;
  background-color: #000;
}
.formContainer-0-3-98 {
  padding-bottom: 50px;
}
@media (min-width: 1024px) {
  .formContainer-0-3-98 {
    padding-bottom: 50px;
  }
}
.form-0-3-99 {
  margin-bottom: 25px;
}
@media (min-width: 1024px) {
  .form-0-3-99 {
    margin-bottom: 25px;
  }
}
.errors-0-3-100 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.error-0-3-101 {
  color: #cf6c4f;
  font-size: 10px;
  text-align: center;
  font-family: "ABCMonumentGroteskMono", "Lucida Sans", Tahoma, sans-serif;
  line-height: 1;
  margin-bottom: 25px;
  text-transform: uppercase;
}
@media (min-width: 1024px) {
  .error-0-3-101 {
    margin-bottom: 25px;
  }
}
.error-0-3-101:last-child {
  margin-bottom: 0;
}
.inputContainer-0-3-102 {
  height: 50px;
  display: flex;
  position: relative;
  align-items: center;
}
.inputContainer-0-3-102.hasError {
  color: #cf6c4f;
}
.inputContainer-0-3-102:focus-within .bottomBorder {
  opacity: 1;
}
.inputContainer-0-3-102.hasError > label {
  opacity: 1;
}
.inputLabel-0-3-103 {
  flex: 1;
  opacity: 0.5;
  font-size: 10px;
  font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
  line-height: 1;
  text-transform: uppercase;
}
.input-0-3-104 {
  flex: 2;
  color: currentColor;
  border: none;
  outline: none;
  padding: 16px 0;
  font-size: 12px;
  font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
  line-height: 1;
  border-radius: 0;
  background-color: transparent;
}
.input-0-3-104:-webkit-autofill,
.input-0-3-104:-webkit-autofill:hover,
.input-0-3-104:-webkit-autofill:focus .input-0-3-104:-webkit-autofill,
.input-0-3-104:-webkit-autofill:hover
  .input-0-3-104:-webkit-autofill:focus {
  box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
  caret-color: currentColor;
}
.bottomBorder-0-3-105 {
  opacity: 0.2;
  transition: opacity 0.25s ease-in-out;
}
.hiddenButton-0-3-106 {
  opacity: 1;
}
.container-0-3-86 {
  margin-top: 0;
}
@media (min-width: 1024px) {
  .container-0-3-86 {
    margin-top: 75px;
  }
}
.image-0-3-87 {
  margin-bottom: 20px;
}
@media (min-width: 1024px) {
  .image-0-3-87 {
    margin-bottom: 35px;
  }
}
.content-0-3-88 {
  width: 250px;
  margin: auto;
  text-align: center;
}
.title-0-3-89 {
  font-size: 15px;
  margin-bottom: 10px;
}
@media (min-width: 1024px) {
  .title-0-3-89 {
    margin-bottom: 20px;
  }
}
.copy-0-3-90 {
  opacity: 0.5;
  font-size: 12px;
  white-space: pre-wrap;
  margin-bottom: 10px;
}
@media (min-width: 1024px) {
  .copy-0-3-90 {
    margin-bottom: 20px;
  }
}
.linkLabel-0-3-91 {
  opacity: 0.5;
}
.container-0-3-61 {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1003;
  overflow: hidden;
  position: fixed;
  pointer-events: none;
}
.overlay-0-3-62 {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
   opacity: 1;
  position: absolute;
  background-color: #000;
}
.cart-0-3-63 {
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: 25px 15px 15px;
  position: absolute;
  transform: translate(100%, 0);
  background-color: var(--background);
}
@media (min-width: 1024px) {
  .cart-0-3-63 {
    width: 480px;
    bottom: 0;
    height: auto;
    padding: 25px;
  }
}
.cartContainer-0-3-64 {
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
}
.header-0-3-65 {
  display: flex;
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: space-between;
}
.headerTop-0-3-66 {
  display: flex;
  align-items: start;
  margin-bottom: 25px;
  justify-content: space-between;
}
@media (min-width: 1024px) {
  .headerTop-0-3-66 {
    margin-bottom: 25px;
  }
}
.headerBottom-0-3-67 {
  margin-bottom: 45px;
}
@media (min-width: 1024px) {
  .headerBottom-0-3-67 {
    margin-bottom: 45px;
  }
}
.backToCartButton-0-3-68 {
  visibility: hidden;
}
.backToCartButton-0-3-68.show {
  visibility: visible;
}
.content-0-3-69 {
  overflow: auto;
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
}
.content-0-3-69:before {
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  /* content: ""; */
  opacity: 0.3;
  position: absolute;
  background-color: var(--border);
}
.content-0-3-69.empty:after {
  opacity: 1;
}
.innerContent-0-3-70 {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow-y: auto;
}
.formContent-0-3-71 {
   opacity: 1;
}
.footer-0-3-72 {
  flex-grow: 0;
  flex-shrink: 0;
}
.title-0-3-73 {
  display: flex;
  font-size: 20px;
  align-items: center;
  font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
  text-transform: none;
}
.count-0-3-74 {
  margin-left: 5px;
}
.copy-0-3-75 {
  font-size: 10px;
  text-align: center;
  white-space: pre-wrap;
  margin-bottom: 15px;
}
.emptyCopy-0-3-76 {
  font-size: 15px;
  margin-top: 15px;
}
.deliveryCopy-0-3-77 {
  opacity: 0.5;
}
.successCopy-0-3-78 {
  color: #219653;
}
.errorCopy-0-3-79 {
  color: #eb5757;
}
.submitButton-0-3-80 {
  width: 100%;
  padding: 16px 32px;
}
.closeButton-0-3-81 {
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  border-radius: 0;
  background-color: transparent;
}
.successMessage-0-3-82 {
  top: 50%;
  left: 50%;
  width: 100%;
  display: flex;
  position: absolute;
  max-width: 340px;
  transform: translateX(-50%) translateY(-50%);
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.successTitle-0-3-83 {
  font-size: 15px;
}
.successSubtitle-0-3-84 {
  opacity: 0.5;
  font-size: 12px;
}
.form-0-3-169 {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.errors-0-3-170 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.error-0-3-171 {
  color: #cf6c4f;
  font-size: 10px;
  margin-top: 10px;
  text-align: center;
  font-family: "ABCMonumentGroteskMono", "Lucida Sans", Tahoma, sans-serif;
  line-height: 1;
  margin-bottom: 25px;
  text-transform: uppercase;
}
@media (min-width: 1024px) {
  .error-0-3-171 {
    margin-bottom: 25px;
  }
}
.error-0-3-171:last-child {
  margin-bottom: 0;
}
.formFieldContainer-0-3-172 {
  display: flex;
  position: relative;
}
.formFieldContainer-0-3-172.hasError {
  color: #cf6c4f;
}
.formFieldContainer-0-3-172:focus-within .bottomBorder {
  opacity: 1;
}
.formFieldContainer-0-3-172.hasError > label {
  opacity: 1;
}
.inputContainer-0-3-173 {
  height: 50px;
  align-items: center;
}
.textareaContainer-0-3-174 {
  padding: 20px 0 0;
  flex-grow: 2;
  flex-direction: column;
}
.textareaContainer-0-3-174 label {
  flex: 0;
}
.textareaContainer-0-3-174 textarea {
  flex: auto;
  resize: none;
  font-size: 12px;
  max-width: 100%;
}
.textareaContainer-0-3-174 textarea::placeholder {
  opacity: 0.3;
}
.inputLabel-0-3-175 {
  flex: 1;
  opacity: 0.5;
  font-size: 10px;
  font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
  line-height: 1;
  text-transform: uppercase;
}
.input-0-3-176 {
  flex: 2;
  color: currentColor;
  border: none;
  outline: none;
  padding: 16px 0;
  font-size: 12px;
  font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
  line-height: 1;
  border-radius: 0;
  background-color: transparent;
}
.input-0-3-176:-webkit-autofill,
.input-0-3-176:-webkit-autofill:hover,
.input-0-3-176:-webkit-autofill:focus .input-0-3-176:-webkit-autofill,
.input-0-3-176:-webkit-autofill:hover
  .input-0-3-176:-webkit-autofill:focus {
  box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
  caret-color: currentColor;
}
.bottomBorder-0-3-177 {
  opacity: 0.2;
  transition: opacity 0.25s ease-in-out;
}
.hiddenButton-0-3-178 {
  opacity: 1;
}
.container-0-3-150 {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1003;
  overflow: hidden;
  position: fixed;
  pointer-events: none;
}
.overlay-0-3-151 {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
   opacity: 1;
  position: absolute;
  background-color: #000;
}
.quoteForm-0-3-152 {
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  transform: translate(100%, 0);
  background-color: var(--background);
}
@media (min-width: 1024px) {
  .quoteForm-0-3-152 {
    width: 480px;
    bottom: 0;
    height: auto;
  }
}
.quoteFormContainer-0-3-153 {
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
}
.header-0-3-154 {
  margin: 25px 15px 0;
  display: flex;
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: space-between;
}
@media (min-width: 1024px) {
  .header-0-3-154 {
    margin: 25px 25px 0;
  }
}
.header-0-3-154:before {
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  /* content: ""; */
  opacity: 0.3;
  position: absolute;
  background-color: var(--border);
}
.headerTop-0-3-155 {
  display: flex;
  align-items: start;
  margin-bottom: 25px;
  justify-content: space-between;
}
@media (min-width: 1024px) {
  .headerTop-0-3-155 {
    margin-bottom: 25px;
  }
}
.content-0-3-156 {
  display: flex;
  padding: 0 15px;
  overflow: auto;
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
  flex-direction: column;
}
.content-0-3-156.empty:after {
  opacity: 1;
}
@media (min-width: 1024px) {
  .content-0-3-156 {
    padding: 0 25px;
  }
}
.quoteProcessCopy-0-3-157 {
  margin: 0;
  padding: 40px 0;
  position: relative;
  font-size: 15px;
}
.quoteProcessCopy-0-3-157:before {
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  /* content: ""; */
  opacity: 0.3;
  position: absolute;
  background-color: var(--border);
}
.quoteSubmissionCopy-0-3-158 {
  margin: 15px auto 0;
  font-size: 10px;
  max-width: 260px;
}
.formContent-0-3-159 {
   opacity: 1;
  flex-grow: 2;
}
.footer-0-3-160 {
  padding: 0 15px 15px;
  flex-grow: 0;
  flex-shrink: 0;
}
@media (min-width: 1024px) {
  .footer-0-3-160 {
    padding: 0 25px 25px;
  }
}
.title-0-3-161 {
  display: flex;
  font-size: 20px;
  align-items: center;
  font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
  text-transform: none;
}
.count-0-3-162 {
  margin-left: 5px;
}
.copy-0-3-163 {
  font-size: 10px;
  text-align: center;
  white-space: pre-wrap;
  margin-bottom: 15px;
}
.submitButton-0-3-164 {
  width: 100%;
  padding: 16px 32px;
}
.closeButton-0-3-165 {
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  border-radius: 0;
  background-color: transparent;
}
.successMessage-0-3-166 {
  top: 50%;
  left: 50%;
  width: 100%;
  display: flex;
  position: absolute;
  max-width: 340px;
  transform: translateX(-50%) translateY(-50%);
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.successTitle-0-3-167 {
  margin: 0 0 20px;
  font-size: 15px;
}
.successSubtitle-0-3-168 {
  margin: 0 0 20px;
  opacity: 0.5;
  font-size: 12px;
}
*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}
:root {
  --border: #808080;
  --background: #ffffff;
  --foreground: #3a3a1f;
  --scrollbar-width: 0px;
}
html {
  line-height: 1.5;
}
body {
  color: var(--foreground);
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  background-color: var(--background);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  font-family: "NHaasGroteskDSPro", "Lucida Sans", Tahoma, sans-serif;
  font-weight: 400;
  margin-bottom: 0.4em;
  text-transform: uppercase;
}
h1 {
  font-size: 45px;
  line-height: 0.95;
}
@media (min-width: 1024px) {
  h1 {
    font-size: 65px;
  }
}
h2 {
  font-size: 35px;
  line-height: 0.9;
  letter-spacing: 0.65px;
}
@media (min-width: 1024px) {
  h2 {
    font-size: 65px;
  }
}
h3 {
  font-size: 48px;
}
h4 {
  font-size: 32px;
}
h5 {
  font-size: 20px;
  margin-bottom: 25px;
  text-transform: none;
}
h6 {
  font-size: 15px;
  text-transform: none;
}
p {
  margin: 0 0 15px;
  font-size: 15px;
}
.srOnly {
  clip: rect(1px, 1px, 1px, 1px) !important;
  width: 1px !important;
  border: 0 !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  position: absolute !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
}
a {
  color: currentColor;
  text-decoration: underline;
}
a:hover {
  text-decoration: none;
}
button {
  color: currentColor;
  font-size: 10px;
  font-family: "ABCMonumentGroteskMono", "Lucida Sans", Tahoma, sans-serif;
  font-weight: 400;
  text-transform: uppercase;
}
.scrollbar-track.scrollbar-track-y {
  z-index: 2000;
}
main .scrollbar-track {
  width: 5px;
  border-radius: 2px;
  background-color: transparent;
}
main .scrollbar-thumb.scrollbar-thumb-y {
  width: 3px;
  border-radius: 2px;
}
.chatlio-title-bar {
  opacity: 1;
}
.chatlio-widget-body {
  bottom: 10px !important;
}
@media (min-width: 1024px) {
  .chatlio-widget-body {
    right: 10px !important;
    bottom: 60px !important;
  }
}
.Layout-main-0-3-1 {
  width: 100%;
  /* height: 100vh; */
  overflow-x: hidden;
  /* overflow-y: auto; */
}
.Layout-inner-0-3-2 {
  padding-top: 72px;
}
/* </style> */.productpage{
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: right;
    /* align-items: l; */
    min-height: 100vh;
    /* background: #262626; */
    font-family: "poppins", sans-serif;
}
  .mainproductcontainer {
    /* width: 1200px; */
    /* min-height: 600px; */
    display: flex;
    
    flex-wrap:wrap;
    margin-top: 1rem;
    /* padding-right: 4rem; */
    margin-right: 4rem;

  }
  .card {
    position: relative;
    width: calc(50% - 20px);
    height: 300px;
   
    margin: 10px;
    box-sizing: border-box;
  }
  .card .imgBx {
    position: absolute;
    width: 50%;
    height: 100%;
  }
  .card .imgBx img {
    position: absolute;
    top: 0;
    left: 0;
    width: 80%;
    /* height: 100%; */
    height: 80%;
    object-fit: cover;
    
    border-radius: 5px;
    /* margin-top: -19px; */
  }
  .card .details {
    position: absolute;
    right: 0;
    width: 59%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
   
    align-items: flex-start;
  }
  .card .details .content {
    padding: 5px;
    box-sizing: border-box;
  }
  .card .details .content h2 {
    margin: 0;
    padding-top: -810px;
    font-size: 18px;
    /* line-height: 0.85em; */
    font-weight: 600;
    color: #005E8C;
  }
  .card .details .content h2 span {
    font-size: 15px;
    color: #5C5C5C;
    font-weight: 500;
  }
  .card .details .content ul {
    margin: 0;
    padding: 0;
    display: flex;
  }
  .card .details .content ul li {
    list-style: none;
    margin-left: 2px;
  }
  .card .details .content ul li a {
    color: #fff;
    border: none;
  }
 
  .card .details .content a {
    display: inline-block;
    text-decoration: none;
    padding: 5px 10px;
    margin-top: 10px;
    border: 2px solid #262626;
    color: #262626;
    font-weight: 500;
  }
  .card .details .content a:hover {
    background: #262626;
    color: #fff;
  }
  @media (max-width: 1200px) {
    .mainproductcontainer {
      width: 100%;
    }
  }
  @media (max-width: 991px) {
    .card {
      width: calc(70% - 20px);
      margin: 10px auto;
      flex-direction: row;
    }
  }
  @media (max-width: 768px) {
    .card {
      width: calc(70% - 20px);
      margin: 10px auto;
    }
    .card .imgBx {
      position: relative;
      width: 100%;
      height: 200px;
    }
    .card .details {
      position: relative;
      width: 100%;
      height: 200px;
    }
  }
  @media (max-width: 480px) {
    .card {
      width: calc(100% - 40px);
      margin: 10px auto;
    }
  }
  .product-line-main{
    width:0%;
	margin:auto;
	padding:0.01em; 
  	background-color:rgb(158, 13, 13); 
	animation: line 1.25s ease forwards;
  
  margin-top: 2vh;
  
  /* margin-left: -89px; */
  }
  @keyframes line {
    from { width: 0%; }
    to { width:100%; }
  }
  .product-head{
    /* color: #3A3A1F; */
 
    left: 0px;
    position: absolute;
    margin-top: 8rem;
    margin-left: 2.5rem;
     opacity: 1;
  }

  input {
    /* position: absolute;
     opacity: 1;
    z-index: -1; */
}
.row {
    display: flex;
}
.row .col {
    flex: 1;
}
.row .col:last-child {
    margin-left: 1em;
}
/* Accordion styles */
.tabs {
    /* border-radius: 8px; */
    overflow: hidden;
    /* border: 1px solid rgba(58, 58, 31, 0.7); */
    /* width: 350%; */
    /* box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5); */
}
.tab {
    width: 100%;
    /* color:  rgba(58, 58, 31, 0.7);; */
    color: rgba(92, 92, 92, 0.70);
    overflow: hidden;
}
.tab-label {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    text-transform: uppercase;
  
    cursor: pointer;
   /* Icon */
}
.tab-label:hover {
  
}
.tab-label::after {
    content: "\276F";
    width: 1em;
    height: 1em;
    text-align: center;
    transition: all 0.35s;
}
.tab-content {
    max-height: 0;
    /* padding: 0 1em; */
    color: #2c3e50;
    background: white;
    transition: all 0.35s;
}
.tab-close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
 
    cursor: pointer;
}
.tab-close:hover {
   
}
input:checked + .tab-label {

}
input:checked + .tab-label::after {
    transform: rotate(90deg);
}
input:checked ~ .tab-content {
    max-height: 100vh;
    /* padding: 1em; */
}

@media screen and(min-width:1052px){
  .productaccordian{
    /* margin: 45rem; */
    position: absolute;
    left: 3rem;
    top: 40rem;
    width: 30%;
    /* margin: 24rem; */
}
}
.productaccordian{
    /* margin: 45rem; */
    position: absolute;
    left: 0rem;
    top: 40rem;
    width: 30%;
    /* margin: 24rem; */
}
.tab:nth-child(-n+5) {
    border-bottom: 1px solid rgba(58, 58, 31, 0.7);
    /* margin-bottom: 1px; */
}
.card{
  border: navajowhite;
}
.card:nth-child(-n+10) {
    border-bottom: 1px solid rgba(58, 58, 31, 0.7);
    /* margin-top: 45%; */
    /* padding-top: 2rem; */
    /* padding-bottom: 24rem; */
   /* margin-top: 4rem; */
   margin-bottom: 24px     ;
   /* position: absolute; */
   /* position: relative; */
   /* border: 0px;
    */
    border-radius: 0px;
}
.productvline{
  height: 137%;

  opacity: 0.8;
  border-left: 1px solid #818170;
  left: 32%;

  /* margin-top: -99px; */
margin-top: 3.8%;
  position: absolute;
  animation: 2.5s cubic-bezier(0.25, 1, 0.3, 1) wipe-in-down both;
}
@keyframes wipe-in-down {
  from {
    clip-path: inset(0 0 100% 0);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

.producthline-01{
  position: absolute;
width: 100%;
height: 0px;
/* left: 1440px; */
top: 10.5%;

opacity: 0.8;
border-bottom: 1px solid rgba(58, 58, 31, 0.8);
animation: 2.5s cubic-bezier(.25, 1, .30, 1) wipe-in-right both;
}
@keyframes wipe-in-right {
  from {
    clip-path: inset(0 100% 0 0);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}


@keyframes wipe-in-down {
  from {
    clip-path: inset(0 0 100% 0);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

  

/* @keyframes linep {
  from { height: 0%; }
  to { height:100%; }
} */

@media screen and (max-width:1200px){
  .mainproductcontainer{
    /* margin-top: 55rem; */
    /* margin-bottom: 94rem; */
  }
  .productaccordian{
    width: 95%;
  }
    .productvline{
    display: none;
  }
 .product-head{
  margin-top: 5rem;
 }
 .producthline-01{
  top: 18%;
 }
}
/* .productaccordian .tab-label */
@media screen and (max-width:1400px) and (min-width:1200px){
  .productaccordian{
    width: 85%;
  }
  .mainproductcontainer{
    /* margin-top: 65rem; */
    margin-right: 7rem;
  }
  .productvline{
    display: none;
  }
  .producthline-01{
    top: 15%;
   }
}

.productaccordian input{
  display: none;
}


.productpage{
  margin-bottom: 195px;
}


.productpage .card{
  cursor: pointer;
}
.productpage .card{
 
  overflow: hidden; 
}
.imgBx {
  transition: 0.3s;
}

.imgBx:hover {
  transform: scale(1.1);
}


@media screen and (max-width:500px){
.producthline-01{
margin-top: 3rem;
}
}



@media (max-width: 1400px) {
  .productaccordian{
    top: 23rem;
    margin-left: 2%;
  }
  .mainproductcontainer{
    /* margin-top: 35rem; */
    margin-left: auto;
    margin-right: auto;
  }
}


@media screen and (max-width:1400px){
  
    .productvline{
    display: none;
  }
 .product-head{
  margin-top: 8rem;
 }
 .producthline-01{
  top: 13%;
 }
}
@media (max-width: 1100px) {
  .productaccordian{
    top: 23rem;
    margin-left: 2%;
  }
  .mainproductcontainer{
    /* margin-top: 48rem; */
    margin-left: auto;
    margin-right: auto;
  }
  .product-head{
    margin-left: 4%;
  }
}

@media screen and (max-width:950px){
  .mainproductcontainer{
    /* margin-top: 61rem; */
    /* margin-bottom: 94rem; */
    /* margin-right: 55rem; */
    /* margin-left: -4rem; */
    /* align-items: flex-start; */
  }
  .productaccordian{
    margin-top: 5rem;
  }
  .product-head{
    margin-top: 12rem;
    margin-left: 3rem;
   }
   .producthline-01{
    margin-top: -4rem;
   }
   .accordionItem-0-3-239{
    width: 100%;
   }
   .card .details .content h2{
    font-size: 15px;
   }
   .card .imgBx img{
    width: 100%;
   }
   .card .details .content {
    padding: 0px 7px 7px 7px;
    box-sizing: border-box;
}
footer{
     padding: -710px 0 0;
}
.mainpppageb .title-0-3-226{
  font-size: 20px;
  left: 0;
  margin-left: 0px;
}
}@media (min-width: 1024px){
/* <style> */
.image-0-3-231 {
    width: calc(50% - 12.5px);
    grid-column: span 2;
}
}
/* <style> */
.image-0-3-231 {
    width: 50%;
    flex-grow: 0;
    flex-shrink: 0;
}

@media (min-width: 1024px){
/* <style> */
.tile-0-3-230 {
    grid-column: span 2;
}
}

/* <style> */
.tile-0-3-230 {
    display: flex;
    padding: 20px 0;
    position: relative;
    text-decoration: none;
}
@media (max-width: 1524px){
.card{
height: 200px;
}
}


.mainproductcontainer .card{
  cursor: pointer;
}

.productsclleft{
  position: sticky;
  top: 100%;
}

.mainpppageb{
  margin-top: 4.6rem;
}

 .mainpppageb .accordionItem-0-3-239{
  width: 100%;
 }