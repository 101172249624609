
.gvkmainlogo{
  width: 5rem;
  height: 3rem;

  margin-left: 2.5rem;
  margin-top: 2rem;
  cursor: pointer;
 
}
.theme-options {
  
  /* position: absolute; */
  top: 0;
  /* background-color: red; */
  position: sticky;

}

.mr-auto{
 
  position: absolute;
  right: 5rem;
  cursor: pointer;
  

}

.gvknavbar{
  background-color: inherit;
  height: 80px;
  /* height: 4%; */
  /* position: sticky; */
}

* {
  margin: 0;
  padding: 0;
}

body {
  /* background-color: #b5fac7; */
}

.pages {
  /* color: #316685; */
  text-align: center;
  font-size: calc(1.5rem + 2vw);
  margin-top: 10%;
  
 
}

.navbar {
  background-color: inherit;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 20;
}

.nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  /* background-color: red; */
  /* max-width: 1500px; */
}

.main-container{
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  /* background-color:rgba(0,0,0,0.3); */
}

.nav-logo {
  /* color: #f5b921; */
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  flex-grow: 1;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  margin-right: -2rem;
}

.nav-links {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  border-bottom: 3px solid transparent;
}
.fa-code {
  margin-left: 1rem;
}

.nav-item {
  line-height: 40px;
  margin-right: 1rem;
}

.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
  /* width: 100%;
  background: #3a3a1f; */
}

.nav-item.active {
  color: #005E8C;
  /* border: 1px solid #ffdd40; */
}

.nav-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1pxsolid #fff;
    position: absolute;
    top: 80px;
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
  }
  .nav-container{
    /* border-bottom: 1px solid red; */
    padding-bottom: 1em;
  }

  .nav-menu.active {
    /* background: #1f5156; */
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .nav-item .active {
    color: #005E8C;
    border: none;
  }
  .nav-links {
    padding: 1.5rem;
    width: 100%;
    display: table;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    /* color: #ffdd40; */
  }
}

@media (max-width: 1400px) {
 
  .gvkmainlogo{
    margin-left: 0px;
    margin-left: 0.1rem;
    margin-top: 0.5rem;
    /* position: absolute; */
  }
}

@media (max-width: 700px) {
 
  .gvkmainlogo{
    margin-left: 0px;
    margin-left: 0.1rem;
    margin-top: 1.6rem;
    cursor: pointer;
    width: 4rem;
    height: 2rem;
    /* position: absolute; */
  }
}

.line-0-3-4 {
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  opacity: 1;
  position: absolute;
  background-color: var(--border);
}
.main-container{
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  /* background-color:rgba(0,0,0,0.3); */
  /* border-bottom: 2px solid red; */
}

.nav-logo {
  /* color: #f5b921; */
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  flex-grow: 1;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  margin-right: -2rem;
}

.nav-links {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  border-bottom: 3px solid transparent;
}
.fa-code {
  margin-left: 1rem;
}

.nav-item {
  line-height: 40px;
  margin-right: 1rem;
}

.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
  width: 100%;
  /* background: #ffdd40; */
}

.nav-item.active {
  color: #005E8C;
  border: 1px solid #005E8C;
}

.nav-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1pxsolid #fff;
    position: absolute;
    top: 65px;
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
    background-color: white;
    height: 800px;
    font-size: 1rem;
    align-items: baseline;
    z-index: 500;
  }

  .nav-menu.active {
    /* background: #1f5156; */
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .nav-item .active {
    /* color: #ffdd40; */
    border: none;
  }
  .nav-links {
    padding: 1.5rem;
    width: 100%;
    display: table;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: -1.0rem;
    right: -1.7rem;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #005E8C;
  }
  .nav-logo-image {
    width: 4rem;
  }
  .header-0-3-3 LI A{
    /* font-size: 2rem; */
    color: #5c5c5c;
  }
  .nav-menu li{
    /* border-bottom: 1px solid red;
    width: 100%; */
  }
  .nav-menu li a{
    align-items: baseline;
    /* border-bottom: 1px solid red; */
  }
}


.nav-menu li {
  /* width: calc(100% / 7); */
  /* margin: 0 7.5px; */
  opacity: 1;
  padding: 12px 35px;
}

@media (min-width:1024px) {
  .nav-menu li {
      /* margin: 0 12.5px; */
      /* padding: 12px 75px; */
      cursor: pointer;

    font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
  
  }
}

.nav-logo{
  text-decoration: none;
}
.nav-logo:hover{
  border: none;
}

.nav-container ul{
  list-style: none;
  display: flex;
  /* justify-content: space-between;  */
}
.nav-logo-image{
  width: 6.5rem;
  margin-left: -0.8rem;
}
@media (min-width:934px) {
  .nav-menu li:nth-child(6), .nav-menu li:nth-child(7){
    background-color: red;
    display: none;
  }
}
.nav-menu li:nth-child(6),.nav-menu li:nth-child(7){
  /* background-color: red; */
  /* margin-top: 5rem; */
  /* margin-bottom: 5rem; */
}
.nav-item.last{
  padding: 12px 17px;
}

.nav-icon i{
  font-size: 16px;
  font-style: normal;
  color: white;
  /* margin-bottom: 5rem; */
  /* margin-top: -5rem; */
}

/* Add the following styles at the end of your CSS file or style tag */
.responmenuiov{
  display: none;
}
@media (max-width: 900px) {
  .nav-menu {
    height: 100vh; /* Set height to full screen */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 3.1rem;
    left: 0;
    background-color: #fff; /* Set your background color */
    width: 100%;
    z-index: 10;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    
  }
  .nav-menu li{
    padding: 0px 0px;
  }
  .nav-item.last{
    padding: 0px 0px;
  }
  .nav-logo-image{
    width: 4rem;
    margin-left: -1.2rem;
  }
  .responmenuiov{
    display: block;
  }
  .nav-link{
    padding: 0px 0px;
  }
  .nav-menu.active {
    transform: translateX(0);
  }

  .nav-menu li {
    border-bottom: 1px solid #ccc; /* Set border color */
    width: 100%;
    text-align: left;
  }

  .nav-menu li:last-child {
    border-bottom: none; /* Remove border from the last li */
  }
}
