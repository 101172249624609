/* <style type="text/css" id="server-side-jss"> */
.button-0-3-39 {
    color: currentColor;
    /* border: 1px solid var(--foreground); */
    cursor: pointer;
    margin: 0;
    display: inline-flex;
    padding: 7.5px 15px;
    overflow: hidden;
    position: relative;
    font-size: 10px;
    background: transparent;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
    align-items: center;
    line-height: 1.6;
    border-radius: 80px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    justify-content: center;
    text-decoration: none;
}

.button-0-3-39:disabled {
    color: currentColor;
    cursor: inherit;
    opacity: 0.5;
    background: transparent;
}

.clone-0-3-40 {
    top: 0;
    left: 0;
    color: var(--background);
    right: 0;
    bottom: 0;
    overflow: hidden;
    position: absolute;
    transform: translateY(110%);
    background-color: var(--foreground);
}

.button-0-3-39.selected .clone-0-3-40 {
    transform: translateY(0%);
}

.cloneContent-0-3-41 {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: inline-flex;
    padding: 7.5px 15px;
    position: absolute;
    transform: translateY(-110%);
    align-items: center;
    justify-content: center;
}

.button-0-3-39.selected .cloneContent-0-3-41 {
    transform: translateY(0%);
}

.icon-0-3-42 {
    width: 18px;
    display: block;
}

.icon-0-3-42.backArrow {
    transform: rotate(180deg);
}

.iconContainer-0-3-43 {
    display: block;
    overflow: hidden;
    margin-top: 1px;
    margin-left: 5px;
}

.iconContainer-0-3-43.backArrow {
    margin-left: 0;
    margin-right: 5px;
}

.Layout-section-0-3-33.gutter {
    margin-left: 15px;
    margin-right: 15px;
}

.Layout-section-0-3-33.bottomMargin {
    /* margin-bottom: 75px; */
}

.Layout-section-0-3-33.grid {
    gap: 15px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    
}

@media (min-width:1024px) {
    .Layout-section-0-3-33.grid {
        gap: 25px;
        grid-template-columns: repeat(7, 1fr);
    }
}

@media (min-width:1024px) {
    .Layout-section-0-3-33.bottomMargin {
        /* margin-bottom: 100px; */
    }
}

@media (min-width:1024px) {
    .Layout-section-0-3-33.gutter {
        margin-left: 25px;
        margin-right: 25px;
    }
}

.caption-0-3-228 {
    font-size: 10px;
    font-family: "ABCMonumentGroteskMono", "Lucida Sans", Tahoma, sans-serif;
    line-height: 1.2;
    white-space: pre-wrap;
    text-transform: uppercase;
}

.imageContainer-0-3-92 {
    width: 100%;
    display: block;
    overflow: hidden;
    position: relative;
}

.imageContainer-0-3-92 picture::before {
    display: block;
}

@media (min-width:1024px) {
    .imageContainer-0-3-92 {}
    .imageContainer-0-3-92 picture::before {}
}

.picture-0-3-93 {}

.image-0-3-94 {
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    bottom: 0;
    height: 100%;
    position: absolute;
    object-fit: cover;
}

.image-0-3-94.fadeIn {
    opacity: 0;
}

.caption-0-3-95 {
    top: calc(100% + 10px);
    right: 0;
    position: absolute;
    font-size: 10px;
    font-family: "ABCMonumentGroteskMono", "Lucida Sans", Tahoma, sans-serif;
}

.imageContainer-d0-0-3-96 {}

.imageContainer-d0-0-3-96 picture::before {
    content: "";
    padding-top: 66.74082313681869%;
}

@media (min-width:1024px) {
    .imageContainer-d0-0-3-96 {}
    .imageContainer-d0-0-3-96 picture::before {
        padding-top: 66.74082313681869%;
    }
}

.image-d2-0-3-97 {
    object-position: 50% 50%;
}

.line-0-3-107 {
    /* background-color: var(--border); */
    /* background-color: wheat; */
    /* background-color: #CCA99F; */
}


.vertical-0-3-109 {
    top: 0;
    width: 1px;
    bottom: 0;
    position: absolute;
    /* transform: scaley(); */
}

.vertical-0-3-109.extend {
    top: -15px;
    bottom: -15px;
}

@media (min-width:1024px) {
    .vertical-0-3-109.extend {
        top: -25px;
        bottom: -25px;
    }
}

.horizontal-d1-0-3-110 {
    transform-origin: 50% 50%;
}

.vertical-d2-0-3-111 {
    transform-origin: 50% 50%;
}

.horizontal-d3-0-3-112 {
    transform-origin: 50% 50%;
}

.vertical-d4-0-3-113 {
    transform-origin: 50% 50%;
}

.horizontal-d5-0-3-114 {
    transform-origin: 50% 50%;
}

.vertical-d6-0-3-115 {
    transform-origin: 50% 50%;
}

.horizontal-d7-0-3-116 {
    transform-origin: 50% 50%;
}

.vertical-d8-0-3-117 {
    transform-origin: 50% 50%;
}

.horizontal-d9-0-3-118 {
    transform-origin: 50% 50%;
}

.vertical-d10-0-3-119 {
    transform-origin: 50% 50%;
}

.horizontal-d11-0-3-120 {
    transform-origin: 50% 50%;
}

.vertical-d12-0-3-121 {
    transform-origin: 50% 50%;
}

.horizontal-d13-0-3-122 {
    transform-origin: 50% 50%;
}

.vertical-d14-0-3-123 {
    transform-origin: 50% 50%;
}

.horizontal-d15-0-3-124 {
    transform-origin: 50% 50%;
}

.vertical-d16-0-3-125 {
    transform-origin: 50% 50%;
}

.horizontal-d17-0-3-126 {
    transform-origin: 50% 50%;
}

.vertical-d18-0-3-127 {
    transform-origin: 50% 50%;
}

.horizontal-d19-0-3-128 {
    transform-origin: 50% 50%;
}

.vertical-d20-0-3-129 {
    transform-origin: 50% 50%;
}

.horizontal-d21-0-3-130 {
    transform-origin: 50% 50%;
}

.vertical-d22-0-3-131 {
    transform-origin: 50% 50%;
}

.horizontal-d23-0-3-132 {
    transform-origin: 50% 50%;
}

.vertical-d24-0-3-133 {
    transform-origin: 50% 50%;
}

.horizontal-d25-0-3-134 {
    transform-origin: 50% 50%;
}

.vertical-d26-0-3-135 {
    transform-origin: 50% 50%;
}

.horizontal-d27-0-3-136 {
    transform-origin: 50% 50%;
}

.vertical-d28-0-3-137 {
    transform-origin: 50% 50%;
}

.horizontal-d29-0-3-138 {
    transform-origin: 50% 50%;
}

.vertical-d30-0-3-139 {
    transform-origin: 50% 50%;
}

.horizontal-d31-0-3-140 {
    transform-origin: 50% 50%;
}

.vertical-d32-0-3-141 {
    transform-origin: 50% 50%;
}

.horizontal-d33-0-3-142 {
    transform-origin: 50% 50%;
}

.vertical-d34-0-3-143 {
    transform-origin: 50% 50%;
}

.horizontal-d35-0-3-144 {
    transform-origin: 50% 50%;
}

.vertical-d36-0-3-145 {
    transform-origin: 50% 50%;
}

.horizontal-d37-0-3-146 {
    transform-origin: 50% 50%;
}

.vertical-d38-0-3-147 {
    transform-origin: 50% 50%;
}

.horizontal-d39-0-3-148 {
    transform-origin: 50% 50%;
}

.vertical-d40-0-3-149 {
    transform-origin: 50% 50%;
}

.horizontal-d41-0-3-179 {
    transform-origin: 50% 50%;
}

.vertical-d42-0-3-180 {
    transform-origin: 50% 50%;
}

.horizontal-d43-0-3-181 {
    transform-origin: 50% 50%;
}

.vertical-d44-0-3-182 {
    transform-origin: 50% 50%;
}

.horizontal-d45-0-3-183 {
    transform-origin: 50% 50%;
}

.vertical-d46-0-3-184 {
    transform-origin: 50% 50%;
}

.horizontal-d47-0-3-185 {
    transform-origin: 50% 50%;
}

.vertical-d48-0-3-186 {
    transform-origin: 50% 50%;
}

.horizontal-d49-0-3-187 {
    transform-origin: 50% 50%;
}

.vertical-d50-0-3-188 {
    transform-origin: 50% 50%;
}

.horizontal-d51-0-3-189 {
    transform-origin: 50% 50%;
}

.vertical-d52-0-3-190 {
    transform-origin: 50% 50%;
}

.horizontal-d53-0-3-191 {
    transform-origin: 50% 50%;
}

.vertical-d54-0-3-192 {
    transform-origin: 50% 50%;
}

.horizontal-d55-0-3-193 {
    transform-origin: 50% 50%;
}

.vertical-d56-0-3-194 {
    transform-origin: 50% 50%;
}

.horizontal-d57-0-3-195 {
    transform-origin: 50% 50%;
}

.vertical-d58-0-3-196 {
    transform-origin: 50% 50%;
}

.horizontal-d59-0-3-197 {
    transform-origin: 50% 50%;
}

.vertical-d60-0-3-198 {
    transform-origin: 50% 50%;
}

.horizontal-d61-0-3-199 {
    transform-origin: 50% 50%;
}

.vertical-d62-0-3-200 {
    transform-origin: 50% 50%;
}

.horizontal-d63-0-3-201 {
    transform-origin: 50% 50%;
}

.vertical-d64-0-3-202 {
    transform-origin: 50% 50%;
}

.horizontal-d65-0-3-203 {
    transform-origin: 50% 50%;
}

.vertical-d66-0-3-204 {
    transform-origin: 50% 50%;
}

.horizontal-d67-0-3-205 {
    transform-origin: 50% 50%;
}

.vertical-d68-0-3-206 {
    transform-origin: 50% 50%;
}

.horizontal-d69-0-3-207 {
    transform-origin: 50% 50%;
}

.vertical-d70-0-3-208 {
    transform-origin: 50% 50%;
}

.horizontal-d71-0-3-209 {
    transform-origin: 50% 50%;
}

.vertical-d72-0-3-210 {
    transform-origin: 50% 50%;
}

.horizontal-d73-0-3-211 {
    transform-origin: 50% 50%;
}

.vertical-d74-0-3-212 {
    transform-origin: 50% 50%;
}

.horizontal-d75-0-3-213 {
    transform-origin: 50% 50%;
}

.vertical-d76-0-3-214 {
    transform-origin: 50% 50%;
}

.horizontal-d77-0-3-263 {
    transform-origin: 0% 50%;
}

.vertical-d78-0-3-264 {
    transform-origin: 50% 0%;
}

.horizontal-d79-0-3-265 {
    transform-origin: 50% 50%;
}

.vertical-d80-0-3-266 {
    transform-origin: 50% 50%;
}

.horizontal-d81-0-3-267 {
    transform-origin: 50% 50%;
}

.vertical-d82-0-3-268 {
    transform-origin: 50% 50%;
}

.horizontal-d83-0-3-269 {
    transform-origin: 50% 50%;
}

.vertical-d84-0-3-270 {
    transform-origin: 50% 50%;
}

.horizontal-d85-0-3-271 {
    transform-origin: 0% 50%;
}

.vertical-d86-0-3-272 {
    transform-origin: 50% 0%;
}

.horizontal-d87-0-3-273 {
    transform-origin: 50% 50%;
}

.vertical-d88-0-3-274 {
    transform-origin: 50% 50%;
}

.horizontal-d89-0-3-275 {
    transform-origin: 50% 50%;
}

.vertical-d90-0-3-276 {
    transform-origin: 50% 50%;
}

.horizontal-d91-0-3-277 {
    transform-origin: 50% 50%;
}

.vertical-d92-0-3-278 {
    transform-origin: 50% 50%;
}

.horizontal-d93-0-3-279 {
    transform-origin: 50% 50%;
}

.vertical-d94-0-3-280 {
    transform-origin: 50% 50%;
}

.horizontal-d95-0-3-281 {
    transform-origin: 50% 50%;
}

.vertical-d96-0-3-282 {
    transform-origin: 50% 50%;
}

.horizontal-d97-0-3-283 {
    transform-origin: 50% 50%;
}

.vertical-d98-0-3-284 {
    transform-origin: 50% 50%;
}

.horizontal-d99-0-3-285 {
    transform-origin: 50% 50%;
}

.vertical-d100-0-3-286 {
    transform-origin: 50% 50%;
}

.horizontal-d101-0-3-287 {
    transform-origin: 0% 50%;
}

.vertical-d102-0-3-288 {
    transform-origin: 50% 0%;
}

.horizontal-d103-0-3-289 {
    transform-origin: 50% 50%;
}

.vertical-d104-0-3-290 {
    transform-origin: 50% 50%;
}

.horizontal-d105-0-3-291 {
    transform-origin: 50% 50%;
}

.vertical-d106-0-3-292 {
    transform-origin: 50% 50%;
}

.horizontal-d107-0-3-293 {
    transform-origin: 50% 50%;
}

.vertical-d108-0-3-294 {
    transform-origin: 50% 50%;
}

.horizontal-d109-0-3-295 {
    transform-origin: 0% 50%;
}

.vertical-d110-0-3-296 {
    transform-origin: 50% 0%;
}

.horizontal-d111-0-3-297 {
    transform-origin: 50% 50%;
}

.vertical-d112-0-3-298 {
    transform-origin: 50% 50%;
}

.horizontal-d113-0-3-299 {
    transform-origin: 50% 50%;
}

.vertical-d114-0-3-300 {
    transform-origin: 50% 50%;
}

.horizontal-d115-0-3-301 {
    transform-origin: 50% 50%;
}

.vertical-d116-0-3-302 {
    transform-origin: 50% 50%;
}

.horizontal-d117-0-3-303 {
    transform-origin: 50% 50%;
}

.vertical-d118-0-3-304 {
    transform-origin: 50% 50%;
}

.horizontal-d119-0-3-305 {
    transform-origin: 50% 50%;
}

.vertical-d120-0-3-306 {
    transform-origin: 50% 50%;
}

.horizontal-d121-0-3-307 {
    transform-origin: 50% 50%;
}

.vertical-d122-0-3-308 {
    transform-origin: 50% 50%;
}

.horizontal-d123-0-3-309 {
    transform-origin: 50% 50%;
}

.vertical-d124-0-3-310 {
    transform-origin: 50% 50%;
}

.horizontal-d125-0-3-311 {
    transform-origin: 0% 50%;
}

.vertical-d126-0-3-312 {
    transform-origin: 50% 0%;
}

.horizontal-d127-0-3-313 {
    transform-origin: 50% 50%;
}

.vertical-d128-0-3-314 {
    transform-origin: 50% 50%;
}

.horizontal-d129-0-3-315 {
    transform-origin: 50% 50%;
}

.vertical-d130-0-3-316 {
    transform-origin: 50% 50%;
}

.horizontal-d131-0-3-317 {
    transform-origin: 50% 50%;
}

.vertical-d132-0-3-318 {
    transform-origin: 50% 50%;
}

.horizontal-d133-0-3-319 {
    transform-origin: 0% 50%;
}

.vertical-d134-0-3-320 {
    transform-origin: 50% 0%;
}

.horizontal-d135-0-3-321 {
    transform-origin: 50% 50%;
}

.vertical-d136-0-3-322 {
    transform-origin: 50% 50%;
}

.horizontal-d137-0-3-323 {
    transform-origin: 50% 50%;
}

.vertical-d138-0-3-324 {
    transform-origin: 50% 50%;
}

.horizontal-d139-0-3-325 {
    transform-origin: 50% 50%;
}

.vertical-d140-0-3-326 {
    transform-origin: 50% 50%;
}

.horizontal-d141-0-3-327 {
    transform-origin: 50% 50%;
}

.vertical-d142-0-3-328 {
    transform-origin: 50% 50%;
}

.horizontal-d143-0-3-329 {
    transform-origin: 50% 50%;
}

.vertical-d144-0-3-330 {
    transform-origin: 50% 50%;
}

.horizontal-d145-0-3-331 {
    transform-origin: 50% 50%;
}

.vertical-d146-0-3-332 {
    transform-origin: 50% 50%;
}

.horizontal-d147-0-3-333 {
    transform-origin: 50% 50%;
}

.vertical-d148-0-3-334 {
    transform-origin: 50% 50%;
}

.horizontal-d149-0-3-335 {
    transform-origin: 0% 50%;
}

.vertical-d150-0-3-336 {
    transform-origin: 50% 0%;
}

.horizontal-d151-0-3-337 {
    transform-origin: 50% 50%;
}

.vertical-d152-0-3-338 {
    transform-origin: 50% 50%;
}

.horizontal-d153-0-3-339 {
    transform-origin: 50% 50%;
}

.vertical-d154-0-3-340 {
    transform-origin: 50% 50%;
}

.horizontal-d155-0-3-341 {
    transform-origin: 50% 50%;
}

.vertical-d156-0-3-342 {
    transform-origin: 50% 50%;
}

.horizontal-d157-0-3-343 {
    transform-origin: 0% 50%;
}

.vertical-d158-0-3-344 {
    transform-origin: 50% 0%;
}

.horizontal-d159-0-3-345 {
    transform-origin: 50% 50%;
}

.vertical-d160-0-3-346 {
    transform-origin: 50% 50%;
}

.horizontal-d161-0-3-347 {
    transform-origin: 50% 50%;
}

.vertical-d162-0-3-348 {
    transform-origin: 50% 50%;
}

.horizontal-d163-0-3-349 {
    transform-origin: 50% 50%;
}

.vertical-d164-0-3-350 {
    transform-origin: 50% 50%;
}

.horizontal-d165-0-3-351 {
    transform-origin: 50% 50%;
}

.vertical-d166-0-3-352 {
    transform-origin: 50% 50%;
}

.horizontal-d167-0-3-353 {
    transform-origin: 50% 50%;
}

.vertical-d168-0-3-354 {
    transform-origin: 50% 50%;
}

.horizontal-d169-0-3-355 {
    transform-origin: 50% 50%;
}

.vertical-d170-0-3-356 {
    transform-origin: 50% 50%;
}

.horizontal-d171-0-3-357 {
    transform-origin: 50% 50%;
}

.vertical-d172-0-3-358 {
    transform-origin: 50% 50%;
}

.horizontal-d173-0-3-359 {
    transform-origin: 50% 50%;
}

.vertical-d174-0-3-360 {
    transform-origin: 50% 50%;
}

.horizontal-d175-0-3-361 {
    transform-origin: 50% 50%;
}

.vertical-d176-0-3-362 {
    transform-origin: 50% 50%;
}

.horizontal-d177-0-3-389 {
    transform-origin: 50% 50%;
}

.vertical-d178-0-3-390 {
    transform-origin: 50% 50%;
}

.horizontal-d179-0-3-391 {
    transform-origin: 50% 50%;
}

.vertical-d180-0-3-392 {
    transform-origin: 50% 50%;
}

.horizontal-d181-0-3-393 {
    transform-origin: 0 50%;
}

.vertical-d182-0-3-394 {
    transform-origin: 50% 0;
}

.horizontal-d183-0-3-395 {
    transform-origin: 50% 50%;
}

.vertical-d184-0-3-396 {
    transform-origin: 50% 50%;
}

.horizontal-d185-0-3-397 {
    transform-origin: 0 50%;
}

.vertical-d186-0-3-398 {
    transform-origin: 50% 0;
}

.horizontal-d187-0-3-399 {
    transform-origin: 50% 50%;
}

.vertical-d188-0-3-400 {
    transform-origin: 50% 50%;
}

.horizontal-d189-0-3-401 {
    transform-origin: 50% 50%;
}

.vertical-d190-0-3-402 {
    transform-origin: 50% 50%;
}

.horizontal-d191-0-3-403 {
    transform-origin: 50% 50%;
}

.vertical-d192-0-3-404 {
    transform-origin: 50% 50%;
}

.horizontal-d193-0-3-405 {
    transform-origin: 50% 50%;
}

.vertical-d194-0-3-406 {
    transform-origin: 50% 50%;
}

.horizontal-d195-0-3-407 {
    transform-origin: 50% 50%;
}

.vertical-d196-0-3-408 {
    transform-origin: 50% 50%;
}

.placeholder-0-3-262 {
    width: 100%;
    /* opacity: 0.1; */
    border-radius: 20px;
    margin-top: 0.2rem;
    font-size: 13px;
    text-transform: capitalize;
   
}

.placeholder-0-3-262.fill {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
}

.tile-0-3-243 {
    display: flex;
    padding: 0 0 15px 0;
    position: relative;
    grid-column: span 4;
    flex-direction: column-reverse;
    text-decoration: none;
}

.tile-0-3-243.page {
    flex-direction: column;
}

@media (min-width:1024px) {
    .tile-0-3-243 {
        padding: 0 25px 0 0;
        grid-column: span 2;
        flex-direction: column;
    }
}

.tile-0-3-243.large {
    grid-column: span 4;
}

@media (min-width:1024px) {
    .tile-0-3-243.noPadding {
        padding-right: 0;
    }
}

@media (min-width:1024px) {
    .tile-0-3-243.large {
        grid-column: span 3;
    }
}

.mobileLine-0-3-244 {
    display: block;
}

@media (min-width:1024px) {
    .mobileLine-0-3-244 {
        display: none;
    }
}

.tileContent-0-3-245 {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}

.imageContainer-0-3-246 {
    width: 100%;
    height: 64vw;
    position: relative;
    margin-bottom: 0;
}

.tile-0-3-243.page .imageContainer-0-3-246 {
    margin-bottom: 15px;
}

@media (min-width:1024px) {
    .imageContainer-0-3-246 {
        height: 26vw;
        margin-bottom: 25px;
    }
}

.image-0-3-247 {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
}

.bottomLine-0-3-248 {
    display: none;
    position: relative;
}

@media (min-width:1024px) {
    .bottomLine-0-3-248 {
        display: block;
        grid-column: span 7;
    }
}

.verticalLine-0-3-249 {
    right: 0;
    /* display: none; */
    position: absolute;
}

@media (min-width:1024px) {
    .verticalLine-0-3-249 {
        top: -25px;
        bottom: -25px;
        display: block;
    }
}

.caption-0-3-250 {
    opacity: 0.5;
}

.tile-0-3-243.page .titleCaption-0-3-251 {
    display: none;
}

@media (max-width:1023.98px) {
    .dateCaption-0-3-252 {
        grid-column: 3 / span 2;
    }
}

.title-0-3-253 {
    font-size: 12px;
    line-height: 1.4;
}

.tile-0-3-243.page .title-0-3-253 {
    font-size: 18px;
}

@media (min-width:1024px) {
    .title-0-3-253 {
        font-size: 20px;
    }
}

@media (min-width:1024px) {
    .tile-0-3-243.page .title-0-3-253 {
        font-size: 20px;
    }
}

.meta-0-3-254 {
    /* display: none; */
    justify-content: space-between;
}

@media (min-width:1024px) {
    .meta-0-3-254 {
        display: flex;
        max-width: calc(((100vw - 200px) / 7) * 2 + (25px * 1) + 0px);
    }
}

@media (max-width:1023.98px) {
    .tile-0-3-243.page .meta-0-3-254 {
        gap: 15px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    }
}

.loadMoreButton-0-3-255 {
    padding: 15px;
    grid-column: 1 / span 4;
    justify-content: center;
}

@media (min-width:1024px) {
    .loadMoreButton-0-3-255 {
        padding: 25px;
        grid-column: 1 / span 7;
    }
}

.titleContainer-0-3-256 {
    gap: 15px;
    display: grid;
    /* margin-bottom: 15px; */
    grid-template-columns: repeat(4, 1fr);
}

@media (min-width:1024px) {
    .titleContainer-0-3-256 {
        display: block;
    }
}

.titleFieldset-0-3-257 {
    display: flex;
    grid-column: span 2;
    flex-direction: column;
}

.tile-0-3-243.page .titleFieldset-0-3-257 {
    grid-column: span 4;
}

.productFieldset-0-3-258 {
    display: flex;
    flex-direction: column;
}

.tile-0-3-243.page .productFieldset-0-3-258 {
    display: none;
}

@media (min-width:1024px) {
    .productFieldset-0-3-258 {
        display: none;
    }
}

.arrowFieldset-0-3-259 {
    display: block;
    align-self: end;
    justify-self: end;
}

.arrowFieldset-0-3-259 button {
    padding: 4px 8px;
}

.tile-0-3-243.page .arrowFieldset-0-3-259 {
    display: none;
}

@media (min-width:1024px) {
    .arrowFieldset-0-3-259 {
        display: none;
    }
}

.summaryContainer-0-3-260 {
    /* flex-grow: 1; */
}

.summary-0-3-261 {
    height: 4.2em;
    display: -webkit-box;
    overflow: hidden;
    font-size: 12px;
    line-height: 1.4;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

@media (min-width:1024px) {
    .summary-0-3-261 {
        max-width: calc(((100vw - 200px) / 7) * 2 + (25px * 1) + 0px);
    }
}

.section-0-3-240 {
    padding: 15px 0;
    position: relative;
    margin-bottom: 100px;
}

@media (min-width:1024px) {
    .section-0-3-240 {
        padding: 25px 0;
        margin-bottom: 125px;
    }
}

.emptyResults-0-3-241 {
    grid-column: 1 / span 4;
}

@media (min-width:1024px) {
    .emptyResults-0-3-241 {
        grid-column: 1 / span 7;
    }
}

.loadMoreButton-0-3-242 {
    padding: 15px;
    margin-top: -15px;
    grid-column: 1 / span 4;
    justify-content: center;
}

@media (min-width:1024px) {
    .loadMoreButton-0-3-242 {
        padding: 25px;
        margin-top: -25px;
        grid-column: 1 / span 7;
    }
}

.spacer-0-3-239 {
    display: none;
}

@media (min-width:1024px) {
    .spacer-0-3-239 {
        display: block;
        margin-left: -25px;
    }
}

.item-0-3-231 {
    height: 100%;
    position: relative;
    border-right: 1px solid var(--border);
}

@media (min-width:1024px) {
    .item-0-3-231 {
        width: 100%;
        border: none;
        grid-column: span 1;
    }
}

.button-0-3-232 {
    top: 0;
    border: none;
    bottom: 0;
    cursor: pointer;
    margin: 0;
    display: none;
    padding: 0;
    position: absolute;
    font-size: 10px;
    transition: border-color 0.15s ease-in-out;
    align-items: center;
    border-left: 1px solid transparent;
    font-family: "ABCMonumentGroteskMono", "Lucida Sans", Tahoma, sans-serif;
    border-right: 1px solid transparent;
    border-radius: 0;
    text-transform: uppercase;
    background-color: var(--background);
}

@media (min-width:1024px) {
    .button-0-3-232 {
        width: calc(100% + 25px);
        margin: 0 0 0 -25px;
        display: flex;
        padding: 0 25px;
    }
}

.button-0-3-232:hover,
.button-0-3-232.open {
    /* border-left: 1px solid var(--border); */
    /* border-right: 1px solid var(--border); */
}

.itemIcon-0-3-233 {
    margin-left: 10px;
}

.dropdown-0-3-234 {
    top: 100%;
    left: 0;
    right: 0;
    /* border: 1px solid var(--border); */
    margin: -1px 0 0 -15px;
    display: none;
    padding: 15px;
    position: absolute;
    transform: translate(0, -100%);
    transition: transform 0.15s ease-in-out;
    background-color: var(--background);
}

.dropdown-0-3-234.open {
    transform: translate(0, 0);
}

@media (min-width:1024px) {
    .dropdown-0-3-234 {
        margin: -1px 0 0 -25px;
        display: block;
        padding: 15px 25px;
    }
}

.dropdownItem-0-3-235 {
    color: currentColor;
    width: 100%;
    border: none;
    cursor: pointer;
    text-align: left;
    background-color: transparent;
}

.dropdownItem-0-3-235:hover {
    text-decoration: underline;
}

.selectWrapper-0-3-236 {
    height: 100%;
    min-width: 150px;
}

@media (min-width:1024px) {
    .selectWrapper-0-3-236 {
        display: none;
    }
}

.selectCaret-0-3-237 {
    top: 50%;
    right: 15px;
    position: absolute;
    transform: translate(-50%, 0);
}

.select-0-3-238 {
    color: currentColor;
    width: 100%;
    border: none;
    height: 100%;
    margin: 0 30px 0 15px;
    outline: none;
    padding: 0;
    background: transparent;
    -webkit-appearance: none;
}

.line-0-3-229 {
    width: 100%;
    height: 1px;
    z-index: 1;
    position: relative;
    background-color: var(--border);
}

.toolbar-0-3-230 {
    height: 50px;
    display: flex;
    overflow: auto;
    position: relative;
    grid-column: 1 / span 4;
}

@media (min-width:1024px) {
    .toolbar-0-3-230 {
        gap: 25px;
        border: none;
        margin: 0 25px;
        display: grid;
        overflow: visible;
        grid-column: 1 / span 7;
        grid-template-columns: repeat(7, 1fr);
    }
}

.section-0-3-224 {
    /* z-index: 1; */
    z-index: 0;
    position: relative;
}

.header-0-3-225 {
    gap: 15px;
    display: grid;
    padding: 230px 15px 15px;
    z-index: 1;
    position: relative;
    /* background-color: var(--background); */
    grid-template-columns: repeat(4, 1fr);
}

@media (min-width:1024px) {
    .header-0-3-225 {
        gap: 25px;
        padding: 230px 25px 25px;
        grid-template-columns: repeat(7, 1fr);
    }
}

.title-0-3-226 {
    margin: 0;
    max-width: 500px;
    grid-column: 1 / span 5;
    line-height: 1;
}

.caption-0-3-227 {
    display: none;
    align-self: end;
    white-space: pre-wrap;
}

@media (min-width:1024px) {
    .caption-0-3-227 {
        display: block;
    }
}

.toolbarContainer-0-3-363 {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    z-index: 900;
    overflow: hidden;
    position: fixed;
    pointer-events: none;
}

@media (min-width:1024px) {
    .toolbarContainer-0-3-363 {
        position: absolute;
    }
}

.toolbar-0-3-364 {
    left: 0;
    right: 0;
    bottom: 0;
    height: 50px;
    display: flex;
    position: absolute;
    transform: translate(0, 100%);
    border-top: 1px solid var(--border);
    align-items: center;
    pointer-events: all;
    justify-content: flex-end;
    background-color: var(--background);
}

.titleItem-0-3-365 {
    min-width: 220px;
}

.hideOnMobile-0-3-366 {
    display: none;
}

@media (min-width:1024px) {
    .hideOnMobile-0-3-366 {
        display: flex;
    }
}

.title-0-3-410 {
    display: block;
    opacity: 0.5;
    font-size: 12px;
    margin-bottom: 5px;
}

.input-0-3-411 {
    width: 100%;
    border: none;
    margin: -1px 0 0 0;
    outline: none;
    padding: 10px 30px 10px 0;
    font-size: 12px;
    appearance: none;
    border-top: 1px solid var(--border);
    box-shadow: none;
    border-bottom: 1px solid var(--border);
    border-radius: 0;
    background-color: transparent;
}

.inputContainer-0-3-412 {
    position: relative;
}

.button-0-3-413 {
    top: 50%;
    right: 0;
    width: 18px;
    border: none;
    cursor: pointer;
    height: 30px;
    margin: 0;
    display: flex;
    padding: 0;
    overflow: hidden;
    position: absolute;
    transform: translate(0, -50%);
    align-items: center;
    justify-content: flex-end;
    background-color: transparent;
}

.button-0-3-413:hover>.arrow-0-3-414 {
    transform: translate(200%, 0);
}

.button-0-3-413:hover>.arrow2-0-3-415 {
    transform: translate(200%, 0);
}

.arrow-0-3-414 {
    width: 18px;
    transition: transform 0.35s cubic-bezier(0.61, 1, 0.88, 1);
    flex-shrink: 0;
}

.arrow2-0-3-415 {
    right: 200%;
    width: 18px;
    position: absolute;
    transition: transform 0.35s cubic-bezier(0.61, 1, 0.88, 1);
}

.error-0-3-416 {
    color: #AA0000;
    margin-top: 5px;
}

.footer-0-3-367 {
    position: relative;
    font-size: 12px;
}

.section-0-3-368 {
    padding: 50px 0;
    row-gap: 50px !important;
}

@media (min-width:1024px) {
    .section-0-3-368 {
        padding: 25px 0;
        row-gap: 25px !important;
    }
}

@media (min-width:1024px) {
    .section-0-3-368.extraToolbarPadding {
        padding: 25px 0 75px;
    }
}

.logoNewsletterContainer-0-3-369 {
    grid-row: 1;
    grid-column: 1 / span 4;
    /* margin-top: -2rem; */
}

@media (min-width:1024px) {
    .logoNewsletterContainer-0-3-369 {
        grid-column: 1 / span 2;
        padding-right: 25px;
    }
}

.acknowledgeContainer-0-3-370 {
    margin: 0;
    grid-row: 7;
    font-size: 12px;
    text-align: center;
    grid-column: 1 / span 4;
    margin-bottom: 60px;
}

@media (min-width:1024px) {
    .acknowledgeContainer-0-3-370 {
        grid-row: 2;
        margin-top: 280px;
        text-align: left;
        grid-column: 1 / span 2;
        margin-bottom: 0;
        padding-right: 50px;
    }
}

.socialLinksContainer-0-3-371 {
    grid-row: 5;
    grid-column: 1 / span 2;
}

@media (min-width:1024px) {
    .socialLinksContainer-0-3-371 {
        grid-row: 1;
        grid-column: 3 / span 2;
    }
}

.copyrightContainer-0-3-372 {
    grid-row: 5;
    grid-column: 3 / span 2;
}

@media (min-width:1024px) {
    .copyrightContainer-0-3-372 {
        grid-row: 2;
        align-self: end;
        grid-column: 3 / span 2;
    }
}

.productsContainer-0-3-373 {
    grid-row: 2;
    text-align: right;
    grid-column: 3 / span 2;
}

@media (min-width:1024px) {
    .productsContainer-0-3-373 {
        grid-row: 1;
        text-align: left;
        grid-column: 5 / span 2;
    }
}

.projectsContainer-0-3-374 {
    grid-row: 2;
    grid-column: 1 / span 2;
}

@media (min-width:1024px) {
    .projectsContainer-0-3-374 {
        grid-row: 1;
        text-align: right;
        grid-column: 7;
    }
}

.contactsContainer-0-3-375 {
    display: flex;
    grid-row: 3;
    grid-column: 1 / span 2;
    flex-direction: column;
}

@media (min-width:1024px) {
    .contactsContainer-0-3-375 {
        grid-row: 2;
        align-self: end;
        grid-column: 5 / span 2;
    }
}

.resourcesContainer-0-3-376 {
    grid-row: 3;
    text-align: right;
    grid-column: 3 / span 2;
}

@media (min-width:1024px) {
    .resourcesContainer-0-3-376 {
        grid-row: 2;
        align-self: end;
        grid-column: 7;
    }
}

.logo-0-3-377 {
    width: 94px;
    /* height: 44px; */
    margin-bottom: 50px;
}

.link-0-3-378 {
    line-height: 1.8;
    text-decoration: none;
}

.link-0-3-378:hover {
    text-decoration: underline;
}

.caption-0-3-379 {
    display: block;
    opacity: 0.5;
    margin-bottom: 15px;
}

.partnerLogos-0-3-380 {
    margin: 0 -5px 15px;
    display: flex;
    flex-wrap: wrap;
    max-width: 20em;
}

@media (min-width:1024px) {
    .partnerLogos-0-3-380 {
        margin: 0 -5px 30px;
    }
}

.partnerLogo-0-3-381 {
    width: auto;
    height: 34px;
    margin: 5px 5px;
}

.linkList-0-3-382 {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.verticalLineContainer-0-3-383 {
    display: none;
    position: relative;
    pointer-events: none;
}

@media (min-width:1024px) {
    .verticalLineContainer-0-3-383 {
        margin: -25px 0px 0px -34px;
        display: block;
        grid-row: 1 / span 2;
    }
}

@media (min-width:1024px) {
    .firstLine-0-3-384 {
        grid-column: 3;
        /* padding-left: 845px; */
    }
}

@media (min-width:1024px) {
    .secondLine-0-3-385 {
        grid-column: 5;
    }
}

.horizontalLineContainer-0-3-386 {
    margin: 0 -15px;
    display: block;
    position: relative;
    grid-column: 1 / span 4;
    pointer-events: none;
}

@media (min-width:1024px) {
    .horizontalLineContainer-0-3-386 {
        display: none;
    }
}

.firstMobileLine-0-3-387 {
    grid-row: 4;
}

.secondMobileLine-0-3-388 {
    grid-row: 6;
}

.button-0-3-18 {
    color: currentColor;
    border: none;
    cursor: pointer;
    display: inline=block;
    padding: 0;
    position: relative;
    font-size: 12px;
    background: transparent;
    min-height: 42px;
    align-items: center;
    font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
    line-height: 1.2;
    border-radius: 0;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    text-decoration: none;
}

.labelContainer-0-3-19 {
    height: 1.2em;
    display: block;
    overflow: hidden;
    position: relative;
}

.menuLabel-0-3-20 {
    display: block;
    position: relative;
}

.closeLabel-0-3-21 {
    right: 0;
    display: block;
}

.count-0-3-17 {
    width: 20px;
    /* border: 1px solid currentColor; */
    height: 20px;
    display: inline-flex;
    font-size: 9px;
    align-items: center;
    font-family: "ABCMonumentGroteskMono", "Lucida Sans", Tahoma, sans-serif;
    border-radius: 50%;
    justify-content: center;
}

@media (min-width:1024px) {
    .count-0-3-17 {
        width: 24px;
        height: 24px;
        font-size: 10px;
    }
}

.count-0-3-16 {
    margin-left: 5px;
}

.menuItem-0-3-14 {
    display: none;
}

@media (min-width:1024px) {
    .menuItem-0-3-14 {
        display: flex;
        align-items: center;
    }
}

.link-0-3-15 {
    color: currentColor;
    border: none;
    cursor: pointer;
    display: inline-flex;
    padding: 0;
    overflow: hidden;
    position: relative;
    font-size: 10px;
    background: transparent;
    min-height: 42px;
    align-items: center;
    font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
    line-height: 1.2;
    border-radius: 0;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    text-decoration: none;
}

.link-0-3-15:after {
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    content: "";
    display: block;
    position: absolute;
    transform: translate(-110%, 1.2em);
    transition: transform 0.15s ease-in-out;
    background-color: currentColor;
    transform-origin: 0% 50%;
}

.link-0-3-15:hover:after,
.link-0-3-15.active:after,
.link-0-3-15.selected:after {
    transform: translate(0%, 1.2em);
}

.header-0-3-3 {
    top: 0;
    left: 0;
    right: 0;
    display: block;
    z-index: 1002;
    position: fixed;
    background-color: var(--background);
}

/* .line-0-3-4 {
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    opacity: 1;
    position: absolute;
    background-color: var(--border);
} */

.nav-0-3-5 {
    padding: 12px 15px;
}

@media (min-width:1024px) {
    .nav-0-3-5 {
        padding: 12px 25px;
    }
}

.list-0-3-6 {
    margin: 0 -7.5px;
    display: flex;
    padding: 0;
    list-style: none;
    justify-content: space-between;
}

@media (min-width:1024px) {
    .list-0-3-6 {
        margin: 0 -12.5px;
    }
}

.list-0-3-6 li {
    width: calc(100% / 7);
    margin: 0 7.5px;
    opacity: 1;
}

@media (min-width:1024px) {
    .list-0-3-6 li {
        margin: 0 12.5px;
    }
}

.menuItem-0-3-7 {
    display: none;
}

@media (min-width:1024px) {
    .menuItem-0-3-7 {
        display: flex;
        align-items: center;
    }
}

.mobileMenu-0-3-8 {
    display: flex;
    justify-content: flex-end;
}

@media (min-width:1024px) {
    .mobileMenu-0-3-8 {
        display: none;
    }
}

.logoLink-0-3-9 {
    color: currentColor;
    display: inline-flex;
    min-height: 50px;
    align-items: center;
    text-decoration: none;
}

.logo-0-3-10 {
    width: 105px;
    height: 26px;
}

.link-0-3-11 {
    color: currentColor;
    border: none;
    cursor: pointer;
    display: inline-flex;
    padding: 0;
    overflow: hidden;
    position: relative;
    font-size: 10px;
    background: transparent;
    min-height: 42px;
    align-items: center;
    font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
    line-height: 1.2;
    border-radius: 0;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    text-decoration: none;
}

.link-0-3-11:after {
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    content: "";
    display: block;
    position: absolute;
    transform: translate(-110%, 1.2em);
    transition: transform 0.15s ease-in-out;
    background-color: currentColor;
    transform-origin: 0% 50%;
}

.link-0-3-11:hover:after,
.link-0-3-11.active:after {
    transform: translate(0%, 1.2em);
}

.cartLink-0-3-12:after {
    right: 22px;
}

@media (min-width:1024px) {
    .cartCount-0-3-13 {
        width: 20px;
        height: 20px;
    }
}

.MobileMenu-container-0-3-22 {
    top: 0;
    left: 0;
    color: currentColor;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    position: fixed;
    transform: translate(100%, 0);
    pointer-events: none;
    background-color: var(--background);
}

.MobileMenu-container-0-3-22.open {
    pointer-events: all;
}

.MobileMenu-inner-0-3-23 {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    display: flex;
    position: absolute;
    padding-top: 42px;
    flex-direction: column;
}

.MobileMenu-header-0-3-24 {
    padding: 30px 15px;
}

.MobileMenu-breadcrumb-0-3-25 {
    font-size: 12px;
    visibility: hidden;
    align-items: center;
    line-height: 1.4;
    margin-bottom: 30px;
    text-transform: uppercase;
}

.MobileMenu-breadcrumb-0-3-25.show {
    visibility: visible;
}

.MobileMenu-title-0-3-26 {
    font-size: 20px;
    line-height: 1.4;
}

.MobileMenu-menuItems-0-3-27 {
    margin: 0;
    display: block;
    padding: 0;
    overflow: hidden;
    list-style: none;
    padding-bottom: 1px;
}

.MobileMenu-menuItem-0-3-28 {
    display: block;
    border-top: 1px solid var(--border);
    border-bottom: 1px solid var(--border);
    margin-bottom: -1px;
}

.MobileMenu-link-0-3-29 {
    width: 100%;
    border: none;
    cursor: pointer;
    display: flex;
    padding: 15px;
    font-size: 15px;
    background: transparent;
    text-align: left;
    align-items: center;
    font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
    letter-spacing: 0.03em;
    text-transform: none;
    justify-content: space-between;
    text-decoration: none;
}

.MobileMenu-count-0-3-30 {
    font-size: 9px;
}

.panel-0-3-44 {
    height: 100vh;
    display: none;
    overflow: auto;
    position: relative;
    transform: translate(110%, 0);
    background-color: var(--background);
}

@media (min-width:1024px) {
    .panel-0-3-44 {
        transform: translate(-110%, 0);
    }
}

.panel-0-3-44.padding {
    padding: 20px 15px 15px;
}

.panel-0-3-44.flex {
    display: flex;
    flex-direction: column;
}

.panel-0-3-44::-webkit-scrollbar {
    width: 5px;
}

.panel-0-3-44::-webkit-scrollbar-track {
    background: transparent;
}

.panel-0-3-44::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: var(--border);
}

@media (min-width:1024px) {
    .panel-0-3-44.padding {
        padding: 30px 25px 25px;
    }
}

.breadcrumb-0-3-46 {
    cursor: pointer;
    display: flex;
    font-size: 12px;
    min-height: 42px;
    visibility: hidden;
    align-items: center;
    line-height: 1.4;
    margin-bottom: 30px;
    text-transform: uppercase;
}

@media (max-width:1023.98px) {
    .breadcrumb-0-3-46.show {
        visibility: visible;
    }
}

.title-0-3-47 {
    display: flex;
    font-size: 20px;
    align-items: center;
    line-height: 1.4;
    margin-bottom: 30px;
}

@media (min-width:1024px) {
    .title-0-3-47 {
        margin-bottom: 50px;
    }
}

.titleLink-0-3-48 {
    transition: opacity 0.15s ease-in-out;
    text-decoration: none;
}

.titleLink-0-3-48:hover {
    opacity: 0.5;
}

.count-0-3-49 {
    margin-left: 5px;
}

.closeButton-0-3-50 {
    top: 24px;
    right: 15px;
    width: 26px;
    border: none;
    cursor: pointer;
    height: 26px;
    margin: 0;
    display: flex;
    opacity: 0;
    padding: 0;
    z-index: 1;
    position: absolute;
    transition: opacity 0.5s ease-in-out;
    align-items: center;
    border-radius: 50%;
    pointer-events: none;
    justify-content: center;
    background-color: transparent;
}

@media (min-width:1024px) {
    .closeButton-0-3-50 {
        top: 24px;
        right: 24px;
        display: flex;
    }
}

.closeButton-0-3-50:hover,
.closeButton-0-3-50.fill {
    background-color: #F2F2F2;
}

.closeButton-0-3-50.show {
    opacity: 1;
    pointer-events: all;
}

.backIcon-0-3-51 {
    width: 24px;
    height: 24px;
    margin-right: 5px;
}

.container-0-3-52 {
    height: 100%;
    display: flex;
    font-size: 12px;
    flex-direction: column;
}

.imageContainer-0-3-53 {
    width: 100%;
    position: relative;
    flex-grow: 1;
    flex-shrink: 1;
}

.image-0-3-54 {
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    bottom: 0;
    height: 100%;
    position: absolute;
    object-fit: cover;
}

.product-0-3-55 {
    display: flex;
    padding: 15px;
    flex-grow: 0;
    min-height: 280px;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: space-between;
}

@media (min-width:1024px) {
    .product-0-3-55 {
        padding: 25px;
    }
}

.title-0-3-56 {
    display: block;
    font-size: 15px;
    margin-bottom: 25px;
}

.productDetails-0-3-57 {
    display: flex;
    margin-bottom: 25px;
}

.productDetails-0-3-57>* {
    width: 50%;
}

.locationDetails-0-3-58 {
    margin-bottom: 25px;
}

.caption-0-3-59 {
    display: block;
    opacity: 0.5;
}

.detailsPanel-0-3-60 {
    overflow: hidden;
}

.container-0-3-215 {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1003;
    overflow: hidden;
    position: fixed;
    pointer-events: none;
}

.overlay-0-3-216 {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    position: absolute;
    background-color: #000;
}

.drawerWrapper-0-3-217 {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    transform: translate(-100%, 0);
    background-color: var(--background);
}

@media (min-width:1024px) {
    .drawerWrapper-0-3-217 {
        width: 480px;
        bottom: 0;
        height: auto;
    }
}

.drawerContainer-0-3-218 {
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
}

.header-0-3-219 {
    margin: 25px 15px 0;
    display: flex;
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: space-between;
}

@media (min-width:1024px) {
    .header-0-3-219 {
        margin: 25px 25px 0;
    }
}

.header-0-3-219:before {
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    content: "";
    opacity: 0.3;
    position: absolute;
    background-color: var(--border);
}

.headerTop-0-3-220 {
    display: flex;
    align-items: start;
    margin-bottom: 25px;
    justify-content: space-between;
}

@media (min-width:1024px) {
    .headerTop-0-3-220 {
        margin-bottom: 25px;
    }
}

.drawerBody-0-3-221 {
    display: flex;
    padding: 15px;
    overflow: auto;
    position: relative;
    flex-grow: 1;
    margin-top: -8px;
    flex-shrink: 1;
    padding-top: 0;
    flex-direction: column;
}

.drawerBody-0-3-221.empty:after {
    display: none;
}

@media (min-width:1024px) {
    .drawerBody-0-3-221 {
        padding: 25px;
        padding-top: 0;
    }
}

.title-0-3-222 {
    display: flex;
    font-size: 20px;
    align-items: center;
    font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
    text-transform: none;
}

.closeButton-0-3-223 {
    border: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    margin-left: auto;
    border-radius: 0;
    background-color: transparent;
}

.container-0-3-34 {
    display: none;
    padding: 15px;
    position: relative;
    transform: translateX(-110%);
    grid-column: 1 / span 4;
    border-right: 1px solid var(--border);
    justify-content: space-between;
    background-color: var(--background);
}

@media (min-width:1024px) {
    .container-0-3-34 {
        display: flex;
        padding: 23px 25px 25px;
        z-index: 4;
        grid-column: 1;
        margin-left: -25px;
        flex-direction: column;
    }
}

.list-0-3-35 {
    margin: 40px 0 0;
    padding: 0;
    list-style: none;
}

.item-0-3-36 {
    opacity: 0.5;
    font-size: 15px;
    transition: opacity 0.25s ease-in-out;
    font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
    text-transform: none;
}

.item-0-3-36:after {
    top: 1.2em;
}

.item-0-3-36:hover,
.item-0-3-36.selected {
    opacity: 1;
}

.button-0-3-37 {
    align-self: start;
}

.buttonLabel-0-3-38 {
    opacity: 0.5;
    margin-left: 5px;
    white-space: nowrap;
}

.container-0-3-31 {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0;
    z-index: 1003;
    overflow: hidden;
    position: fixed;
    pointer-events: none;
}

@media (min-width:1024px) {
    .container-0-3-31 {
        padding: 0 25px;
    }
}

.container-0-3-31>.panel1 {
    grid-row: 1;
    grid-column: 1 / span 4;
}

.container-0-3-31>.panel2 {
    grid-row: 1;
    grid-column: 1 / span 4;
}

.container-0-3-31>.panel3 {
    grid-row: 1;
    grid-column: 1 / span 4;
    margin-right: 15px;
}

@media (min-width:1024px) {
    .container-0-3-31>.panel3 {
        z-index: 1;
        grid-column: 6 / span 2;
        margin-left: -25px;
        border-right: 1px solid var(--border);
        margin-right: -26px;
    }
}

@media (min-width:1024px) {
    .container-0-3-31>.panel2 {
        z-index: 2;
        grid-column: 4 / span 2;
        margin-left: -25px;
        border-right: 1px solid var(--border);
    }
}

@media (min-width:1024px) {
    .container-0-3-31>.panel1 {
        z-index: 3;
        grid-column: 2 / span 2;
        margin-left: -25px;
        border-right: 1px solid var(--border);
    }
}

.overlay-0-3-32 {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    position: absolute;
    background-color: #000;
}

.formContainer-0-3-98 {
    padding-bottom: 50px;
}

@media (min-width:1024px) {
    .formContainer-0-3-98 {
        padding-bottom: 50px;
    }
}

.form-0-3-99 {
    margin-bottom: 25px;
}

@media (min-width:1024px) {
    .form-0-3-99 {
        margin-bottom: 25px;
    }
}

.errors-0-3-100 {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.error-0-3-101 {
    color: #CF6C4F;
    font-size: 10px;
    text-align: center;
    font-family: "ABCMonumentGroteskMono", "Lucida Sans", Tahoma, sans-serif;
    line-height: 1;
    margin-bottom: 25px;
    text-transform: uppercase;
}

@media (min-width:1024px) {
    .error-0-3-101 {
        margin-bottom: 25px;
    }
}

.error-0-3-101:last-child {
    margin-bottom: 0;
}

.inputContainer-0-3-102 {
    height: 50px;
    display: flex;
    position: relative;
    align-items: center;
}

.inputContainer-0-3-102.hasError {
    color: #CF6C4F;
}

.inputContainer-0-3-102:focus-within .bottomBorder {
    opacity: 1;
}

.inputContainer-0-3-102.hasError>label {
    opacity: 1;
}

.inputLabel-0-3-103 {
    flex: 1;
    opacity: 0.5;
    font-size: 10px;
    font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
    line-height: 1;
    text-transform: uppercase;
}

.input-0-3-104 {
    flex: 2;
    color: currentColor;
    border: none;
    outline: none;
    padding: 16px 0;
    font-size: 12px;
    font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
    line-height: 1;
    border-radius: 0;
    background-color: transparent;
}

.input-0-3-104:-webkit-autofill,
.input-0-3-104:-webkit-autofill:hover,
.input-0-3-104:-webkit-autofill:focus .input-0-3-104:-webkit-autofill,
.input-0-3-104:-webkit-autofill:hover .input-0-3-104:-webkit-autofill:focus {
    box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
    caret-color: currentColor;
}

.bottomBorder-0-3-105 {
    opacity: 0.2;
    transition: opacity 0.25s ease-in-out;
}

.hiddenButton-0-3-106 {
    display: none;
}

.container-0-3-86 {
    margin-top: 0;
}

@media (min-width:1024px) {
    .container-0-3-86 {
        margin-top: 75px;
    }
}

.image-0-3-87 {
    margin-bottom: 20px;
}

@media (min-width:1024px) {
    .image-0-3-87 {
        margin-bottom: 35px;
    }
}

.content-0-3-88 {
    width: 250px;
    margin: auto;
    text-align: center;
}

.title-0-3-89 {
    font-size: 15px;
    margin-bottom: 10px;
}

@media (min-width:1024px) {
    .title-0-3-89 {
        margin-bottom: 20px;
    }
}

.copy-0-3-90 {
    opacity: 0.5;
    font-size: 12px;
    white-space: pre-wrap;
    margin-bottom: 10px;
}

@media (min-width:1024px) {
    .copy-0-3-90 {
        margin-bottom: 20px;
    }
}

.linkLabel-0-3-91 {
    opacity: 0.5;
}

.container-0-3-61 {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1003;
    overflow: hidden;
    position: fixed;
    pointer-events: none;
}

.overlay-0-3-62 {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    position: absolute;
    background-color: #000;
}

.cart-0-3-63 {
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    padding: 25px 15px 15px;
    position: absolute;
    transform: translate(100%, 0);
    background-color: var(--background);
}

@media (min-width:1024px) {
    .cart-0-3-63 {
        width: 480px;
        bottom: 0;
        height: auto;
        padding: 25px;
    }
}

.cartContainer-0-3-64 {
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
}

.header-0-3-65 {
    display: flex;
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: space-between;
}

.headerTop-0-3-66 {
    display: flex;
    align-items: start;
    margin-bottom: 25px;
    justify-content: space-between;
}

@media (min-width:1024px) {
    .headerTop-0-3-66 {
        margin-bottom: 25px;
    }
}

.headerBottom-0-3-67 {
    margin-bottom: 45px;
}

@media (min-width:1024px) {
    .headerBottom-0-3-67 {
        margin-bottom: 45px;
    }
}

.backToCartButton-0-3-68 {
    visibility: hidden;
}

.backToCartButton-0-3-68.show {
    visibility: visible;
}

.content-0-3-69 {
    overflow: auto;
    position: relative;
    flex-grow: 1;
    flex-shrink: 1;
}

.content-0-3-69:before {
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    content: "";
    opacity: 0.3;
    position: absolute;
    background-color: var(--border);
}

.content-0-3-69.empty:after {
    display: none;
}

.innerContent-0-3-70 {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    overflow-y: auto;
}

.formContent-0-3-71 {
    opacity: 0;
}

.footer-0-3-72 {
    flex-grow: 0;
    flex-shrink: 0;
}

.title-0-3-73 {
    display: flex;
    font-size: 20px;
    align-items: center;
    font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
    text-transform: none;
}

.count-0-3-74 {
    margin-left: 5px;
}

.copy-0-3-75 {
    font-size: 10px;
    text-align: center;
    white-space: pre-wrap;
    margin-bottom: 15px;
}

.emptyCopy-0-3-76 {
    font-size: 15px;
    margin-top: 15px;
}

.deliveryCopy-0-3-77 {
    opacity: 0.5;
}

.successCopy-0-3-78 {
    color: #219653;
}

.errorCopy-0-3-79 {
    color: #EB5757;
}

.submitButton-0-3-80 {
    width: 100%;
    padding: 16px 32px;
}

.closeButton-0-3-81 {
    border: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    border-radius: 0;
    background-color: transparent;
}

.successMessage-0-3-82 {
    top: 50%;
    left: 50%;
    width: 100%;
    display: flex;
    position: absolute;
    max-width: 340px;
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.successTitle-0-3-83 {
    font-size: 15px;
}

.successSubtitle-0-3-84 {
    opacity: 0.5;
    font-size: 12px;
}

.form-0-3-169 {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.errors-0-3-170 {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.error-0-3-171 {
    color: #CF6C4F;
    font-size: 10px;
    margin-top: 10px;
    text-align: center;
    font-family: "ABCMonumentGroteskMono", "Lucida Sans", Tahoma, sans-serif;
    line-height: 1;
    margin-bottom: 25px;
    text-transform: uppercase;
}

@media (min-width:1024px) {
    .error-0-3-171 {
        margin-bottom: 25px;
    }
}

.error-0-3-171:last-child {
    margin-bottom: 0;
}

.formFieldContainer-0-3-172 {
    display: flex;
    position: relative;
}

.formFieldContainer-0-3-172.hasError {
    color: #CF6C4F;
}

.formFieldContainer-0-3-172:focus-within .bottomBorder {
    opacity: 1;
}

.formFieldContainer-0-3-172.hasError>label {
    opacity: 1;
}

.inputContainer-0-3-173 {
    height: 50px;
    align-items: center;
}

.textareaContainer-0-3-174 {
    padding: 20px 0 0;
    flex-grow: 2;
    flex-direction: column;
}

.textareaContainer-0-3-174 label {
    flex: 0;
}

.textareaContainer-0-3-174 textarea {
    flex: auto;
    resize: none;
    font-size: 12px;
    max-width: 100%;
}

.textareaContainer-0-3-174 textarea::placeholder {
    opacity: 0.3;
}

.inputLabel-0-3-175 {
    flex: 1;
    opacity: 0.5;
    font-size: 10px;
    font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
    line-height: 1;
    text-transform: uppercase;
}

.input-0-3-176 {
    flex: 2;
    color: currentColor;
    border: none;
    outline: none;
    padding: 16px 0;
    font-size: 12px;
    font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
    line-height: 1;
    border-radius: 0;
    background-color: transparent;
}

.input-0-3-176:-webkit-autofill,
.input-0-3-176:-webkit-autofill:hover,
.input-0-3-176:-webkit-autofill:focus .input-0-3-176:-webkit-autofill,
.input-0-3-176:-webkit-autofill:hover .input-0-3-176:-webkit-autofill:focus {
    box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
    caret-color: currentColor;
}

.bottomBorder-0-3-177 {
    opacity: 0.2;
    transition: opacity 0.25s ease-in-out;
}

.hiddenButton-0-3-178 {
    display: none;
}

.container-0-3-150 {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1003;
    overflow: hidden;
    position: fixed;
    pointer-events: none;
}

.overlay-0-3-151 {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    position: absolute;
    background-color: #000;
}

.quoteForm-0-3-152 {
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    transform: translate(100%, 0);
    background-color: var(--background);
}

@media (min-width:1024px) {
    .quoteForm-0-3-152 {
        width: 480px;
        bottom: 0;
        height: auto;
    }
}

.quoteFormContainer-0-3-153 {
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
}

.header-0-3-154 {
    margin: 25px 15px 0;
    display: flex;
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: space-between;
}

@media (min-width:1024px) {
    .header-0-3-154 {
        margin: 25px 25px 0;
    }
}

.header-0-3-154:before {
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    content: "";
    opacity: 0.3;
    position: absolute;
    background-color: var(--border);
}

.headerTop-0-3-155 {
    display: flex;
    align-items: start;
    margin-bottom: 25px;
    justify-content: space-between;
}

@media (min-width:1024px) {
    .headerTop-0-3-155 {
        margin-bottom: 25px;
    }
}

.content-0-3-156 {
    display: flex;
    padding: 0 15px;
    overflow: auto;
    position: relative;
    flex-grow: 1;
    flex-shrink: 1;
    flex-direction: column;
}

.content-0-3-156.empty:after {
    display: none;
}

@media (min-width:1024px) {
    .content-0-3-156 {
        padding: 0 25px;
    }
}

.quoteProcessCopy-0-3-157 {
    margin: 0;
    padding: 40px 0;
    position: relative;
    font-size: 15px;
}

.quoteProcessCopy-0-3-157:before {
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    content: "";
    opacity: 0.3;
    position: absolute;
    background-color: var(--border);
}

.quoteSubmissionCopy-0-3-158 {
    margin: 15px auto 0;
    font-size: 10px;
    max-width: 260px;
}

.formContent-0-3-159 {
    opacity: 0;
    flex-grow: 2;
}

.footer-0-3-160 {
    padding: 0 15px 15px;
    flex-grow: 0;
    flex-shrink: 0;
}

@media (min-width:1024px) {
    .footer-0-3-160 {
        padding: 0 25px 25px;
    }
}

.title-0-3-161 {
    display: flex;
    font-size: 20px;
    align-items: center;
    font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
    text-transform: none;
}

.count-0-3-162 {
    margin-left: 5px;
}

.copy-0-3-163 {
    font-size: 10px;
    text-align: center;
    white-space: pre-wrap;
    margin-bottom: 15px;
}

.submitButton-0-3-164 {
    width: 100%;
    padding: 16px 32px;
}

.closeButton-0-3-165 {
    border: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    border-radius: 0;
    background-color: transparent;
}

.successMessage-0-3-166 {
    top: 50%;
    left: 50%;
    width: 100%;
    display: flex;
    position: absolute;
    max-width: 340px;
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.successTitle-0-3-167 {
    margin: 0 0 20px;
    font-size: 15px;
}

.successSubtitle-0-3-168 {
    margin: 0 0 20px;
    opacity: 0.5;
    font-size: 12px;
}

*,
*::before,
*::after {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}

:root {
    --border: #808080;
    --background: #FFFFFF;
    --foreground: #3A3A1F;
    --scrollbar-width: 0px;
}

html {
    line-height: 1.5;
}

body {
    color: var(--foreground);
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
    font-weight: 400;
    line-height: 1.5;
    background-color: var(--background);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    font-family: "NHaasGroteskDSPro", "Lucida Sans", Tahoma, sans-serif;
    font-weight: 400;
    margin-bottom: 0.4em;
    text-transform: uppercase;
}

h1 {
    font-size: 45px;
    line-height: 0.95;
}

@media (min-width:1024px) {
    h1 {
        font-size: 65px;
    }
}

h2 {
    font-size: 35px;
    line-height: 0.9;
    letter-spacing: 0.65px;
}

@media (min-width:1024px) {
    h2 {
        font-size: 65px;
    }
}

h3 {
    font-size: 48px;
}

h4 {
    font-size: 32px;
}

h5 {
    font-size: 20px;
    margin-bottom: 25px;
    text-transform: none;
}

h6 {
    font-size: 15px;
    text-transform: none;
}

p {
    margin: 0 0 15px;
    font-size: 15px;
}

.srOnly {
    clip: rect(1px, 1px, 1px, 1px) !important;
    width: 1px !important;
    border: 0 !important;
    height: 1px !important;
    margin: -1px !important;
    padding: 0 !important;
    overflow: hidden !important;
    position: absolute !important;
    clip-path: inset(50%) !important;
    white-space: nowrap !important;
}

a {
    color: currentColor;
    text-decoration: underline;
}

a:hover {
    text-decoration: none;
}

button {
    color: currentColor;
    font-size: 10px;
    font-family: "ABCMonumentGroteskMono", "Lucida Sans", Tahoma, sans-serif;
    font-weight: 400;
    text-transform: uppercase;
}

.scrollbar-track.scrollbar-track-y {
    z-index: 2000;
}

main .scrollbar-track {
    width: 5px;
    border-radius: 2px;
    background-color: transparent;
}

main .scrollbar-thumb.scrollbar-thumb-y {
    width: 3px;
    border-radius: 2px;
}

.chatlio-title-bar {
    display: none;
}

.chatlio-widget-body {
    bottom: 10px !important;
}

@media (min-width:1024px) {
    .chatlio-widget-body {
        right: 10px !important;
        bottom: 60px !important;
    }
}

.Layout-main-0-3-1 {
    width: 100%;
    /* height: 100vh; */
    overflow-x: hidden;
    overflow-y: auto;
}

.Layout-inner-0-3-2 {
    padding-top: 72px;
}
/* </style> */

.projectmainline{
position: absolute;
  animation: line 1.25s ease forwards;
  width:0%;
	margin:auto;
	padding:0.01em; 
  	/* background-color:white;  */
      /* position: absolute; */
  height: 0px;
  left: 0px;
  top: 10%;

  border-bottom: 1px solid rgb(204, 169, 159);
	/* animation: line 1.25s ease forwards; */
}
/* animation: line 1.25s ease forwards; */
@keyframes line {
  from { width: 0%; }
  to { width:100%; }
}

@media (min-width: 1024px)
{
    .title-0-4{
        /* background-color: yellow; */
        /* font-size: 100px; */
      }
}

@media (max-width:924px) {
    .logoNewsletterContainer-0-3-369 {
        grid-column: 1 / span 2;
        padding-right: 25px;
        margin-top: -2rem;
    }
}