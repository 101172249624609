.rishabi-line01 {
  position: absolute;
  width: 100%;
  height: 0px;
  top: 10%;
  opacity: 0.8;
  border-bottom: 1px solid rgba(58, 58, 31, 0.8);
  animation: 2.5s cubic-bezier(0.25, 1, 0.3, 1) wipe-in-right both;
}
@keyframes wipe-in-right {
  from {
    clip-path: inset(0 100% 0 0);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}
.rishabmain{
  margin-top: 4rem;
  margin-left: 2rem;
}
.rishabcard-details a{
  text-decoration: none;
}
.rilogo{
  width: 2rem;
  height: 2rem;
  margin-left: 2rem;
  /* padding-top: 1rem; */
  /* margin-top: 2rem; */
}
.rishabmainhead{
  font-size: 35px;
  margin-left: 5rem;
  margin-top: -2.2rem;
     color: rgba(58, 58, 31, 0.8); 
     
}
.rishabmainshead{
  font-size: 20px;
  text-align: left;
  text-decoration: underline;
  text-transform: uppercase;
  color: rgba(58, 58, 31, 0.8); 
}
.rishabmaindesc{
  width: 50%;
  font-size: 17px;
  color: rgba(58, 58, 31, 0.8); 
}
.rishabflex-item-left{
  /* margin-left: 1.2rem; */
}
.rishabflex-item-left .terminalaccordian{
  /* margin-left: 1rem; */
  /* text-align: justify; */
  left: 0.7%;
}
.rishabrightimage{
  width: 100%;
  height: 100%;
  margin-top: -20rem;
}
.rishabflex-container {
  display: flex;
  flex-direction: row;
  font-size: 30px;
  text-align: center;
}

.rishabflex-item-left {
  /* background-color: #f1f1f1; */
  padding: 10px;
  flex: 50%;
}
.rishabcard-details {
  padding-top: 2rem;
  /* margin-top: -20rem; */
  padding-left: 5rem;
  padding-right: 5rem;
}
.rishabcardrdetail{
/* position: absolute;*/
margin-top: -17rem;
}
.ricard {
  margin: 10px auto;


  border: 0.3px solid rgba(58, 58, 31, 0.8);
  /* height: 100%; */
  transition: 0.3s;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  overflow-x: hidden;
}
.ricard:nth-child() {
}
.ricard:hover {
}
.ricard-title {
  text-align: center;
  color: #005E8C;
  /* text-decoration: underline; */
  font-size:20px;
}
.ricard-body{
  /* margin-top: 45rem; */
}
.ricard-text {
  font-weight: 500;
  color: rgba(92, 92, 92, 0.70);;
  text-align: justify;
  text-align: left;
  font-weight: 400;
}
.ricard-image {
  text-align: center;
  margin: 5vh 0;
}
.ricard-text {
  margin: 5vh 2vh;
}


.rititle-heading {
  font-size: 30px;
  text-align: center;
  margin: 4%;


  text-transform: capitalize;


  color: #005E8C;
}
.ricard:nth-child(6){


}


.rishabflex-item-right {
  /* background-color: dodgerblue; */
  padding: 10px;
  flex: 50%;
}
@media (max-width: 1538px) {
 
  
 
  .rishabcardrdetail{
    margin-top: 1rem;
  }

}
@media (max-width: 1156px) {
 
  
 
  .rishabcardrdetail{
    margin-top: 8rem;
  }

}
/* Responsive layout - makes a one column-layout instead of two-column layout */
@media (max-width: 800px) {
  .rishabflex-container {
    flex-direction: column;
  }
  .rishabmaindesc{
    width: 100%;
  }
  .rishabrightimage{
    margin-top: 4rem;
  }
  .rishabflex-item-left .terminalaccordian{
    margin-top: 102rem;

  }
  .rishabcardrdetail{
    margin-top: 28rem;
  }
  .rishabflex-item-left .terminalpr{
    margin-top: 102rem;
    /* text-align: left; */
    margin-left: 2rem;
  }
  .rishabmain{
    margin-top: 8rem;
  }
  .rilogo{
    /* margin-top: 5rem; */
    /* padding-top: 5rem; */
    /* margin-top: 9rem; */
    /* text-align: left; */
  }
}

@media (max-width: 618px) {
 
  
  .rishabflex-item-left .terminalaccordian{
    margin-top: 52rem;

  }
  .rishabcardrdetail{
    margin-top: 38rem;
  }
  .rishabflex-item-left .terminalpr{
    margin-top: 52rem;
  }
}

@media (max-width: 570px) {
 
  
  .rishabflex-item-left .terminalaccordian{
    margin-top: 50rem;

  }
  .rishabcardrdetail{
    margin-top: 45rem;
  }
  .rishabflex-item-left .terminalpr{
    margin-top: 50rem;
  }
}

@media (max-width: 456px) {
 
  
  .rishabflex-item-left .terminalaccordian{
    margin-top: 35rem;

  }
  .rishabcardrdetail{
    margin-top: 58rem;
  }
  .rishabflex-item-left .terminalpr{
    margin-top: 35rem;
  }
}

@media (max-width: 378px) {
 
  
  .rishabflex-item-left .terminalaccordian{
    /* margin-top: 70rem; */

  }
  .rishabcardrdetail{
    margin-top: 35rem;
  }
  .rishabflex-item-left .terminalpr{
    /* margin-top: 70rem; */
  }
}

@media (max-width: 292px) {
 
  
  .rishabflex-item-left .terminalaccordian{
    margin-top: 50rem;

  }
  .rishabcardrdetail{
    margin-top: 58rem;
  }
  .rishabflex-item-left .terminalpr{
    margin-top: 50rem;
  }
}

.rishabfmain{
  margin-bottom: 245px;
  margin-top: 8%;
}

@media (max-width: 930px) {
  .rishabfmain{
    margin-bottom: 595px;
  }
  .rishabcard-details{
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .col-lg-4,.col-sm-4,.col-md-4,.col-xl-4.col-xs-12{
    /* background-color: red; */
    padding-top: 1rem;
  }
}
.ricard-image img{
  width: 5rem;
  height: 5rem;
}

.rishablogop{
  width: 4rem;
}
@media (max-width: 500px) {
  .rishablogop{
    width: 3rem;
  }
  .riiam{
    /* margin-top: -; */
    /* margin-top: -2rem; */
    margin-bottom: -2rem;
  }
  .rishabcard-details{
    padding-top: -2rem;
  }
}

@media (max-width: 970px){
.rishbanmep .rititle-heading {
    font-size: 20px;
    text-align: center;
    margin: 0% 0px 0%;
    text-transform: capitalize;
    color: #005E8C;
}
}

@media (max-width: 300px){
.rishabcard-details {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
}
}