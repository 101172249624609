
.gvkmainloader{
    
    height: 100%;
    width: 100%;
    overflow-x: hidden;
}


.loader {
   width:50px;
   height:50px;
   display:inline-block;
   padding:0px;
   opacity:0.5;
   border:3px solid #3A3A1F;
   -webkit-animation: loader 1s ease-in-out infinite alternate;
   animation: loader 1s ease-in-out infinite alternate;
   display: block;
  
   margin-left: auto;
   margin-top: 40%;
   align-items: center;
   overflow-x: hidden;
  
}

.loader:before {
  content: " ";
  position: absolute;
  z-index: -1;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border: 3px solid #3A3A1F;
}

.loader:after {
  content: " ";
  position: absolute;
  z-index: -1;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border: 3px solid #3A3A1F;
}

@keyframes loader {
   from {transform: rotate(0deg) scale(1,1);border-radius:0px;}
   to {transform: rotate(360deg) scale(0, 0);border-radius:50px;}
}
@-webkit-keyframes loader {
   from {-webkit-transform: rotate(0deg) scale(1, 1);border-radius:0px;}
   to {-webkit-transform: rotate(360deg) scale(0,0 );border-radius:50px;}
}



