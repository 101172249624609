/* <style type="text/css" id="server-side-jss"> */
.button-0-3-39 {
    color: currentColor;
    /* border: 1px solid var(--foreground); */
    cursor: pointer;
    margin: 0;
    display: inline-flex;
    padding: 7.5px 15px;
    overflow: hidden;
    position: relative;
    font-size: 10px;
    background: transparent;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
    align-items: center;
    line-height: 1.6;
    border-radius: 80px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    justify-content: center;
    text-decoration: none;
}

.button-0-3-39:disabled {
    color: currentColor;
    cursor: inherit;
    opacity: 0.5;
    background: transparent;
}

.clone-0-3-40 {
    top: 0;
    left: 0;
    color: var(--background);
    right: 0;
    bottom: 0;
    overflow: hidden;
    position: absolute;
    transform: translateY(110%);
    background-color: var(--foreground);
}

.button-0-3-39.selected .clone-0-3-40 {
    transform: translateY(0%);
}

.cloneContent-0-3-41 {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: inline-flex;
    padding: 7.5px 15px;
    position: absolute;
    transform: translateY(-110%);
    align-items: center;
    justify-content: center;
}

.button-0-3-39.selected .cloneContent-0-3-41 {
    transform: translateY(0%);
}

.icon-0-3-42 {
    width: 18px;
    display: block;
}

.icon-0-3-42.backArrow {
    transform: rotate(180deg);
}

.iconContainer-0-3-43 {
    display: block;
    overflow: hidden;
    margin-top: 1px;
    margin-left: 5px;
}

.iconContainer-0-3-43.backArrow {
    margin-left: 0;
    margin-right: 5px;
}

.Layout-section-0-3-33.gutter {
    margin-left: 15px;
    margin-right: 15px;
}

.Layout-section-0-3-33.bottomMargin {
    /* margin-bottom: 75px; */
}

.Layout-section-0-3-33.grid {
    gap: 15px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

@media (min-width:1024px) {
    .Layout-section-0-3-33.grid {
        gap: 25px;
        grid-template-columns: repeat(7, 1fr);
    }
}

@media (min-width:1024px) {
    .Layout-section-0-3-33.bottomMargin {
        /* margin-bottom: 100px; */
    }
}

@media (min-width:1024px) {
    .Layout-section-0-3-33.gutter {
        margin-left: 25px;
        margin-right: 25px;
    }
}

.caption-0-3-236 {
    font-size: 10px;
    font-family: "ABCMonumentGroteskMono", "Lucida Sans", Tahoma, sans-serif;
    line-height: 1.2;
    white-space: pre-wrap;
    text-transform: uppercase;
}

.imageContainer-0-3-92 {
    width: 100%;
    display: block;
    overflow: hidden;
    position: relative;
}

.imageContainer-0-3-92 picture::before {
    display: block;
}

@media (min-width:1024px) {
    .imageContainer-0-3-92 {}
    .imageContainer-0-3-92 picture::before {}
}

.picture-0-3-93 {}

.image-0-3-94 {
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    bottom: 0;
    height: 100%;
    position: absolute;
    object-fit: cover;
}

.image-0-3-94.fadeIn {
    opacity: 1;
}

.caption-0-3-95 {
    top: calc(100% + 10px);
    right: 0;
    position: absolute;
    font-size: 10px;
    font-family: "ABCMonumentGroteskMono", "Lucida Sans", Tahoma, sans-serif;
}

.imageContainer-d0-0-3-96 {}

.imageContainer-d0-0-3-96 picture::before {
    content: "";
    padding-top: 66.74082313681869%;
}

@media (min-width:1024px) {
    .imageContainer-d0-0-3-96 {}
    .imageContainer-d0-0-3-96 picture::before {
        padding-top: 66.74082313681869%;
    }
}

.image-d2-0-3-97 {
    object-position: 50% 50%;
}

.imageContainer-d3-0-3-240 {}

.imageContainer-d3-0-3-240 picture::before {
    content: "";
    padding-top: 122.66666666666667%;
}

@media (min-width:1024px) {
    .imageContainer-d3-0-3-240 {}
    .imageContainer-d3-0-3-240 picture::before {
        padding-top: 56.25%;
    }
}

.image-d5-0-3-241 {
    object-position: 50% 50%;
}

.imageContainer-d6-0-3-280 {}

.imageContainer-d6-0-3-280 picture::before {
    content: "";
    padding-top: 100%;
}

@media (min-width:1024px) {
    .imageContainer-d6-0-3-280 {}
    .imageContainer-d6-0-3-280 picture::before {
        padding-top: 100%;
    }
}

.image-d8-0-3-281 {
    object-position: 50% 50%;
}

.imageContainer-d9-0-3-286 {}

.imageContainer-d9-0-3-286 picture::before {
    content: "";
    padding-top: 157.14285714285714%;
}

@media (min-width:1024px) {
    .imageContainer-d9-0-3-286 {}
    .imageContainer-d9-0-3-286 picture::before {
        padding-top: 157.14285714285714%;
    }
}

.image-d11-0-3-287 {
    object-position: 50% 50%;
}

.imageContainer-d12-0-3-289 {}

.imageContainer-d12-0-3-289 picture::before {
    content: "";
    padding-top: 157.14285714285714%;
}

@media (min-width:1024px) {
    .imageContainer-d12-0-3-289 {}
    .imageContainer-d12-0-3-289 picture::before {
        padding-top: 157.14285714285714%;
    }
}

.image-d14-0-3-290 {
    object-position: 50% 50%;
}

.imageContainer-d15-0-3-292 {}

.imageContainer-d15-0-3-292 picture::before {
    content: "";
    padding-top: 75.86206896551724%;
}

@media (min-width:1024px) {
    .imageContainer-d15-0-3-292 {}
    .imageContainer-d15-0-3-292 picture::before {
        padding-top: 75.86206896551724%;
    }
}

.image-d17-0-3-293 {
    object-position: 50% 50%;
}

.imageContainer-d18-0-3-307 {}

.imageContainer-d18-0-3-307 picture::before {
    content: "";
    padding-top: 100%;
}

@media (min-width:1024px) {
    .imageContainer-d18-0-3-307 {}
    .imageContainer-d18-0-3-307 picture::before {
        padding-top: 100%;
    }
}

.image-d20-0-3-308 {
    object-position: 50% 50%;
}

.imageContainer-d21-0-3-310 {}

.imageContainer-d21-0-3-310 picture::before {
    content: "";
    padding-top: 100%;
}

@media (min-width:1024px) {
    .imageContainer-d21-0-3-310 {}
    .imageContainer-d21-0-3-310 picture::before {
        padding-top: 100%;
    }
}

.image-d23-0-3-311 {
    object-position: 50% 50%;
}

.imageContainer-d24-0-3-313 {}

.imageContainer-d24-0-3-313 picture::before {
    content: "";
    padding-top: 157.14285714285714%;
}

@media (min-width:1024px) {
    .imageContainer-d24-0-3-313 {}
    .imageContainer-d24-0-3-313 picture::before {
        padding-top: 157.14285714285714%;
    }
}

.image-d26-0-3-314 {
    object-position: 50% 50%;
}

.imageContainer-d27-0-3-316 {}

.imageContainer-d27-0-3-316 picture::before {
    content: "";
    padding-top: 75.86206896551724%;
}

@media (min-width:1024px) {
    .imageContainer-d27-0-3-316 {}
    .imageContainer-d27-0-3-316 picture::before {
        padding-top: 75.86206896551724%;
    }
}

.image-d29-0-3-317 {
    object-position: 50% 50%;
}

.imageContainer-d30-0-3-331 {}

.imageContainer-d30-0-3-331 picture::before {
    content: "";
    padding-top: 75.86206896551724%;
}

@media (min-width:1024px) {
    .imageContainer-d30-0-3-331 {}
    .imageContainer-d30-0-3-331 picture::before {
        padding-top: 75.86206896551724%;
    }
}

.image-d32-0-3-332 {
    object-position: 50% 50%;
}

.imageContainer-d33-0-3-334 {}

.imageContainer-d33-0-3-334 picture::before {
    content: "";
    padding-top: 100%;
}

@media (min-width:1024px) {
    .imageContainer-d33-0-3-334 {}
    .imageContainer-d33-0-3-334 picture::before {
        padding-top: 100%;
    }
}

.image-d35-0-3-335 {
    object-position: 50% 50%;
}

.imageContainer-d36-0-3-337 {}

.imageContainer-d36-0-3-337 picture::before {
    content: "";
    padding-top: 157.14285714285714%;
}

@media (min-width:1024px) {
    .imageContainer-d36-0-3-337 {}
    .imageContainer-d36-0-3-337 picture::before {
        padding-top: 157.14285714285714%;
    }
}

.image-d38-0-3-338 {
    object-position: 50% 50%;
}

.imageContainer-d39-0-3-340 {}

.imageContainer-d39-0-3-340 picture::before {
    content: "";
    padding-top: 75.86206896551724%;
}

@media (min-width:1024px) {
    .imageContainer-d39-0-3-340 {}
    .imageContainer-d39-0-3-340 picture::before {
        padding-top: 75.86206896551724%;
    }
}

.image-d41-0-3-341 {
    object-position: 50% 50%;
}

.imageContainer-d42-0-3-372 {}

.imageContainer-d42-0-3-372 picture::before {
    content: "";
    padding-top: 74.12790697674419%;
}

@media (min-width:1024px) {
    .imageContainer-d42-0-3-372 {}
    .imageContainer-d42-0-3-372 picture::before {
        padding-top: 80.82191780821917%;
    }
}

.image-d44-0-3-373 {
    object-position: 50% 50%;
}

.imageContainer-d45-0-3-377 {}

.imageContainer-d45-0-3-377 picture::before {
    content: "";
    padding-top: 74.12790697674419%;
}

@media (min-width:1024px) {
    .imageContainer-d45-0-3-377 {}
    .imageContainer-d45-0-3-377 picture::before {
        padding-top: 124.21052631578948%;
    }
}

.image-d47-0-3-378 {
    object-position: 50% 50%;
}

.imageContainer-d48-0-3-382 {}

.imageContainer-d48-0-3-382 picture::before {
    content: "";
    padding-top: 74.12790697674419%;
}

@media (min-width:1024px) {
    .imageContainer-d48-0-3-382 {}
    .imageContainer-d48-0-3-382 picture::before {
        padding-top: 124.21052631578948%;
    }
}

.image-d50-0-3-383 {
    object-position: 50% 50%;
}

.imageContainer-d51-0-3-387 {}

.imageContainer-d51-0-3-387 picture::before {
    content: "";
    padding-top: 74.12790697674419%;
}

@media (min-width:1024px) {
    .imageContainer-d51-0-3-387 {}
    .imageContainer-d51-0-3-387 picture::before {
        padding-top: 80.82191780821917%;
    }
}

.image-d53-0-3-388 {
    object-position: 50% 50%;
}

.imageContainer-d54-0-3-392 {}

.imageContainer-d54-0-3-392 picture::before {
    content: "";
    padding-top: 74.12790697674419%;
}

@media (min-width:1024px) {
    .imageContainer-d54-0-3-392 {}
    .imageContainer-d54-0-3-392 picture::before {
        padding-top: 124.21052631578948%;
    }
}

.image-d56-0-3-393 {
    object-position: 50% 50%;
}

.imageContainer-d57-0-3-397 {}

.imageContainer-d57-0-3-397 picture::before {
    content: "";
    padding-top: 74.12790697674419%;
}

@media (min-width:1024px) {
    .imageContainer-d57-0-3-397 {}
    .imageContainer-d57-0-3-397 picture::before {
        padding-top: 124.21052631578948%;
    }
}

.image-d59-0-3-398 {
    object-position: 50% 50%;
}

.imageContainer-d60-0-3-416 {}

.imageContainer-d60-0-3-416 picture::before {
    content: "";
    padding-top: 55.26315789473684%;
}

@media (min-width:1024px) {
    .imageContainer-d60-0-3-416 {}
    .imageContainer-d60-0-3-416 picture::before {
        padding-top: 55.26315789473684%;
    }
}

.image-d62-0-3-417 {
    object-position: 50% 50%;
}

.imageContainer-d63-0-3-422 {}

.imageContainer-d63-0-3-422 picture::before {
    content: "";
    padding-top: 55.26315789473684%;
}

@media (min-width:1024px) {
    .imageContainer-d63-0-3-422 {}
    .imageContainer-d63-0-3-422 picture::before {
        padding-top: 55.26315789473684%;
    }
}

.image-d65-0-3-423 {
    object-position: 50% 50%;
}

.imageContainer-d66-0-3-428 {}

.imageContainer-d66-0-3-428 picture::before {
    content: "";
    padding-top: 55.26315789473684%;
}

@media (min-width:1024px) {
    .imageContainer-d66-0-3-428 {}
    .imageContainer-d66-0-3-428 picture::before {
        padding-top: 55.26315789473684%;
    }
}

.image-d68-0-3-429 {
    object-position: 50% 50%;
}

.splitWords-0-3-234 {
    /* visibility: hidden; */
}

.splitWords-0-3-234 a {
    text-decoration: none;
}

.lines-0-3-237 {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: grid;
    z-index: -1;
    position: absolute;
}

.verticalLine-0-3-238 {
    width: 1px;
    
    /* transform: scaleY(0); */
    justify-self: end;
    background-color: var(--border);
}

.verticalLine-0-3-238.desktopOnly {
    display: none;
}

@media (min-width:1024px) {
    .verticalLine-0-3-238.desktopOnly {
        display: block;
    }
}

.horizontalLine-0-3-239 {
    left: -15px;
    right: -15px;
    bottom: 0;
    height: 1px;
    opacity: 0.15;
    position: absolute;
    /* transform: scaleX(0); */
    background-color: var(--border);
}

@media (min-width:1024px) {
    .horizontalLine-0-3-239 {
        left: -25px;
        right: -25px;
    }
}

.horizontalLine-0-3-239.top {
    top: 0;
    bottom: auto;
}

.horizontalLine-0-3-239.bottom {
    opacity: 1;
}

.section-0-3-224 {
    margin-bottom: 30px;
}

.content-0-3-225 {
    height: calc(100vh - 250px);
    position: relative;
    align-items: end;
    padding-bottom: 25px;
    grid-template-rows: auto min-content min-content;
}

@media (min-width:1024px) {
    .content-0-3-225 {
        height: auto;
        align-items: start;
        padding-top: 15vw;
        padding-bottom: 50px;
        grid-template-rows: auto;
    }
}

.mmtitle-0-3-226 {
    font-size: 42px;
    max-width: 600px;
    grid-column: 1 / span 4;
    line-height: 0.9;
    margin-bottom: 35px;
}

@media (min-width:1024px) {
    .mmtitle-0-3-226 {
        font-size: 8vw;
        max-width: 100%;
        grid-column: 1 / span 7;
        margin-bottom: 50px;
    }
}

.copy-0-3-227 {
    opacity: 1;
    max-width: 360px;
    grid-column: 1 / span 4;
    text-indent: 25px;
    margin-bottom: 0;
}

@media (min-width:1024px) {
    .copy-0-3-227 {
        grid-column: 2 / span 2;
    }
}

.captions-0-3-228 {
    display: none;
    opacity: 1;
}

@media (min-width:1024px) {
    .captions-0-3-228 {
        gap: 24px;
        display: grid;
        grid-column: 5 / span 3;
        grid-template-columns: 1fr 1fr 1fr;
    }
}

.button-0-3-229 {
    opacity: 1;
    grid-column: 1 / span 4;
}

@media (min-width:1024px) {
    .button-0-3-229 {
        grid-column: 2 / span 2;
    }
}

.image-0-3-230 {
    opacity: 1;
}

.imageCaptions-0-3-231 {
    top: 0;
    left: 0;
    color: #FFF;
    right: 0;
    position: absolute;
    margin-top: 30px;
}

@media (min-width:1024px) {
    .imageCaptions-0-3-231 {
        margin-top: 50px;
    }
}

.imageCaption-0-3-232 {
    grid-column: span 2;
}

@media (min-width:1024px) {
    .imageCaption-0-3-232 {
        grid-column: span 1;
    }
    .imageCaption-0-3-232:first-child {
        grid-column: 5;
    }
}

.line-0-3-233 {
    /* width: 100%; */
    height: 1px;
    width:0%;
	/* margin:auto; */
    /* margin: -5px; */
	padding:0.01em; 
    position: relative;
  	/* background-color:white;  */
    margin-top: -3rem;
    opacity: 1;
    background-color: var(--border);
    /* background-color: red; */
    animation: line 15.25s ease forwards;
  

}

@keyframes line {
    from { width: 0%; }
    to { width:100%; }
  }
  

.line-0-3-107 {
    background-color: var(--border);
}


.horizontal-0-3-108 {
    left: 0;
    right: 0;
    height: 1px;
    position: absolute;
    width:0%;
	margin:auto;
	padding:0.01em; 
  	background-color:white; 
	animation: line 1.25s ease forwards;

    /* transform: scaleX(0); */
}

.horizontal-0-3-108.extend {
    left: -15px;
    right: -15px;
}

@media (min-width:1024px) {
    .horizontal-0-3-108.extend {
        left: -25px;
        right: -25px;
    }
}

.vertical-0-3-109 {
    top: 0;
    width: 1px;
    bottom: 0;
    position: absolute;
    animation: 15.5s cubic-bezier(.25, 1, .30, 1) wipe-out-down forwards;
    /* transform: scaley(0); */
}
@keyframes wipe-out-down {
 
        from { height: 0%; }
        to { height:100%; }
      
  }
  

.vertical-0-3-109.extend {
    top: -15px;
    bottom: -15px;
}

@media (min-width:1024px) {
    .vertical-0-3-109.extend {
        top: -25px;
        bottom: -25px;
    }
}

.horizontal-d1-0-3-110 {
    transform-origin: 50% 50%;
}

.vertical-d2-0-3-111 {
    transform-origin: 50% 50%;
}

.horizontal-d3-0-3-112 {
    transform-origin: 50% 50%;
}

.vertical-d4-0-3-113 {
    transform-origin: 50% 50%;
}

.horizontal-d5-0-3-114 {
    transform-origin: 50% 50%;
}

.vertical-d6-0-3-115 {
    transform-origin: 50% 50%;
}

.horizontal-d7-0-3-116 {
    transform-origin: 50% 50%;
}

.vertical-d8-0-3-117 {
    transform-origin: 50% 50%;
}

.horizontal-d9-0-3-118 {
    transform-origin: 50% 50%;
}

.vertical-d10-0-3-119 {
    transform-origin: 50% 50%;
}

.horizontal-d11-0-3-120 {
    transform-origin: 50% 50%;
}

.vertical-d12-0-3-121 {
    transform-origin: 50% 50%;
}

.horizontal-d13-0-3-122 {
    transform-origin: 50% 50%;
}

.vertical-d14-0-3-123 {
    transform-origin: 50% 50%;
}

.horizontal-d15-0-3-124 {
    transform-origin: 50% 50%;
}

.vertical-d16-0-3-125 {
    transform-origin: 50% 50%;
}

.horizontal-d17-0-3-126 {
    transform-origin: 50% 50%;
}

.vertical-d18-0-3-127 {
    transform-origin: 50% 50%;
}

.horizontal-d19-0-3-128 {
    transform-origin: 50% 50%;
}

.vertical-d20-0-3-129 {
    transform-origin: 50% 50%;
}

.horizontal-d21-0-3-130 {
    transform-origin: 50% 50%;
}

.vertical-d22-0-3-131 {
    transform-origin: 50% 50%;
}

.horizontal-d23-0-3-132 {
    transform-origin: 50% 50%;
}

.vertical-d24-0-3-133 {
    transform-origin: 50% 50%;
}

.horizontal-d25-0-3-134 {
    transform-origin: 50% 50%;
}

.vertical-d26-0-3-135 {
    transform-origin: 50% 50%;
}

.horizontal-d27-0-3-136 {
    transform-origin: 50% 50%;
}

.vertical-d28-0-3-137 {
    transform-origin: 50% 50%;
}

.horizontal-d29-0-3-138 {
    transform-origin: 50% 50%;
}

.vertical-d30-0-3-139 {
    transform-origin: 50% 50%;
}

.horizontal-d31-0-3-140 {
    transform-origin: 50% 50%;
}

.vertical-d32-0-3-141 {
    transform-origin: 50% 50%;
}

.horizontal-d33-0-3-142 {
    transform-origin: 50% 50%;
}

.vertical-d34-0-3-143 {
    transform-origin: 50% 50%;
}

.horizontal-d35-0-3-144 {
    transform-origin: 50% 50%;
}

.vertical-d36-0-3-145 {
    transform-origin: 50% 50%;
}

.horizontal-d37-0-3-146 {
    transform-origin: 50% 50%;
}

.vertical-d38-0-3-147 {
    transform-origin: 50% 50%;
}

.horizontal-d39-0-3-148 {
    transform-origin: 50% 50%;
}

.vertical-d40-0-3-149 {
    transform-origin: 50% 50%;
}

.horizontal-d41-0-3-179 {
    transform-origin: 50% 50%;
}

.vertical-d42-0-3-180 {
    transform-origin: 50% 50%;
}

.horizontal-d43-0-3-181 {
    transform-origin: 50% 50%;
}

.vertical-d44-0-3-182 {
    transform-origin: 50% 50%;
}

.horizontal-d45-0-3-183 {
    transform-origin: 50% 50%;
}

.vertical-d46-0-3-184 {
    transform-origin: 50% 50%;
}

.horizontal-d47-0-3-185 {
    transform-origin: 50% 50%;
}

.vertical-d48-0-3-186 {
    transform-origin: 50% 50%;
}

.horizontal-d49-0-3-187 {
    transform-origin: 50% 50%;
}

.vertical-d50-0-3-188 {
    transform-origin: 50% 50%;
}

.horizontal-d51-0-3-189 {
    transform-origin: 50% 50%;
}

.vertical-d52-0-3-190 {
    transform-origin: 50% 50%;
}

.horizontal-d53-0-3-191 {
    transform-origin: 50% 50%;
}

.vertical-d54-0-3-192 {
    transform-origin: 50% 50%;
}

.horizontal-d55-0-3-193 {
    transform-origin: 50% 50%;
}

.vertical-d56-0-3-194 {
    transform-origin: 50% 50%;
}

.horizontal-d57-0-3-195 {
    transform-origin: 50% 50%;
}

.vertical-d58-0-3-196 {
    transform-origin: 50% 50%;
}

.horizontal-d59-0-3-197 {
    transform-origin: 50% 50%;
}

.vertical-d60-0-3-198 {
    transform-origin: 50% 50%;
}

.horizontal-d61-0-3-199 {
    transform-origin: 50% 50%;
}

.vertical-d62-0-3-200 {
    transform-origin: 50% 50%;
}

.horizontal-d63-0-3-201 {
    transform-origin: 50% 50%;
}

.vertical-d64-0-3-202 {
    transform-origin: 50% 50%;
}

.horizontal-d65-0-3-203 {
    transform-origin: 50% 50%;
}

.vertical-d66-0-3-204 {
    transform-origin: 50% 50%;
}

.horizontal-d67-0-3-205 {
    transform-origin: 50% 50%;
}

.vertical-d68-0-3-206 {
    transform-origin: 50% 50%;
}

.horizontal-d69-0-3-207 {
    transform-origin: 50% 50%;
}

.vertical-d70-0-3-208 {
    transform-origin: 50% 50%;
}

.horizontal-d71-0-3-209 {
    transform-origin: 50% 50%;
}

.vertical-d72-0-3-210 {
    transform-origin: 50% 50%;
}

.horizontal-d73-0-3-211 {
    transform-origin: 50% 50%;
}

.vertical-d74-0-3-212 {
    transform-origin: 50% 50%;
}

.horizontal-d75-0-3-213 {
    transform-origin: 50% 50%;
}

.vertical-d76-0-3-214 {
    transform-origin: 50% 50%;
}

.horizontal-d77-0-3-260 {
    transform-origin: 28% 50%;
}

.vertical-d78-0-3-261 {
    transform-origin: 50% 28%;
}

.horizontal-d79-0-3-262 {
    transform-origin: 50% 50%;
}

.vertical-d80-0-3-263 {
    transform-origin: 50% 50%;
}

.horizontal-d81-0-3-272 {
    transform-origin: 0% 50%;
}

.vertical-d82-0-3-273 {
    /* transform-origin: 50% 0%; */
    /* margin-left: 4rem; */
}

.horizontal-d83-0-3-274 {
    transform-origin: 50% 50%;
}

.vertical-d84-0-3-275 {
    transform-origin: 50% 50%;
}

.horizontal-d85-0-3-294 {
    transform-origin: 28% 50%;
}

.vertical-d86-0-3-295 {
    transform-origin: 50% 28%;
}

.horizontal-d87-0-3-296 {
    transform-origin: 50% 50%;
}

.vertical-d88-0-3-297 {
    transform-origin: 50% 50%;
}

.horizontal-d89-0-3-302 {
    transform-origin: 0% 50%;
}

.vertical-d90-0-3-303 {
    transform-origin: 50% 0%;
}

.horizontal-d91-0-3-304 {
    transform-origin: 50% 50%;
}

.vertical-d92-0-3-305 {
    transform-origin: 50% 50%;
}

.horizontal-d93-0-3-318 {
    transform-origin: 28% 50%;
}

.vertical-d94-0-3-319 {
    transform-origin: 50% 28%;
}

.horizontal-d95-0-3-320 {
    transform-origin: 50% 50%;
}

.vertical-d96-0-3-321 {
    transform-origin: 50% 50%;
}

.horizontal-d97-0-3-326 {
    transform-origin: 0% 50%;
}

.vertical-d98-0-3-327 {
    transform-origin: 50% 0%;
}

.horizontal-d99-0-3-328 {
    transform-origin: 50% 50%;
}

.vertical-d100-0-3-329 {
    transform-origin: 50% 50%;
}

.horizontal-d101-0-3-342 {
    transform-origin: 28% 50%;
}

.vertical-d102-0-3-343 {
    transform-origin: 50% 28%;
}

.horizontal-d103-0-3-344 {
    transform-origin: 50% 50%;
}

.vertical-d104-0-3-345 {
    transform-origin: 50% 50%;
}

.horizontal-d105-0-3-402 {
    transform-origin: 50% 50%;
}

.vertical-d106-0-3-403 {
    transform-origin: 50% 50%;
}

.horizontal-d107-0-3-404 {
    transform-origin: 50% 50%;
}

.vertical-d108-0-3-405 {
    transform-origin: 50% 50%;
}

.horizontal-d109-0-3-418 {
    transform-origin: 50% 50%;
}

.vertical-d110-0-3-419 {
    transform-origin: 50% 50%;
}

.horizontal-d111-0-3-420 {
    transform-origin: 50% 50%;
}

.vertical-d112-0-3-421 {
    transform-origin: 50% 50%;
}

.horizontal-d113-0-3-424 {
    transform-origin: 50% 50%;
}

.vertical-d114-0-3-425 {
    transform-origin: 50% 50%;
}

.horizontal-d115-0-3-426 {
    transform-origin: 50% 50%;
}

.vertical-d116-0-3-427 {
    transform-origin: 50% 50%;
}

.horizontal-d117-0-3-430 {
    transform-origin: 50% 50%;
}

.vertical-d118-0-3-431 {
    transform-origin: 50% 50%;
}

.horizontal-d119-0-3-432 {
    transform-origin: 50% 50%;
}

.vertical-d120-0-3-433 {
    transform-origin: 50% 50%;
}

.horizontal-d121-0-3-460 {
    transform-origin: 50% 50%;
}

.vertical-d122-0-3-461 {
    transform-origin: 50% 50%;
}

.horizontal-d123-0-3-462 {
    transform-origin: 50% 50%;
}

.vertical-d124-0-3-463 {
    transform-origin: 50% 50%;
}

.horizontal-d125-0-3-464 {
    transform-origin: 0 50%;
}

.vertical-d126-0-3-465 {
    transform-origin: 50% 0;
}

.horizontal-d127-0-3-466 {
    transform-origin: 50% 50%;
}

.vertical-d128-0-3-467 {
    transform-origin: 50% 50%;
}

.horizontal-d129-0-3-468 {
    transform-origin: 0 50%;
}

.vertical-d130-0-3-469 {
    transform-origin: 50% 0;
}

.horizontal-d131-0-3-470 {
    transform-origin: 50% 50%;
}

.vertical-d132-0-3-471 {
    transform-origin: 50% 50%;
}

.horizontal-d133-0-3-472 {
    transform-origin: 50% 50%;
}

.vertical-d134-0-3-473 {
    transform-origin: 50% 50%;
}

.horizontal-d135-0-3-474 {
    transform-origin: 50% 50%;
}

.vertical-d136-0-3-475 {
    transform-origin: 50% 50%;
}

.horizontal-d137-0-3-476 {
    transform-origin: 50% 50%;
}

.vertical-d138-0-3-477 {
    transform-origin: 50% 50%;
}

.horizontal-d139-0-3-478 {
    transform-origin: 50% 50%;
}

.vertical-d140-0-3-479 {
    transform-origin: 50% 50%;
}

.curtain-0-3-282 {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    overflow: hidden;
    position: absolute;
    pointer-events: none;
}

.leftCurtain-0-3-283 {
    width: 50%;
    background-color: var(--background);
}

.leftCurtain-0-3-283.topDown {
    width: 100%;
}

.rightCurtain-0-3-284 {
    width: 50%;
    background-color: var(--background);
}

.rightCurtain-0-3-284.topDown {
    display: none;
}

.Slider-flickity-0-3-277 {
    display: flex;
    outline: none;
    position: relative;
    white-space: nowrap;
}

.Slider-flickity-0-3-277>* {
    flex-shrink: 0;
}

.Slider-flickity-0-3-277 .flickity-slider {
    height: 100%;
}

.container-0-3-276 {
    margin: 0 -15px;
    padding: 0 15px;
    overflow: hidden;
}

@media (min-width:1024px) {
    .container-0-3-276 {
        margin: 0 -25px;
        padding: 0 25px;
    }
}

.image-0-3-278 {
    cursor: pointer;
    height: calc((100vw / 375) * 300);
    flex-shrink: 0;
    margin-right: 15px;
}

@media (min-width:1024px) {
    .image-0-3-278 {
        height: calc((100vw / 1440) * 600);
        margin-right: 25px;
    }
}

.image-d0-0-3-279 {
    width: 75%;
}

@media (min-width:1024px) {
    .image-d0-0-3-279 {
        width: calc(((100vw - 200px) / 7) * 3 + (25px * 2) + 0px);
    }
}

.image-d2-0-3-285 {
    width: 50%;
}

@media (min-width:1024px) {
    .image-d2-0-3-285 {
        width: calc(((100vw - 200px) / 7) * 2 + (25px * 1) + 0px);
    }
}

.image-d4-0-3-288 {
    width: 50%;
}

@media (min-width:1024px) {
    .image-d4-0-3-288 {
        width: calc(((100vw - 200px) / 7) * 2 + (25px * 1) + 0px);
    }
}

.image-d6-0-3-291 {
    width: 100%;
}

@media (min-width:1024px) {
    .image-d6-0-3-291 {
        width: calc(((100vw - 200px) / 7) * 4 + (25px * 3) + 0px);
    }
}

.image-d8-0-3-306 {
    width: 75%;
}

@media (min-width:1024px) {
    .image-d8-0-3-306 {
        width: calc(((100vw - 200px) / 7) * 3 + (25px * 2) + 0px);
    }
}

.image-d10-0-3-309 {
    width: 75%;
}

@media (min-width:1024px) {
    .image-d10-0-3-309 {
        width: calc(((100vw - 200px) / 7) * 3 + (25px * 2) + 0px);
    }
}

.image-d12-0-3-312 {
    width: 50%;
}

@media (min-width:1024px) {
    .image-d12-0-3-312 {
        width: calc(((100vw - 200px) / 7) * 2 + (25px * 1) + 0px);
    }
}

.image-d14-0-3-315 {
    width: 100%;
}

@media (min-width:1024px) {
    .image-d14-0-3-315 {
        width: calc(((100vw - 200px) / 7) * 4 + (25px * 3) + 0px);
    }
}

.image-d16-0-3-330 {
    width: 100%;
}

@media (min-width:1024px) {
    .image-d16-0-3-330 {
        width: calc(((100vw - 200px) / 7) * 4 + (25px * 3) + 0px);
    }
}

.image-d18-0-3-333 {
    width: 75%;
}

@media (min-width:1024px) {
    .image-d18-0-3-333 {
        width: calc(((100vw - 200px) / 7) * 3 + (25px * 2) + 0px);
    }
}

.image-d20-0-3-336 {
    width: 50%;
}

@media (min-width:1024px) {
    .image-d20-0-3-336 {
        width: calc(((100vw - 200px) / 7) * 2 + (25px * 1) + 0px);
    }
}

.image-d22-0-3-339 {
    width: 100%;
}

@media (min-width:1024px) {
    .image-d22-0-3-339 {
        width: calc(((100vw - 200px) / 7) * 4 + (25px * 3) + 0px);
    }
}

.text-0-3-264 {
    display: block;
}

.label-0-3-265 {
    display: block;
    opacity: 0.5;
}

.text-d0-0-3-266 {
    font-size: 12px;
}

.text-d1-0-3-268 {
    font-size: 12px;
}

.text-d2-0-3-269 {
    font-size: 12px;
}

.text-d3-0-3-270 {
    font-size: 12px;
}

.text-d4-0-3-298 {
    font-size: 12px;
}

.text-d5-0-3-299 {
    font-size: 12px;
}

.text-d6-0-3-300 {
    font-size: 12px;
}

.text-d7-0-3-301 {
    font-size: 12px;
}

.text-d8-0-3-322 {
    font-size: 12px;
}

.text-d9-0-3-323 {
    font-size: 12px;
}

.text-d10-0-3-324 {
    font-size: 12px;
}

.text-d11-0-3-325 {
    font-size: 12px;
}

.text-d12-0-3-374 {
    font-size: 10px;
}

.text-d13-0-3-375 {
    font-size: 10px;
}

.text-d14-0-3-376 {
    font-size: 10px;
}

.text-d15-0-3-379 {
    font-size: 10px;
}

.text-d16-0-3-380 {
    font-size: 10px;
}

.text-d17-0-3-381 {
    font-size: 10px;
}

.text-d18-0-3-384 {
    font-size: 10px;
}

.text-d19-0-3-385 {
    font-size: 10px;
}

.text-d20-0-3-386 {
    font-size: 10px;
}

.text-d21-0-3-389 {
    font-size: 10px;
}

.text-d22-0-3-390 {
    font-size: 10px;
}

.text-d23-0-3-391 {
    font-size: 10px;
}

.text-d24-0-3-394 {
    font-size: 10px;
}

.text-d25-0-3-395 {
    font-size: 10px;
}

.text-d26-0-3-396 {
    font-size: 10px;
}

.text-d27-0-3-399 {
    font-size: 10px;
}

.text-d28-0-3-400 {
    font-size: 10px;
}

.text-d29-0-3-401 {
    font-size: 10px;
}

.paginationButton-0-3-271 {
    padding: 4px 8px;
    min-width: 42px;
    margin-top: 8px;
}

.paginationButton-0-3-271:first-child {
    margin-right: 8px;
}

.paginationButton-0-3-271 span {
    margin: 0;
}

.paginationButton-0-3-271>div>span {
    padding: 4px 8px;
}

.projects-0-3-250 {
    position: relative;
}

.project-0-3-251 {
    padding: 0 15px;
    position: relative;
}

@media (min-width:1024px) {
    .project-0-3-251 {
        padding: 0 25px;
    }
}

.content-0-3-252 {
    gap: 15px;
    display: grid;
    padding: 15px 0 0;
    position: relative;
    grid-column: 1 / span 4;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

@media (min-width:1024px) {
    .content-0-3-252 {
        display: flex;
        padding: 25px 25px 25px 0;
        grid-column: 1 / span 2;
        flex-direction: column;
    }
}

.images-0-3-253 {
    padding: 0 0 15px;
    grid-column: 1 / span 4;
}

@media (min-width:1024px) {
    .images-0-3-253 {
        padding: 25px 0;
        grid-column: 3 / span 5;
    }
}

.verticalLine-0-3-254 {
    display: none;
    z-index: 1;
}

@media (min-width:1024px) {
    .verticalLine-0-3-254 {
        display: block;
    }
}

.onlyDesktop-0-3-255 {
    display: none;
}

@media (min-width:1024px) {
    .onlyDesktop-0-3-255 {
        display: block;
    }
}

.titleField-0-3-256 {
    opacity: 1;
    flex-grow: 1;
    flex-shrink: 1;
    grid-column: 1 / span 2;
}

.title-0-3-257 {
    font-size: 12px;
    text-decoration: none;
}

@media (min-width:1024px) {
    .title-0-3-257 {
        font-size: 24px;
    }
}

.detailsContainer-0-3-258 {
    gap: 15px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

@media (min-width:1024px) {
    .detailsContainer-0-3-258 {
        gap: 25px;
    }
}

.detailsContainer-0-3-258>* {
    opacity: 1;
}

.paginationButton-0-3-259 {
    padding: 6px 8px;
    margin-top: 8px;
}

.paginationButton-0-3-259:first-child {
    margin-right: 8px;
}

.paginationButton-0-3-259 svg {
    margin: 0;
}

.RichText-container-0-3-359>div>p:last-child,
.RichText-container-0-3-359>p:last-child {
    margin-bottom: 0;
}

.RichText-list-0-3-360 {
    margin: 0;
    padding: 0;
    position: relative;
    list-style: none;
}

.RichText-linkListLine-0-3-361 {
    opacity: 0.3;
}

.RichText-linkListLink-0-3-362 {
    display: flex;
    padding: 15px 0;
    position: relative;
    text-decoration: none;
}

.RichText-linkListLink-0-3-362:hover .RichText-linkListItemLine-0-3-363 {
    opacity: 1;
}

.RichText-linkListItemLine-0-3-363 {
    opacity: 0.3;
    transition: opacity 0.15s ease-in-out;
}

.RichText-linkListTitle-0-3-364 {
    display: flex;
    flex-direction: column;
}

.RichText-linkListImage-0-3-365 {
    width: 38px;
    height: 38px;
    margin-right: 20px;
}

.header-0-3-245 {
    margin-bottom: 75px;
}

@media (min-width:1024px) {
    .header-0-3-245 {
        margin-bottom: 100px;
    }
}

.title-0-3-246 {
    opacity: 1;
    grid-column: 1 / span 4;
}

@media (min-width:1024px) {
    .title-0-3-246 {
        margin: 0;
        grid-column: 1 / span 3;
    }
}

@media (min-width:1440px) {
    .title-0-3-246 {
        grid-column: 1 / span 2;
    }
}

.copyContainer-0-3-247 {
    opacity: 1;
    grid-column: 1 / span 4;
}

@media (min-width:1024px) {
    .copyContainer-0-3-247 {
        max-width: 400px;
        grid-column: 5 / span 2;
    }
}

.copyContainer-0-3-247.end {
    align-self: end;
}

.copy-0-3-248 {
    margin: 0 0 25px;
}

.buttonLabel-0-3-249 {
    opacity: 0.5;
}

.section-0-3-242 {
    margin-bottom: 75px;
}

@media (min-width:1024px) {
    .section-0-3-242 {
        margin-bottom: 100px;
    }
}

.header-0-3-243 {
    margin-bottom: 30px;
}

@media (min-width:1024px) {
    .header-0-3-243 {
        margin-bottom: 100px;
    }
}

.title-0-3-244 {
    margin-bottom: 100px;
}

@media (min-width:1024px) {
    .title-0-3-244 {
        margin: 0;
    }
}

.section-0-3-406 {
    margin-bottom: 100px;
}

.article-0-3-407 {
    display: block;
    position: relative;
    margin-bottom: 15px;
    padding-bottom: 15px;
    text-decoration: none;
}

@media (min-width:1024px) {
    .article-0-3-407 {
        margin-bottom: 25px;
        padding-bottom: 25px;
    }
}

.article-0-3-407 .category-0-3-414,
.article-0-3-407 .date-0-3-413,
.article-0-3-407 .line-0-3-415 {
    transition: opacity 0.15s ease-in-out;
}

.article-0-3-407:hover .category-0-3-414,
.article-0-3-407:hover .date-0-3-413,
.article-0-3-407:hover .line-0-3-415 {
    opacity: 1;
}

.image-0-3-409 {
    opacity: 1;
    align-self: start;
    grid-column: 1 / span 4;
}

@media (min-width:1024px) {
    .image-0-3-409 {
        grid-column: 1 / span 2;
    }
}

.content-0-3-410 {
    gap: 15px;
    display: grid;
    opacity: 1;
    grid-column: 1 / span 4;
    grid-template-columns: repeat(4, 1fr);
}

@media (min-width:1024px) {
    .content-0-3-410 {
        gap: 25px;
        grid-column: 3 / span 5;
        grid-template-rows: auto min-content;
        grid-template-columns: repeat(5, 1fr);
    }
}

.title-0-3-411 {
    margin: 0;
    font-size: 18px;
    grid-column: 1 / span 4;
    line-height: 1.4;
}

@media (min-width:1024px) {
    .title-0-3-411 {
        font-size: 20px;
        grid-column: 1 / span 2;
    }
}

.summary-0-3-412 {
    margin: 0 0 15px;
    font-size: 12px;
    grid-column: 1 / span 4;
}

@media (min-width:1024px) {
    .summary-0-3-412 {
        margin: 0;
        font-size: 15px;
        max-width: 400px;
        grid-column: 3 / span 2;
    }
}

.date-0-3-413 {
    margin: 0;
    opacity: 0.5;
    grid-row: 3;
    grid-column: 3 / span 2;
}

@media (min-width:1024px) {
    .date-0-3-413 {
        grid-row: 2;
        grid-column: 2;
    }
}

.category-0-3-414 {
    margin: 0;
    opacity: 0.5;
    grid-row: 3;
    grid-column: 1 / span 2;
}

@media (min-width:1024px) {
    .category-0-3-414 {
        grid-row: 2;
        grid-column: 1;
    }
}

.line-0-3-415 {
    bottom: 0;
    opacity: 0.1;
}

.section-0-3-366 {
    overflow: hidden;
    position: relative;
    margin-bottom: 75px;
    padding-bottom: 75px;
}

@media (min-width:1024px) {
    .section-0-3-366 {
        margin-bottom: 100px;
        padding-bottom: 100px;
    }
}

.product-0-3-367 {
    overflow: hidden;
    grid-column: 1 / span 4;
    padding-bottom: 0;
    text-decoration: none;
}

@media (min-width:1024px) {
    .product-0-3-367 {
        grid-column: span 2;
        padding-bottom: 25px;
    }
    .product-0-3-367.large {
        grid-column: span 3;
    }
}

.product-0-3-367 label {
    transition: opacity 0.15s ease-in-out;
}

.product-0-3-367:hover label {
    opacity: 1;
}

.image-0-3-368 {
    margin-bottom: 15px;
}

.title-0-3-369 {
    display: block;
    margin-bottom: 5px;
}

@media (min-width:1024px) {
    .title-0-3-369 {
        margin-bottom: 50px;
    }
}

.fieldContainer-0-3-370 {
    margin: 0;
    display: grid;
    grid-gap: 15px;
    text-transform: uppercase;
    grid-template-columns: 1fr 1fr;
}

@media (min-width:1024px) {
    .fieldContainer-0-3-370 {
        margin: 0 -15px;
        display: flex;
    }
    .fieldContainer-0-3-370>* {
        margin: 0 15px;
    }
}

@media (min-width:1440px) {
    .fieldContainer-0-3-370 {
        margin: 0 -30px;
    }
    .fieldContainer-0-3-370>* {
        margin: 0 30px;
    }
}

.fireGroup-0-3-371 {
    display: none;
}

@media (min-width:1024px) {
    .fireGroup-0-3-371 {
        display: block;
    }
}

.chartContainer-0-3-346 {
    display: block;
    position: relative;
    padding-top: 27.77777777777778%;
}

.title-0-3-347 {
    max-width: 540px;
    grid-column: 1 / span 4;
}

@media (min-width:1024px) {
    .title-0-3-347 {
        grid-column: 1 / span 4;
    }
}

.copy-0-3-348 {
    display: flex;
    align-items: start;
    grid-column: 1 / span 4;
    flex-direction: column;
}

@media (min-width:1024px) {
    .copy-0-3-348 {
        max-width: 300px;
        grid-column: span 3;
    }
}

.copy-0-3-348>*:not(:last-child) {
    margin-bottom: 15px;
}

.actionLabel-0-3-349 {
    opacity: 0.5;
    margin-left: 5px;
}

.chart-0-3-350 {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
}

.chart-0-3-350 svg>g>g>path {
    fill: var(--foreground);
}

.chart-0-3-350 svg>g>g:nth-child(6)>g>path,
.chart-0-3-350 svg>g>g:nth-child(7)>g>path,
.chart-0-3-350 svg>g>g:nth-child(8)>g>path,
.chart-0-3-350 svg>g>g:nth-child(9)>g>path {
    fill: var(--foreground);
}

.labels-0-3-351 {
    top: 8%;
    left: 0;
    right: 0;
    position: absolute;
}

.labels-0-3-351>* {
    opacity: 1;
}

.chartTitle-0-3-352 {
    left: 15px;
    display: none;
    position: absolute;
}

@media (min-width:1024px) {
    .chartTitle-0-3-352 {
        left: 25px;
        display: block;
    }
}

.label-0-3-353 {
    display: flex;
    position: absolute;
}

.soundProfileLabel-0-3-354 {
    display: none;
    opacity: 0.5;
    margin-left: 15px;
}

@media (min-width:1024px) {
    .soundProfileLabel-0-3-354 {
        display: block;
    }
}

.chartLabel1-0-3-355 {
    left: 17%;
}

.chartLabel2-0-3-356 {
    left: 31%;
}

.chartLabel3-0-3-357 {
    left: 59%;
}

.chartLabel4-0-3-358 {
    left: 87%;
}

.toolbarContainer-0-3-434 {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    z-index: 900;
    overflow: hidden;
    position: fixed;
    pointer-events: none;
}

@media (min-width:1024px) {
    .toolbarContainer-0-3-434 {
        position: absolute;
    }
}

.toolbar-0-3-435 {
    left: 0;
    right: 0;
    bottom: 0;
    height: 50px;
    display: flex;
    position: absolute;
    transform: translate(0, 100%);
    border-top: 1px solid var(--border);
    align-items: center;
    pointer-events: all;
    justify-content: flex-end;
    background-color: var(--background);
}

.titleItem-0-3-436 {
    min-width: 220px;
}

.hideOnMobile-0-3-437 {
    display: none;
}

@media (min-width:1024px) {
    .hideOnMobile-0-3-437 {
        display: flex;
    }
}

.title-0-3-481 {
    display: block;
    opacity: 0.5;
    font-size: 12px;
    margin-bottom: 5px;
}

.input-0-3-482 {
    width: 100%;
    border: none;
    margin: -1px 0 0 0;
    outline: none;
    padding: 10px 30px 10px 0;
    font-size: 12px;
    appearance: none;
    border-top: 1px solid var(--border);
    box-shadow: none;
    border-bottom: 1px solid var(--border);
    border-radius: 0;
    background-color: transparent;
}

.inputContainer-0-3-483 {
    position: relative;
}

.button-0-3-484 {
    top: 50%;
    right: 0;
    width: 18px;
    border: none;
    cursor: pointer;
    height: 30px;
    margin: 0;
    display: flex;
    padding: 0;
    overflow: hidden;
    position: absolute;
    transform: translate(0, -50%);
    align-items: center;
    justify-content: flex-end;
    background-color: transparent;
}

.button-0-3-484:hover>.arrow-0-3-485 {
    transform: translate(200%, 0);
}

.button-0-3-484:hover>.arrow2-0-3-486 {
    transform: translate(200%, 0);
}

.arrow-0-3-485 {
    width: 18px;
    transition: transform 0.35s cubic-bezier(0.61, 1, 0.88, 1);
    flex-shrink: 0;
}

.arrow2-0-3-486 {
    right: 200%;
    width: 18px;
    position: absolute;
    transition: transform 0.35s cubic-bezier(0.61, 1, 0.88, 1);
}

.error-0-3-487 {
    color: #AA0000;
    margin-top: 5px;
}

.footer-0-3-438 {
    position: relative;
    font-size: 12px;
}

.section-0-3-439 {
    padding: 50px 0;
    row-gap: 50px !important;
}

@media (min-width:1024px) {
    .section-0-3-439 {
        padding: 25px 0;
        row-gap: 25px !important;
    }
}

@media (min-width:1024px) {
    .section-0-3-439.extraToolbarPadding {
        padding: 25px 0 75px;
    }
}

.logoNewsletterContainer-0-3-440 {
    grid-row: 1;
    grid-column: 1 / span 4;
}

@media (min-width:1024px) {
    .logoNewsletterContainer-0-3-440 {
        grid-column: 1 / span 2;
        padding-right: 25px;
    }
}

.acknowledgeContainer-0-3-441 {
    margin: 0;
    grid-row: 7;
    font-size: 12px;
    text-align: center;
    grid-column: 1 / span 4;
    margin-bottom: 60px;
}

@media (min-width:1024px) {
    .acknowledgeContainer-0-3-441 {
        grid-row: 2;
        margin-top: 280px;
        text-align: left;
        grid-column: 1 / span 2;
        margin-bottom: 0;
        padding-right: 50px;
    }
}

.socialLinksContainer-0-3-442 {
    grid-row: 5;
    grid-column: 1 / span 2;
}

@media (min-width:1024px) {
    .socialLinksContainer-0-3-442 {
        grid-row: 1;
        grid-column: 3 / span 2;
    }
}

.copyrightContainer-0-3-443 {
    grid-row: 5;
    grid-column: 3 / span 2;
}

@media (min-width:1024px) {
    .copyrightContainer-0-3-443 {
        grid-row: 2;
        align-self: end;
        grid-column: 3 / span 2;
    }
}

.productsContainer-0-3-444 {
    grid-row: 2;
    text-align: right;
    grid-column: 3 / span 2;
}

@media (min-width:1024px) {
    .productsContainer-0-3-444 {
        grid-row: 1;
        text-align: left;
        grid-column: 5 / span 2;
    }
}

.projectsContainer-0-3-445 {
    grid-row: 2;
    grid-column: 1 / span 2;
}

@media (min-width:1024px) {
    .projectsContainer-0-3-445 {
        grid-row: 1;
        text-align: right;
        grid-column: 7;
    }
}

.contactsContainer-0-3-446 {
    display: flex;
    grid-row: 3;
    grid-column: 1 / span 2;
    flex-direction: column;
}

@media (min-width:1024px) {
    .contactsContainer-0-3-446 {
        grid-row: 2;
        align-self: end;
        grid-column: 5 / span 2;
    }
}

.resourcesContainer-0-3-447 {
    grid-row: 3;
    text-align: right;
    grid-column: 3 / span 2;
}

@media (min-width:1024px) {
    .resourcesContainer-0-3-447 {
        grid-row: 2;
        align-self: end;
        grid-column: 7;
    }
}

.logo-0-3-448 {
    width: 64px;
    height: 64px;
    margin-bottom: 50px;
}

.link-0-3-449 {
    line-height: 1.8;
    text-decoration: none;
}

.link-0-3-449:hover {
    text-decoration: underline;
}

.caption-0-3-450 {
    display: block;
    opacity: 0.5;
    margin-bottom: 15px;
}

.partnerLogos-0-3-451 {
    margin: 0 -5px 15px;
    display: flex;
    flex-wrap: wrap;
    max-width: 20em;
}

@media (min-width:1024px) {
    .partnerLogos-0-3-451 {
        margin: 0 -5px 30px;
    }
}

.partnerLogo-0-3-452 {
    width: auto;
    height: 34px;
    margin: 5px 5px;
}

.linkList-0-3-453 {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.verticalLineContainer-0-3-454 {
    display: none;
    position: relative;
    pointer-events: none;
}

@media (min-width:1024px) {
    .verticalLineContainer-0-3-454 {
        margin: -25px 0;
        display: block;
        grid-row: 1 / span 2;
    }
}

@media (min-width:1024px) {
    .firstLine-0-3-455 {
        grid-column: 2;
    }
}

@media (min-width:1024px) {
    .secondLine-0-3-456 {
        grid-column: 4;
    }
}

.horizontalLineContainer-0-3-457 {
    margin: 0 -15px;
    display: block;
    position: relative;
    grid-column: 1 / span 4;
    pointer-events: none;
}

@media (min-width:1024px) {
    .horizontalLineContainer-0-3-457 {
        display: none;
    }
}

.firstMobileLine-0-3-458 {
    grid-row: 4;
}

.secondMobileLine-0-3-459 {
    grid-row: 6;
}

.link-0-3-267 {
    display: inline-flex;
    position: relative;
}

.link-0-3-267:after {
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    content: "";
    display: block;
    position: absolute;
    /* transform: scaleX(0); */
    transition: transform 0.15s ease-in-out;
    background-color: currentColor;
    transform-origin: 0 0;
}

.link-0-3-267:hover:after,
.link-0-3-267.active:after {
    /* transform: scaleX(1); */
}

.button-0-3-18 {
    color: currentColor;
    border: none;
    cursor: pointer;
    display: inline=block;
    padding: 0;
    position: relative;
    font-size: 12px;
    background: transparent;
    min-height: 42px;
    align-items: center;
    font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
    line-height: 1.2;
    border-radius: 0;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    text-decoration: none;
}

.labelContainer-0-3-19 {
    height: 1.2em;
    display: block;
    overflow: hidden;
    position: relative;
}

.menuLabel-0-3-20 {
    display: block;
    position: relative;
}

.closeLabel-0-3-21 {
    right: 0;
    display: block;
}

.count-0-3-17 {
    width: 20px;
    border: 1px solid currentColor;
    height: 20px;
    display: inline-flex;
    font-size: 9px;
    align-items: center;
    font-family: "ABCMonumentGroteskMono", "Lucida Sans", Tahoma, sans-serif;
    border-radius: 50%;
    justify-content: center;
}

@media (min-width:1024px) {
    .count-0-3-17 {
        width: 24px;
        height: 24px;
        font-size: 10px;
    }
}

.count-0-3-16 {
    margin-left: 5px;
}

.menuItem-0-3-14 {
    display: none;
}

@media (min-width:1024px) {
    .menuItem-0-3-14 {
        display: flex;
        align-items: center;
    }
}

.link-0-3-15 {
    color: currentColor;
    border: none;
    cursor: pointer;
    display: inline-flex;
    padding: 0;
    overflow: hidden;
    position: relative;
    font-size: 10px;
    background: transparent;
    min-height: 42px;
    align-items: center;
    font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
    line-height: 1.2;
    border-radius: 0;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    text-decoration: none;
}

.link-0-3-15:after {
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    content: "";
    display: block;
    position: absolute;
    transform: translate(-110%, 1.2em);
    transition: transform 0.15s ease-in-out;
    background-color: currentColor;
    transform-origin: 0% 50%;
}

.link-0-3-15:hover:after,
.link-0-3-15.active:after,
.link-0-3-15.selected:after {
    transform: translate(0%, 1.2em);
}

.header-0-3-3 {
    top: 0;
    left: 0;
    right: 0;
    display: block;
    z-index: 1002;
    position: fixed;
    background-color: var(--background);
}

/* .line-0-3-4 {
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    opacity: 1;
    position: absolute;
    background-color: var(--border);
} */

.nav-0-3-5 {
    padding: 12px 15px;
}

@media (min-width:1024px) {
    .nav-0-3-5 {
        padding: 12px 25px;
    }
}

.list-0-3-6 {
    margin: 0 -7.5px;
    display: flex;
    padding: 0;
    list-style: none;
    justify-content: space-between;
}

@media (min-width:1024px) {
    .list-0-3-6 {
        margin: 0 -12.5px;
    }
}

.list-0-3-6 li {
    width: calc(100% / 7);
    margin: 0 7.5px;
    opacity: 1;
}

@media (min-width:1024px) {
    .list-0-3-6 li {
        margin: 0 12.5px;
    }
}

.menuItem-0-3-7 {
    display: none;
}

@media (min-width:1024px) {
    .menuItem-0-3-7 {
        display: flex;
        align-items: center;
    }
}

.mobileMenu-0-3-8 {
    display: flex;
    justify-content: flex-end;
}

@media (min-width:1024px) {
    .mobileMenu-0-3-8 {
        display: none;
    }
}

.logoLink-0-3-9 {
    color: currentColor;
    display: inline-flex;
    min-height: 50px;
    align-items: center;
    text-decoration: none;
}

.logo-0-3-10 {
    width: 105px;
    height: 26px;
}

.link-0-3-11 {
    color: currentColor;
    border: none;
    cursor: pointer;
    display: inline-flex;
    padding: 0;
    overflow: hidden;
    position: relative;
    font-size: 10px;
    background: transparent;
    min-height: 42px;
    align-items: center;
    font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
    line-height: 1.2;
    border-radius: 0;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    text-decoration: none;
}

.link-0-3-11:after {
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    content: "";
    display: block;
    position: absolute;
    transform: translate(-110%, 1.2em);
    transition: transform 0.15s ease-in-out;
    background-color: currentColor;
    transform-origin: 0% 50%;
}

.link-0-3-11:hover:after,
.link-0-3-11.active:after {
    transform: translate(0%, 1.2em);
}

.cartLink-0-3-12:after {
    right: 22px;
}

@media (min-width:1024px) {
    .cartCount-0-3-13 {
        width: 20px;
        height: 20px;
    }
}

.MobileMenu-container-0-3-22 {
    top: 0;
    left: 0;
    color: currentColor;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    position: fixed;
    transform: translate(100%, 0);
    pointer-events: none;
    background-color: var(--background);
}

.MobileMenu-container-0-3-22.open {
    pointer-events: all;
}

.MobileMenu-inner-0-3-23 {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    display: flex;
    position: absolute;
    padding-top: 42px;
    flex-direction: column;
}

.MobileMenu-header-0-3-24 {
    padding: 30px 15px;
}

.MobileMenu-breadcrumb-0-3-25 {
    font-size: 12px;
    visibility: hidden;
    align-items: center;
    line-height: 1.4;
    margin-bottom: 30px;
    text-transform: uppercase;
}

.MobileMenu-breadcrumb-0-3-25.show {
    visibility: visible;
}

.MobileMenu-title-0-3-26 {
    font-size: 20px;
    line-height: 1.4;
}

.MobileMenu-menuItems-0-3-27 {
    margin: 0;
    display: block;
    padding: 0;
    overflow: hidden;
    list-style: none;
    padding-bottom: 1px;
}

.MobileMenu-menuItem-0-3-28 {
    display: block;
    border-top: 1px solid var(--border);
    border-bottom: 1px solid var(--border);
    margin-bottom: -1px;
}

.MobileMenu-link-0-3-29 {
    width: 100%;
    border: none;
    cursor: pointer;
    display: flex;
    padding: 15px;
    font-size: 15px;
    background: transparent;
    text-align: left;
    align-items: center;
    font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
    letter-spacing: 0.03em;
    text-transform: none;
    justify-content: space-between;
    text-decoration: none;
}

.MobileMenu-count-0-3-30 {
    font-size: 9px;
}

.panel-0-3-44 {
    height: 100vh;
    display: none;
    overflow: auto;
    position: relative;
    transform: translate(110%, 0);
    background-color: var(--background);
}

@media (min-width:1024px) {
    .panel-0-3-44 {
        transform: translate(-110%, 0);
    }
}

.panel-0-3-44.padding {
    padding: 20px 15px 15px;
}

.panel-0-3-44.flex {
    display: flex;
    flex-direction: column;
}

.panel-0-3-44::-webkit-scrollbar {
    width: 5px;
}

.panel-0-3-44::-webkit-scrollbar-track {
    background: transparent;
}

.panel-0-3-44::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: var(--border);
}

@media (min-width:1024px) {
    .panel-0-3-44.padding {
        padding: 30px 25px 25px;
    }
}

.breadcrumb-0-3-46 {
    cursor: pointer;
    display: flex;
    font-size: 12px;
    min-height: 42px;
    visibility: hidden;
    align-items: center;
    line-height: 1.4;
    margin-bottom: 30px;
    text-transform: uppercase;
}

@media (max-width:1023.98px) {
    .breadcrumb-0-3-46.show {
        visibility: visible;
    }
}

.title-0-3-47 {
    display: flex;
    font-size: 20px;
    align-items: center;
    line-height: 1.4;
    margin-bottom: 30px;
}

@media (min-width:1024px) {
    .title-0-3-47 {
        margin-bottom: 50px;
    }
}

.titleLink-0-3-48 {
    transition: opacity 0.15s ease-in-out;
    text-decoration: none;
}

.titleLink-0-3-48:hover {
    opacity: 0.5;
}

.count-0-3-49 {
    margin-left: 5px;
}

.closeButton-0-3-50 {
    top: 24px;
    right: 15px;
    width: 26px;
    border: none;
    cursor: pointer;
    height: 26px;
    margin: 0;
    display: flex;
    opacity: 1;
    padding: 0;
    z-index: 1;
    position: absolute;
    transition: opacity 0.5s ease-in-out;
    align-items: center;
    border-radius: 50%;
    pointer-events: none;
    justify-content: center;
    background-color: transparent;
}

@media (min-width:1024px) {
    .closeButton-0-3-50 {
        top: 24px;
        right: 24px;
        display: flex;
    }
}

.closeButton-0-3-50:hover,
.closeButton-0-3-50.fill {
    background-color: #F2F2F2;
}

.closeButton-0-3-50.show {
    opacity: 1;
    pointer-events: all;
}

.backIcon-0-3-51 {
    width: 24px;
    height: 24px;
    margin-right: 5px;
}

.container-0-3-52 {
    height: 100%;
    display: flex;
    font-size: 12px;
    flex-direction: column;
}

.imageContainer-0-3-53 {
    width: 100%;
    position: relative;
    flex-grow: 1;
    flex-shrink: 1;
}

.image-0-3-54 {
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    bottom: 0;
    height: 100%;
    position: absolute;
    object-fit: cover;
}

.product-0-3-55 {
    display: flex;
    padding: 15px;
    flex-grow: 0;
    min-height: 280px;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: space-between;
}

@media (min-width:1024px) {
    .product-0-3-55 {
        padding: 25px;
    }
}

.title-0-3-56 {
    display: block;
    font-size: 15px;
    margin-bottom: 25px;
}

.productDetails-0-3-57 {
    display: flex;
    margin-bottom: 25px;
}

.productDetails-0-3-57>* {
    width: 50%;
}

.locationDetails-0-3-58 {
    margin-bottom: 25px;
}

.caption-0-3-59 {
    display: block;
    opacity: 0.5;
}

.detailsPanel-0-3-60 {
    overflow: hidden;
}

.container-0-3-215 {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1003;
    overflow: hidden;
    position: fixed;
    pointer-events: none;
}

.overlay-0-3-216 {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
    position: absolute;
    background-color: #000;
}

.drawerWrapper-0-3-217 {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    transform: translate(-100%, 0);
    background-color: var(--background);
}

@media (min-width:1024px) {
    .drawerWrapper-0-3-217 {
        width: 480px;
        bottom: 0;
        height: auto;
    }
}

.drawerContainer-0-3-218 {
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
}

.header-0-3-219 {
    margin: 25px 15px 0;
    display: flex;
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: space-between;
}

@media (min-width:1024px) {
    .header-0-3-219 {
        margin: 25px 25px 0;
    }
}

.header-0-3-219:before {
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    content: "";
    opacity: 0.3;
    position: absolute;
    background-color: var(--border);
}

.headerTop-0-3-220 {
    display: flex;
    align-items: start;
    margin-bottom: 25px;
    justify-content: space-between;
}

@media (min-width:1024px) {
    .headerTop-0-3-220 {
        margin-bottom: 25px;
    }
}

.drawerBody-0-3-221 {
    display: flex;
    padding: 15px;
    overflow: auto;
    position: relative;
    flex-grow: 1;
    margin-top: -8px;
    flex-shrink: 1;
    padding-top: 0;
    flex-direction: column;
}

.drawerBody-0-3-221.empty:after {
    display: none;
}

@media (min-width:1024px) {
    .drawerBody-0-3-221 {
        padding: 25px;
        padding-top: 0;
    }
}

.title-0-3-222 {
    display: flex;
    font-size: 20px;
    align-items: center;
    font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
    text-transform: none;
}

.closeButton-0-3-223 {
    border: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    margin-left: auto;
    border-radius: 0;
    background-color: transparent;
}

.container-0-3-34 {
    display: none;
    padding: 15px;
    position: relative;
    transform: translateX(-110%);
    grid-column: 1 / span 4;
    border-right: 1px solid var(--border);
    justify-content: space-between;
    background-color: var(--background);
}

@media (min-width:1024px) {
    .container-0-3-34 {
        display: flex;
        padding: 23px 25px 25px;
        z-index: 4;
        grid-column: 1;
        margin-left: -25px;
        flex-direction: column;
    }
}

.list-0-3-35 {
    margin: 40px 0 0;
    padding: 0;
    list-style: none;
}

.item-0-3-36 {
    opacity: 0.5;
    font-size: 15px;
    transition: opacity 0.25s ease-in-out;
    font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
    text-transform: none;
}

.item-0-3-36:after {
    top: 1.2em;
}

.item-0-3-36:hover,
.item-0-3-36.selected {
    opacity: 1;
}

.button-0-3-37 {
    align-self: start;
}

.buttonLabel-0-3-38 {
    opacity: 0.5;
    margin-left: 5px;
    white-space: nowrap;
}

.container-0-3-31 {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0;
    z-index: 1003;
    overflow: hidden;
    position: fixed;
    pointer-events: none;
}

@media (min-width:1024px) {
    .container-0-3-31 {
        padding: 0 25px;
    }
}

.container-0-3-31>.panel1 {
    grid-row: 1;
    grid-column: 1 / span 4;
}

.container-0-3-31>.panel2 {
    grid-row: 1;
    grid-column: 1 / span 4;
}

.container-0-3-31>.panel3 {
    grid-row: 1;
    grid-column: 1 / span 4;
    margin-right: 15px;
}

@media (min-width:1024px) {
    .container-0-3-31>.panel3 {
        z-index: 1;
        grid-column: 6 / span 2;
        margin-left: -25px;
        border-right: 1px solid var(--border);
        margin-right: -26px;
    }
}

@media (min-width:1024px) {
    .container-0-3-31>.panel2 {
        z-index: 2;
        grid-column: 4 / span 2;
        margin-left: -25px;
        border-right: 1px solid var(--border);
    }
}

@media (min-width:1024px) {
    .container-0-3-31>.panel1 {
        z-index: 3;
        grid-column: 2 / span 2;
        margin-left: -25px;
        border-right: 1px solid var(--border);
    }
}

.overlay-0-3-32 {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
    position: absolute;
    background-color: #000;
}

.formContainer-0-3-98 {
    padding-bottom: 50px;
}

@media (min-width:1024px) {
    .formContainer-0-3-98 {
        padding-bottom: 50px;
    }
}

.form-0-3-99 {
    margin-bottom: 25px;
}

@media (min-width:1024px) {
    .form-0-3-99 {
        margin-bottom: 25px;
    }
}

.errors-0-3-100 {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.error-0-3-101 {
    color: #CF6C4F;
    font-size: 10px;
    text-align: center;
    font-family: "ABCMonumentGroteskMono", "Lucida Sans", Tahoma, sans-serif;
    line-height: 1;
    margin-bottom: 25px;
    text-transform: uppercase;
}

@media (min-width:1024px) {
    .error-0-3-101 {
        margin-bottom: 25px;
    }
}

.error-0-3-101:last-child {
    margin-bottom: 0;
}

.inputContainer-0-3-102 {
    height: 50px;
    display: flex;
    position: relative;
    align-items: center;
}

.inputContainer-0-3-102.hasError {
    color: #CF6C4F;
}

.inputContainer-0-3-102:focus-within .bottomBorder {
    opacity: 1;
}

.inputContainer-0-3-102.hasError>label {
    opacity: 1;
}

.inputLabel-0-3-103 {
    flex: 1;
    opacity: 0.5;
    font-size: 10px;
    font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
    line-height: 1;
    text-transform: uppercase;
}

.input-0-3-104 {
    flex: 2;
    color: currentColor;
    border: none;
    outline: none;
    padding: 16px 0;
    font-size: 12px;
    font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
    line-height: 1;
    border-radius: 0;
    background-color: transparent;
}

.input-0-3-104:-webkit-autofill,
.input-0-3-104:-webkit-autofill:hover,
.input-0-3-104:-webkit-autofill:focus .input-0-3-104:-webkit-autofill,
.input-0-3-104:-webkit-autofill:hover .input-0-3-104:-webkit-autofill:focus {
    box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
    caret-color: currentColor;
}

.bottomBorder-0-3-105 {
    opacity: 0.2;
    transition: opacity 0.25s ease-in-out;
}

.hiddenButton-0-3-106 {
    display: none;
}

.container-0-3-86 {
    margin-top: 0;
}

@media (min-width:1024px) {
    .container-0-3-86 {
        margin-top: 75px;
    }
}

.image-0-3-87 {
    margin-bottom: 20px;
}

@media (min-width:1024px) {
    .image-0-3-87 {
        margin-bottom: 35px;
    }
}

.content-0-3-88 {
    width: 250px;
    margin: auto;
    text-align: center;
}

.title-0-3-89 {
    font-size: 15px;
    margin-bottom: 10px;
}

@media (min-width:1024px) {
    .title-0-3-89 {
        margin-bottom: 20px;
    }
}

.copy-0-3-90 {
    opacity: 0.5;
    font-size: 12px;
    white-space: pre-wrap;
    margin-bottom: 10px;
}

@media (min-width:1024px) {
    .copy-0-3-90 {
        margin-bottom: 20px;
    }
}

.linkLabel-0-3-91 {
    opacity: 0.5;
}

.container-0-3-61 {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1003;
    overflow: hidden;
    position: fixed;
    pointer-events: none;
}

.overlay-0-3-62 {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
    position: absolute;
    background-color: #000;
}

.cart-0-3-63 {
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    padding: 25px 15px 15px;
    position: absolute;
    transform: translate(100%, 0);
    background-color: var(--background);
}

@media (min-width:1024px) {
    .cart-0-3-63 {
        width: 480px;
        bottom: 0;
        height: auto;
        padding: 25px;
    }
}

.cartContainer-0-3-64 {
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
}

.header-0-3-65 {
    display: flex;
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: space-between;
}

.headerTop-0-3-66 {
    display: flex;
    align-items: start;
    margin-bottom: 25px;
    justify-content: space-between;
}

@media (min-width:1024px) {
    .headerTop-0-3-66 {
        margin-bottom: 25px;
    }
}

.headerBottom-0-3-67 {
    margin-bottom: 45px;
}

@media (min-width:1024px) {
    .headerBottom-0-3-67 {
        margin-bottom: 45px;
    }
}

.backToCartButton-0-3-68 {
    visibility: hidden;
}

.backToCartButton-0-3-68.show {
    visibility: visible;
}

.content-0-3-69 {
    overflow: auto;
    position: relative;
    flex-grow: 1;
    flex-shrink: 1;
}

.content-0-3-69:before {
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    content: "";
    opacity: 0.3;
    position: absolute;
    background-color: var(--border);
}

.content-0-3-69.empty:after {
    display: none;
}

.innerContent-0-3-70 {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    overflow-y: auto;
}

.formContent-0-3-71 {
    opacity: 1;
}

.footer-0-3-72 {
    flex-grow: 0;
    flex-shrink: 0;
}

.title-0-3-73 {
    display: flex;
    font-size: 20px;
    align-items: center;
    font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
    text-transform: none;
}

.count-0-3-74 {
    margin-left: 5px;
}

.copy-0-3-75 {
    font-size: 10px;
    text-align: center;
    white-space: pre-wrap;
    margin-bottom: 15px;
}

.emptyCopy-0-3-76 {
    font-size: 15px;
    margin-top: 15px;
}

.deliveryCopy-0-3-77 {
    opacity: 0.5;
}

.successCopy-0-3-78 {
    color: #219653;
}

.errorCopy-0-3-79 {
    color: #EB5757;
}

.submitButton-0-3-80 {
    width: 100%;
    padding: 16px 32px;
}

.closeButton-0-3-81 {
    border: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    border-radius: 0;
    background-color: transparent;
}

.successMessage-0-3-82 {
    top: 50%;
    left: 50%;
    width: 100%;
    display: flex;
    position: absolute;
    max-width: 340px;
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.successTitle-0-3-83 {
    font-size: 15px;
}

.successSubtitle-0-3-84 {
    opacity: 0.5;
    font-size: 12px;
}

.form-0-3-169 {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.errors-0-3-170 {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.error-0-3-171 {
    color: #CF6C4F;
    font-size: 10px;
    margin-top: 10px;
    text-align: center;
    font-family: "ABCMonumentGroteskMono", "Lucida Sans", Tahoma, sans-serif;
    line-height: 1;
    margin-bottom: 25px;
    text-transform: uppercase;
}

@media (min-width:1024px) {
    .error-0-3-171 {
        margin-bottom: 25px;
    }
}

.error-0-3-171:last-child {
    margin-bottom: 0;
}

.formFieldContainer-0-3-172 {
    display: flex;
    position: relative;
}

.formFieldContainer-0-3-172.hasError {
    color: #CF6C4F;
}

.formFieldContainer-0-3-172:focus-within .bottomBorder {
    opacity: 1;
}

.formFieldContainer-0-3-172.hasError>label {
    opacity: 1;
}

.inputContainer-0-3-173 {
    height: 50px;
    align-items: center;
}

.textareaContainer-0-3-174 {
    padding: 20px 0 0;
    flex-grow: 2;
    flex-direction: column;
}

.textareaContainer-0-3-174 label {
    flex: 0;
}

.textareaContainer-0-3-174 textarea {
    flex: auto;
    resize: none;
    font-size: 12px;
    max-width: 100%;
}

.textareaContainer-0-3-174 textarea::placeholder {
    opacity: 0.3;
}

.inputLabel-0-3-175 {
    flex: 1;
    opacity: 0.5;
    font-size: 10px;
    font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
    line-height: 1;
    text-transform: uppercase;
}

.input-0-3-176 {
    flex: 2;
    color: currentColor;
    border: none;
    outline: none;
    padding: 16px 0;
    font-size: 12px;
    font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
    line-height: 1;
    border-radius: 0;
    background-color: transparent;
}

.input-0-3-176:-webkit-autofill,
.input-0-3-176:-webkit-autofill:hover,
.input-0-3-176:-webkit-autofill:focus .input-0-3-176:-webkit-autofill,
.input-0-3-176:-webkit-autofill:hover .input-0-3-176:-webkit-autofill:focus {
    box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
    caret-color: currentColor;
}

.bottomBorder-0-3-177 {
    opacity: 0.2;
    transition: opacity 0.25s ease-in-out;
}

.hiddenButton-0-3-178 {
    display: none;
}

.container-0-3-150 {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1003;
    overflow: hidden;
    position: fixed;
    pointer-events: none;
}

.overlay-0-3-151 {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
    position: absolute;
    background-color: #000;
}

.quoteForm-0-3-152 {
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    transform: translate(100%, 0);
    background-color: var(--background);
}

@media (min-width:1024px) {
    .quoteForm-0-3-152 {
        width: 480px;
        bottom: 0;
        height: auto;
    }
}

.quoteFormContainer-0-3-153 {
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
}

.header-0-3-154 {
    margin: 25px 15px 0;
    display: flex;
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: space-between;
}

@media (min-width:1024px) {
    .header-0-3-154 {
        margin: 25px 25px 0;
    }
}

.header-0-3-154:before {
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    content: "";
    opacity: 0.3;
    position: absolute;
    background-color: var(--border);
}

.headerTop-0-3-155 {
    display: flex;
    align-items: start;
    margin-bottom: 25px;
    justify-content: space-between;
}

@media (min-width:1024px) {
    .headerTop-0-3-155 {
        margin-bottom: 25px;
    }
}

.content-0-3-156 {
    display: flex;
    padding: 0 15px;
    overflow: auto;
    position: relative;
    flex-grow: 1;
    flex-shrink: 1;
    flex-direction: column;
}

.content-0-3-156.empty:after {
    display: none;
}

@media (min-width:1024px) {
    .content-0-3-156 {
        padding: 0 25px;
    }
}

.quoteProcessCopy-0-3-157 {
    margin: 0;
    padding: 40px 0;
    position: relative;
    font-size: 15px;
}

.quoteProcessCopy-0-3-157:before {
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    content: "";
    opacity: 0.3;
    position: absolute;
    background-color: var(--border);
}

.quoteSubmissionCopy-0-3-158 {
    margin: 15px auto 0;
    font-size: 10px;
    max-width: 260px;
}

.formContent-0-3-159 {
    opacity: 1;
    flex-grow: 2;
}

.footer-0-3-160 {
    padding: 0 15px 15px;
    flex-grow: 0;
    flex-shrink: 0;
}

@media (min-width:1024px) {
    .footer-0-3-160 {
        padding: 0 25px 25px;
    }
}

.title-0-3-161 {
    display: flex;
    font-size: 20px;
    align-items: center;
    font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
    text-transform: none;
}

.count-0-3-162 {
    margin-left: 5px;
}

.copy-0-3-163 {
    font-size: 10px;
    text-align: center;
    white-space: pre-wrap;
    margin-bottom: 15px;
}

.submitButton-0-3-164 {
    width: 100%;
    padding: 16px 32px;
}

.closeButton-0-3-165 {
    border: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    border-radius: 0;
    background-color: transparent;
}

.successMessage-0-3-166 {
    top: 50%;
    left: 50%;
    width: 100%;
    display: flex;
    position: absolute;
    max-width: 340px;
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.successTitle-0-3-167 {
    margin: 0 0 20px;
    font-size: 15px;
}

.successSubtitle-0-3-168 {
    margin: 0 0 20px;
    opacity: 0.5;
    font-size: 12px;
}

*,
*::before,
*::after {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}

:root {
    --border: #808080;
    --background: #FFFFFF;
    --foreground: #3A3A1F;
    --scrollbar-width: 0px;
}

html {
    line-height: 1.5;
}

body {
    color: var(--foreground);
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
    font-weight: 400;
    line-height: 1.5;
    background-color: var(--background);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    font-family: "NHaasGroteskDSPro", "Lucida Sans", Tahoma, sans-serif;
    font-weight: 400;
    margin-bottom: 0.4em;
    text-transform: uppercase;
}

h1 {
    font-size: 45px;
    line-height: 0.95;
}

@media (min-width:1024px) {
    h1 {
        font-size: 65px;
    }
}

h2 {
    font-size: 35px;
    line-height: 0.9;
    letter-spacing: 0.65px;
}

@media (min-width:1024px) {
    h2 {
        font-size: 65px;
    }
}

h3 {
    font-size: 48px;
}

h4 {
    font-size: 32px;
}

h5 {
    font-size: 20px;
    margin-bottom: 25px;
    text-transform: none;
}

h6 {
    font-size: 15px;
    text-transform: none;
}

p {
    margin: 0 0 15px;
    font-size: 15px;
}

.srOnly {
    clip: rect(1px, 1px, 1px, 1px) !important;
    width: 1px !important;
    border: 0 !important;
    height: 1px !important;
    margin: -1px !important;
    padding: 0 !important;
    overflow: hidden !important;
    position: absolute !important;
    clip-path: inset(50%) !important;
    white-space: nowrap !important;
}

a {
    color: currentColor;
    text-decoration: underline;
}

a:hover {
    text-decoration: none;
}

button {
    color: currentColor;
    font-size: 10px;
    font-family: "ABCMonumentGroteskMono", "Lucida Sans", Tahoma, sans-serif;
    font-weight: 400;
    text-transform: uppercase;
}

.scrollbar-track.scrollbar-track-y {
    z-index: 2000;
}

main .scrollbar-track {
    width: 5px;
    border-radius: 2px;
    background-color: transparent;
}

main .scrollbar-thumb.scrollbar-thumb-y {
    width: 3px;
    border-radius: 2px;
}

.chatlio-title-bar {
    display: none;
}

.chatlio-widget-body {
    bottom: 10px !important;
}

@media (min-width:1024px) {
    .chatlio-widget-body {
        right: 10px !important;
        bottom: 60px !important;
    }
}

.Layout-main-0-3-1 {
    width: 100%;
    /* height: 100vh; */
    overflow-x: hidden;
    overflow-y: auto;
}

.Layout-inner-0-3-2 {
    padding-top: 72px;
}
/* </style> */


.img-slider {
    position: relative;
    width: 100vw;
    height: 51.3vw;
    /* background-color: red; */
    /* margin-top: 9rem; */
  }
  .img-slider .slider-container {
    position: absolute;
    width: 300%;
    top: 0;
    left: 0;
    overflow: hidden;
    animation: slider 12s infinite;
  }
  .img-slider .slider-container .slide {
    position: relative;
    width: 33.33333333%;
    height: 100%;
    float: left;
    overflow: hidden;
  }
  .img-slider .slider-container .slide img {
    display: block;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
  }
  @keyframes slider {
    0%,
    25%,
    100% {
      left: 0;
    }
  
    30%,
    55% {
      left: -100%;
    }
  
    60%,
    85% {
      left: -200%;
    }
  }
  
  .verticalLine-0-3-238{
    animation-name: fadeInOut;

  animation-duration: 3s;
  opacity: 0;

  }


  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }
  
    45% {
      opacity: 1;
    }
  
    100% {
      opacity: 0;
    }
  }
  

  .mySwiper{
    /* width: 456px; */
  }
  .projects-0-3-250 img{
    width: 10rem;
    height: 15rem;
  }
  @media (min-width:1024px) {
 
    .projects-0-3-250 img{
        width: 100%;
        /* height: 100%; */
        height: 799px;
      }
  }

  @media (max-width:1324px) {
    .projects-0-3-250 img{
        width: 27rem;
        height: 32rem;
      }
  }
  .splitWords-0-3-234{
    /* font-size: 78px; */
  position: relative;
  animation-name: text-fade-in;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  }
  @keyframes text-fade-in {
    from {
      opacity: 0;
      transform: translateY(50%);
    }
    to {
      opacity: 1;
      transform: translateY(0%);
    }
  }
  
  
  
  