* {
  box-sizing: border-box;
}
html {
  height: 100%;
}
body {
  /* background-color: #fff; */
  /* color: #222; */
  padding: 0;
  /* height: 100vh; */
}
.theme-options {
  width: 100%;
  margin: 0 auto;
  text-align: right;
  padding: 0px 0;
  /* margin-left: 25px; */
  position: sticky;
  top: 0;
  position: absolute;
  /* background-color: red; */
}
.theme-options div {
  cursor: pointer;
  width: 20px;
  height: 20px;

  margin: 2px 10px;

  display: inline-block;
  opacity: 0.5;
  padding: 45px 155px 0px 45px;
  /* background-color: red; */
}
.header-0-3-3 LI A{
  font-size: 15.5px;
  text-decoration: none;
  color: white;
  font-weight: 500;
}

@media (max-width: 950px) {
  .header-0-3-3 LI A{
    font-size: 15.5px;
    text-decoration: none;
    color: black;
    font-weight: 500;
  }
  .header-0-3-3{
    background: red;
  }
}
.theme-options div a {
  text-decoration: none;
  font-size: 0.8rem;
  /* margin-left: -889px; */
  /* padding: 45px; */
}
.theme-options div.active {
  opacity: 1;
}
#theme-white {
  /* color: red; */
  /* color: rgba(0, 0, 0, 0.5); */
}
a:hover{
  /* color: inherit; */
  color: rgba(92, 92, 92, 0.60);
}
.content-box {
  /* margin: 0 auto;
  background-color: #eee;
  color: #222;
  border-radius: 8px;
  padding: 30px 30px 40px 30px;
  max-width: 800px; */
}
.content-box h3 {
  line-height: 1.2;
  margin: 0;
  /* font-size: 26px; */
}
.content-box h5 {
  line-height: 1;
  margin: 0 0 22px 0;
  /* font-size: 20px; */
}
.App.theme-blue {
  /* background-color: white; */
}

.App.theme-blue .theme-options {
  /* background-color: white; */
}

.App.theme-blue .theme-options a {
  color: rgba(58, 58, 31, 0.8);
  font-size: 0.8rem;
}
.App.theme-blue .content-box {
  background-color: white;
}
.App.theme-blue .content-box h3,
.App.theme-blue .content-box h5,h1,h2 {
  color: #3a3a1f;
  color: #005E8C;
}
.App.theme-blue .content-box p {

  color: rgba(92, 92, 92, 0.70);

}
.App.theme-blue  a:hover {
  color: #3a3a1f;
  color: #005E8C;
  /* border-bottom: 3px solid #3a3a1f; */
}
.App.theme-blue .nav-menu.active{
  background-color: white;
  color: #3a3a1f;
  /* z-index: 19; */
}
.App.theme-blue nav{
  /* color: red; */
  /* background-color: white; */
}
.App.theme-blue ::placeholder{
  /* background-color: yellow; */
  /* border-bottom: 1px solid red; */
  color: #3a3a1f;
}
.App.theme-blue #contact input[type="text"], #contact input[type="email"], #contact input[type="tel"], #contact input[type="url"], #contact textarea{
  border-bottom: 1px solid #3a3a1f;
}
.App.theme-blue #contact button[type="submit"]{
  border-bottom: 1px solid #3a3a1f;
}
.App.theme-blue #contact textarea{
  background-color: white;;
}
.App.theme-blue .line-0-3-107 {
  background-color: #3a3a1f;
}

/*  */
.App.theme-orange {
  /* background-color: #291a16; */
  background-color: white;
}

.App.theme-orange .theme-options {
  /* background-color: #291a16; */
  /* height: 10px; */
}
.App.theme-orange .theme-options a {
  color: #cca99f;
  font-size: 50.8rem;
  /* background-color: yellow; */
}
.App.theme-orange .content-box {
  background-color: #fff;
}
.App.theme-orange .line-0-3-107 {
  background-color: #cca99f;
}
.App.theme-orange .nav-menu.active{
  background-color: #fff;
  color: #cca99f;
  /* z-index: 19; */
}
.App.theme-orange .content-box h3,
.App.theme-orange .content-box h5 {
  /* color: rgba(204, 169, 159, 0.7); */
}
.App.theme-orange .content-box p {
  /* color: #cca99f; */
}
.App.theme-orange  p {
  /* color: #cca99f; */
}
.App.theme-orange  a {
  /* color: #cca99f; */
}
.App.theme-orange  a:hover {
  /* color: #cca99f; */
  color: #005E8C;
  border-bottom: 3px solid #cca99f;
}
.App.theme-orange  nav{
  /* color: red; */
  background-color: #fff;
}
.App.theme-orange ::placeholder{
  
}
.App.theme-orange #contact input[type="text"], #contact input[type="email"], #contact input[type="tel"], #contact input[type="url"], #contact textarea{
  border-bottom: 1px solid #cca99f;
}
.App.theme-orange #contact button[type="submit"]{
  border: 1px solid #cca99f;
}
.App.theme-orange #contact textarea{
  /* background-color: #291a16; */
}
.App.theme-green .respnavbarm{
  color: #cca99f;
  font-size: 12px;
}
/*  */
.App.theme-green {
  background-color: #3a3a1f;
}

.App.theme-green .theme-options {
  /* background-color:  #3A3A1F;; */
}
.App.theme-green .respnavbarm{
  color: #3a3a1f;
  font-size: 12px;
}

.App.theme-green input {
  color: #cfcfb0;
  /* font-size: 0.8rem; */
  border-bottom: 1px solid #cfcfb0;
  background-color: red;
}
.App.theme-green ::placeholder{
  /* background-color: yellow; */
  /* border-bottom: 1px solid red; */
  color: #cfcfb0;
}
.App.theme-green #contact input[type="text"], #contact input[type="email"], #contact input[type="tel"], #contact input[type="url"], #contact textarea{
  border-bottom: 1px solid #cfcfb0;
}
.App.theme-green #contact button[type="submit"]{
  border: 1px solid #cfcfb0;
}
.App.theme-green .theme-options a {
  color: #cfcfb0;
  font-size: 0.8rem;
}
.App.theme-green .content-box {
  background-color: hsl(60, 30%, 17%);
}
.App.theme-green nav {
  background-color: hsl(60, 30%, 17%);
}
.App.theme-green  h4,h3,h1,h2{
  /* color: #cfcfb0; */
}
.App.theme-green .content-box h3,
.App.theme-green .content-box h4,
.App.theme-green .content-box h5 {
  color: #cfcfb0;
}
.App.theme-green .content-box p {
  color: #cfcfb0;
}
.App.theme-green  p {
  color: #cfcfb0;
}
.App.theme-green .line-0-3-107 {
  background-color: #cfcfb0;
}
.App.theme-green  a {
  color: #cfcfb0;
}
.App.theme-green  a:hover {
  color: #cfcfb0;
  border-bottom: 3px solid #cfcfb0;
}
.App.theme-orange .content-box p {
  color: #cca99f;
}
.App.theme-green .content-box a {
  color: #cfcfb0;
}
/*  */
.App.theme-black {
  background-color: #fff;
}
.App.theme-black .content-box {
  background-color: #fff;
}
.App.theme-black .line-0-3-107 {
  background-color:rgba(167, 182, 196, 0.8) ;
}
.App.theme-black ::placeholder{
  /* background-color: yellow; */
  /* border-bottom: 1px solid red; */
  color: rgba(167, 182, 196, 0.8);
}
.App.theme-black .respnavbarm{
  color: rgba(167, 182, 196, 0.8);
  font-size: 12px;
}
.App.theme-black #contact input[type="text"], #contact input[type="email"], #contact input[type="tel"], #contact input[type="url"], #contact textarea{
  border-bottom: 1px solid rgba(167, 182, 196, 0.8);
}
.App.theme-black #contact button[type="submit"]{
  border: 1px solid rgba(167, 182, 196, 0.8);
}
.App.theme-black #contact textarea{
  background-color: #fff;
  color: white;
}
.App.App.theme-black .nav-menu.active{
  background-color: #fff;
  color: #005E8C;

  /* z-index: 19; */
}
.App.App.theme-black .nav-menu{
  font-size: 3rem;
}
.App.theme-black .theme-options {
  /* background-color:#042442; ; */
}
.App.theme-black .theme-options a {
  /* color: rgba(167, 182, 196, 0.8); */
  font-size: 0.8rem;
}
.App.theme-black .content-box h3,
.App.theme-black .content-box h5 {
  /* color: #fff; */
}
.App.theme-black .content-box a {
  /* color: #a7b6c4; */
}
.App.theme-black p {
  /* color: #a7b6c4; */
  color: rgba(92, 92, 92, 0.70);
}
.App.theme-black h1 {
  /* color: #a7b6c4; */
}
.App.theme-black span {
  /* color: #a7b6c4; */
}
.App.theme-black h2{
  /* color: #a7b6c4; */
}
.App.theme-black nav{
  background-color: #fff;
}
.App.theme-black p:hover {
  /* color: #a7b6c4; */
  /* width: 100%; */
  /* background: #a7b6c4; */
}
.App.theme-black  a {
  /* color: #a7b6c4; */
}
.App.theme-black a:hover {
  /* color: #a7b6c4; */
  width: 100%;
  /* background: #a7b6c4; */
  border-bottom: 3px solid #a7b6c4;
  transition: width 0.7s ease, background-color 0.5s ease;
}
/*  */

.App.theme-white {
  background-color: white;
}


.App.theme-white .theme-options {
  /* background-color: white; */
  /* position: sticky; */
}

.App.theme-white .theme-options a {
  color: rgba(58, 58, 31, 0.8);
  /* padding-left: 4rem; */
}
.App.theme-white .content-box {
  background-color: white;
}
.App.theme-white .line-0-3-107{
  background-color: #3a3a1f;
}
.App.theme-white .content-box h3,
.App.theme-white .content-box h5 {
  color: #3a3a1f;
}
.App.theme-white .content-box p {
  /* color: #3a3a1f; */
}
.App.theme-white nav {
  /* color: #3a3a1f; */
  /* background-color: white; */
}
.App.theme-white ::placeholder{
  /* background-color: yellow; */
  /* border-bottom: 1px solid red; */
  /* color: #3a3a1f; */
}
.App.theme-white #contact input[type="text"], #contact input[type="email"], #contact input[type="tel"], #contact input[type="url"], #contact textarea{
  /* border-bottom: 1px solid #3a3a1f; */
  border-bottom: 1px solid #5c5c5c17;
}
.App.theme-white #contact button[type="submit"]{
  border-bottom: 1px solid #3a3a1f;
}
.App.theme-white #contact textarea{
  background-color: white;;
}
.App.theme-white .respnavbarm{
  color: #3a3a1f;
  font-size: 12px;
}
