.App {
    height: 100vh;
    padding-top: 10px;
  }
  
  body {
    margin: 0;
    padding: 0;
    font-family: "Courier New", Courier, monospace;
    font-size: 16px;
    line-height: 1.6;
  }
  
  .text-center {
    text-align: center;
  }
  
  .title {
    font-size: 20px;
  }
  