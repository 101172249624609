.elmex-body {
  padding-left: 4rem;
  padding-right: 4rem;
  padding-top: 6rem;
  color: rgba(58, 58, 31, 0.8);
}
.elmex-01 {
  padding-left: 4rem;
  font-size: 3vw;
}
.flex-container {
  display: flex;
  flex-direction: row;
  font-size: 30px;
  text-align: center;
}
.elmex-body .elmex-head {
  font-size: 1.5rem;
  text-decoration: underline;
  color: rgba(58, 58, 31, 0.8);
}
.elmexlogo {
  position: absolute;
  width: 4rem;
  padding-top: 1.3rem;
}
.elmex01 {
  position: absolute;
  width: 100%;
  height: 0px;

  top: 11%;

  opacity: 0.8;
  border-bottom: 1px solid rgba(58, 58, 31, 0.8);
  transform: rotate(-180deg);
  animation: 2.5s cubic-bezier(.25, 1, .30, 1) wipe-in-left both;
}
@keyframes wipe-in-left {
  from {
    clip-path: inset(0 0 0 100%);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

.elmex-desc {
  font-size: 15px;
  width: 32%;
  padding-top: 2rem;
  text-align: justify;
}
.flex-item-left {
  padding: 10px;
  flex: 50%;
}

.flex-item-right {
  padding: 10px;
  flex: 50%;
}
.flex-item-right img {
  width: 38rem;
  border-radius: 10px;
  position: absolute;
  right: 4%;
  top: 8%;

}
.elmexaccordian {
  width: 35%;
  left: 1.9%;
  position: absolute;
  padding-top: 10rem;
}
.elmexaccordian .tab-label {

  font-size: 0.6vw;
  font-weight: 100;
}
.elemexpr {
  position: absolute;
  padding-top: 3rem;
  text-decoration: underline;

  text-align: left;
  /* left:3.5%; */

 
}
@media (max-width: 800px) {
  .flex-container {
    flex-direction: column;
  }
}
@media (max-width: 960px) {
  .elmex-body {
    padding-left: 4rem;
    padding-right: 4rem;
    padding-top: 15rem;
  }
  .elemexpr {
    font-size: 3vw;
  }
  .elmexaccordian {
    width: 80%;
    
  }
  
}
@media (max-width: 990px) {
  .elmex-body {
    padding-left: 4rem;
    padding-right: 4rem;
    padding-top: 15rem;
  }
  .elemexpr {
    font-size: 3vw;
  }
  .elmexaccordian {
    width: 80%;
  }
  .elmex-desc {
    /* background-color: red; */
    width: 100%;
    font-size: 2vw;
  }
  .flex-item-right img {
    left: 15%;
    padding-top: 49rem;
    /* width: 50%; */
  }
  .elmexaccordian .tab-label {
    /* color: red; */
    font-size: 1.1vw;
    font-weight: 100;
  }
  .elmexlogo{
    /* padding-top: -8rem; */
    margin-top: -1rem;
  }
}

@media (max-width: 355px) {
  .elmex-body {
    padding-left: 4rem;
    padding-right: 4rem;
    padding-top: 15rem;
  }
  .elemexpr {
    font-size: 3vw;
  }
  .elmexaccordian {
    width: 80%;
  }
  .elmex-desc {
    /* background-color: red; */
    width: 100%;
    font-size: 2vw;
  }
  .flex-item-right img {
    left: 11%;
    padding-top: 40rem;
    width: 100%;
  }
  .elmexlogo{
    /* padding-top: -8rem; */
    margin-top: -1.5rem;
  }
}

.elmexaccordian input {
  display: none;
}


.emlexfbody{
  /* margin-bottom: 525px; */
  margin-top: 3%;
}

@media (max-width: 1023px) {
  .emlexfbody{
    /* margin-bottom: 85%; */
  }
}
@media (max-width: 900px) {
  .emlexfbody{
    /* margin-bottom: 135%; */
  }
  .flex-item-right img{
    width: 60%;
    margin-top: -14%;
  }
}
@media (max-width: 645px) {
  .emlexfbody{
    /* margin-bottom: 165%; */
  }
}
@media (max-width: 450px) {
  .emlexfbody{
    /* margin-bottom: 125%; */
  }
  .flex-item-right img{
    width: 60%;
    margin-top: -34%;
  }
}
@media (max-width: 319px) {
  .emlexfbody{
    /* margin-bottom: 135%; */
  }
}
@media (max-width: 250px) {
  .emlexfbody{
    /* margin-bottom: 395%; */
  }
}
.insideproductim{
 
}
@media (min-width: 1250px) {
.insideproductim{
  width: 35rem;
  height: 38rem;
  right: 0;
  position: absolute;
}
}
.elmexlomp{
  width: 8rem;
  /* margin-left: 3rem; */
}

@media (max-width: 550px) {
  .elmexlomp{
    width: 4rem;
    margin-left: 0rem;
  }
  .insideproductim{
    margin-top: -1rem;
  }
  .panelcardheonew{
    margin-top: -8rem;
  }
  .panelcards-01{
    gap: 0;
  }
  .panelcardheonew{
    margin-bottom: -5rem;
  }
}