
.solarpanelpage{
    color: #005E8C;
        margin: 0 50px; 
}
.center-text {
    text-align: center;
}
.solarpanelpage{
    margin-top: 6.5rem;
}
.spimage-container {
    margin: 0 50px; 
    margin-top: 2rem;
}
.spmain{
  margin: 0 50px; 
}
.spimage-container img {
    width: 100%;
    /* height: 550px; */
}
.spimg-sub{
    /* margin-top: 2rem; */
    padding-top: 1rem;
}
.spimage-container p{
    color: rgba(92, 92, 92, 0.70);

text-align: left;

font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 140.023%;
padding-top: 1rem;
}
@media (max-width: 900px) {
 
    .spimage-container {
        margin: 0 20px; 
        margin-top: 2rem;
    }
    .spimage-container img {
        width: 100%;
        /* height: 250px; */
    }
  }
  




.spmain{

  margin: 0 110px 0px 100px; 
  padding-top: 1rem;
}





.spcards {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0px 86px;
  padding: 0;
}

.spcards_item {
  display: flex;
  padding: 1rem;
}

@media (min-width: 40rem) {
  .spcards_item {
    width: 50%;
  }
}

@media (min-width: 56rem) {
  .spcards_item {
    width: 33.3333%;
  
  }
}

.spcard {
 
  display: flex;
  flex-direction: column;
  overflow: hidden;
}



.spcard_title {
  
  font-size: 1.1rem;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: capitalize;
  margin: 0px;
  padding-top: 1rem;
  text-align: center;


text-align: center;


font-style: normal;
font-weight: 400;


text-transform: capitalize;
}
.spdetails{
    margin: 0 50px; 
    margin-top: 2rem;
}
.spdetails p{
    margin-top: 2rem;
    color: rgba(217, 0, 0, 0.75);
    font-weight: 400;
    font-size: 20px;
    text-align: center;
}
.spdetailus {
    list-style-type: disc; /* You can use 'circle' for circles or 'square' for squares, for example */
    color: rgba(92, 92, 92, 0.70);
  }
  .spdetailus span{
    color: rgba(92, 92, 92, 0.90);
font-family: Amaranth;
font-size: 22px;
font-style: normal;
/* font-weight: 900; */
font-weight: bolder;
line-height: 140.023%;
  }
  
  .sptableddetails table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-top: 2rem;
  }
  
  .sptableddetails td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 2px;
  }
  
  .sptableddetails tr:nth-child(odd) {
    background: rgba(0, 94, 140, 0.60);
  }
  .spabove{
    border-bottom: 1px solid #5C5C5CCC;
    opacity: 0.8;
    padding-top: 2rem;
/* background: rgba(92, 92, 92, 0.80); */
    
  }
  .splastpoints ul {
    list-style-type: none;
    padding: 0;
    /* text-align: ; */
 
    
}

.splastpoints li {
    display: inline;
     /* Adjust the margin as needed to control spacing between items */
    /* margin: 0 150px; */
    /* width: 500px; */
    /* align-items: center; */
    text-align: left;
    /* margin: 0 50px; */
    color: rgba(92, 92, 92, 0.70);
}

.splastpoints li::before {
    content: "\2022"; /* Unicode bullet character */
    display: inline-block;
    width: 1em; /* Adjust the width as needed for spacing */
    margin-right: 5px; /* Adjust the margin as needed for spacing between bullet and text */
}
/*  */

.sspmain {
    display: flex;
    justify-content: center;
  }
  
  .sspcards {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    /* width: 100%; */
  }
  
  .sspcards_item {
    flex: 1;
    margin: 10px;
    text-align: center;
  }
  
  .centered {
    margin: 10px auto; /* Center the third item in the row */
  }
  
  /* Media query for screens with a maximum width of 900px */
  @media (max-width: 900px) {
    .sspcards_item {
      flex-basis: 100%; /* Display each item in a single column */
    }
    .sspcards {
      width: 100%;
    }
  }
  
  .sspcards_item{
    width: 500px ;
    /* background-color: red; */

  }
  .sspcards_item img{
    width: 100%;
    object-fit: contain;
  }
  .sspcardsl {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45vh; 
    /* width: 500px; */
  }
  .sspcardsl img{
width: 35%;
/* width: 100%; */
  }
  @media (max-width: 900px) {
    .sspcardsl {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 28vh; 
        /* width: 500px; */
        margin-top: -1.5rem;
      }
      .sspcardsll{
        padding-top: -2.1rem;
      }
    .sspcardsl img{
        width: 90%;
        /* width: 100%; */
          }
         
       
  }
  .sspcardsll{
    /* margin: 0 50px; */
    text-align: center;
    padding-top: 1rem;
  }
  .sspcard_title{
    /* margin-top: 1rem; */
    padding-top: 1rem;
  }
  .sptableddetails table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-top: 2rem;
    /* Add overflow-x to the table container */
    overflow-x: auto;
  }
  
  /* Media query for screens with a maximum width of 900px */
  @media (max-width: 900px) {
    .sptableddetails table {
      /* Remove overflow property to fit the complete table without scrolling */
      overflow-x: initial;
      
    }
    .sptableddetails td, th {
      border: 1px solid #dddddd;
      text-align: left;
      /* padding: 8px; */
      font-size: 10px;
    }
    .sspcardsll{
      /* padding-top: 0.2rem; */
    }
    
  }
  @media (min-width: 900px) {
    .sptableddetails td, th {
      border: 1px solid #dddddd;
      text-align: left;
      /* padding: 8px; */
      font-size: 16px;
 
    }
  }

  .gsolarp{
    /* width: 50%; */
    text-align: left;
    /* font-size: 10px; */
    width: 100%;
    color: rgba(92, 92, 92, 0.70);
  }
  .solartable{
    width: 100%;
    margin-top: 1rem;
    height: 400px;
  }
.splastpoints svg{
  width: 100%;
  /* align-items: center; */
}
.center-text h6{
  font-weight: 500;
  font-size: 30px;
}
.spimg-sub{
  font-weight: 500;
  font-size: 30px;
}
.spdetails h6{
  font-weight: 500;
  font-size: 30px;
}

@media (max-width: 900px) {
.solarpanelpage{
  color: #005E8C;
      margin: 0 0px; 
      margin-top: 6rem;
}
.spmain{
  margin: 0 20px 0px 20px; 
}
.sspmain{
  /* margin: 0 0px 0px 0px;  */
}

}

.gsolarp {
  font-size: 16px; /* Default font size */
  margin: 10px; /* Default margin */
  width: 100%;
}

.gsolarp strong {
  font-weight: bold;
}
.spdetails p{
  text-align: center;
  color: red;
}
@media (max-width: 768px) {
  /* Adjust font size and margin for smaller screens (e.g., mobile devices) */
  .gsolarp {
      font-size: 14px;
      margin: 5px;
  }
  .spdetails p{
    font-size: 16px;
    text-align: left;
    color: #5C5C5CE5;
  }
  .center-text h6{
    font-size: 20px;
  }
  .spimg-sub{
    font-size: 18px;
  }
  .sspcards_item{
    margin: -2px;
  }
  .spcards {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0px 8px;
    padding: 0;
  }
}

.downlaod-solapnel{
  height: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}
.downlaod-solapnel a{
  background-color: #005E8C;
  color: white;
  width: 15rem;
  text-align: center;
  height: 3rem;
  font-size: 1.2rem;
  padding-top: 0.5rem;
  /* margin-top: 2rem; */
}
/* .download-panel {
  text-align: center;
  background-color: #005E8C;
} */

.solarlip-container{
  /* position: fixed; */
  /* top: 0; */
  right: 2rem;
  max-width: 400px;
  position: absolute;
  align-items: flex-end;
  /* display: none; */
}
.solarlip-container img{
  width: 100%;
  height: auto;
  align-items: flex-end;
  right: 0;
  /* position: absolute; */
}
.productswesellsolar .image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(13vw, 1fr)); /* Responsive grid with a minimum column width of 300px */
  gap: 30px; /* Adjust the gap between images as needed */
  margin: 0 5px 0px 0px;
  align-items: center;
  /* width: 104%; */
}
@media (max-width: 1026px) {
.solarlip-container img{
display: none;
}
}
@media (max-width: 600px) {
.spdetails{
  margin: 0 25px
}
.productswesellsolar .image-grid{
  gap: 25px;
}
.solartable{
  height: 100%;
}
}
.splastpoints svg{
  width: 100%;
}

@media (max-width: 600px) {
  .splastpoints svg{
    width: 100%;
    margin:0px -20px;
  }
  .downlaod-solapnel a {
    background-color: #005E8C;
    color: white;
    width: 10rem;
    text-align: center;
    height: 3rem;
    font-size: 15px;
    padding-top: 0.7rem;
    /* left: 2.7rem; */
    /* margin-top: 2rem; */
}
.ltmianb .downlaod-solapnel a{
  /* margin-left: 12rem; */
  position: absolute;
  left: 4rem;
}
.kiewandcmp .downlaod-solapnel a{
  .downlaod-solapnel a {
    background-color: #005E8C;
    color: white;
    width: 10rem;
    text-align: center;
    height: 3rem;
    font-size: 15px;
    padding-top: 0.7rem;
    left: 3.4rem;
    /* margin-top: 2rem; */
}
}

.elmseauremainp .elmeasureproduct{
  /* margin-top: -2rem; */
}

.orbitlomp .downlaod-solapnel a{
  left: 1.8rem;
}
.havelslomp .downlaod-solapnel a{
  /* margin-top: -5rem; */
  left: 0rem;
}
}
/* .kiewandcmp .downlaod-solapnels{
  height: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}

.kiewandcmp.downlaod-solapnels a{
  background-color: #005E8C;
  color: white;
  width: 15rem;
  text-align: center;
  height: 3rem;
  font-size: 1.2rem;
  padding-top: 0.5rem;
  
} */