.ltmianb {
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 3%;
}
.ltflex-container {
  display: flex;
  flex-direction: row;
  font-size: 30px;
  text-align: center;
}
.ltflex-item-left {
  padding: 10px;
  flex: 50%;
  padding-top: 6rem;
}
.ltmianb {
  /* padding-top: 4rem; */
}
.ltflex-item-right {
  padding: 10px;
  flex: 50%;
  /* margin-top: -8rem; */
}
.ltlogo {
  width: 10%;

  margin-left: 10%;
}
.ltdesc {
  width: 45%;
  text-align: justify;
  font-size: 15px;
}
.tfitccontainer li {
  text-transform: capitalize;
}
@media (min-width: 1024px){

.ltbmimage {
  width: 80%;
  height: 100%;
  margin-top: -9rem;
}
}
.lttagline {
  margin-left: 6%;

  font-style: italic;
  font-weight: 400;
  font-size: 20px;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.09em;
  text-transform: uppercase;
  padding-top: 1rem;

  color: #004b88;
}
@media (max-width: 1400px) {
  .ltmianb  .tfitccontainer{
    /* margin-top: 9rem; */
      }
}
@media (max-width: 800px) {
  .ltflex-container {
    flex-direction: column;
  }
  .ltdesc{
    width: 100%;

  }
  .ltflex-item-left .terminalpr{
    margin-top: 30rem;
    /* text-align: center; */
    margin-left: 1%;
  }
  .ltflex-item-left .terminalaccordian{
    margin-top: 30rem;
  }
 .ltmianb  .tfitccontainer{
/* margin-top: 44rem; */
  }
  .lattext h3 {
    
    text-align: left;
    
}
.ltlogo{
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.lttagline{
  display: block;
  margin-left: auto;
  margin-right: auto;
}
}
@media (max-width: 505px) {
  .ltflex-container {
    flex-direction: column;
  }
  .ltdesc{
    width: 100%;

  }
  .ltflex-item-left .terminalpr{
    margin-top: 40rem;
    /* text-align: center; */
    margin-left: 1%;
  }
  .ltflex-item-left .terminalaccordian{
    margin-top: 40rem;
  }
 .ltmianb  .tfitccontainer{
/* margin-top: 44rem; */
  }
}
@media (max-width: 360px) {
  .ltflex-container {
    flex-direction: column;
  }
  .ltdesc{
    width: 100%;

  }
  .ltflex-item-left .terminalpr{
    margin-top: 22rem;
    /* text-align: center; */
    margin-left: 1%;
  }
  .ltflex-item-left .terminalaccordian{
    margin-top: 20rem;
  }
 .ltmianb  .tfitccontainer{
/* margin-top: 34rem; */
  }
}
.ltmianb .card-header img{
  height: auto;
}
.ltdheadline {
  background: #004b88;
  font-size: 45px;
  color: white;

  text-align: left;
  padding-left: 4rem;
}
.ltdimg{
  width: 100%;
  height: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
}
.ltmianb .card-body{
  display: block;
}
.ltdh1{
  margin-top: 3rem;
  font-size: 18px;

font-style: normal;
font-weight: 400;

/* text-align: justify; */
}
.ltdpra{
  margin-top: 3rem;
  font-size: 18px;

font-style: normal;
font-weight: 400;
/* width: 45%; */
}


.latgrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  grid-gap: 30px;
  margin-top: 2rem;

  /* align-items: stretch; */
}


.ltdetailedproducts{
  padding-top: 3rem;
}
.latgrid > article img {
  width: 100%;
  height: 200px;
}

.latgrid .text {
  padding: 20px;
  font-size: 1px;
}
.lattext h3{
  font-size: 20px;
  text-align: center;
  padding-top: 1rem;
  color: #005e8c;
  font-weight: 400;
}
.latcontainer{
  margin-top: 5rem;
}
.latcontainer.active{
display: none;
}
.latcontainer h1{
  font-size: 20px;
  font-weight: 400;
}
.ltmianb a{
  text-decoration: none;
}

.ltmianb{
  /* max-block-size: 145px; */
  margin-bottom: 85px;
}

@media (max-width: 930px) {
  .ltmianb{
    /* margin-bottom: 595px; */
  }
}

.moretransform{
  animation: 2.5s cubic-bezier(.25, 1, .30, 1) wipe-in-down both;
}

@keyframes wipe-in-down {
  from {
    clip-path: inset(0 0 100% 0);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}
.moreclass{
  /* color: red; */
  /* margin-top: 4rem; */
  /* position: absolute; */
  margin-top: -2rem;
  margin-left: 12rem;
  
  /* padding: auto; */

}

.ltbmimage {
  width: 80%;
  height: 100%;
  /* margin-top: -9rem; */
}
.ltmianb .card{
  height: auto;
  flex-direction: column;
}
@media (max-width: 800px) {
.latgrid > article img {
  /* width: 40rem; */
}
.lattext h3{
  text-align: left;
  font-size: 15px;
}
.ltdheadline{
  font-size: 30px;
}
}

.ltlogomp{
  width: 10rem;
  margin-left: 0rem;
  /* margin-top: -1rem; */
}
.ltpagedes{
  /* margin-left: 2rem; */
}
@media (max-width: 550px) {
  .ltlogomp{
    width: 10rem;
    margin-left: 0rem;
  }
  .ltpagedes{
    margin-left: 0rem;
  }
  .ltlogomp{
    margin-top: 0px;
  }
  .ltdetailedproducts{
    padding-top: 1rem;
  }
  .latcontainer {
    margin-top: 3rem;
}
}
@media (max-width: 800px){
.latgrid > article img {
    /* width: 40rem; */
    width:50%;
}
/* .havellogo img{
  width: 5rem;
} */
.ltmianb .downlaod-solapnel a{
  /* margin-left: 12rem; */
  position: absolute;
  left: 4rem;
}
}

.ltmianb .tfitccontainer .card-body{
     min-height: 0px; 
}
.ltmianb span{
  color: #004b88;
}
.ltmianb .accordionButton-0-3-240{
font-size: 20px;
}
.ltmianb{
  margin: 0px 30px;
}
.ltmianb .latgrid{
  margin-top: 2rem;
}

@media (max-width: 500px){
.latgrid > article img {
    /* width: 40rem; */
    width: 94%;
}
.ltmianb{
  padding-left: 0rem;
  padding-right: 0rem;
}
}