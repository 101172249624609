@import "https://unpkg.com/open-props";
*,
*::before,
*::after {
  box-sizing: border-box;
}

.partnerwithusp button {
  font: inherit;
}

.partnerwithusp .page {
  color: var(--gray-9);
  background-color: var(--gray-0);
  display: grid;
  grid-template-areas: "main";
  min-height: 100vh;
  font-family: var(--font-sans);
}
.partnerwithusp .page__mani {
  grid-area: main;
}

.partnerwithusp .main {
  display: grid;
  justify-items: center;
  align-items: center;
  padding: var(--size-3);
}
.partnerwithusp .main__form {
  max-width: 30em;
}

.partnerwithusp .form {
  color: var(--gray-9);
  background-color: var(--gray-3);
  display: grid;
  padding: var(--size-4);
  width: 100%;
  border: 1px solid var(--gray-4);
  border-radius: var(--radius-2);
}
.partnerwithusp .form__linput {
  display: grid;
  margin-bottom: var(--size-3);
}
.partnerwithusp .form__label {
  margin-bottom: var(--size-2);
}
.partnerwithusp .form__input,
.partnerwithusp .form__select {
  padding: 1em 0.7rem;
  border: 1px solid var(--gray-4);
  border-radius: var(--radius-2);
}
.partnerwithusp .form__select {
  background: inherit;
}
.partnerwithusp .form__textarea {
  padding: 1em 0.7rem;
  resize: vertical;
  border: 1px solid var(--gray-4);
  border-radius: var(--radius-2);
  font: inherit;
}
.partnerwithusp .form__btn {
  margin-right: auto;
}

.partnerwithusp .primary-btn {
  transition: 180ms ease-in;
  color: var(--gray-0);
  background-color: #005e8c;
  padding: 0.7em var(--size-3);
  border: 0;
  border-radius: var(--radius-2);
  cursor: pointer;
}

.partnerwithusp .sub_header_banner.partner-header.pr {
  background-image: url('https://images.unsplash.com/photo-1521790797524-b2497295b8a0?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
  background-size: cover; /* Adjust this property based on your image requirements */
  background-position: center center; /* Adjust this property based on your image requirements */
  background-repeat: no-repeat;
  color: #fff; /* Set text color to be visible against the background */
}
@media (max-width: 450px) {
.partnerwithusp .main{
  padding: var(--size-0);
}
.zcwf_row{
  width: 100%;
}
}

.fieldStyle {
  font-size: 16px;
  border: 1px solid #444;
  padding: 5px 10px;
  width: 100%;
  resize: none;
}

@media (max-width: 750px) {
 
  .zcwf_row{
    width: 100%;
  }
  }