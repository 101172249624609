/* <style> */
/* Get rid of top margin on first element in any rich text element */
.w-richtext > :not(div):first-child,
.w-richtext > div:first-child > :first-child {
  margin-top: 0 !important;
}

/* Get rid of bottom margin on last element in any rich text element */
.w-richtext > :last-child,
.w-richtext ol li:last-child,
.w-richtext ul li:last-child {
  margin-bottom: 0 !important;
}

/* 
Make the following elements inherit typography styles from the parent and not have hardcoded values. 
Important: You will not be able to style for example "All Links" in Designer with this CSS applied.
Uncomment this CSS to use it in the project. Leave this message for future hand-off.
*/
/*
a,
.w-input,
.w-select,
.w-tab-link,
.w-nav-link,
.w-dropdown-btn,
.w-dropdown-toggle,
.w-dropdown-link {
color: inherit;
text-decoration: inherit;
font-size: inherit;
}
*/

/* Prevent all click and hover interaction with an element */
.pointer-events-off {
  pointer-events: none;
}

/* Enables all click and hover interaction with an element */
.pointer-events-on {
  pointer-events: auto;
}

/* Snippet enables you to add class of div-square which creates and maintains a 1:1 dimension of a div.*/
.div-square::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

/*Hide focus outline for main content element*/
main:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}

/* Make sure containers never lose their center alignment*/
.container-medium,
.container-small,
.container-large {
  margin-right: auto !important;
  margin-left: auto !important;
}

/*Reset selects, buttons, and links styles*/
.w-input,
.w-select,
a {
  color: inherit;
  text-decoration: inherit;
  font-size: inherit;
}

/*Apply "..." after 3 lines of text */
.text-style-3lines {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

/* Apply "..." after 2 lines of text */
.text-style-2lines {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
/* Apply "..." at 100% width */
.truncate-width {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* Removes native scrollbar */
.no-scrollbar {
  -ms-overflow-style: none;
   /* // IE 10+ */
  /* overflow: -moz-scrollbars-none; // Firefox */
}

.no-scrollbar::-webkit-scrollbar {
  display: none; 
  /* // Safari and Chrome */
}

/* Adds inline flex display */
.display-inlineflex {
  display: inline-flex;
}

/* These classes are never overwritten */
.hide {
  display: none !important;
}

@media screen and (max-width: 991px) {
  .hide,
  .hide-tablet {
    display: none !important;
  }
}
@media screen and (max-width: 767px) {
  .hide-mobile-landscape {
    display: none !important;
  }
}
@media screen and (max-width: 479px) {
  .hide-mobile {
    display: none !important;
  }
}

.margin-0 {
  margin: 0rem !important;
}

.padding-0 {
  padding: 0rem !important;
}

.spacing-clean {
  padding: 0rem !important;
  margin: 0rem !important;
}

.margin-top {
  margin-right: 0rem !important;
  margin-bottom: 0rem !important;
  margin-left: 0rem !important;
}

.padding-top {
  padding-right: 0rem !important;
  padding-bottom: 0rem !important;
  padding-left: 0rem !important;
}

.margin-right {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
  margin-left: 0rem !important;
}

.padding-right {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
  padding-left: 0rem !important;
}

.margin-bottom {
  margin-top: 0rem !important;
  margin-right: 0rem !important;
  margin-left: 0rem !important;
}

.padding-bottom {
  padding-top: 0rem !important;
  padding-right: 0rem !important;
  padding-left: 0rem !important;
}

.margin-left {
  margin-top: 0rem !important;
  margin-right: 0rem !important;
  margin-bottom: 0rem !important;
}

.padding-left {
  padding-top: 0rem !important;
  padding-right: 0rem !important;
  padding-bottom: 0rem !important;
}

.margin-horizontal {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
}

.padding-horizontal {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}

.margin-vertical {
  margin-right: 0rem !important;
  margin-left: 0rem !important;
}

.padding-vertical {
  padding-right: 0rem !important;
  padding-left: 0rem !important;
}
/* </style     <style> */
        /*width*/
        ::-webkit-scrollbar {
            width: 10px;
          }
          /*track*/
          ::-webkit-scrollbar-track {
            /* background: rgb(27, 27, 27); */
          }
          /*thumb*/
          ::-webkit-scrollbar-thumb {
            /* background: rgb(27, 27, 27); */
            border-width: 1px;
            border-style: solid;
            /* border-color: rgb(65, 65, 65); */
            border-radius: 20px;
          }
          html {
      -ms-text-size-adjust: 100%;
      -webkit-text-size-adjust: 100%;
      font-family: sans-serif
  }
  
  body {
      margin: 0
  }
  
  article,aside,details,figcaption,figure,footer,header,hgroup,main,menu,nav,section,summary {
      display: block
  }
  
  audio,canvas,progress,video {
      vertical-align: baseline;
      display: inline-block
  }
  
  audio:not([controls]) {
      height: 0;
      display: none
  }
  
  [hidden],template {
      display: none
  }
  
  a {
      background-color: transparent
  }
  
  a:active,a:hover {
      outline: 0
  }
  
  abbr[title] {
      border-bottom: 1px dotted
  }
  
  b,strong {
      font-weight: 700
  }
  
  dfn {
      font-style: italic
  }
  
  h1 {
      margin: .67em 0;
      font-size: 2em
  }
  
  mark {
      color: #000;
      background: #ff0
  }
  
  small {
      font-size: 80%
  }
  
  sub,sup {
      vertical-align: baseline;
      font-size: 75%;
      line-height: 0;
      position: relative
  }
  
  sup {
      top: -.5em
  }
  
  sub {
      bottom: -.25em
  }
  
  img {
      border: 0
  }
  
  svg:not(:root) {
      overflow: hidden
  }
  
  figure {
      margin: 1em 40px
  }
  
  hr {
      box-sizing: content-box;
      height: 0
  }
  
  pre {
      overflow: auto
  }
  
  code,kbd,pre,samp {
      font-family: monospace;
      font-size: 1em
  }
  
  button,input,optgroup,select,textarea {
      color: inherit;
      font: inherit;
      margin: 0
  }
  
  button {
      overflow: visible
  }
  
  button,select {
      text-transform: none
  }
  
  button,html input[type=button],input[type=reset] {
      -webkit-appearance: button;
      cursor: pointer
  }
  
  button[disabled],html input[disabled] {
      cursor: default
  }
  
  button::-moz-focus-inner,input::-moz-focus-inner {
      border: 0;
      padding: 0
  }
  
  input {
      line-height: normal
  }
  
  input[type=checkbox],input[type=radio] {
      box-sizing: border-box;
      padding: 0
  }
  
  input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button {
      height: auto
  }
  
  input[type=search] {
      -webkit-appearance: none
  }
  
  input[type=search]::-webkit-search-cancel-button,input[type=search]::-webkit-search-decoration {
      -webkit-appearance: none
  }
  
  fieldset {
      border: 1px solid silver;
      margin: 0 2px;
      padding: .35em .625em .75em
  }
  
  legend {
      border: 0;
      padding: 0
  }
  
  textarea {
      overflow: auto
  }
  
  optgroup {
      font-weight: 700
  }
  
  table {
      border-collapse: collapse;
      border-spacing: 0
  }
  
  td,th {
      padding: 0;
  }
  

  
  [class^=w-icon-],[class*=\ w-icon-] {
      speak: none;
      font-variant: normal;
      text-transform: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-style: normal;
      font-weight: 400;
      line-height: 1;
      font-family: webflow-icons!important
  }
  
  .w-icon-slider-right:before {
      content: "î˜€"
  }
  
  .w-icon-slider-left:before {
      content: "î˜"
  }
  
  .w-icon-nav-menu:before {
      content: "î˜‚"
  }
  
  .w-icon-arrow-down:before,.w-icon-dropdown-toggle:before {
      content: "î˜ƒ"
  }
  
  .w-icon-file-upload-remove:before {
      content: "î¤€"
  }
  
  .w-icon-file-upload-icon:before {
      content: "î¤ƒ"
  }
  
  * {
      box-sizing: border-box
  }
  
  html {
      height: 100%
  }
  
  body {
      min-height: 100%;
      color: blue;
      background-color: #fff;
      margin: 0;
      font-family: Arial,sans-serif;
      font-size: 14px;
      line-height: 20px
  }
  
  img {
      max-width: 100%;
      vertical-align: middle;
      display: inline-block
  }
  
  html.w-mod-touch * {
      background-attachment: scroll!important
  }
  
  .w-block {
      display: block
  }
  
  .w-inline-block {
      max-width: 100%;
      display: inline-block
  }
  
  .w-clearfix:before,.w-clearfix:after {
      content: " ";
      grid-area: 1/1/2/2;
      display: table
  }
  
  .w-clearfix:after {
      clear: both
  }
  
  .w-hidden {
      display: none
  }
  
  .w-button {
      color: #fff;
      line-height: inherit;
      cursor: pointer;
      background-color: #3898ec;
      border: 0;
      border-radius: 0;
      padding: 9px 15px;
      text-decoration: none;
      display: inline-block
  }
  
  input.w-button {
      -webkit-appearance: button
  }
  
  html[data-w-dynpage] [data-w-cloak] {
      color: transparent!important
  }
  
  .w-webflow-badge,.w-webflow-badge * {
      z-index: auto;
      visibility: visible;
      box-sizing: border-box;
      width: auto;
      height: auto;
      max-height: none;
      max-width: none;
      min-height: 0;
      min-width: 0;
      float: none;
      clear: none;
      box-shadow: none;
      opacity: 1;
      direction: ltr;
      font-family: inherit;
      font-weight: inherit;
      color: inherit;
      font-size: inherit;
      line-height: inherit;
      font-style: inherit;
      font-variant: inherit;
      text-align: inherit;
      letter-spacing: inherit;
      -webkit-text-decoration: inherit;
      text-decoration: inherit;
      text-indent: 0;
      text-transform: inherit;
      text-shadow: none;
      font-smoothing: auto;
      vertical-align: baseline;
      cursor: inherit;
      white-space: inherit;
      word-break: normal;
      word-spacing: normal;
      word-wrap: normal;
      background: 0 0;
      border: 0 transparent;
      border-radius: 0;
      margin: 0;
      padding: 0;
      list-style-type: disc;
      transition: none;
      display: block;
      position: static;
      top: auto;
      bottom: auto;
      left: auto;
      right: auto;
      overflow: visible;
      transform: none
  }
  
  .w-webflow-badge {
      white-space: nowrap;
      cursor: pointer;
      box-shadow: 0 0 0 1px rgba(0,0,0,.1),0 1px 3px rgba(0,0,0,.1);
      visibility: visible!important;
      z-index: 2147483647!important;
      color: #aaadb0!important;
      opacity: 1!important;
      width: auto!important;
      height: auto!important;
      background-color: #fff!important;
      border-radius: 3px!important;
      margin: 0!important;
      padding: 6px 8px 6px 6px!important;
      font-size: 12px!important;
      line-height: 14px!important;
      text-decoration: none!important;
      display: inline-block!important;
      position: fixed!important;
      top: auto!important;
      bottom: 12px!important;
      left: auto!important;
      right: 12px!important;
      overflow: visible!important;
      transform: none!important
  }
  
  .w-webflow-badge>img {
      visibility: visible!important;
      opacity: 1!important;
      vertical-align: middle!important;
      display: inline-block!important
  }
  
  h1,h2,h3,h4,h5,h6 {
      margin-bottom: 10px;
      font-weight: 700
  }
  
  h1 {
      margin-top: 20px;
      font-size: 38px;
      line-height: 44px
  }
  
  h2 {
      margin-top: 20px;
      font-size: 32px;
      line-height: 36px
  }
  
  h3 {
      margin-top: 20px;
      font-size: 24px;
      line-height: 30px
  }
  
  h4 {
      margin-top: 10px;
      font-size: 18px;
      line-height: 24px
  }
  
  h5 {
      margin-top: 10px;
      font-size: 14px;
      line-height: 20px
  }
  
  h6 {
      margin-top: 10px;
      font-size: 12px;
      line-height: 18px
  }
  
  p {
      margin-top: 0;
      margin-bottom: 10px
  }
  
  blockquote {
      border-left: 5px solid #e2e2e2;
      margin: 0 0 10px;
      padding: 10px 20px;
      font-size: 18px;
      line-height: 22px
  }
  
  figure {
      margin: 0 0 10px
  }
  
  figcaption {
      text-align: center;
      margin-top: 5px
  }
  
  ul,ol {
      margin-top: 0;
      margin-bottom: 10px;
      padding-left: 40px
  }
  
  .w-list-unstyled {
      padding-left: 0;
      list-style: none
  }
  
  .w-embed:before,.w-embed:after {
      content: " ";
      grid-area: 1/1/2/2;
      display: table
  }
  
  .w-embed:after {
      clear: both
  }
  
  .w-video {
      width: 100%;
      padding: 0;
      position: relative
  }
  
  .w-video iframe,.w-video object,.w-video embed {
      width: 100%;
      height: 100%;
      border: none;
      position: absolute;
      top: 0;
      left: 0
  }
  
  fieldset {
      border: 0;
      margin: 0;
      padding: 0
  }
  
  button,[type=button],[type=reset] {
      cursor: pointer;
      -webkit-appearance: button;
      border: 0
  }
  
  .w-form {
      margin: 0 0 15px
  }
  
  .w-form-done {
      text-align: center;
      background-color: #ddd;
      padding: 20px;
      display: none
  }
  
  .w-form-fail {
      background-color: #ffdede;
      margin-top: 10px;
      padding: 10px;
      display: none
  }
  
  label {
      margin-bottom: 5px;
      font-weight: 700;
      display: block
  }
  
  .w-input,.w-select {
      width: 100%;
      height: 38px;
      color: #333;
      vertical-align: middle;
      background-color: #fff;
      border: 1px solid #ccc;
      margin-bottom: 10px;
      padding: 8px 12px;
      font-size: 14px;
      line-height: 1.42857;
      display: block
  }
  
  .w-input:-moz-placeholder,.w-select:-moz-placeholder {
      color: #999
  }
  
  .w-input::-moz-placeholder,.w-select::-moz-placeholder {
      color: #999;
      opacity: 1
  }
  
  .w-input::-webkit-input-placeholder,.w-select::-webkit-input-placeholder {
      color: #999
  }
  
  .w-input:focus,.w-select:focus {
      border-color: #3898ec;
      outline: 0
  }
  
  .w-input[disabled],.w-select[disabled],.w-input[readonly],.w-select[readonly],fieldset[disabled] .w-input,fieldset[disabled] .w-select {
      cursor: not-allowed
  }
  
  .w-input[disabled]:not(.w-input-disabled),.w-select[disabled]:not(.w-input-disabled),.w-input[readonly],.w-select[readonly],fieldset[disabled]:not(.w-input-disabled) .w-input,fieldset[disabled]:not(.w-input-disabled) .w-select {
      background-color: #eee
  }
  
  textarea.w-input,textarea.w-select {
      height: auto
  }
  
  .w-select {
      background-color: #f3f3f3
  }
  
  .w-select[multiple] {
      height: auto
  }
  
  .w-form-label {
      cursor: pointer;
      margin-bottom: 0;
      font-weight: 400;
      display: inline-block
  }
  
  .w-radio {
      margin-bottom: 5px;
      padding-left: 20px;
      display: block
  }
  
  .w-radio:before,.w-radio:after {
      content: " ";
      grid-area: 1/1/2/2;
      display: table
  }
  
  .w-radio:after {
      clear: both
  }
  
  .w-radio-input {
      float: left;
      margin: 3px 0 0 -20px;
      line-height: normal
  }
  
  .w-file-upload {
      margin-bottom: 10px;
      display: block
  }
  
  .w-file-upload-input {
      width: .1px;
      height: .1px;
      opacity: 1;
      z-index: -100;
      position: absolute;
      overflow: hidden
  }
  
  .w-file-upload-default,.w-file-upload-uploading,.w-file-upload-success {
      color: #333;
      display: inline-block
  }
  
  .w-file-upload-error {
      margin-top: 10px;
      display: block
  }
  
  .w-file-upload-default.w-hidden,.w-file-upload-uploading.w-hidden,.w-file-upload-error.w-hidden,.w-file-upload-success.w-hidden {
      display: none
  }
  
  .w-file-upload-uploading-btn {
      cursor: pointer;
      background-color: #fafafa;
      border: 1px solid #ccc;
      margin: 0;
      padding: 8px 12px;
      font-size: 14px;
      font-weight: 400;
      display: flex
  }
  
  .w-file-upload-file {
      background-color: #fafafa;
      border: 1px solid #ccc;
      flex-grow: 1;
      justify-content: space-between;
      margin: 0;
      padding: 8px 9px 8px 11px;
      display: flex
  }
  
  .w-file-upload-file-name {
      font-size: 14px;
      font-weight: 400;
      display: block
  }
  
  .w-file-remove-link {
      width: auto;
      height: auto;
      cursor: pointer;
      margin-top: 3px;
      margin-left: 10px;
      padding: 3px;
      display: block
  }
  
  .w-icon-file-upload-remove {
      margin: auto;
      font-size: 10px
  }
  
  .w-file-upload-error-msg {
      color: #ea384c;
      padding: 2px 0;
      display: inline-block
  }
  
  .w-file-upload-info {
      padding: 0 12px;
      line-height: 38px;
      display: inline-block
  }
  
  .w-file-upload-label {
      cursor: pointer;
      background-color: #fafafa;
      border: 1px solid #ccc;
      margin: 0;
      padding: 8px 12px;
      font-size: 14px;
      font-weight: 400;
      display: inline-block
  }
  
  .w-icon-file-upload-icon,.w-icon-file-upload-uploading {
      width: 20px;
      margin-right: 8px;
      display: inline-block
  }
  
  .w-icon-file-upload-uploading {
      height: 20px
  }
  
  .w-container {
      max-width: 940px;
      margin-left: auto;
      margin-right: auto
  }
  
  .w-container:before,.w-container:after {
      content: " ";
      grid-area: 1/1/2/2;
      display: table
  }
  
  .w-container:after {
      clear: both
  }
  
  .w-container .w-row {
      margin-left: -10px;
      margin-right: -10px
  }
  
  .w-row:before,.w-row:after {
      content: " ";
      grid-area: 1/1/2/2;
      display: table
  }
  
  .w-row:after {
      clear: both
  }
  
  .w-row .w-row {
      margin-left: 0;
      margin-right: 0
  }
  
  .w-col {
      float: left;
      width: 100%;
      min-height: 1px;
      padding-left: 10px;
      padding-right: 10px;
      position: relative
  }
  
  .w-col .w-col {
      padding-left: 0;
      padding-right: 0
  }
  
  .w-col-1 {
      width: 8.33333%
  }
  
  .w-col-2 {
      width: 16.6667%
  }
  
  .w-col-3 {
      width: 25%
  }
  
  .w-col-4 {
      width: 33.3333%
  }
  
  .w-col-5 {
      width: 41.6667%
  }
  
  .w-col-6 {
      width: 50%
  }
  
  .w-col-7 {
      width: 58.3333%
  }
  
  .w-col-8 {
      width: 66.6667%
  }
  
  .w-col-9 {
      width: 75%
  }
  
  .w-col-10 {
      width: 83.3333%
  }
  
  .w-col-11 {
      width: 91.6667%
  }
  
  .w-col-12 {
      width: 100%
  }
  
  .w-hidden-main {
      display: none!important
  }
  
  @media screen and (max-width: 991px) {
      .w-container {
          max-width:728px
      }
  
      .w-hidden-main {
          display: inherit!important
      }
  
      .w-hidden-medium {
          display: none!important
      }
  
      .w-col-medium-1 {
          width: 8.33333%
      }
  
      .w-col-medium-2 {
          width: 16.6667%
      }
  
      .w-col-medium-3 {
          width: 25%
      }
  
      .w-col-medium-4 {
          width: 33.3333%
      }
  
      .w-col-medium-5 {
          width: 41.6667%
      }
  
      .w-col-medium-6 {
          width: 50%
      }
  
      .w-col-medium-7 {
          width: 58.3333%
      }
  
      .w-col-medium-8 {
          width: 66.6667%
      }
  
      .w-col-medium-9 {
          width: 75%
      }
  
      .w-col-medium-10 {
          width: 83.3333%
      }
  
      .w-col-medium-11 {
          width: 91.6667%
      }
  
      .w-col-medium-12 {
          width: 100%
      }
  
      .w-col-stack {
          width: 100%;
          left: auto;
          right: auto
      }
  }
  
  @media screen and (max-width: 767px) {
      .w-hidden-main,.w-hidden-medium {
          display:inherit!important
      }
  
      .w-hidden-small {
          display: none!important
      }
  
      .w-row,.w-container .w-row {
          margin-left: 0;
          margin-right: 0
      }
  
      .w-col {
          width: 100%;
          left: auto;
          right: auto
      }
  
      .w-col-small-1 {
          width: 8.33333%
      }
  
      .w-col-small-2 {
          width: 16.6667%
      }
  
      .w-col-small-3 {
          width: 25%
      }
  
      .w-col-small-4 {
          width: 33.3333%
      }
  
      .w-col-small-5 {
          width: 41.6667%
      }
  
      .w-col-small-6 {
          width: 50%
      }
  
      .w-col-small-7 {
          width: 58.3333%
      }
  
      .w-col-small-8 {
          width: 66.6667%
      }
  
      .w-col-small-9 {
          width: 75%
      }
  
      .w-col-small-10 {
          width: 83.3333%
      }
  
      .w-col-small-11 {
          width: 91.6667%
      }
  
      .w-col-small-12 {
          width: 100%
      }
  }
  
  @media screen and (max-width: 479px) {
      .w-container {
          max-width:none
      }
  
      .w-hidden-main,.w-hidden-medium,.w-hidden-small {
          display: inherit!important
      }
  
      .w-hidden-tiny {
          display: none!important
      }
  
      .w-col {
          width: 100%
      }
  
      .w-col-tiny-1 {
          width: 8.33333%
      }
  
      .w-col-tiny-2 {
          width: 16.6667%
      }
  
      .w-col-tiny-3 {
          width: 25%
      }
  
      .w-col-tiny-4 {
          width: 33.3333%
      }
  
      .w-col-tiny-5 {
          width: 41.6667%
      }
  
      .w-col-tiny-6 {
          width: 50%
      }
  
      .w-col-tiny-7 {
          width: 58.3333%
      }
  
      .w-col-tiny-8 {
          width: 66.6667%
      }
  
      .w-col-tiny-9 {
          width: 75%
      }
  
      .w-col-tiny-10 {
          width: 83.3333%
      }
  
      .w-col-tiny-11 {
          width: 91.6667%
      }
  
      .w-col-tiny-12 {
          width: 100%
      }
  }
  
  .w-widget {
      position: relative
  }
  
  .w-widget-map {
      width: 100%;
      height: 400px
  }
  
  .w-widget-map label {
      width: auto;
      display: inline
  }
  
  .w-widget-map img {
      max-width: inherit
  }
  
  .w-widget-map .gm-style-iw {
      text-align: center
  }
  
  .w-widget-map .gm-style-iw>button {
      display: none!important
  }
  
  .w-widget-twitter {
      overflow: hidden
  }
  
  .w-widget-twitter-count-shim {
      vertical-align: top;
      width: 28px;
      height: 20px;
      text-align: center;
      background: #fff;
      border: 1px solid #758696;
      border-radius: 3px;
      display: inline-block;
      position: relative
  }
  
  .w-widget-twitter-count-shim * {
      pointer-events: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none
  }
  
  .w-widget-twitter-count-shim .w-widget-twitter-count-inner {
      text-align: center;
      color: #999;
      font-family: serif;
      font-size: 15px;
      line-height: 12px;
      position: relative
  }
  
  .w-widget-twitter-count-shim .w-widget-twitter-count-clear {
      display: block;
      position: relative
  }
  
  .w-widget-twitter-count-shim.w--large {
      width: 36px;
      height: 28px
  }
  
  .w-widget-twitter-count-shim.w--large .w-widget-twitter-count-inner {
      font-size: 18px;
      line-height: 18px
  }
  
  .w-widget-twitter-count-shim:not(.w--vertical) {
      margin-left: 5px;
      margin-right: 8px
  }
  
  .w-widget-twitter-count-shim:not(.w--vertical).w--large {
      margin-left: 6px
  }
  
  .w-widget-twitter-count-shim:not(.w--vertical):before,.w-widget-twitter-count-shim:not(.w--vertical):after {
      content: " ";
      height: 0;
      width: 0;
      pointer-events: none;
      border: solid transparent;
      position: absolute;
      top: 50%;
      left: 0
  }
  
  .w-widget-twitter-count-shim:not(.w--vertical):before {
      border-width: 4px;
      border-color: rgba(117,134,150,0) #5d6c7b rgba(117,134,150,0) rgba(117,134,150,0);
      margin-top: -4px;
      margin-left: -9px
  }
  
  .w-widget-twitter-count-shim:not(.w--vertical).w--large:before {
      border-width: 5px;
      margin-top: -5px;
      margin-left: -10px
  }
  
  .w-widget-twitter-count-shim:not(.w--vertical):after {
      border-width: 4px;
      border-color: rgba(255,255,255,0) #fff rgba(255,255,255,0) rgba(255,255,255,0);
      margin-top: -4px;
      margin-left: -8px
  }
  
  .w-widget-twitter-count-shim:not(.w--vertical).w--large:after {
      border-width: 5px;
      margin-top: -5px;
      margin-left: -9px
  }
  
  .w-widget-twitter-count-shim.w--vertical {
      width: 61px;
      height: 33px;
      margin-bottom: 8px
  }
  
  .w-widget-twitter-count-shim.w--vertical:before,.w-widget-twitter-count-shim.w--vertical:after {
      content: " ";
      height: 0;
      width: 0;
      pointer-events: none;
      border: solid transparent;
      position: absolute;
      top: 100%;
      left: 50%
  }
  
  .w-widget-twitter-count-shim.w--vertical:before {
      border-width: 5px;
      border-color: #5d6c7b rgba(117,134,150,0) rgba(117,134,150,0);
      margin-left: -5px
  }
  
  .w-widget-twitter-count-shim.w--vertical:after {
      border-width: 4px;
      border-color: #fff rgba(255,255,255,0) rgba(255,255,255,0);
      margin-left: -4px
  }
  
  .w-widget-twitter-count-shim.w--vertical .w-widget-twitter-count-inner {
      font-size: 18px;
      line-height: 22px
  }
  
  .w-widget-twitter-count-shim.w--vertical.w--large {
      width: 76px
  }
  
  .w-background-video {
      height: 500px;
      color: #fff;
      position: relative;
      overflow: hidden
  }
  
  .w-background-video>video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -100;
      background-position: 50%;
      background-size: cover;
      margin: auto;
      position: absolute;
      top: -100%;
      bottom: -100%;
      left: -100%;
      right: -100%
  }
  
  .w-background-video>video::-webkit-media-controls-start-playback-button {
      -webkit-appearance: none;
      display: none!important
  }
  
  .w-background-video--control {
      background-color: transparent;
      padding: 0;
      position: absolute;
      bottom: 1em;
      right: 1em
  }
  
  .w-background-video--control>[hidden] {
      display: none!important
  }
  
  .w-slider {
      height: 300px;
      text-align: center;
      clear: both;
      -webkit-tap-highlight-color: transparent;
      tap-highlight-color: transparent;
      background: #ddd;
      position: relative
  }
  
  .w-slider-mask {
      z-index: 1;
      height: 100%;
      white-space: nowrap;
      display: block;
      position: relative;
      left: 0;
      right: 0;
      overflow: hidden
  }
  
  .w-slide {
      vertical-align: top;
      width: 100%;
      height: 100%;
      white-space: normal;
      text-align: left;
      display: inline-block;
      position: relative
  }
  
  .w-slider-nav {
      z-index: 2;
      height: 40px;
      text-align: center;
      -webkit-tap-highlight-color: transparent;
      tap-highlight-color: transparent;
      margin: auto;
      padding-top: 10px;
      position: absolute;
      top: auto;
      bottom: 0;
      left: 0;
      right: 0
  }
  
  .w-slider-nav.w-round>div {
      border-radius: 100%
  }
  
  .w-slider-nav.w-num>div {
      width: auto;
      height: auto;
      font-size: inherit;
      line-height: inherit;
      padding: .2em .5em
  }
  
  .w-slider-nav.w-shadow>div {
      box-shadow: 0 0 3px rgba(51,51,51,.4)
  }
  
  .w-slider-nav-invert {
      color: #fff
  }
  
  .w-slider-nav-invert>div {
      background-color: rgba(34,34,34,.4)
  }
  
  .w-slider-nav-invert>div.w-active {
      background-color: #222
  }
  
  .w-slider-dot {
      width: 1em;
      height: 1em;
      cursor: pointer;
      background-color: rgba(255,255,255,.4);
      margin: 0 3px .5em;
      transition: background-color .1s,color .1s;
      display: inline-block;
      position: relative
  }
  
  .w-slider-dot.w-active {
      background-color: #fff
  }
  
  .w-slider-dot:focus {
      outline: none;
      box-shadow: 0 0 0 2px #fff
  }
  
  .w-slider-dot:focus.w-active {
      box-shadow: none
  }
  
  .w-slider-arrow-left,.w-slider-arrow-right {
      width: 80px;
      cursor: pointer;
      color: #fff;
      -webkit-tap-highlight-color: transparent;
      tap-highlight-color: transparent;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
      margin: auto;
      font-size: 40px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      overflow: hidden
  }
  
  .w-slider-arrow-left [class^=w-icon-],.w-slider-arrow-right [class^=w-icon-],.w-slider-arrow-left [class*=\ w-icon-],.w-slider-arrow-right [class*=\ w-icon-] {
      position: absolute
  }
  
  .w-slider-arrow-left:focus,.w-slider-arrow-right:focus {
      outline: 0
  }
  
  .w-slider-arrow-left {
      z-index: 3;
      right: auto
  }
  
  .w-slider-arrow-right {
      z-index: 4;
      left: auto
  }
  
  .w-icon-slider-left,.w-icon-slider-right {
      width: 1em;
      height: 1em;
      margin: auto;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0
  }
  
  .w-slider-aria-label {
      clip: rect(0 0 0 0);
      height: 1px;
      width: 1px;
      border: 0;
      margin: -1px;
      padding: 0;
      position: absolute;
      overflow: hidden
  }
  
  .w-slider-force-show {
      display: block!important
  }
  
  .w-dropdown {
      text-align: left;
      z-index: 900;
      margin-left: auto;
      margin-right: auto;
      display: inline-block;
      position: relative
  }
  
  .w-dropdown-btn,.w-dropdown-toggle,.w-dropdown-link {
      vertical-align: top;
      color: #222;
      text-align: left;
      white-space: nowrap;
      margin-left: auto;
      margin-right: auto;
      padding: 20px;
      text-decoration: none;
      position: relative
  }
  
  .w-dropdown-toggle {
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
      cursor: pointer;
      padding-right: 40px;
      display: inline-block
  }
  
  .w-dropdown-toggle:focus {
      outline: 0
  }
  
  .w-icon-dropdown-toggle {
      width: 1em;
      height: 1em;
      margin: auto 20px auto auto;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0
  }
  
  .w-dropdown-list {
      min-width: 100%;
      background: #ddd;
      display: none;
      position: absolute
  }
  
  .w-dropdown-list.w--open {
      display: block
  }
  
  .w-dropdown-link {
      color: #222;
      padding: 10px 20px;
      display: block
  }
  
  .w-dropdown-link.w--current {
      color: #0082f3
  }
  
  .w-dropdown-link:focus {
      outline: 0
  }
  
  @media screen and (max-width: 767px) {
      .w-nav-brand {
          padding-left:10px
      }
  }
  
  .w-lightbox-backdrop {
      cursor: auto;
      letter-spacing: normal;
      text-indent: 0;
      text-shadow: none;
      text-transform: none;
      visibility: visible;
      white-space: normal;
      word-break: normal;
      word-spacing: normal;
      word-wrap: normal;
      color: #fff;
      text-align: center;
      z-index: 2000;
      opacity: 1;
      -webkit-user-select: none;
      -moz-user-select: none;
      -webkit-tap-highlight-color: transparent;
      background: rgba(0,0,0,.9);
      outline: 0;
      font-family: Helvetica Neue,Helvetica,Ubuntu,Segoe UI,Verdana,sans-serif;
      font-size: 17px;
      font-style: normal;
      font-weight: 300;
      line-height: 1.2;
      list-style: disc;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      -webkit-transform: translate(0)
  }
  
  .w-lightbox-backdrop,.w-lightbox-container {
      height: 100%;
      -webkit-overflow-scrolling: touch;
      overflow: auto
  }
  
  .w-lightbox-content {
      height: 100vh;
      position: relative;
      overflow: hidden
  }
  
  .w-lightbox-view {
      width: 100vw;
      height: 100vh;
      opacity: 1;
      position: absolute
  }
  
  .w-lightbox-view:before {
      content: "";
      height: 100vh
  }
  
  .w-lightbox-group,.w-lightbox-group .w-lightbox-view,.w-lightbox-group .w-lightbox-view:before {
      height: 86vh
  }
  
  .w-lightbox-frame,.w-lightbox-view:before {
      vertical-align: middle;
      display: inline-block
  }
  
  .w-lightbox-figure {
      margin: 0;
      position: relative
  }
  
  .w-lightbox-group .w-lightbox-figure {
      cursor: pointer
  }
  
  .w-lightbox-img {
      width: auto;
      height: auto;
      max-width: none
  }
  
  .w-lightbox-image {
      float: none;
      max-width: 100vw;
      max-height: 100vh;
      display: block
  }
  
  .w-lightbox-group .w-lightbox-image {
      max-height: 86vh
  }
  
  .w-lightbox-caption {
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      background: rgba(0,0,0,.4);
      padding: .5em 1em;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      overflow: hidden
  }
  
  .w-lightbox-embed {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0
  }
  
  .w-lightbox-control {
      width: 4em;
      cursor: pointer;
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: 24px;
      transition: all .3s;
      position: absolute;
      top: 0
  }
  
  .w-lightbox-left {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0yMCAwIDI0IDQwIiB3aWR0aD0iMjQiIGhlaWdodD0iNDAiPjxnIHRyYW5zZm9ybT0icm90YXRlKDQ1KSI+PHBhdGggZD0ibTAgMGg1djIzaDIzdjVoLTI4eiIgb3BhY2l0eT0iLjQiLz48cGF0aCBkPSJtMSAxaDN2MjNoMjN2M2gtMjZ6IiBmaWxsPSIjZmZmIi8+PC9nPjwvc3ZnPg==);
      display: none;
      bottom: 0;
      left: 0
  }
  
  .w-lightbox-right {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii00IDAgMjQgNDAiIHdpZHRoPSIyNCIgaGVpZ2h0PSI0MCI+PGcgdHJhbnNmb3JtPSJyb3RhdGUoNDUpIj48cGF0aCBkPSJtMC0waDI4djI4aC01di0yM2gtMjN6IiBvcGFjaXR5PSIuNCIvPjxwYXRoIGQ9Im0xIDFoMjZ2MjZoLTN2LTIzaC0yM3oiIGZpbGw9IiNmZmYiLz48L2c+PC9zdmc+);
      display: none;
      bottom: 0;
      right: 0
  }
  
  .w-lightbox-close {
      height: 2.6em;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii00IDAgMTggMTciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxNyI+PGcgdHJhbnNmb3JtPSJyb3RhdGUoNDUpIj48cGF0aCBkPSJtMCAwaDd2LTdoNXY3aDd2NWgtN3Y3aC01di03aC03eiIgb3BhY2l0eT0iLjQiLz48cGF0aCBkPSJtMSAxaDd2LTdoM3Y3aDd2M2gtN3Y3aC0zdi03aC03eiIgZmlsbD0iI2ZmZiIvPjwvZz48L3N2Zz4=);
      background-size: 18px;
      right: 0
  }
  
  .w-lightbox-strip {
      white-space: nowrap;
      padding: 0 1vh;
      line-height: 0;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      overflow-x: auto;
      overflow-y: hidden
  }
  
  .w-lightbox-item {
      width: 10vh;
      box-sizing: content-box;
      cursor: pointer;
      padding: 2vh 1vh;
      display: inline-block;
      -webkit-transform: translate(0,0)
  }
  
  .w-lightbox-active {
      opacity: .3
  }
  
  .w-lightbox-thumbnail {
      height: 10vh;
      background: #222;
      position: relative;
      overflow: hidden
  }
  
  .w-lightbox-thumbnail-image {
      position: absolute;
      top: 0;
      left: 0
  }
  
  .w-lightbox-thumbnail .w-lightbox-tall {
      width: 100%;
      top: 50%;
      transform: translateY(-50%)
  }
  
  .w-lightbox-thumbnail .w-lightbox-wide {
      height: 100%;
      left: 50%;
      transform: translate(-50%)
  }
  
  .w-lightbox-spinner {
      box-sizing: border-box;
      width: 40px;
      height: 40px;
      border: 5px solid rgba(0,0,0,.4);
      border-radius: 50%;
      margin-top: -20px;
      margin-left: -20px;
      animation: .8s linear infinite spin;
      position: absolute;
      top: 50%;
      left: 50%
  }
  
  .w-lightbox-spinner:after {
      content: "";
      border: 3px solid transparent;
      border-bottom-color: #fff;
      border-radius: 50%;
      position: absolute;
      top: -4px;
      bottom: -4px;
      left: -4px;
      right: -4px
  }
  
  .w-lightbox-hide {
      display: none
  }
  
  .w-lightbox-noscroll {
      overflow: hidden
  }
  
  @media (min-width: 768px) {
      .w-lightbox-content {
          height:96vh;
          margin-top: 2vh
      }
  
      .w-lightbox-view,.w-lightbox-view:before {
          height: 96vh
      }
  
      .w-lightbox-group,.w-lightbox-group .w-lightbox-view,.w-lightbox-group .w-lightbox-view:before {
          height: 84vh
      }
  
      .w-lightbox-image {
          max-width: 96vw;
          max-height: 96vh
      }
  
      .w-lightbox-group .w-lightbox-image {
          max-width: 82.3vw;
          max-height: 84vh
      }
  
      .w-lightbox-left,.w-lightbox-right {
          opacity: .5;
          display: block
      }
  
      .w-lightbox-close {
          opacity: .8
      }
  
      .w-lightbox-control:hover {
          opacity: 1
      }
  }
  
  .w-lightbox-inactive,.w-lightbox-inactive:hover {
      opacity: 1
  }
  
  .w-richtext:before,.w-richtext:after {
      content: " ";
      grid-area: 1/1/2/2;
      display: table
  }
  
  .w-richtext:after {
      clear: both
  }
  
  .w-richtext[contenteditable=true]:before,.w-richtext[contenteditable=true]:after {
      white-space: initial
  }
  
  .w-richtext ol,.w-richtext ul {
      overflow: hidden
  }
  
  .w-richtext .w-richtext-figure-selected.w-richtext-figure-type-video div:after,.w-richtext .w-richtext-figure-selected[data-rt-type=video] div:after,.w-richtext .w-richtext-figure-selected.w-richtext-figure-type-image div,.w-richtext .w-richtext-figure-selected[data-rt-type=image] div {
      outline: 2px solid #2895f7
  }
  
  .w-richtext figure.w-richtext-figure-type-video>div:after,.w-richtext figure[data-rt-type=video]>div:after {
      content: "";
      display: none;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0
  }
  
  .w-richtext figure {
      max-width: 60%;
      position: relative
  }
  
  .w-richtext figure>div:before {
      cursor: default!important
  }
  
  .w-richtext figure img {
      width: 100%
  }
  
  .w-richtext figure figcaption.w-richtext-figcaption-placeholder {
      opacity: .6
  }
  
  .w-richtext figure div {
      color: transparent;
      font-size: 0
  }
  
  .w-richtext figure.w-richtext-figure-type-image,.w-richtext figure[data-rt-type=image] {
      display: table
  }
  
  .w-richtext figure.w-richtext-figure-type-image>div,.w-richtext figure[data-rt-type=image]>div {
      display: inline-block
  }
  
  .w-richtext figure.w-richtext-figure-type-image>figcaption,.w-richtext figure[data-rt-type=image]>figcaption {
      caption-side: bottom;
      display: table-caption
  }
  
  .w-richtext figure.w-richtext-figure-type-video,.w-richtext figure[data-rt-type=video] {
      width: 60%;
      height: 0
  }
  
  .w-richtext figure.w-richtext-figure-type-video iframe,.w-richtext figure[data-rt-type=video] iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0
  }
  
  .w-richtext figure.w-richtext-figure-type-video>div,.w-richtext figure[data-rt-type=video]>div {
      width: 100%
  }
  
  .w-richtext figure.w-richtext-align-center {
      clear: both;
      margin-left: auto;
      margin-right: auto
  }
  
  .w-richtext figure.w-richtext-align-center.w-richtext-figure-type-image>div,.w-richtext figure.w-richtext-align-center[data-rt-type=image]>div {
      max-width: 100%
  }
  
  .w-richtext figure.w-richtext-align-normal {
      clear: both
  }
  
  .w-richtext figure.w-richtext-align-fullwidth {
      width: 100%;
      max-width: 100%;
      text-align: center;
      clear: both;
      margin-left: auto;
      margin-right: auto;
      display: block
  }
  
  .w-richtext figure.w-richtext-align-fullwidth>div {
      padding-bottom: inherit;
      display: inline-block
  }
  
  .w-richtext figure.w-richtext-align-fullwidth>figcaption {
      display: block
  }
  
  .w-richtext figure.w-richtext-align-floatleft {
      float: left;
      clear: none;
      margin-right: 15px
  }
  
  .w-richtext figure.w-richtext-align-floatright {
      float: right;
      clear: none;
      margin-left: 15px
  }
  
  .w-nav {
      z-index: 1000;
      background: #ddd;
      position: relative
  }
  
  .w-nav:before,.w-nav:after {
      content: " ";
      grid-area: 1/1/2/2;
      display: table
  }
  
  .w-nav:after {
      clear: both
  }
  
  .w-nav-brand {
      float: left;
      color: #333;
      text-decoration: none;
      position: relative
  }
  
  .w-nav-link {
      vertical-align: top;
      color: #222;
      text-align: left;
      margin-left: auto;
      margin-right: auto;
      padding: 20px;
      text-decoration: none;
      display: inline-block;
      position: relative
  }
  
  .w-nav-link.w--current {
      color: #0082f3
  }
  
  .w-nav-menu {
      float: right;
      position: relative
  }
  
  [data-nav-menu-open] {
      text-align: center;
      min-width: 200px;
      background: #c8c8c8;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      overflow: visible;
      display: block!important
  }
  
  .w--nav-link-open {
      display: block;
      position: relative
  }
  
  .w-nav-overlay {
      width: 100%;
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      overflow: hidden
  }
  
  .w-nav-overlay [data-nav-menu-open] {
      top: 0
  }
  
  .w-nav[data-animation=over-left] .w-nav-overlay {
      width: auto
  }
  
  .w-nav[data-animation=over-left] .w-nav-overlay,.w-nav[data-animation=over-left] [data-nav-menu-open] {
      z-index: 1;
      top: 0;
      right: auto
  }
  
  .w-nav[data-animation=over-right] .w-nav-overlay {
      width: auto
  }
  
  .w-nav[data-animation=over-right] .w-nav-overlay,.w-nav[data-animation=over-right] [data-nav-menu-open] {
      z-index: 1;
      top: 0;
      left: auto
  }
  
  .w-nav-button {
      float: right;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
      tap-highlight-color: transparent;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
      padding: 18px;
      font-size: 24px;
      display: none;
      position: relative
  }
  
  .w-nav-button:focus {
      outline: 0
  }
  
  .w-nav-button.w--open {
      color: #fff;
      background-color: #c8c8c8
  }
  
  .w-nav[data-collapse=all] .w-nav-menu {
      display: none
  }
  
  .w-nav[data-collapse=all] .w-nav-button,.w--nav-dropdown-open,.w--nav-dropdown-toggle-open {
      display: block
  }
  
  .w--nav-dropdown-list-open {
      position: static
  }
  
  @media screen and (max-width: 991px) {
      .w-nav[data-collapse=medium] .w-nav-menu {
          display:none
      }
  
      .w-nav[data-collapse=medium] .w-nav-button {
          display: block
      }
  }
  
  @media screen and (max-width: 767px) {
      .w-nav[data-collapse=small] .w-nav-menu {
          display:none
      }
  
      .w-nav[data-collapse=small] .w-nav-button {
          display: block
      }
  
      .w-nav-brand {
          padding-left: 10px
      }
  }
  
  @media screen and (max-width: 479px) {
      .w-nav[data-collapse=tiny] .w-nav-menu {
          display:none
      }
  
      .w-nav[data-collapse=tiny] .w-nav-button {
          display: block
      }
  }
  
  .w-tabs {
      position: relative
  }
  
  .w-tabs:before,.w-tabs:after {
      content: " ";
      grid-area: 1/1/2/2;
      display: table
  }
  
  .w-tabs:after {
      clear: both
  }
  
  .w-tab-menu {
      position: relative
  }
  
  .w-tab-link {
      vertical-align: top;
      text-align: left;
      cursor: pointer;
      color: #222;
      background-color: #ddd;
      padding: 9px 30px;
      text-decoration: none;
      display: inline-block;
      position: relative
  }
  
  .w-tab-link.w--current {
      background-color: #c8c8c8
  }
  
  .w-tab-link:focus {
      outline: 0
  }
  
  .w-tab-content {
      display: block;
      position: relative;
      overflow: hidden
  }
  
  .w-tab-pane {
      display: none;
      position: relative
  }
  
  .w--tab-active {
      display: block
  }
  
  @media screen and (max-width: 479px) {
      .w-tab-link {
          display:block
      }
  }
  
  .w-ix-emptyfix:after {
      content: ""
  }
  
  @keyframes spin {
      0% {
          transform: rotate(0)
      }
  
      to {
          transform: rotate(360deg)
      }
  }
  
  .w-dyn-empty {
      background-color: #ddd;
      padding: 10px
  }
  
  .w-dyn-hide,.w-dyn-bind-empty,.w-condition-invisible {
      display: none!important
  }
  
  .wf-layout-layout {
      display: grid
  }
  
  .w-layout-grid {
      grid-row-gap: 16px;
      grid-column-gap: 16px;
      grid-template-rows: auto auto;
      grid-template-columns: 1fr 1fr;
      grid-auto-columns: 1fr;
      display: grid
  }
  
  .w-checkbox {
      margin-bottom: 5px;
      padding-left: 20px;
      display: block
  }
  
  .w-checkbox:before {
      content: " ";
      grid-area: 1/1/2/2;
      display: table
  }
  
  .w-checkbox:after {
      content: " ";
      clear: both;
      grid-area: 1/1/2/2;
      display: table
  }
  
  .w-checkbox-input {
      float: left;
      margin: 4px 0 0 -20px;
      line-height: normal
  }
  
  .w-checkbox-input--inputType-custom {
      width: 12px;
      height: 12px;
      border: 1px solid #ccc;
      border-radius: 2px
  }
  
  .w-checkbox-input--inputType-custom.w--redirected-checked {
      background-color: #3898ec;
      background-image: url(https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg);
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: cover;
      border-color: #3898ec
  }
  
  .w-checkbox-input--inputType-custom.w--redirected-focus {
      box-shadow: 0 0 3px 1px #3898ec
  }
  
  body {
      color: #5C5C5CB2;
      font-family: system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Noto Sans,Ubuntu,Cantarell,Helvetica Neue,Oxygen,Fira Sans,Droid Sans,sans-serif;
      font-size: 1rem;
      line-height: 1.5
  }
  
  h1 {
      text-transform: uppercase;
      margin-top: 0;
      margin-bottom: 0;
      font-family: Satoshi,sans-serif;
      font-size: 3.5rem;
      font-weight: 500;
      line-height: 1.2
  }
  
  h2 {
      text-transform: uppercase;
      margin-top: 0;
      margin-bottom: 0;
      font-family: Satoshi,sans-serif;
      font-size: 3rem;
      font-weight: 500;
      line-height: 1.2
  }
  
  h3 {
      margin-top: 0;
      margin-bottom: 0;
      font-family: Satoshi,sans-serif;
      font-size: 2.5rem;
      font-weight: 500;
      line-height: 1.2
  }
  
  h4 {
      margin-top: 0;
      margin-bottom: 0;
      font-family: Satoshi,sans-serif;
      font-size: 2rem;
      font-weight: 400;
      line-height: 1.3
  }
  
  h5 {
      margin-top: 0;
      margin-bottom: 0;
      font-family: Satoshi,sans-serif;
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 1.4
  }
  
  h6 {
      margin-top: 0;
      margin-bottom: 0;
      font-family: Satoshi,sans-serif;
      font-size: 1.25rem;
      font-weight: 400;
      line-height: 1.4
  }
  
  p {
      margin-bottom: 0;
      font-family: Satoshi,sans-serif;
      font-weight: 400
  }
  
  a {
      color: #1d1d1d;
      border-color: #1d1d1d;
      font-family: Satoshi,sans-serif;
      /* text-decoration: underline */
  }
  
  ul {
      margin-top: 1rem;
      margin-bottom: 1rem;
      padding-left: 1.25rem
  }
  
  ol {
      margin-top: 1rem;
      margin-bottom: 1rem;
      padding-left: 1.5rem
  }
  
  li {
      margin-top: .25rem;
      margin-bottom: .25rem;
      padding-left: .5rem
  }
  
  img {
      max-width: 100%;
      display: inline-block
  }
  
  label {
      margin-bottom: .25rem;
      font-weight: 500
  }
  
  blockquote {
      border-left: .1875rem solid #1d1d1d;
      margin-bottom: 0;
      padding: .75rem 1.25rem;
      font-family: Satoshi,sans-serif;
      font-size: 1.25rem;
      font-weight: 500;
      line-height: 1.5
  }
  
  figure {
      margin-top: 3rem;
      margin-bottom: 3rem
  }
  
  figcaption {
      text-align: center;
      margin-top: .25rem
  }
  
  .z-index-2 {
      z-index: 2;
      position: relative
  }
  
  .max-width-full {
      width: 100%;
      max-width: none
  }
  
  .max-width-full.text-align-left.horizontal-align {
      justify-content: space-between;
      align-items: flex-end;
      display: flex
  }
  
  .max-width-full.text-align-left.horizontal-align.align-top {
      align-items: flex-start
  }
  
  .max-width-full.text-align-left.horizontal-align.align-top.grid-project-intro {
      grid-column-gap: 16px;
      grid-row-gap: 16px;
      grid-template-rows: auto;
      grid-template-columns: .75fr 1.25fr;
      grid-auto-columns: 1fr;
      display: grid
  }
  
  .max-width-full.text-align-left.vertical-align {
      grid-row-gap: 2rem;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      display: flex
  }
  
  .layer {
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0%;
      bottom: 0%;
      left: 0%;
      right: 0%
  }
  
  .overflow-hidden {
      overflow: hidden
  }
  
  .global-styles {
      display: block;
      position: fixed;
      top: 0%;
      bottom: auto;
      left: 0%;
      right: auto
  }
  
  .z-index-1 {
      z-index: 1;
      position: relative
  }
  
  .hide {
      display: none
  }
  
  .text-style-link {
      font-family: Satoshi,sans-serif;
      text-decoration: underline
  }
  
  .max-width-xlarge {
      width: 100%;
      max-width: 64rem
  }
  
  .max-width-medium {
      width: 100%;
      max-width: 38rem
  }
  
  .max-width-medium.text-weight-light {
      font-family: Generalsans,sans-serif
  }
  
  .max-width-xsmall {
      width: 100%;
      max-width: 25rem
  }
  
  .max-width-xxsmall {
      width: 100%;
      max-width: 20rem
  }
  
  .container-large {
      width: 100%;
      max-width: 90vw;
      margin-left: auto;
      margin-right: auto
  }
  
  .container-large._90vw {
      max-width: 96vw
  }
  
  .container-large._100--vw {
      max-width: none
  }
  
  .max-width-xxlarge {
      width: 100%;
      max-width: 80rem
  }
  
  .align-center {
      margin-left: auto;
      margin-right: auto
  }
  
  .spacing-clean {
      margin: 0;
      padding: 0
  }
  
  .max-width-large {
      width: 100%;
      max-width: 48rem
  }
  
  .max-width-small {
      width: 100%;
      max-width: 30rem
  }
  
  .form-message-error {
      margin-top: .75rem;
      padding: .75rem
  }
  
  .utility_component {
      width: 100vw;
      height: 100vh;
      max-height: 100%;
      max-width: 100%;
      justify-content: center;
      align-items: center;
      padding-left: 1.25rem;
      padding-right: 1.25rem;
      display: flex
  }
  
  .utility_form-block {
      max-width: 20rem;
      text-align: center;
      flex-direction: column;
      display: flex
  }
  
  .utility_form {
      flex-direction: column;
      align-items: stretch;
      display: flex
  }
  
  .utility_image {
      margin-bottom: .5rem;
      margin-left: auto;
      margin-right: auto
  }
  
  .container-medium {
      width: 100%;
      max-width: 64rem;
      margin-left: auto;
      margin-right: auto
  }
  
  .container-small {
      width: 100%;
      max-width: 48rem;
      margin-left: auto;
      margin-right: auto
  }
  
  .margin-tiny {
      margin: .25rem
  }
  
  .margin-xxsmall {
      margin: .5rem
  }
  
  .margin-xsmall {
      margin: 1rem
  }
  
  .margin-small {
      margin: 1.5rem
  }
  
  .margin-medium {
      margin: 2rem
  }
  
  .margin-large {
      margin: 3rem
  }
  
  .margin-xlarge {
      margin: 4rem
  }
  
  .margin-xxlarge {
      margin: 5rem
  }
  
  .margin-huge {
      margin: 6rem
  }
  
  .margin-xhuge {
      margin: 7rem
  }
  
  .margin-xxhuge {
      margin: 10rem
  }
  
  .margin-0 {
      margin: 0
  }
  
  .padding-0 {
      padding: 0
  }
  
  .padding-tiny {
      padding: .25rem
  }
  
  .padding-xxsmall {
      padding: .5rem
  }
  
  .padding-xsmall {
      padding: 1rem
  }
  
  .padding-small {
      padding: 1.5rem
  }
  
  .padding-medium {
      padding: 2rem
  }
  
  .padding-large {
      padding: 3rem
  }
  
  .padding-xlarge {
      padding: 4rem
  }
  
  .padding-xxlarge {
      padding: 5rem
  }
  
  .padding-huge {
      padding: 6rem
  }
  
  .padding-xhuge {
      padding: 7rem
  }
  
  .padding-xxhuge {
      padding: 10rem
  }
  
  .margin-top,.margin-top.margin-custom1,.margin-top.margin-huge,.margin-top.margin-small,.margin-top.margin-tiny,.margin-top.margin-custom3,.margin-top.margin-medium,.margin-top.margin-xxsmall,.margin-top.margin-xxhuge,.margin-top.margin-custom2,.margin-top.margin-xsmall,.margin-top.margin-large,.margin-top.margin-xxlarge,.margin-top.margin-xlarge,.margin-top.margin-0,.margin-top.margin-xhuge {
      margin-bottom: 0;
      margin-left: 0;
      margin-right: 0
  }
  
  .margin-bottom {
      margin-top: 0;
      margin-left: 0;
      margin-right: 0
  }
  
  .margin-bottom.margin-small.z {
      z-index: 10;
      position: relative
  }
  
  .margin-bottom.margin-xsmall.horizontal-align {
      flex-direction: row;
      justify-content: space-between;
      display: flex
  }
  
  .margin-bottom.margin-xsmall.horizontal-align.mobile-vertical {
      margin-bottom: 0;
      padding-top: 1rem
  }
  
  .margin-bottom.margin-xxlarge,.margin-bottom.margin-medium,.margin-bottom.margin-custom3,.margin-bottom.margin-custom1,.margin-bottom.margin-tiny,.margin-bottom.margin-small,.margin-bottom.margin-large,.margin-bottom.margin-0,.margin-bottom.margin-xlarge,.margin-bottom.margin-xhuge,.margin-bottom.margin-xxhuge,.margin-bottom.margin-huge,.margin-bottom.margin-xxsmall,.margin-bottom.margin-custom2,.margin-bottom.margin-xsmall {
      margin-top: 0;
      margin-left: 0;
      margin-right: 0
  }
  
  .margin-bottom.align-horizontal {
      justify-content: space-between;
      display: flex
  }
  
  .margin-left,.margin-left.margin-tiny,.margin-left.margin-xxlarge,.margin-left.margin-0,.margin-left.margin-small,.margin-left.margin-xsmall,.margin-left.margin-large,.margin-left.margin-huge,.margin-left.margin-xlarge,.margin-left.margin-custom3,.margin-left.margin-xxsmall,.margin-left.margin-custom1,.margin-left.margin-custom2,.margin-left.margin-xhuge,.margin-left.margin-xxhuge,.margin-left.margin-medium {
      margin-top: 0;
      margin-bottom: 0;
      margin-right: 0
  }
  
  .margin-right,.margin-right.margin-huge {
      margin-top: 0;
      margin-bottom: 0;
      margin-left: 0
  }
  
  .margin-right.margin-huge.responsive-max-width {
      z-index: 5;
      position: relative
  }
  
  .margin-right.margin-xsmall,.margin-right.margin-xxhuge,.margin-right.margin-small,.margin-right.margin-medium,.margin-right.margin-xlarge,.margin-right.margin-custom3,.margin-right.margin-custom2,.margin-right.margin-xxsmall,.margin-right.margin-xhuge,.margin-right.margin-custom1,.margin-right.margin-large,.margin-right.margin-0,.margin-right.margin-xxlarge,.margin-right.margin-tiny {
      margin-top: 0;
      margin-bottom: 0;
      margin-left: 0
  }
  
  .margin-vertical,.margin-vertical.margin-medium,.margin-vertical.margin-xlarge,.margin-vertical.margin-xxsmall,.margin-vertical.margin-xxlarge {
      margin-left: 0;
      margin-right: 0
  }
  
  .margin-vertical.margin-xxlarge.less-margin-bottom {
      margin-top: 1rem;
      margin-bottom: 3rem
  }
  
  .margin-vertical.margin-huge,.margin-vertical.margin-0,.margin-vertical.margin-xxhuge,.margin-vertical.margin-tiny,.margin-vertical.margin-xsmall,.margin-vertical.margin-custom1,.margin-vertical.margin-small,.margin-vertical.margin-large,.margin-vertical.margin-xhuge,.margin-vertical.margin-custom2,.margin-vertical.margin-custom3 {
      margin-left: 0;
      margin-right: 0
  }
  
  .margin-horizontal,.margin-horizontal.margin-medium,.margin-horizontal.margin-tiny,.margin-horizontal.margin-custom3,.margin-horizontal.margin-0,.margin-horizontal.margin-xxlarge,.margin-horizontal.margin-xhuge,.margin-horizontal.margin-huge,.margin-horizontal.margin-xlarge,.margin-horizontal.margin-custom1,.margin-horizontal.margin-xxsmall,.margin-horizontal.margin-xsmall,.margin-horizontal.margin-large,.margin-horizontal.margin-custom2,.margin-horizontal.margin-small,.margin-horizontal.margin-xxhuge {
      margin-top: 0;
      margin-bottom: 0
  }
  
  .padding-top,.padding-top.padding-xhuge,.padding-top.padding-xxhuge,.padding-top.padding-0,.padding-top.padding-large,.padding-top.padding-custom2,.padding-top.padding-xlarge,.padding-top.padding-huge,.padding-top.padding-xxsmall,.padding-top.padding-custom3,.padding-top.padding-tiny,.padding-top.padding-medium,.padding-top.padding-xxlarge,.padding-top.padding-small,.padding-top.padding-xsmall,.padding-top.padding-custom1 {
      padding-bottom: 0;
      padding-left: 0;
      padding-right: 0
  }
  
  .padding-bottom,.padding-bottom.padding-xlarge,.padding-bottom.padding-small,.padding-bottom.padding-xxhuge,.padding-bottom.padding-large,.padding-bottom.padding-xhuge,.padding-bottom.padding-custom2,.padding-bottom.padding-xxsmall,.padding-bottom.padding-xxlarge,.padding-bottom.padding-custom3,.padding-bottom.padding-custom1,.padding-bottom.padding-xsmall,.padding-bottom.padding-huge,.padding-bottom.padding-tiny,.padding-bottom.padding-medium,.padding-bottom.padding-0 {
      padding-top: 0;
      padding-left: 0;
      padding-right: 0
  }
  
  .padding-left,.padding-left.padding-xhuge,.padding-left.padding-xlarge,.padding-left.padding-0,.padding-left.padding-small,.padding-left.padding-xsmall,.padding-left.padding-medium,.padding-left.padding-xxhuge,.padding-left.padding-huge,.padding-left.padding-custom1,.padding-left.padding-tiny,.padding-left.padding-xxsmall,.padding-left.padding-custom3,.padding-left.padding-custom2,.padding-left.padding-xxlarge,.padding-left.padding-large {
      padding-top: 0;
      padding-bottom: 0;
      padding-right: 0
  }
  
  .padding-right,.padding-right.padding-xxhuge,.padding-right.padding-custom2,.padding-right.padding-large,.padding-right.padding-xlarge,.padding-right.padding-tiny,.padding-right.padding-custom3,.padding-right.padding-custom1,.padding-right.padding-xsmall,.padding-right.padding-xxlarge,.padding-right.padding-xxsmall,.padding-right.padding-medium,.padding-right.padding-xhuge,.padding-right.padding-huge,.padding-right.padding-0,.padding-right.padding-small {
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 0
  }
  
  .padding-vertical,.padding-vertical.padding-0,.padding-vertical.padding-xxhuge,.padding-vertical.padding-large,.padding-vertical.padding-custom3,.padding-vertical.padding-medium,.padding-vertical.padding-tiny,.padding-vertical.padding-xxlarge,.padding-vertical.padding-custom1,.padding-vertical.padding-xsmall,.padding-vertical.padding-xhuge,.padding-vertical.padding-xlarge,.padding-vertical.padding-custom2,.padding-vertical.padding-xxsmall,.padding-vertical.padding-huge,.padding-vertical.padding-small {
      padding-left: 0;
      padding-right: 0
  }
  
  .padding-horizontal,.padding-horizontal.padding-xxsmall,.padding-horizontal.padding-0,.padding-horizontal.padding-custom3,.padding-horizontal.padding-huge,.padding-horizontal.padding-xsmall,.padding-horizontal.padding-medium,.padding-horizontal.padding-xxlarge,.padding-horizontal.padding-large,.padding-horizontal.padding-xhuge,.padding-horizontal.padding-custom1,.padding-horizontal.padding-tiny,.padding-horizontal.padding-custom2,.padding-horizontal.padding-xxhuge,.padding-horizontal.padding-small,.padding-horizontal.padding-xlarge {
      padding-top: 0;
      padding-bottom: 0
  }
  
  .overflow-scroll {
      overflow: scroll
  }
  
  .overflow-auto {
      overflow: auto
  }
  
  .text-weight-medium {
      font-family: Satoshi,sans-serif;
      font-weight: 500
  }
  
  .text-size-medium {
      font-family: Generalsans,sans-serif;
      /* font-size: 0.925rem */
      font-size: 0.75rem;
  }
  
  .text-size-medium.max-width-medium.margin-right.margin-medium.text-weight-light.max-width-37rem {
      max-width: 37rem
  }
  
  .text-size-medium.max-width-medium.text-weight-light {
      font-weight: 300
  }
  
  .text-size-medium.max-width-medium.text-weight-light.full-width-respomsive.width-36rem {
      max-width: 36rem
  }
  
  .text-size-medium.max-width-medium.text-style-allcaps {
      font-family: Generalsans,sans-serif
  }
  
  .text-size-medium.max-width-medium.text-style-allcaps.text-colour-grey {
      color: #ababab
  }
  
  .text-size-medium.max-width-medium.text-style-allcaps.text-colour-grey.project-detail-width {
      max-width: 38rem
  }
  
  .text-size-medium.margin-top.margin-medium.max-width-large.text-weight-light,.text-size-medium.max-width-large.text-weight-light {
      font-family: Generalsans,sans-serif;
      font-weight: 300
  }
  
  .text-size-medium.max-width-xxsmall.text-style-allcaps.text-colour-grey {
      color: #ababab
  }
  
  .margin-top-auto {
      margin-top: auto
  }
  
  .text-style-quote {
      border-left: .1875rem solid #000;
      margin-bottom: 0;
      padding: .75rem 1.25rem;
      font-family: Satoshi,sans-serif;
      font-size: 1.25rem;
      font-weight: 500;
      line-height: 1.5
  }
  
  .icon-embed-small {
      width: 1.8rem;
      height: 1.8rem;
      color: #1d1d1d;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      display: flex
  }
  
  .heading-style-h2 {
      font-size: 20px;
      font-weight: 500;
      line-height: 1.2;
      overflow: hidden;
      text-transform: capitalize;
      margin-top: 1.2rem;
  }
  
  .heading-style-h2._8vw-title {
      /* line-height: 1; */
      /* color: #3A3A1F; */
  }
  
  .heading-style-h2._8vw-title.project-intro-title {
      font-size: 3.2rem;
      color: #3A3A1F;
  }
  
  .heading-style-h2._8vw-title.project-title {
      font-size: 6.15vw;
      color: #3A3A1F;
  }
  
  .heading-style-h2.about-title {
      font-size: 7.6vw;
      line-height: 1;
    
  }
  
  .rl-styleguide_tutorial-callout {
      grid-column-gap: 2rem;
      background-color: #fff;
      border: 1px solid #000;
      justify-content: space-between;
      align-items: center;
      padding: .75rem .75rem .75rem 1rem;
      display: flex
  }
  
  .rl-styleguide_callout-link-wrapper {
      width: 100%;
      background-image: linear-gradient(135deg,rgba(255,116,72,.15),rgba(255,72,72,.15) 50%,rgba(98,72,255,.15)),linear-gradient(#fff,#fff);
      justify-content: space-between;
      align-items: center;
      padding: .75rem 1rem;
      display: flex
  }
  
  .rl-styleguide_heading {
      z-index: 4;
      color: #fff;
      background-color: #000;
      padding: .25rem .5rem;
      position: -webkit-sticky;
      position: sticky;
      top: 0
  }
  
  .styleguide_grid-colours {
      grid-column-gap: 1rem;
      grid-row-gap: 1rem;
      grid-template-rows: auto;
      grid-template-columns: 1fr 1fr 1fr
  }
  
  .background-color-gray {
      background-color: #f4f4f4
  }
  
  .heading-style-h6 {
      text-transform: none;
      font-size: 1.25rem;
      font-weight: 400;
      line-height: 1.4
  }
  
  .rl-styleguide_item-row {
      grid-column-gap: 1.5rem;
      grid-row-gap: 0rem;
      grid-template-rows: auto;
      grid-template-columns: 15rem 1fr;
      align-items: center
  }
  
  .icon-1x1-xsmall {
      width: 1.5rem;
      height: 1.5rem
  }
  
  .rl-styleguide_empty-space {
      z-index: -1;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      padding: 2rem;
      display: flex;
      position: relative
  }
  
  .shadow-xxlarge {
      box-shadow: 0 32px 64px -12px rgba(0,0,0,.14)
  }
  
  .rl-styleguide_icons-list {
      grid-column-gap: 1rem;
      grid-row-gap: 1rem;
      flex-wrap: wrap;
      grid-template-rows: auto;
      grid-template-columns: 1fr;
      grid-auto-columns: 1fr;
      grid-auto-flow: column;
      display: grid
  }
  
  .icon-embed-medium {
      width: 3rem;
      height: 3rem;
      color: #ababab;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      display: flex
  }
  
  .form-radio {
      align-items: center;
      margin-bottom: 0;
      padding-left: 1.125rem;
      display: flex
  }
  
  .heading-style-h5 {
      text-transform: none;
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 1.4
  }
  
  .heading-style-h5.text-style-allcaps.text-weight-medium {
      font-weight: 400
  }
  
  .text-style-strikethrough {
      font-family: Generalsans,sans-serif;
      text-decoration: line-through
  }
  
  .heading-style-h1 {
      font-size: 3.5rem;
      font-weight: 500;
      line-height: 1.2
  }
  
  .form-checkbox-icon {
      width: 1.125rem;
      height: 1.125rem;
      min-height: 1.125rem;
      min-width: 1.125rem;
      cursor: pointer;
      border: 1px solid #ababab;
      border-radius: 100px;
      margin-top: 0;
      margin-left: -1.25rem;
      margin-right: .5rem;
      transition: all .2s
  }
  
  .form-checkbox-icon.w--redirected-checked {
      box-shadow: none;
      background-color: #fff8f1;
      background-image: none;
      border-width: 1px;
      border-color: #fff8f1
  }
  
  .form-checkbox-icon.w--redirected-focus {
      box-shadow: none;
      border-color: #fff8f1
  }
  
  .form-checkbox-label {
      margin-bottom: 0
  }
  
  .form-checkbox-label.text-size-small {
      text-transform: uppercase
  }
  
  .text-size-tiny {
      font-family: Generalsans,sans-serif;
      font-size: .75rem
  }
  
  .field-label {
      text-transform: uppercase;
      margin-bottom: .5rem;
      font-family: Generalsans,sans-serif;
      font-size: 1rem;
      font-weight: 400
  }
  
  .button {
      color: #1d1d1d;
      text-align: center;
      text-transform: uppercase;
      background-color: #fff8f1;
      border: 1px solid #1d1d1d;
      border-radius: 30rem;
      padding: .75rem 1.5rem;
      font-family: Generalsans,sans-serif
  }
  
  .button.is-link {
      color: #1d1d1d;
      background-color: transparent;
      border-style: none;
      padding: .25rem 0;
      line-height: 1;
      text-decoration: none
  }
  
  .button.is-link.is-alternate {
      color: #fff8f1;
      background-color: transparent
  }
  
  .button.is-link.is-icon {
      grid-column-gap: .5rem;
      grid-row-gap: .5rem
  }
  
  .button.is-icon {
      grid-column-gap: .5rem;
      grid-row-gap: .75rem;
      justify-content: center;
      align-items: center;
      display: flex
  }
  
  .button.is-secondary {
      color: #1d1d1d;
      background-color: transparent
  }
  
  .button.is-secondary.is-alternate {
      color: #fff8f1;
      background-color: transparent;
      border-color: #fff8f1
  }
  
  .button.is-secondary.is-small.is-icon {
      font-family: Generalsans,sans-serif
  }
  
  .button.is-small {
      padding: .5rem 1.25rem
  }
  
  .button.is-small.is-icon {
      text-transform: uppercase;
      font-family: Generalsans,sans-serif
  }
  
  .button.is-small.is-icon.is-alternate {
      font-size: .8rem;
      font-weight: 400;
      width: 8rem;
      text-decoration: none;
      border: 1.5px solid rgba(92, 92, 92, 0.70);
    color: rgba(92, 92, 92, 0.70);
  }
  
  .button.is-small.is-icon.is-alternate.is-secondary {
      border-color: #545454;
      transition: border-color .3s
  }
  
  .button.is-small.is-icon.is-alternate.is-secondary:hover {
      border-color: #fff8f1
  }
  
  .button.is-small.is-icon.is-alternate.button-smaller {
      width: 5rem;
      height: 1.5rem;
      text-transform: none
  }
  
  .button.is-small.is-icon.is-alternate.button-smaller.ro-button {
      width: 6.5rem
  }
  
  .button.is-small.is-icon.is-alternate.button-smaller-footer {
      width: auto;
      height: 2rem;
      color: #ababab;
      text-transform: none;
      background-color: transparent;
      border-color: #535353;
      padding: .7rem 1rem
  }
  
  .button.is-alternate {
      /* color: #000; */
      background-color: white
  }
  
  .text-weight-normal {
      font-family: Satoshi,sans-serif;
      font-weight: 400
  }
  
  .heading-style-h4 {
      text-transform: none;
      font-size: 2rem;
      font-weight: 400;
      line-height: 1.3
  }
  
  .text-style-italic {
      font-family: Generalsans,sans-serif;
      font-style: italic
  }
  
  .icon-1x1-xxsmall {
      width: 1rem;
      height: 1rem
  }
  
  .form-radio-label {
      margin-bottom: 0
  }
  
  .rl-styleguide_item {
      grid-column-gap: 1rem;
      grid-row-gap: 1rem;
      flex-direction: column;
      grid-template-rows: auto;
      grid-template-columns: 1fr;
      grid-auto-columns: 1fr;
      align-content: start;
      justify-content: start;
      align-items: start;
      justify-items: start;
      padding-bottom: 1rem;
      display: grid
  }
  
  .rl-styleguide_item.is-stretch {
      grid-row-gap: 1rem;
      justify-items: stretch
  }
  
  .icon-1x1-xlarge {
      width: 6.5rem;
      height: 6.5rem
  }
  
  .shadow-xlarge {
      box-shadow: 0 24px 48px -12px rgba(0,0,0,.18)
  }
  
  .text-weight-light {
      font-family: Satoshi,sans-serif;
      font-weight: 400
  }
  
  .rl-styleguide_label {
      color: #fff8f1;
      white-space: nowrap;
      cursor: context-menu;
      background-color: #0073e6;
      padding: .25rem .5rem;
      font-size: .75rem;
      display: inline-block
  }
  
  .rl-styleguide_label.is-html-tag {
      background-color: #be4aa5
  }
  
  .text-size-regular {
      /* font-family: Generalsans,sans-serif; */
      font-size: 15px;
      font-family: sans-serif;
  }
  
  .text-size-regular.text-colour-grey {
      color: #ababab
  }
  
  .text-size-regular.text-colour-grey.align-text-center {
      align-self: center
  }
  
  .text-weight-xbold {
      font-family: Satoshi,sans-serif;
      font-weight: 700
  }
  
  .text-align-right {
      text-align: right
  }
  
  .text-weight-bold {
      font-family: Satoshi,sans-serif;
      font-weight: 700
  }
  
  .icon-height-xlarge {
      height: 6.5rem
  }
  
  .heading-style-h3 {
      text-transform: none;
      font-size: 2.5rem;
      font-weight: 500;
      line-height: 1.2
  }
  
  .icon-1x1-large {
      width: 5rem;
      height: 5rem
  }
  
  .form-input {
      height: auto;
      min-height: 2.75rem;
      color: #fff8f1;
      background-color: transparent;
      border: 1px #545454;
      border-radius: 0;
      margin-bottom: 0;
      padding: .5rem .75rem .5rem .5rem;
      font-family: Generalsans,sans-serif;
      font-size: 16px;
      font-weight: 300;
      line-height: 1.6;
      transition: border-color .25s
  }
  
  .form-input:focus {
      border-style: solid solid none;
      border-color: #545454 #545454 #000;
      border-radius: 0
  }
  
  .form-input::-ms-input-placeholder {
      color: #ababab;
      font-size: 14px
  }
  
  .form-input::placeholder {
      color: #ababab;
      font-size: 14px
  }
  
  .form-input.is-text-area {
      height: auto;
      min-height: 11.25rem;
      padding-top: .75rem;
      padding-bottom: .75rem;
      overflow: auto
  }
  
  .form-input.is-select-input {
      color: #fff8f1;
      background-image: none;
      padding-left: .5rem
  }
  
  .rl-styleguide_paste-text {
      color: rgba(0,0,0,.5);
      text-align: center;
      border: 1px dashed rgba(0,0,0,.15);
      padding: 1rem
  }
  
  .rl-styleguide_button-list {
      grid-column-gap: 1rem;
      grid-row-gap: 1.5rem;
      white-space: normal;
      grid-template-rows: auto;
      grid-template-columns: auto;
      grid-auto-columns: max-content;
      grid-auto-flow: row;
      align-items: center;
      justify-items: start;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      display: grid
  }
  
  .rl-styleguide_list {
      grid-column-gap: 1rem;
      grid-row-gap: 1rem;
      grid-template-rows: auto;
      grid-template-columns: 1fr;
      padding-bottom: 4rem
  }
  
  .icon-embed-custom1 {
      width: 1.25rem;
      height: 1.25rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      display: flex
  }
  
  .rl-styleguide_color {
      padding-bottom: 8rem
  }
  
  .rl-styleguide_color.is-black {
      background-color: #1d1d1d
  }
  
  .rl-styleguide_color.is-gray {
      background-color: #f4f4f4
  }
  
  .rl-styleguide_color.is-white {
      background-color: #fff8f1
  }
  
  .button-group {
      grid-column-gap: 2rem;
      grid-row-gap: 1rem;
      flex-wrap: wrap;
      align-items: center;
      display: flex
  }
  
  .button-group.is-center {
      justify-content: center
  }
  
  .button-group.margin-top.margin-large.absolute-button {
      position: absolute;
      top: auto;
      bottom: 0;
      left: 0;
      right: 0%
  }
  
  .text-color-white {
      color: #fff8f1
  }
  
  .background-color-white {
      background-color: #fff8f1
  }
  
  .text-weight-semibold {
      font-family: Satoshi,sans-serif;
      font-weight: 600
  }
  
  .text-style-muted {
      opacity: .6;
      font-family: Satoshi,sans-serif
  }
  
  .text-style-nowrap {
      white-space: nowrap;
      font-family: Satoshi,sans-serif
  }
  
  .text-align-left {
      text-align: left
  }
  
  .icon-embed-large {
      width: 5rem;
      height: 5rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      display: flex
  }
  
  .shadow-xxsmall {
      box-shadow: 0 1px 2px rgba(0,0,0,.05)
  }
  
  .text-color-black {
      color: #1d1d1d
  }
  
  .text-color-black.text-size-regular.font-satoshi {
      font-family: Satoshi,sans-serif;
      font-weight: 500
  }
  
  .icon-embed-xxsmall {
      width: 1rem;
      height: 1rem;
      color: #1d1d1d;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      display: flex
  }
  
  .icon-embed-xsmall {
      width: 1.4rem;
      height: 1.4rem;
      /* color: #1d1d1d; */
      flex-direction: column;
      flex: none;
      justify-content: center;
      align-items: center;
      display: flex
  }
  
  .icon-embed-xsmall.icon-dark {
      color: #1d1d1d
  }
  
  .icon-embed-xsmall.icon-grey {
      color: #ababab
  }
  
  .icon-embed-xsmall.is-white {
      color: #fff8f1
  }
  
  .icon-height-xxsmall {
      height: 1rem
  }
  
  .background-color-black {
      color: #fff8f1;
      background-color: #1d1d1d
  }
  
  .shadow-xsmall {
      box-shadow: 0 1px 3px rgba(0,0,0,.1),0 1px 2px rgba(0,0,0,.06)
  }
  
  .icon-1x1-custom1 {
      width: 1.25rem;
      height: 1.25rem
  }
  
  .rl-styleguide_subheading {
      z-index: 3;
      color: #000;
      background-color: #d3d3d3;
      padding: .25rem .5rem;
      position: -webkit-sticky;
      position: sticky;
      top: 2rem
  }
  
  .form-radio-icon {
      width: 1.125rem;
      height: 1.125rem;
      min-height: 1.125rem;
      min-width: 1.125rem;
      cursor: pointer;
      border: 1px solid #1d1d1d;
      border-radius: 100px;
      margin-top: 0;
      margin-left: -1.125rem;
      margin-right: .5rem
  }
  
  .form-radio-icon.w--redirected-checked {
      background-color: #fff;
      background-image: none;
      border-width: 6px;
      border-color: #000
  }
  
  .form-radio-icon.w--redirected-focus {
      box-shadow: none;
      border-color: #000;
      margin-top: 0
  }
  
  .rl-styleguide_shadows-list {
      grid-column-gap: 1.5rem;
      grid-row-gap: 1.5rem;
      grid-template-rows: auto;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      align-items: start
  }
  
  .form {
      grid-column-gap: 1.5rem;
      grid-row-gap: 1.5rem;
      grid-template-rows: auto;
      grid-template-columns: 1fr;
      grid-auto-columns: 1fr;
      display: grid
  }
  
  .text-style-allcaps {
      text-transform: uppercase;
      font-family: Satoshi,sans-serif
  }
  
  .rl-styleguide_callout-link {
      background-color: #fff;
      background-image: linear-gradient(135deg,#ff7448,#ff4848 50%,#6248ff);
      justify-content: space-between;
      align-items: center;
      padding: 1px;
      display: flex
  }
  
  .icon-height-custom1 {
      height: 1.25rem
  }
  
  .text-align-center {
      text-align: center
  }
  
  .text-size-small {
      font-family: Generalsans,sans-serif;
      font-size: .875rem
  }
  
  .text-size-small.text-style-allcaps {
      /* color: #fff8f1 */
  }
  
  .text-size-small.text-style-allcaps.text-colour-grey {
      color: #ababab
  }
  
  .text-size-small.text-style-allcaps._0-9rem-text {
      font-size: .9rem
  }
  
  .text-size-small.text-colour-grey {
      color: #ababab
  }
  
  .class-label-column {
      grid-column-gap: .75rem;
      grid-row-gap: .75rem;
      grid-template-rows: auto;
      grid-template-columns: 1fr;
      grid-auto-columns: 1fr;
      display: grid
  }
  
  .form-field-wrapper {
      position: relative
  }
  
  .icon-height-small {
      height: 2rem
  }
  
  .icon-height-large {
      height: 5rem
  }
  
  .shadow-large {
      box-shadow: 0 20px 24px -4px rgba(0,0,0,.08),0 8px 8px -4px rgba(0,0,0,.03)
  }
  
  .icon-embed-xlarge {
      width: 6.5rem;
      height: 6.5rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      display: flex
  }
  
  .icon-1x1-small {
      width: 2rem;
      height: 2rem
  }
  
  .class-label-row {
      grid-column-gap: .25rem;
      grid-row-gap: .25rem;
      flex-wrap: wrap;
      align-items: center;
      display: flex
  }
  
  .text-rich-text h1 {
      margin-top: 2rem;
      margin-bottom: 1rem;
      font-weight: 500
  }
  
  .text-rich-text blockquote {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem
  }
  
  .text-rich-text h2 {
      margin-top: 1.5rem;
      margin-bottom: 1rem;
      font-weight: 500
  }
  
  .text-rich-text h3,.text-rich-text h4 {
      margin-top: 1.5rem;
      margin-bottom: 1rem
  }
  
  .text-rich-text h5,.text-rich-text h6 {
      margin-top: 1.25rem;
      margin-bottom: 1rem
  }
  
  .text-rich-text p {
      margin-bottom: 1rem;
      font-family: Generalsans,sans-serif
  }
  
  .text-rich-text a {
      font-family: Generalsans,sans-serif;
      text-decoration: underline
  }
  
  .text-rich-text figcaption {
      text-align: left;
      border-left: 2px solid #1d1d1d;
      margin-top: .5rem;
      padding-left: .5rem;
      font-size: .875rem
  }
  
  .text-style-2lines {
      font-family: Generalsans,sans-serif
  }
  
  .icon-height-medium {
      height: 3rem
  }
  
  .form-checkbox {
      align-items: center;
      margin-bottom: 0;
      padding-left: 1.25rem;
      display: flex
  }
  
  .shadow-small {
      box-shadow: 0 4px 8px -2px rgba(0,0,0,.1),0 2px 4px -2px rgba(0,0,0,.06)
  }
  
  .icon-1x1-medium {
      width: 3rem;
      height: 3rem
  }
  
  .shadow-medium {
      box-shadow: 0 12px 16px -4px rgba(0,0,0,.08),0 4px 6px -2px rgba(0,0,0,.03)
  }
  
  .text-style-3lines {
      font-family: Generalsans,sans-serif
  }
  
  .text-size-large {
      font-family: Generalsans,sans-serif;
      font-size: 1.25rem
  }
  
  .icon-height-xsmall {
      height: 1.5rem
  }
  
  .rl-styleguide_spacing-all {
      display: none
  }
  
  .padding-section-small {
      padding-top: 3rem;
      padding-bottom: 3rem
  }
  
  .rl-styleguide_empty-box {
      z-index: -1;
      height: 3rem;
      min-width: 3rem;
      background-color: #eee;
      position: relative
  }
  
  .rl-styleguide_spacing {
      border: 1px dashed #d3d3d3
  }
  
  .padding-global {
      padding-left: 2%;
      padding-right: 2%
  }
  
  .padding-section-medium {
      padding-top: 5rem;
      padding-bottom: 5rem
  }
  
  .padding-section-large {
      padding-top: 7rem;
      padding-bottom: 7rem
  }
  
  .padding-section-large.no-padding-bottom {
      padding-bottom: 0
  }
  
  .navbar1_container {
      width: 100%;
      height: 100%;
      justify-content: space-between;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      display: flex
  }
  
  .navbar1_dropdown-link {
      padding: .5rem 1rem
  }
  
  .navbar1_dropdown-link.w--current {
      color: #1d1d1d
  }
  
  .navbar1_menu {
      align-items: center;
      display: flex;
      position: static
  }
  
  .navbar1_menu-button {
      padding: 0
  }
  
  .navbar1_dropdown-list.w--open {
      background-color: #fff8f1;
      border: 1px solid #1d1d1d;
      padding: .5rem
  }
  
  .navbar1_dropdown-toggle {
      padding: .5rem 2.5rem .5rem 1rem
  }
  
  .navbar1_component {
      width: 100%;
      height: auto;
      min-height: 4.5rem;
      -webkit-backdrop-filter: blur(25px);
      backdrop-filter: blur(25px);
      color: rgba(92, 92, 92, 0.80);
      background-color: #fff;
      border-bottom: .1px solid #545454;
      align-items: center;
      padding-left: 2%;
      padding-right: 2%;
      display: flex;
      position: fixed;
      top: 0;
  }
  
  .dropdown-icon {
      width: 1rem;
      height: 1rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: auto;
      margin-bottom: auto;
      margin-right: 1rem;
      display: flex;
      position: absolute;
      top: 0%;
      bottom: 0%;
      left: auto;
      right: 0%
  }
  
  .navbar1_logo-link {
      padding-left: 0
  }
  
  .navbar1_link {
      /* color: #fff8f1; */
      text-transform: uppercase;
      border-radius: 20rem;
      margin-right: .25rem;
      padding: .5rem 5rem;
      font-family: Generalsans,sans-serif;
      font-size: .9rem;
      transition: background-color .3s;
      color: rgba(92, 92, 92, 0.80);
  }
  
  .navbar1_link:hover {
      background-color: #2d2d2d
  }
  
  .navbar1_link.w--current {
      /* color: #fff8f1; */
      /* border: .1px solid #545454; */
      border-radius: 20rem;
      text-decoration: none;
      color: rgba(92, 92, 92, 0.80);
  }
  
  .navbar1_link.text-colour-xx {
      height: 1.9rem;
      color: #1d1d1d;
      background-color: #fff8f1;
      border-radius: 20rem;
      justify-content: center;
      align-items: center;
      margin-right: 0;
      font-style: normal;
      font-weight: 400;
      display: flex
  }
  
  .navbar1_link.text-colour-xx.transparent {
      color: #fff8f1;
      background-color: transparent
  }
  
  .navbar1_link.text-colour-xx.transparent:hover {
      background-color: #3a3a3a
  }
  
  .navbar1_link.language-ro {
      height: 1.9rem;
      border-radius: 20rem;
      justify-content: center;
      align-items: center;
      margin-right: 0;
      transition: all .35s;
      display: flex
  }
  
  .navbar1_link.language-ro:hover {
      color: #fff8f1;
      background-color: #3a3a3a;
      font-weight: 400
  }
  
  .navbar1_link.language-ro.ro-page,.navbar1_link.language-ro.ro-page.w--current {
      color: #1d1d1d;
      background-color: #fff8f1
  }
  
  .navbar1_link.all-caps-link {
      text-transform: uppercase;
      margin-right: .5rem;
      font-family: Satoshi,sans-serif
  }
  
  .navbar1_link.all-caps-link._9--margin {
      margin-left: 0%
  }
  
  .navbar1_link.all-caps-link._10--margin {
      margin-left: 9%
  }
  
  .navbar1_logo {
      width: 11.5625rem;
      height: 1.5625rem
  }
  
  .header_content-bottom {
      z-index: 1;
      width: 96vw;
      height: 80vh;
      border-radius: 10px;
      flex-direction: column;
      justify-content: flex-start;
      margin-bottom: -10vh;
      display: flex;
      position: -webkit-sticky;
      position: sticky;
      top: 10vh;
      overflow: hidden
  }
  
  .header_content {
      z-index: 0;
      width: 96vw;
      max-width: 96vw;
      min-height: 80vh;
      justify-content: flex-start;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      padding-top: 15rem;
      padding-bottom: 5rem;
      display: flex;
      position: -webkit-sticky;
      position: sticky;
      top: 0
  }
  
  .header_ix-trigger {
      z-index: -1;
      margin-top: 100vh;
      position: absolute;
      top: 0%;
      bottom: 0%;
      left: 0%;
      right: 0%;
      overflow: hidden
  }
  
  .header_lightbox-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
      overflow: hidden
  }
  
  .header_component {
      height: 300vh;
      flex-direction: column;
      align-items: center;
      display: flex;
      position: relative
  }
  
  .custom-h1 {
      font-size: 7.19vw;
      font-weight: 500;
      line-height: 1;
      overflow: visible
  }
  
  .hero-info-wrapper {
      justify-content: flex-end;
      align-items: center;
      display: flex
  }
  
  .hero-info-wrapper.vertical-align-bottom {
      grid-column-gap: 1rem;
      align-items: flex-end
  }
  
  .button-icon {
      width: 1.5rem;
      height: 1.4rem;
      object-fit: fill;
      flex-direction: row;
      flex: 0 auto;
      justify-content: flex-end;
      align-items: flex-start;
      margin: 0 .125rem 0 0;
      padding: 0;
      display: flex;
      overflow: hidden
  }
  
  .white-line {
      z-index: 999;
      width: 100%;
      height: 1px;
      color: #fff;
    
      background-color: #5C5C5C;
      position: relative
  }
  
  .white-line.scroll-into {
      height: 1px;
      background-color:#5C5C5C;
      opacity: 0.2;
      
      
  }
  
  .white-line.scroll-into._0-opacity {
      opacity: 1
  }
  
  .white-line.scroll-into.hide-mobile-portrait._1px {
      height: 1.3px
  }
  
  .white-line.not-visible-mobile {
      margin-left: auto;
      margin-right: auto
  }
  
  .white-line.not-visible-mobile.about-line {
      width: 95vw
  }
  
  .layout_card-content {
      flex-direction: column;
      flex: 1;
      justify-content: space-between;
      padding: 2rem 0 0;
      display: flex
  }
  
  .layout_image-wrapper {
      width: 100%;
      height: 50vh;
      /* border-radius: 10px; */
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      padding: 1px;
      display: flex;
      position: relative;
      overflow: hidden
  }
  
  .layout_image-wrapper._100--height {
      height: 100%
  }
  
  .layout_image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      /* border-radius: 10px; */
      overflow: hidden
  }
  
  .layout_row {
      grid-column-gap: 1.5rem;
      grid-row-gap: 1rem;
      grid-template-rows: auto;
      grid-template-columns: 1fr 1px 1fr;
      grid-auto-columns: 1fr;
      align-items: stretch;
      display: grid
  }
  
  .layout_card {
      /* border: 1px solid #1d1d1d; */
      /* border-radius: 10px; */
      flex-direction: column;
      grid-template-rows: auto;
      grid-template-columns: 1fr 1fr;
      grid-auto-columns: 1fr;
      align-items: stretch;
      display: flex;
      overflow: visible
  }
  
  .layout_component {
      grid-column-gap: 2rem;
      grid-row-gap: 1.5rem;
      grid-template-rows: auto;
      grid-template-columns: 1fr
  }
  
  .vertical-line {
      width: 1px;
      height: 100%;
      background-color: #545454;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      display: flex
  }
  
  .image-cover {
      width: 100%;
      height: 0%;
      /* background-color: #1d1d1d; */
      flex: 0 auto;
      align-items: flex-start;
      display: flex;
      position: absolute;
      top: auto;
      bottom: 0%;
      left: 0%;
      right: 0%;
      overflow: hidden
  }
  
  .text-size-xtiny {
      font-size: .9rem
  }
  
  .language-wrapper {
      height: 2.5rem;
      grid-column-gap: .5rem;
      border: 1px solid #595959;
      border-radius: 20rem;
      justify-content: center;
      align-items: center;
      margin-left: 2rem;
      padding: 0 .2rem;
      display: flex
  }
  
  .language-wrapper.no-margin-responsive {
      height: 2.5rem;
      border-color: #545454;
      padding-left: .25rem;
      padding-right: .25rem
  }
  
  .language-wrapper.no-margin-responsive.mobile-language {
      display: none
  }
  
  .about-info-wrapper {
      background-color: transparent;
      border-radius: 10px;
      padding-right: 2rem;
      position: relative
  }
  
  .parallax-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      overflow: visible
  }
  
  .paragraph,.link,.paragraph-2,.paragraph-3,.paragraph-4,.paragraph-5,.paragraph-6,.paragraph-7,.paragraph-8 {
      font-family: Generalsans,sans-serif
  }
  
  .partner-details {
      justify-content: space-between;
      align-items: center;
      padding-top: 1rem;
      padding-bottom: 1rem;
      display: flex;
      position: relative;
      overflow: hidden
  }
  
  .partner-logo {
      width: 8rem;
      height: 4.0625rem;
      border-radius: 10px
  }
  
  .section-number-wrapper {
      grid-column-gap: 1rem;
      justify-content: flex-start;
      align-items: center;
      display: flex
  }
  
  .section-number-wrapper.margin-bottom.margin-medium {
      grid-column-gap: .75rem
  }
  
  .number-circle {
      width: 1.5rem;
      height: 1.5rem;
      background-color: #fff8f1;
      border: 1px #000;
      border-radius: 20rem;
      justify-content: center;
      align-items: center;
      display: flex
  }
  
  .section-name-wrapper {
      height: 1.5rem;
      border: 1px solid #545454;
      border-radius: 20rem;
      justify-content: center;
      align-items: center;
      padding-bottom: 1px;
      padding-left: 1rem;
      padding-right: 1rem;
      display: flex
  }
  
  .text-span-7,.text-span-8 {
      color: #fff
  }
  
  .footer-wrapper {
      padding-bottom: 0
  }
  
  .footer-bottom-links {
      height: 3rem;
      justify-content: space-between;
      align-items: center;
      padding-top: 0;
      padding-bottom: 0;
      display: flex;
      position: relative;
      overflow: hidden
  }
  
  .footer-legal {
      grid-column-gap: .5rem;
      justify-content: space-between;
      align-items: center;
      display: flex
  }
  
  .footer-links-full-width {
      width: 100%;
      justify-content: space-between;
      align-items: center;
      padding-left: 1.5rem;
      display: flex
  }
  
  .footer-mid-details {
      height: 30rem;
      justify-content: space-between;
      align-items: center;
      padding-top: 0;
      padding-bottom: 0;
      display: flex;
      position: relative;
      overflow: hidden
  }
  
  .footer-details-full-width {
      width: 100%;
      height: 100%;
      justify-content: space-between;
      align-items: center;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      display: flex
  }
  
  .footer-left-wrapper {
      width: 44vw;
      height: 100%;
      flex-direction: column;
      justify-content: space-between;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      display: flex
  }
  
  .footer-right-wrapper {
      width: 47vw;
      height: 100%;
      grid-column-gap: 16px;
      grid-row-gap: 16px;
      grid-template-rows: auto;
      grid-template-columns: 1fr 1fr;
      grid-auto-columns: 1fr;
      justify-content: space-between;
      align-items: flex-start;
      padding-top: 0;
      padding-bottom: 0;
      display: flex
  }
  
  .quick-links {
      width: 50%;
      flex-direction: column;
      justify-content: flex-start;
      padding-left: 1rem;
      display: flex
  }
  
  .social-links {
      width: 50%;
      flex-direction: column;
      justify-content: flex-start;
      padding-left: 1rem;
      padding-right: 0;
      display: flex
  }
  
  .footer-logo {
      width: 18.75rem;
      height: 2.1875rem;
      margin-bottom: 3rem
  }
  
  .button-group-footer {
      grid-column-gap: 2rem;
      grid-row-gap: 1rem;
      flex-flow: column wrap;
      align-items: flex-start;
      display: flex
  }
  
  .text-span-10 {
      color: #5a5a5a
  }
  
  .text-span-13 {
      color: #ababab
  }
  
  .about-hero-info-wrapper {
      justify-content: flex-end;
      align-items: flex-start;
      display: flex
  }
  
  .about-hero-info-wrapper.margin-top.margin-large {
      justify-content: space-between
  }
  
  .about-hero-info-wrapper.margin-top.margin-large.max-width-custom {
      max-width: 83vw
  }
  
  .about-image-wrapper {
      width: 100%;
      height: 90vh;
      border-radius: 10px;
      margin-top: -2vh;
      margin-left: auto;
      margin-right: auto;
      overflow: hidden
  }
  
  .header_content-copy {
      z-index: 0;
      width: 96vw;
      max-width: 96vw;
      min-height: 80vh;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      padding-top: 15rem;
      padding-bottom: 5rem;
      display: flex;
      position: static;
      top: 0
  }
  
  .about-page-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px
  }
  
  .services-layout_component {
      width: 100%;
      height: 60vh;
      border-top: 1px solid #545454;
      border-bottom: 1px solid #545454;
      border-left: 1px solid #545454;
      border-radius: 10px;
      display: flex;
      overflow: hidden
  }
  
  .services-layout_accordion {
      width: 5rem;
      height: 60vh;
      min-width: 5rem;
      border-right: 1px solid #545454;
      flex-direction: row;
      justify-content: flex-start;
      display: flex;
      overflow: hidden
  }
  
  .services-layout_accordion.active {
      width: 100%
  }
  
  .services-layout_column {
      width: 5rem;
      height: 60vh;
      min-width: 5rem;
      cursor: pointer;
      border-right: 1px solid #545454;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding-top: 2rem;
      padding-bottom: 2rem;
      display: flex;
      position: relative
  }
  
  .services-layout_column-text {
      white-space: nowrap;
      font-family: Satoshi,sans-serif;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1.4
  }
  
  .layout351_column-vertical-text {
      text-transform: uppercase;
      margin-left: auto;
      margin-right: auto;
      font-family: Generalsans,sans-serif;
      font-size: 1.5rem;
      font-weight: 300;
      line-height: 1.4;
      transform: rotate(180deg)
  }
  
  .layout351_column-horiztonal-text {
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1.4;
      display: none
  }
  
  .layout351_column-content {
      height: 100%;
      min-width: 200vw;
      overflow: hidden
  }
  
  .layout351_content-wrapper {
      width: 37%;
      height: 100%;
      flex-direction: column;
      flex: none;
      justify-content: flex-start;
      align-items: center;
      padding: 4rem 3rem;
      display: flex;
      position: relative
  }
  
  .layout351_image-wrapper {
      width: 30vw;
      height: 45vh;
      border-radius: 10px;
      margin-bottom: 0;
      overflow: hidden
  }
  
  .layout351_image {
      width: 100%;
      height: 100%;
      object-fit: cover
  }
  
  .services-content-wrapper {
      display: flex;
      position: static
  }
  
  .process-layout_component {
      grid-column-gap: 5rem;
      grid-row-gap: 4rem;
      grid-template-rows: auto;
      grid-template-columns: 1fr 1fr;
      grid-auto-columns: 1fr;
      align-items: start;
      display: grid
  }
  
  .process-layout_content-left {
      position: -webkit-sticky;
      position: sticky;
      top: 30%
  }
  
  .process-layout_content-item {
      /* background-color: #1d1d1d; */
      border: 2px solid #005E8C;
      background-color: #fff;
      border-radius: 10px;
      /* margin-bottom: 2rem; */
      color: #005E8C;
      padding: 2rem;
      position: -webkit-sticky;
      position: sticky
  }
  
  .process-layout_content-item.content-item-1 {
      /* background-color: #1d1d1d; */
      background-color: #fff;
      border: 2px solid #005E8C;
      border-radius: 10px;
      top: 30%
  }
  
  .process-layout_content-item.content-item-2 {
      background-color: #fff;
      top: 32%
  }
  
  .process-layout_content-item.content-item-3 {
      top: 34%
  }
  
  .process-layout_content-item.content-item-4 {
      top: 36%
  }
  
  .about-section-info-wrapper {
      justify-content: flex-end;
      align-items: flex-start;
      display: flex
  }
  
  .about-section-info-wrapper.margin-top.margin-large {
      justify-content: space-between
  }
  
  .about-section-copy {
      width: 100%;
      grid-column-gap: 4rem;
      justify-content: space-between;
      align-items: flex-end;
      display: flex;
      position: relative
  }
  
  .arrow-svg {
      width: 6.25rem;
      height: 6.25rem;
      opacity: .5;
      position: static;
      top: 0%;
      bottom: auto;
      right: 0%;
      right: auto
  }
  
  .about-intro {
      grid-column-gap: 4rem;
      display: flex
  }
  
  .projects-layout_component {
      grid-column-gap: 2rem;
      grid-row-gap: 1.5rem;
      grid-template-rows: auto;
      grid-template-columns: 1fr
  }
  
  .projects-layout_row {
      grid-column-gap: 1.5rem;
      grid-row-gap: 1rem;
      grid-template-rows: auto;
      grid-template-columns: 1fr 1px 1fr 1px 1fr;
      grid-auto-columns: 1fr;
      align-items: stretch;
      display: grid
  }
  
  .projects-layout_card {
      border: 1px solid #1d1d1d;
      border-radius: 10px;
      flex-direction: column;
      grid-template-rows: auto;
      grid-template-columns: 1fr 1fr;
      grid-auto-columns: 1fr;
      align-items: stretch;
      display: flex;
      overflow: visible
  }
  
  .projects-layout_card.half-top {
      height: 65%
  }
  
  .projects-layout_card.half-bottom {
      height: 65%;
      justify-content: flex-end
  }
  
  .project-layout_image-wrapper {
      width: 100%;
      height: 65vh;
      border-radius: 10px;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      padding: 1px;
      display: flex;
      position: relative;
      overflow: hidden
  }
  
  .content_component {
      grid-column-gap: 2rem;
      grid-row-gap: 4rem;
      grid-template-rows: auto;
      grid-template-columns: 1fr 1px 1fr;
      grid-auto-columns: 1fr;
      align-items: start;
      display: grid
  }
  
  .contact_content {
      height: 100%
  }
  
  .contact_contact-list {
      max-width: 35rem;
      grid-column-gap: 1rem;
      grid-row-gap: 2rem;
      grid-template-rows: auto;
      grid-template-columns: 1fr;
      grid-auto-columns: 1fr;
      padding-top: .5rem;
      padding-bottom: .5rem;
      display: grid
  }
  
  .contact_item {
      display: flex
  }
  
  .contact_icon-wrapper {
      flex: none;
      align-self: flex-start;
      margin-right: 1rem
  }
  
  .contact_form-block {
      flex-direction: column;
      align-items: stretch;
      margin-bottom: 0
  }
  
  .contact_form {
      grid-column-gap: 1.5rem;
      grid-row-gap: 1.5rem;
      grid-template-rows: auto auto;
      grid-template-columns: 1fr;
      grid-auto-columns: 1fr;
      display: grid
  }
  
  .form-field-col {
      grid-column-gap: 2rem;
      grid-row-gap: 1.5rem;
      grid-template-rows: auto;
      grid-template-columns: 1fr 1fr;
      grid-auto-columns: 1fr;
      display: grid
  }
  
  .success-message {
      background-color: #f4f4f4;
      padding: 1.5rem
  }
  
  .success-text {
      color: #1d1d1d;
      font-weight: 600
  }
  
  .error-message {
      margin-top: 1.5rem;
      padding: .875rem 1rem
  }
  
  .error-text {
      color: #e23939
  }
  
  .contact-details-icon {
      width: 2rem;
      height: 2rem;
      background-color: #fff8f1;
      border: 1px #000;
      border-radius: 20rem;
      justify-content: center;
      align-items: center;
      display: flex
  }
  
  .text-span-14,.text-span-15,.text-span-16,.text-span-17,.text-span-18 {
      color: #ababab
  }
  
  .contact-layout_row {
      grid-column-gap: 1.5rem;
      grid-row-gap: 1rem;
      grid-template-rows: auto;
      grid-template-columns: 1fr 1px 1fr;
      grid-auto-columns: 1fr;
      align-items: stretch;
      display: grid
  }
  
  .contact-layout_row.row-mobile {
      grid-template-columns: 1fr 1fr
  }
  
  .contact-layout_card {
      border: 1px solid #1d1d1d;
      border-radius: 10px;
      flex-direction: column;
      grid-template-rows: auto;
      grid-template-columns: 1fr 1fr;
      grid-auto-columns: 1fr;
      align-items: stretch;
      display: flex;
      overflow: visible
  }
  
  .contact-layout_image-wrapper {
      width: 100%;
      height: 70vh;
      border-radius: 10px;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      padding: 1px;
      display: flex;
      position: relative;
      overflow: hidden
  }
  
  .text-span-19 {
      font-family: Satoshi,sans-serif;
      font-weight: 500;
      text-decoration: underline
  }
  
  .section_header-project {
      padding-top: 5.5rem;
      overflow: hidden
  }
  
  .project_header_component {
      flex-direction: column;
      display: flex
  }
  
  .project-header_image-wrapper {
      width: 96vw;
      flex: 1;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      overflow: hidden
  }
  
  .project-header_background-image-wrapper {
      z-index: -1;
      width: 96vw;
      height: 100vh;
      border: 1px solid #1d1d1d;
      border-radius: 10px;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      top: 0%;
      bottom: 0%;
      left: 0%;
      right: 0%;
      overflow: hidden
  }
  
  .project-header_background-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: static;
      top: 0%;
      bottom: 0%;
      left: 0%;
      right: 0%
  }
  
  .project-header_background-image.parallax-image {
      border-radius: 10px;
      overflow: hidden
  }
  
  .project-header_content-wrapper {
      grid-column-gap: 5rem;
      grid-row-gap: 1.5rem;
      flex-direction: column;
      grid-template-rows: auto;
      grid-template-columns: 1fr 1fr;
      grid-auto-columns: 1fr;
      align-items: start;
      display: flex
  }
  
  .project-small-details {
      height: 2rem;
      border: 1px solid #545454;
      border-radius: 20rem;
      justify-content: center;
      align-items: center;
      padding-bottom: 1px;
      padding-left: 1rem;
      padding-right: 1rem;
      display: flex
  }
  
  .project-layout-component {
      height: 75vh;
      grid-column-gap: 2rem;
      grid-row-gap: 1.5rem;
      grid-template-rows: auto;
      grid-template-columns: 1fr
  }
  
  .project-layout_row {
      grid-column-gap: 7rem;
      grid-row-gap: 1rem;
      grid-template-rows: auto;
      grid-template-columns: 1fr 1fr;
      grid-auto-columns: 1fr;
      align-items: stretch;
      display: grid
  }
  
  .project-layout_card {
      height: 75vh;
      grid-row-gap: 7rem;
      border: 1px solid #1d1d1d;
      border-radius: 10px;
      flex-direction: column;
      grid-template-rows: auto;
      grid-template-columns: 1fr 1fr;
      grid-auto-columns: 1fr;
      align-items: stretch;
      display: flex;
      overflow: visible
  }
  
  .layout_project-image-wrapper {
      width: 100%;
      height: 75vh;
      border-radius: 10px;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      padding: 1px;
      display: flex;
      position: relative;
      overflow: hidden
  }
  
  .layout_project-image-wrapper.layout-left {
      width: 65%
  }
  
  .layout_project-image-wrapper.layout-right {
      width: 50%;
      align-self: flex-end
  }
  
  .project-layout_image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
      overflow: hidden
  }
  
  .gallery_component {
      height: 400vh
  }
  
  .gallery_horizontal-scroll-wrapper {
      width: 100%;
      height: 100vh;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding-left: 2%;
      padding-right: 5%;
      display: flex;
      position: -webkit-sticky;
      position: sticky;
      top: 2rem;
      overflow: hidden
  }
  
  .gallery_horizontal-scroll-content {
      width: 400vh;
      grid-column-gap: 2rem;
      align-items: center;
      display: flex
  }
  
  .gallery_image-wrapper {
      width: 100%;
      height: 100%;
      max-width: 100%;
      position: relative;
      overflow: hidden
  }
  
  .gallery_image {
      width: 80vw;
      height: 80vh;
      object-fit: cover;
      border-radius: 10px
  }
  
  .project-info-wrapper {
      grid-row-gap: 2rem;
      flex-direction: column;
      align-items: flex-end;
      display: flex
  }
  
  .footer-links-full-width-ro {
      width: 100%;
      justify-content: space-between;
      align-items: center;
      padding-left: 1.5rem;
      display: flex
  }
  
  .footer-legal-ro {
      grid-column-gap: .5rem;
      justify-content: space-between;
      align-items: center;
      display: flex
  }
  
  .cookies-wrapper {
      display: flex
  }
  
  .fs-cc-banner2_component {
      z-index: 1500;
      height: auto;
      max-width: none;
      color: #fff;
      background-color: #fff;
      border: 1px #909090;
      border-radius: 100rem;
      justify-content: space-between;
      margin-left: auto;
      margin-right: auto;
      padding: .5rem .5rem .5rem 1rem;
      display: none;
      position: fixed;
      top: auto;
      bottom: 1rem;
      left: 2.5%;
      right: 2.5%
  }
  
  .fs-cc-banner2_container {
      width: 100%;
      max-width: none;
      grid-column-gap: 2rem;
      grid-row-gap: 5px;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      display: flex
  }
  
  .fs-cc-banner2_text {
      color: #1d1d1d;
      font-family: Generalsans,sans-serif;
      font-size: .875rem
  }
  
  .fs-cc-banner2_buttons-wrapper {
      grid-column-gap: 1.25rem;
      flex: none;
      grid-template-rows: auto;
      grid-template-columns: auto auto;
      grid-auto-columns: 1fr;
      align-items: center;
      margin-top: 0;
      display: flex
  }
  
  .fs-cc-banner_text-link {
      color: #000;
      border-bottom: 1px #e7e7e7;
      margin-right: 0;
      font-family: Generalsans,sans-serif;
      font-size: .875rem;
      font-weight: 300;
      text-decoration: none
  }
  
  .fs-cc-banner2_button {
      min-width: 6rem;
      color: #fff;
      text-align: center;
      text-transform: none;
      background-color: #1d1d1d;
      border-radius: 100px;
      flex: 1;
      margin-left: 0;
      margin-right: 0;
      padding: .125rem 1.25rem;
      font-family: Generalsans,sans-serif;
      font-size: .875rem;
      font-weight: 400
  }
  
  .fs-cc-banner2_button.fs-cc-button-alt {
      min-width: 6rem;
      color: #fff;
      background-color: #ababab;
      margin-right: 0;
      font-family: Generalsans,sans-serif;
      font-weight: 400
  }
  
  .fs-cc-prefs_component {
      z-index: 997;
      width: 100%;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 0;
      padding: 2rem;
      display: none;
      position: fixed;
      top: 0%;
      bottom: 0%;
      left: 0%;
      right: 0%
  }
  
  .fs-cc-prefs_form {
      width: 100%;
      height: 100%;
      max-height: 70vh;
      max-width: 36rem;
      color: #121212;
      background-color: #fff;
      border-radius: .625rem;
      margin-bottom: 0;
      font-family: Satoshi,sans-serif;
      position: relative
  }
  
  .fs-cc-prefs_close {
      z-index: 1;
      color: #333;
      cursor: pointer;
      background-color: #ababab;
      border-radius: 100%;
      padding: .625rem;
      font-size: 1.25rem;
      text-decoration: none;
      position: absolute;
      top: -.75rem;
      bottom: auto;
      left: auto;
      right: -.75rem;
      box-shadow: -1px 1px 12px rgba(51,51,51,.1)
  }
  
  .fs-cc-prefs_close-icon {
      width: 1rem;
      height: 1rem;
      color: #fff
  }
  
  .fs-cc-prefs_content {
      height: 100%;
      padding: 2.5rem 2rem;
      overflow: visible
  }
  
  .fs-cc-prefs_space-small {
      margin-bottom: .75rem
  }
  
  .fs-cc-prefs_title {
      color: #121212;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1.2em
  }
  
  .fs-cc-prefs_text {
      color: #656565;
      font-size: .75rem
  }
  
  .fs-cc-prefs_space-medium {
      margin-bottom: 2.5rem
  }
  
  .fs-cc-prefs_button {
      color: #fff;
      background-color: #1d1d1d;
      border: 1px solid #121212;
      border-radius: 999rem;
      margin-right: .5rem;
      padding: .625rem 1.5rem;
      font-family: Satoshi,sans-serif;
      font-size: .875rem;
      font-weight: 700
  }
  
  .fs-cc-prefs_button.fs-cc-button-alt {
      color: #121212;
      background-color: #e7e7e7;
      border: 1px #cacaca;
      font-family: Satoshi,sans-serif
  }
  
  .fs-cc-prefs_option {
      border-bottom: 1px solid rgba(51,51,51,.15);
      padding-top: 1rem;
      padding-bottom: 1rem
  }
  
  .fs-cc-prefs_toggle-wrapper {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: .5rem;
      display: flex
  }
  
  .fs-cc-prefs_label {
      color: #121212;
      margin-top: 0;
      margin-bottom: 0;
      margin-right: 2rem;
      font-family: Satoshi,sans-serif;
      font-size: 1rem;
      font-weight: 700
  }
  
  .bold-text {
      font-family: Satoshi,sans-serif
  }
  
  .fs-cc-prefs_checkbox-field {
      width: 2.75rem;
      height: 1.5rem;
      background-color: #ccc;
      border-radius: 999rem;
      flex-direction: row;
      justify-content: flex-start;
      align-items: stretch;
      margin-bottom: 0;
      padding: .125rem;
      display: flex;
      position: relative
  }
  
  .fs-cc-prefs_checkbox {
      z-index: 1;
      width: 100%;
      height: 100%;
      opacity: 1;
      cursor: pointer;
      margin-top: 0;
      margin-left: 0;
      position: absolute;
      top: 0%;
      bottom: 0%;
      left: 0%;
      right: 0%
  }
  
  .fs-cc-prefs_checkbox-label {
      display: none
  }
  
  .fs-cc-prefs_toggle {
      width: 1.25rem;
      height: 1.25rem;
      background-color: #fff;
      border-radius: 999px
  }
  
  .fs-cc-prefs_buttons-wrapper {
      grid-template-rows: auto;
      grid-template-columns: 1fr 1fr;
      grid-auto-columns: 1fr;
      justify-content: flex-end;
      margin-top: 2rem;
      margin-right: -.5rem;
      display: flex
  }
  
  .fs-cc-prefs_submit-hide {
      display: none
  }
  
  .fs-cc-prefs_overlay {
      z-index: -1;
      width: 100%;
      height: 100%;
      background-color: rgba(3,5,36,.6);
      position: absolute;
      top: 0%;
      bottom: 0%;
      left: 0%;
      right: 0%
  }
  
  .fs-cc-prefs_trigger {
      display: none
  }
  
  @media screen and (max-width: 991px) {
      h1 {
          font-size:3.25rem
      }
  
      h2 {
          font-size: 2.75rem
      }
  
      h3 {
          font-size: 2.25rem
      }
  
      h4 {
          font-size: 1.75rem
      }
  
      .max-width-full.text-align-left.horizontal-align {
          grid-row-gap: 1.5rem;
          flex-direction: column;
          align-items: flex-start
      }
  
      .max-width-full.text-align-left.horizontal-align.align-top.grid-project-intro {
          grid-row-gap: 2.5rem;
          display: flex
      }
  
      .max-width-full.text-align-left.horizontal-align.horizontal-responsive {
          flex-direction: row
      }
  
      .max-width-full.text-align-left.vertical-align {
          grid-row-gap: 1.5rem;
          flex-direction: column;
          align-items: flex-start
      }
  
      .hide-tablet {
          display: none
      }
  
      .max-width-full-tablet {
          width: 100%;
          max-width: none
      }
  
      .margin-large {
          margin: 2.5rem
      }
  
      .margin-xlarge {
          margin: 3.5rem
      }
  
      .margin-xxlarge {
          margin: 4.5rem
      }
  
      .margin-huge {
          margin: 5rem
      }
  
      .margin-xhuge {
          margin: 6rem
      }
  
      .margin-xxhuge {
          margin: 7.5rem
      }
  
      .padding-large {
          padding: 2.5rem
      }
  
      .padding-xlarge {
          padding: 3.5rem
      }
  
      .padding-xxlarge {
          padding: 4.5rem
      }
  
      .padding-huge {
          padding: 5rem
      }
  
      .padding-xhuge {
          padding: 6rem
      }
  
      .padding-xxhuge {
          padding: 7.5rem
      }
  
      .margin-top {
          margin-bottom: 0;
          margin-left: 0;
          margin-right: 0
      }
  
      .margin-bottom {
          margin-top: 0;
          margin-left: 0;
          margin-right: 0
      }
  
      .margin-bottom.align-horizontal {
          grid-row-gap: 1.5rem;
          flex-direction: column
      }
  
      .margin-left {
          margin-top: 0;
          margin-bottom: 0;
          margin-right: 0
      }
  
      .margin-right {
          margin-top: 0;
          margin-bottom: 0;
          margin-left: 0
      }
  
      .margin-right.margin-huge.responsive-max-width {
          width: 100%
      }
  
      .margin-vertical {
          margin-left: 0;
          margin-right: 0
      }
  
      .margin-horizontal {
          margin-top: 0;
          margin-bottom: 0
      }
  
      .padding-top {
          padding-bottom: 0;
          padding-left: 0;
          padding-right: 0
      }
  
      .padding-bottom {
          padding-top: 0;
          padding-left: 0;
          padding-right: 0
      }
  
      .padding-left {
          padding-top: 0;
          padding-bottom: 0;
          padding-right: 0
      }
  
      .padding-right {
          padding-top: 0;
          padding-bottom: 0;
          padding-left: 0
      }
  
      .padding-vertical {
          padding-left: 0;
          padding-right: 0
      }
  
      .padding-horizontal {
          padding-top: 0;
          padding-bottom: 0
      }
  
      .text-size-medium.max-width-medium.text-weight-light.full-width-respomsive,.text-size-medium.max-width-xxsmall.text-style-allcaps.text-colour-grey.text-weight-medium.responsive-full-width {
          max-width: none
      }
  
      .heading-style-h2,.heading-style-h2._8vw-title.project-intro-title {
          font-size: 2.75rem;
          
      }
  
      .heading-style-h2.about-title {
          font-size: 6.4vw;
          font-weight: 500;
      }
  
      .rl-styleguide_icons-list {
          grid-auto-flow: row
      }
  
      .heading-style-h1 {
          font-size: 3.25rem
      }
  
      .heading-style-h4 {
          font-size: 1.75rem
      }
  
      .heading-style-h3 {
          font-size: 2.25rem
      }
  
      .form-input::-ms-input-placeholder {
          font-size: 16px
      }
  
      .form-input::placeholder {
          font-size: 16px
      }
  
      .button-group.margin-left.margin-xsmall.no-margin-responsive {
          justify-content: center;
          margin-left: 0
      }
  
      .button-group.margin-top.margin-large.absolute-button {
          position: static
      }
  
      .text-size-small.text-style-allcaps.text-align-right-mobile {
          text-align: right
      }
  
      .padding-global {
          padding-left: 5%;
          padding-right: 5%
      }
  
      .padding-section-medium {
          padding-top: 4rem;
          padding-bottom: 4rem
      }
  
      .padding-section-large {
          padding-top: 6rem;
          padding-bottom: 6rem
      }
  
      .menu-icon1_line-middle {
          width: 24px;
          height: 2px;
          background-color: #fff8f1;
          justify-content: center;
          align-items: center;
          margin-top: 6px;
          margin-bottom: 6px;
          padding-bottom: 0;
          padding-right: 0;
          display: flex
      }
  
      .navbar1_dropdown-link {
          width: auto;
          padding: .75rem 0 .75rem 5%
      }
  
      .navbar1_menu {
          -webkit-text-fill-color: inherit;
          background-color: #fff8f1;
          background-clip: border-box;
          border-bottom: 1px solid #000;
          padding: 1rem 5% 2rem;
          position: absolute;
          overflow: auto
      }
  
      .navbar1_menu.is-page-height-tablet {
          height: 20rem;
          border-radius: 10px;
          margin-top: 5%;
          margin-left: 5%;
          margin-right: 5%;
          padding-top: 3.5rem;
          padding-bottom: 15rem;
          overflow: hidden
      }
  
      .menu-icon1_line-bottom {
          width: 24px;
          height: 2px;
          background-color: #fff8f1;
          padding-bottom: 0;
          padding-right: 0
      }
  
      .navbar1_menu-dropdown {
          width: 100%;
          font-size: 1.125rem
      }
  
      .navbar1_menu-button.w--open {
          background-color: transparent
      }
  
      .menu-icon1 {
          width: 48px;
          height: 48px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-right: -.5rem;
          padding-bottom: 0;
          padding-right: 0;
          display: flex
      }
  
      .navbar1_dropdown-list {
          position: static;
          overflow: hidden
      }
  
      .navbar1_dropdown-list.w--open {
          border-style: none;
          padding: 0
      }
  
      .navbar1_dropdown-toggle {
          align-items: center;
          padding-top: .75rem;
          padding-bottom: .75rem;
          padding-left: 0;
          display: flex
      }
  
      .menu-icon1_line-top {
          width: 24px;
          height: 2px;
          background-color: #fff8f1;
          padding-bottom: 0;
          padding-right: 0
      }
  
      .navbar1_component {
          padding-left: 5%;
          padding-right: 5%
      }
  
      .dropdown-icon {
          margin-top: 0;
          margin-bottom: 0;
          margin-right: 0;
          top: auto;
          bottom: auto;
          left: auto;
          right: 0%
      }
  
      .navbar1_link {
          width: auto;
          color: #1d1d1d;
          padding: .75rem 0;
          font-size: 1.125rem
      }
  
      .navbar1_link.text-colour-xx {
          width: 5.5rem;
          height: 1.5rem;
          color: #1d1d1d;
          background-color: #fff8f1;
          margin-left: 0
      }
  
      .navbar1_link.language-ro {
          width: 5.5rem;
          height: 1.5rem;
          color: #fff8f1
      }
  
      .navbar1_link.language-ro:hover {
          background-color: transparent
      }
  
      .navbar1_link.link-mobile {
          margin-bottom: 1rem
      }
  
      .navbar1_link.all-caps-link {
          color: #fff8f1;
          padding-top: 0;
          padding-bottom: 0;
          font-size: .9rem
      }
  
      .navbar1_link.all-caps-link._9--margin,.navbar1_link.all-caps-link._10--margin {
          order: 1;
          margin-left: 0%;
          margin-right: 0;
          padding-top: 0;
          padding-bottom: 0;
          font-size: .9rem
      }
  
      .navbar1_link.responsive-nav-links {
          text-align: center;
          background-color: #fff8f1
      }
  
      .navbar1_link.responsive-nav-links.w--current {
          color: #1d1d1d;
          border-style: none
      }
  
      .menu-icon_line-middle-inner {
          width: 4px;
          height: 0;
          padding-bottom: 0;
          padding-right: 0
      }
  
      .header_content-bottom {
          justify-content: center
      }
  
      .header_content {
          width: 90vw;
          min-height: auto;
          padding-top: 10rem;
          padding-bottom: 6rem
      }
  
      .custom-h1 {
          font-size: 6.7vw
      }
  
      .hero-info-wrapper {
          grid-row-gap: 2rem;
          flex-direction: column;
          align-items: flex-start
      }
  
      .hero-info-wrapper.vertical-align-bottom {
          align-items: flex-start
      }
  
      .white-line.scroll-into.hide-mobile-portrait {
          display: none
      }
  
      .layout_card-content {
          padding: 2rem 0
      }
  
      .layout_image-wrapper {
          height: 100%
      }
  
      .layout_row {
          grid-template-columns: 1fr 1px 1fr
      }
  
      .layout_component {
          grid-template-columns: 1fr
      }
  
      .vertical-line {
          flex-direction: column
      }
  
      .vertical-line.horizontal-line-responsive {
          width: 100%;
          height: 1px;
          flex-direction: row
      }
  
      .vertical-line.horizontal-line-responsive.hide-mobile-portrait {
          display: none
      }
  
      .vertical-line.hide-mobile-portrait {
          display: block
      }
  
      .language-wrapper {
          border-color: #1d1d1d
      }
  
      .language-wrapper.no-margin-responsive {
          width: 11.5rem;
          margin-top: 2rem;
          margin-left: 0
      }
  
      .language-wrapper.no-margin-responsive.mobile-language {
          width: 9rem;
          height: 2rem;
          margin-top: 0;
          display: flex
      }
  
      .footer-bottom-links {
          height: auto;
          padding-top: 0;
          padding-bottom: 0
      }
  
      .footer-legal {
          grid-column-gap: .5rem
      }
  
      .footer-links-full-width {
          grid-column-gap: 16px;
          grid-row-gap: 1.5rem;
          border-bottom: 1px solid #545454;
          flex-direction: row;
          grid-template-rows: auto auto;
          grid-template-columns: 1fr .75fr;
          grid-auto-columns: 1fr;
          justify-content: center;
          align-items: flex-start;
          padding: 2rem 0;
          display: grid
      }
  
      .footer-mid-details {
          height: auto
      }
  
      .footer-details-full-width {
          flex-direction: column;
          padding-left: 0;
          padding-right: 0
      }
  
      .footer-left-wrapper {
          width: 100%;
          border-top: 1px solid #545454;
          border-bottom: 1px solid #545454;
          padding-top: 2rem;
          padding-bottom: 2rem
      }
  
      .footer-right-wrapper {
          width: 100%;
          grid-row-gap: 0px;
          flex-direction: column
      }
  
      .quick-links {
          width: 100%;
          flex-direction: column;
          padding-bottom: 2rem;
          padding-left: 0
      }
  
      .social-links {
          width: 100%;
          flex-direction: column;
          justify-content: space-between;
          padding: .5rem 0 .7rem
      }
  
      .button-group-footer.margin-top.margin-small {
          flex-direction: row
      }
  
      .button-group-footer.margin-top.margin-small.button-group-horizontal {
          grid-column-gap: 1rem
      }
  
      .about-hero-info-wrapper {
          grid-row-gap: 2rem;
          flex-direction: column;
          align-items: flex-start
      }
  
      .about-image-wrapper {
          height: 40vh
      }
  
      .header_content-copy {
          width: 90vw;
          min-height: auto;
          padding-top: 10rem;
          padding-bottom: 6rem
      }
  
      .services-layout_component {
          height: auto;
          border-top: 0 #545454;
          border-right: 1px solid #545454;
          flex-direction: column
      }
  
      .services-layout_accordion {
          width: 100%;
          height: 5rem;
          min-width: auto;
          border-right-style: none;
          flex-direction: column
      }
  
      .services-layout_accordion.active {
          height: 5rem
      }
  
      .services-layout_column {
          width: 100%;
          height: 5rem;
          min-width: 100%;
          border-top: 1px solid #545454;
          border-bottom: 1px solid #545454;
          flex-direction: row;
          justify-content: center
      }
  
      .services-layout_column-text {
          position: absolute;
          left: 2.5rem
      }
  
      .layout351_column-vertical-text {
          display: none
      }
  
      .layout351_column-horiztonal-text {
          text-transform: uppercase;
          font-family: Generalsans,sans-serif;
          font-size: 1.5rem;
          font-weight: 300;
          display: block
      }
  
      .layout351_column-content {
          width: 100%;
          min-width: auto
      }
  
      .layout351_content-wrapper {
          width: auto;
          padding: 3rem 2.5rem
      }
  
      .layout351_image-wrapper {
          width: 100%;
          height: 40vh;
          margin-bottom: 0
      }
  
      .services-content-wrapper {
          grid-row-gap: 2rem;
          flex-direction: column
      }
  
      .process-layout_component {
          min-height: auto;
          grid-column-gap: 3rem;
          grid-template-columns: 1fr 1fr;
          grid-auto-flow: row
      }
  
      .about-section-info-wrapper {
          grid-row-gap: 2rem;
          flex-direction: column;
          align-items: flex-start
      }
  
      .about-section-copy {
          grid-row-gap: 2rem;
          flex-direction: column
      }
  
      .arrow-svg {
          order: 1;
          display: none
      }
  
      .projects-layout_component {
          grid-template-columns: 1fr
      }
  
      .projects-layout_row {
          grid-template-rows: auto auto;
          grid-template-columns: 1fr 1px 1fr
      }
  
      .projects-layout_card.half-top,.projects-layout_card.half-bottom {
          height: auto
      }
  
      .project-layout_image-wrapper {
          height: 100%
      }
  
      .content_component {
          min-height: auto;
          grid-column-gap: 3rem;
          grid-auto-flow: row
      }
  
      .content_component.margin-vertical.margin-medium {
          grid-row-gap: 2rem;
          grid-template-columns: 1fr
      }
  
      .contact-layout_row,.contact-layout_row.row-mobile {
          grid-template-columns: 1fr
      }
  
      .contact-layout_image-wrapper {
          height: 100%
      }
  
      .project-header_background-image-wrapper {
          height: 50vh
      }
  
      .project-header_content-wrapper {
          grid-column-gap: 3rem;
          grid-row-gap: 2rem
      }
  
      .project-layout-component {
          grid-template-columns: 1fr
      }
  
      .project-layout-component.margin-top.margin-xhuge {
          height: auto
      }
  
      .project-layout_row {
          grid-row-gap: 3rem;
          grid-template-columns: 1fr
      }
  
      .project-layout_card {
          height: 50vh
      }
  
      .project-layout_card.mobile-80vh {
          height: 90vh;
          grid-row-gap: 3rem
      }
  
      .layout_project-image-wrapper {
          height: 100%
      }
  
      .layout_project-image-wrapper.layout-left,.layout_project-image-wrapper.layout-right {
          width: 70%;
          height: 45vh
      }
  
      .gallery_horizontal-scroll-content {
          width: 300vh
      }
  
      .gallery_image {
          max-height: 40rem
      }
  
      .footer-links-full-width-ro {
          grid-column-gap: 16px;
          grid-row-gap: 1.5rem;
          border-bottom: 1px solid #545454;
          flex-direction: row;
          grid-template-rows: auto auto;
          grid-template-columns: .75fr;
          grid-auto-columns: 1fr;
          justify-content: start;
          align-items: flex-start;
          padding: 2rem 0;
          display: grid
      }
  
      .footer-legal-ro {
          grid-column-gap: .5rem
      }
  
      .cookies-wrapper {
          display: block
      }
  
      .fs-cc-banner2_component {
          z-index: 1001;
          width: auto;
          border-radius: .7rem;
          padding: 1rem;
          display: none
      }
  
      .fs-cc-banner2_container {
          align-items: center
      }
  
      .fs-cc-banner2_text {
          text-align: left
      }
  
      .fs-cc-prefs_component {
          z-index: 1000;
          display: none
      }
  
      .fs-cc-prefs_buttons-wrapper {
          justify-content: flex-start
      }
  }
  
  @media screen and (max-width: 767px) {
      h1 {
          font-size:2.5rem
      }
  
      h2 {
          font-size: 2.25rem
      }
  
      h3 {
          font-size: 2rem
      }
  
      h4 {
          font-size: 1.5rem;
          line-height: 1.4
      }
  
      h5 {
          font-size: 1.25rem
      }
  
      h6 {
          font-size: 1.125rem
      }
  
      .hide-mobile-landscape {
          display: none
      }
  
      .max-width-full-mobile-landscape {
          width: 100%;
          max-width: none
      }
  
      .margin-xsmall {
          margin: .75rem
      }
  
      .margin-small {
          margin: 1.25rem
      }
  
      .margin-medium {
          margin: 1.5rem
      }
  
      .margin-large {
          margin: 2rem
      }
  
      .margin-xlarge {
          margin: 2.5rem
      }
  
      .margin-xxlarge {
          margin: 3rem
      }
  
      .margin-huge {
          margin: 3.5rem
      }
  
      .margin-xhuge {
          margin: 4rem
      }
  
      .margin-xxhuge {
          margin: 5rem
      }
  
      .padding-xsmall {
          padding: .75rem
      }
  
      .padding-small {
          padding: 1.25rem
      }
  
      .padding-medium {
          padding: 1.5rem
      }
  
      .padding-large {
          padding: 2rem
      }
  
      .padding-xlarge {
          padding: 2.5rem
      }
  
      .padding-xxlarge {
          padding: 3rem
      }
  
      .padding-huge {
          padding: 3.5rem
      }
  
      .padding-xhuge {
          padding: 4rem
      }
  
      .padding-xxhuge {
          padding: 5rem
      }
  
      .margin-top {
          margin-bottom: 0;
          margin-left: 0;
          margin-right: 0
      }
  
      .margin-bottom {
          margin-top: 0;
          margin-left: 0;
          margin-right: 0
      }
  
      .margin-left {
          margin-top: 0;
          margin-bottom: 0;
          margin-right: 0
      }
  
      .margin-right {
          margin-top: 0;
          margin-bottom: 0;
          margin-left: 0
      }
  
      .margin-vertical {
          margin-left: 0;
          margin-right: 0
      }
  
      .margin-horizontal {
          margin-top: 0;
          margin-bottom: 0
      }
  
      .padding-top {
          padding-bottom: 0;
          padding-left: 0;
          padding-right: 0
      }
  
      .padding-bottom {
          padding-top: 0;
          padding-left: 0;
          padding-right: 0
      }
  
      .padding-left {
          padding-top: 0;
          padding-bottom: 0;
          padding-right: 0
      }
  
      .padding-right {
          padding-top: 0;
          padding-bottom: 0;
          padding-left: 0
      }
  
      .padding-vertical {
          padding-left: 0;
          padding-right: 0
      }
  
      .padding-horizontal {
          padding-top: 0;
          padding-bottom: 0
      }
  
      .text-size-medium {
          /* font-size: 1rem */
      }
  
      .text-style-quote {
          font-size: 1.125rem
      }
  
      .heading-style-h2,.heading-style-h2._8vw-title.project-intro-title {
          font-size: 2.25rem
      }
  
      .heading-style-h6 {
          font-size: 1.125rem
      }
  
      .heading-style-h5 {
          font-size: 1.25rem
      }
  
      .heading-style-h1 {
          font-size: 2.5rem
      }
  
      .heading-style-h4 {
          font-size: 1.5rem;
          line-height: 1.4
      }
  
      .heading-style-h3 {
          font-size: 2rem
      }
  
      .text-style-nowrap {
          white-space: normal
      }
  
      .text-size-large {
          font-size: 1.125rem
      }
  
      .padding-section-small {
          padding-top: 2rem;
          padding-bottom: 2rem
      }
  
      .padding-section-medium {
          padding-top: 3rem;
          padding-bottom: 3rem
      }
  
      .padding-section-large {
          padding-top: 4rem;
          padding-bottom: 4rem
      }
  
      .navbar1_component {
          height: auto;
          min-height: 4rem
      }
  
      .navbar1_logo-link {
          padding-left: 0
      }
  
      .header_content {
          padding-top: 7rem;
          padding-bottom: 4rem
      }
  
      .layout_card-content {
          padding: 1.5rem
      }
  
      .layout_row {
          grid-column-gap: 1.5rem;
          grid-row-gap: 2.5rem;
          grid-template-columns: 1fr
      }
  
      .layout_card {
          grid-template-columns: 1fr
      }
  
      .layout_component {
          grid-column-gap: 1.5rem;
          grid-row-gap: 1.5rem
      }
  
      .vertical-line {
          width: 100%;
          height: 1px;
          flex-direction: row
      }
  
      .vertical-line.hide-mobile-portrait {
          display: none
      }
  
      .about-info-wrapper {
          padding-right: 0
      }
  
      .footer-links-full-width {
          grid-row-gap: 1rem;
          flex-direction: column;
          display: flex
      }
  
      .footer-mid-details {
          flex-direction: column
      }
  
      .social-links {
          padding-bottom: 2rem
      }
  
      .header_content-copy {
          padding-top: 5rem;
          padding-bottom: 4rem
      }
  
      .services-layout_accordion,.services-layout_column {
          height: 4rem
      }
  
      .services-layout_column-text {
          font-size: 1.25rem;
          left: 1.5rem
      }
  
      .layout351_column-vertical-text,.layout351_column-horiztonal-text {
          font-size: 1.25rem
      }
  
      .layout351_content-wrapper {
          padding: 1rem 1.5rem 2rem
      }
  
      .layout351_image {
          height: 20rem
      }
  
      .process-layout_component {
          grid-row-gap: 3rem;
          grid-template-columns: 1fr
      }
  
      .process-layout_content-left {
          position: static
      }
  
      .about-intro {
          grid-row-gap: 2rem;
          flex-direction: column
      }
  
      .projects-layout_component {
          grid-column-gap: 1.5rem;
          grid-row-gap: 1.5rem
      }
  
      .projects-layout_row {
          grid-column-gap: 1.5rem;
          grid-row-gap: 2.5rem;
          grid-template-columns: 1fr
      }
  
      .projects-layout_card {
          grid-template-columns: 1fr
      }
  
      .content_component {
          grid-row-gap: 3rem;
          grid-template-columns: 1fr
      }
  
      .content_component.margin-vertical.margin-medium {
          grid-row-gap: 4rem
      }
  
      .form-field-col.is-mobile-1col {
          grid-template-columns: 1fr
      }
  
      .contact-layout_row {
          grid-column-gap: 1.5rem;
          grid-row-gap: 2.5rem;
          grid-template-columns: 1fr
      }
  
      .contact-layout_card {
          grid-template-columns: 1fr
      }
  
      .project_header_component {
          height: auto;
          max-height: none
      }
  
      .project-header_content-wrapper {
          grid-template-columns: 1fr
      }
  
      .project-layout-component {
          height: auto;
          grid-column-gap: 1.5rem;
          grid-row-gap: 1.5rem
      }
  
      .project-layout_row {
          grid-column-gap: 1.5rem;
          grid-row-gap: 2.5rem;
          grid-template-columns: 1fr
      }
  
      .project-layout_card {
          grid-template-columns: 1fr
      }
  
      .gallery_horizontal-scroll-wrapper {
          margin-top: -10vh
      }
  
      .gallery_horizontal-scroll-content {
          width: 200vh;
          grid-column-gap: 1.5rem
      }
  
      .gallery_image {
          width: 90vw;
          max-height: 30rem
      }
  
      .footer-links-full-width-ro {
          grid-row-gap: 1rem;
          flex-direction: column;
          display: flex
      }
  
      .cookies-wrapper {
          display: block
      }
  
      .fs-cc-banner2_component {
          width: auto;
          display: none
      }
  
      .fs-cc-banner2_container {
          grid-row-gap: 1.5rem;
          flex-direction: column;
          align-items: center
      }
  
      .fs-cc-banner2_text {
          text-align: center
      }
  
      .fs-cc-prefs_title {
          font-size: 1.25rem
      }
  }
  
  @media screen and (max-width: 479px) {
      h1 {
          font-size:2.5rem
      }
  
      .max-width-full {
          width: 100%
      }
  
      .max-width-full.text-align-left.horizontal-align {
          grid-row-gap: 1rem;
          flex-direction: column;
          align-items: flex-start
      }
  
      .max-width-full.text-align-left.horizontal-align.align-top.grid-project-intro {
          grid-row-gap: 1.5rem
      }
  
      .max-width-full.text-align-left.vertical-align {
          grid-row-gap: 1rem;
          flex-direction: column;
          align-items: flex-start
      }
  
      .hide-mobile-portrait {
          display: none
      }
  
      .margin-top {
          margin-bottom: 0;
          margin-left: 0;
          margin-right: 0
      }
  
      .margin-bottom {
          margin-top: 0;
          margin-left: 0;
          margin-right: 0
      }
  
      .margin-bottom.margin-xsmall.horizontal-align.mobile-vertical {
          grid-row-gap: 2rem;
          flex-direction: column;
          padding-top: 0
      }
  
      .margin-bottom.align-horizontal {
          grid-row-gap: 1.25rem;
          flex-direction: column
      }
  
      .margin-left {
          margin-top: 0;
          margin-bottom: 0;
          margin-right: 0
      }
  
      .margin-right {
          margin-top: 0;
          margin-bottom: 0;
          margin-left: 0
      }
  
      .margin-vertical {
          margin-left: 0;
          margin-right: 0
      }
  
      .margin-horizontal {
          margin-top: 0;
          margin-bottom: 0
      }
  
      .padding-top {
          padding-bottom: 0;
          padding-left: 0;
          padding-right: 0
      }
  
      .padding-bottom {
          padding-top: 0;
          padding-left: 0;
          padding-right: 0
      }
  
      .padding-left {
          padding-top: 0;
          padding-bottom: 0;
          padding-right: 0
      }
  
      .padding-right {
          padding-top: 0;
          padding-bottom: 0;
          padding-left: 0
      }
  
      .padding-vertical {
          padding-left: 0;
          padding-right: 0
      }
  
      .padding-horizontal {
          padding-top: 0;
          padding-bottom: 0
      }
  
      .max-width-full-mobile-portrait {
          width: 100%;
          max-width: none
      }
  
      .heading-style-h2._8vw-title,.heading-style-h2._8vw-title.project-intro-title {
          font-size: 1.62rem
      }
  
      .heading-style-h2._8vw-title.project-title {
          padding-left: 1rem;
          padding-right: 1rem;
          font-size: 7.35vw
      }
  
      .heading-style-h2.about-title {
          font-size: 30px;
          /* color: red; */
      }
      .heading-style-h5.text-style-allcaps.text-weight-medium{
        font-size: 30px;
      }
      .text-size-regular.text-style-allcaps._0-875rem-text {
          font-size: .875rem
      }
  
      .heading-style-h3.text-style-allcaps.margin-bottom.margin-medium.mobile-title-8vw {
          font-size: 1.5rem
      }
  
      .form-input.is-text-area {
          min-height: 17rem;
          font-size: 16px
      }
  
      .button-group.margin-left.margin-xsmall.no-margin-responsive {
          margin-left: 0
      }
  
      .button-group.margin-left.margin-xsmall.no-margin-responsive.nav-button-responsive {
          padding-top: .8rem
      }
  
      .text-size-small.text-style-allcaps {
          order: 1;
          font-size: .8rem
      }
  
      .text-size-small.text-style-allcaps.text-align-right-mobile {
          text-align: right
      }
  
      .text-size-small.text-style-allcaps._0-9rem-text {
          order: -1;
          font-size: .8rem
      }
  
      .padding-global {
          padding-left: 5%;
          padding-right: 5%
      }
  
      .menu-icon1_line-middle {
          background-color: #fff8f1
      }
  
      .navbar1_menu.is-page-height-tablet {
          height: 20.3rem;
          border-radius: 10px;
          margin-top: 5%;
          padding-top: 2.3rem;
          overflow: hidden
      }
  
      .menu-icon1_line-bottom,.menu-icon1_line-top {
          background-color: #fff8f1
      }
  
      .navbar1_component {
          padding-left: 5%;
          padding-right: 5%
      }
  
      .navbar1_link.text-colour-xx {
          width: 5.5rem;
          height: 1rem;
          color: #1d1d1d;
          background-color: #fff8f1;
          margin-left: 0;
          padding-top: 0;
          padding-bottom: 0;
          font-size: .9rem
      }
  
      .navbar1_link.language-ro {
          width: 5.5rem;
          height: 1rem;
          padding-top: 0;
          padding-bottom: 0;
          font-size: .9rem
      }
  
      .navbar1_link.link-mobile {
          margin-bottom: 1rem
      }
  
      .navbar1_link.all-caps-link {
          padding-top: 0;
          padding-bottom: 0;
          font-size: .8rem
      }
  
      .navbar1_link.all-caps-link._9--margin,.navbar1_link.all-caps-link._10--margin {
          color: #ababab;
          order: 1;
          font-size: .8rem
      }
  
      .navbar1_link.responsive-nav-links {
          margin-bottom: .5rem
      }
  
      .navbar1_logo {
          max-width: 85%;
          margin-bottom: 2px
      }
  
      .header_content {
          width: 90vw;
          padding-top: 7rem
      }
  
      .custom-h1 {
          font-size: 8.35vw
      }
  
      .hero-info-wrapper {
          grid-row-gap: 2rem;
          flex-direction: column;
          align-items: flex-start
      }
  
      .hero-info-wrapper.vertical-align-bottom {
          align-items: flex-start
      }
  
      .white-line.scroll-into {
          height: 1px
      }
  
      .white-line.not-visible-mobile {
          display: none
      }
  
      .white-line.not-visible-mobile.about-line {
          width: 90vw;
          display: block
      }
  
      .layout_card-content {
          padding-left: 0;
          padding-right: 0
      }
  
      .layout_image-wrapper {
          height: 40vh
      }
  
      .layout_row {
          grid-template-rows: auto 1px auto;
          grid-template-columns: 1fr
      }
  
      .layout_row.row-mobile {
          grid-template-rows: auto
      }
  
      .vertical-line {
          width: 100%;
          height: 1px;
          flex-direction: column
      }
  
      .vertical-line.hide-mobile-portrait {
          display: none
      }
  
      .language-wrapper.no-margin-responsive {
          width: 11.5rem;
          margin-top: 2rem;
          margin-left: 0
      }
  
      .language-wrapper.no-margin-responsive.mobile-language {
          width: 6rem;
          height: 1.5rem
      }
  
      .about-info-wrapper {
          padding-right: 0
      }
  
      .footer-bottom-links {
          padding-top: 0;
          padding-bottom: 0
      }
  
      .footer-legal {
          grid-column-gap: 1.5rem;
          order: -1
      }
  
      .footer-links-full-width {
          grid-row-gap: 1rem;
          border: 1px #545454;
          flex-direction: column;
          align-items: flex-start;
          padding: 2rem 0;
          display: flex
      }
  
      .footer-mid-details {
          height: auto
      }
  
      .footer-details-full-width {
          padding-left: .1rem;
          padding-right: .1rem
      }
  
      .footer-left-wrapper {
          border: 1px solid #545454;
          border-style: solid none;
          padding: 2rem 0
      }
  
      .footer-right-wrapper {
          grid-row-gap: 0px
      }
  
      .quick-links {
          border: 1px #545454;
          padding: 0 0 2rem
      }
  
      .social-links {
          border: 1px #545454;
          flex-direction: column;
          padding: .5rem 0 .7rem
      }
  
      .footer-logo {
          max-width: 100%
      }
  
      .button-group-footer.margin-top.margin-small {
          flex-direction: column
      }
  
      .button-group-footer.margin-top.margin-small.button-group-horizontal {
          grid-column-gap: 1rem;
          grid-row-gap: 1rem;
          flex-direction: row
      }
  
      .text-span-12 {
          color: #fff8f1
      }
  
      .about-hero-info-wrapper {
          grid-row-gap: 2rem;
          flex-direction: column;
          align-items: flex-start
      }
  
      .about-hero-info-wrapper.margin-top.margin-large.max-width-custom {
          max-width: none
      }
  
      .header_content-copy {
          width: 90vw;
          padding-top: 7rem
      }
  
      .services-layout_accordion,.services-layout_accordion.active {
          height: 4rem
      }
  
      .services-layout_column {
          justify-content: flex-start;
          padding-left: 1rem
      }
  
      .services-layout_column-text {
          margin-right: 1rem;
          position: static
      }
  
      .layout351_column-horiztonal-text {
          font-size: 1rem
      }
  
      .layout351_image {
          height: 40vh
      }
  
      .process-layout_component {
          grid-template-columns: 1fr
      }
  
      .process-layout_content-item.content-item-1,.process-layout_content-item.content-item-2,.process-layout_content-item.content-item-3,.process-layout_content-item.content-item-4 {
          padding-left: 1rem;
          padding-right: 1rem
      }
  
      .about-section-info-wrapper {
          grid-row-gap: 2rem;
          flex-direction: column;
          align-items: flex-start
      }
  
      .about-section-copy {
          grid-row-gap: 2rem;
          flex-direction: column
      }
  
      .arrow-svg {
          width: 3rem;
          height: 3rem;
          order: -1;
          display: block
      }
  
      .projects-layout_row {
          grid-template-rows: auto;
          grid-template-columns: 1fr
      }
  
      .project-layout_image-wrapper {
          height: 40vh
      }
  
      .section_contact {
          padding-top: 2rem
      }
  
      .content_component {
          grid-template-columns: 1fr
      }
  
      .contact_content {
          width: 90vw
      }
  
      .contact_form {
          grid-row-gap: 2rem
      }
  
      .form-field-col {
          grid-row-gap: 2rem;
          grid-template-columns: 1fr
      }
  
      .contact-layout_row {
          grid-template-rows: auto 1px auto;
          grid-template-columns: 1fr
      }
  
      .contact-layout_row.row-mobile {
          grid-template-rows: auto
      }
  
      .contact-layout_image-wrapper {
          height: 40vh
      }
  
      .section_header-project {
          padding-top: 5rem
      }
  
      .project-header_background-image-wrapper {
          width: 90vw;
          height: 60vh
      }
  
      .project-header_content-wrapper {
          grid-template-columns: 1fr
      }
  
      .project-small-details {
          height: 1.5rem
      }
  
      .project-layout_row {
          grid-template-rows: auto;
          grid-template-columns: 1fr
      }
  
      .project-layout_card {
          height: 40vh
      }
  
      .project-layout_card.mobile-80vh {
          height: 85vh;
          grid-row-gap: 2.5rem
      }
  
      .layout_project-image-wrapper {
          height: 40vh
      }
  
      .layout_project-image-wrapper.layout-left {
          width: 100%;
          height: 40vh
      }
  
      .layout_project-image-wrapper.layout-right {
          width: 100%
      }
  
      .gallery_horizontal-scroll-content {
          width: 150vh
      }
  
      .gallery_image {
          height: 70vh;
          max-height: 70vh
      }
  
      .footer-links-full-width-ro {
          grid-row-gap: 1rem;
          border: 1px #545454;
          flex-direction: column;
          align-items: flex-start;
          padding: 2rem 0;
          display: flex
      }
  
      .footer-legal-ro {
          grid-column-gap: 1.5rem;
          grid-row-gap: 1rem;
          flex-direction: column;
          order: -1;
          align-items: flex-start
      }
  
      .cookies-wrapper {
          display: block
      }
  
      .fs-cc-banner2_component {
          z-index: 1001;
          width: auto;
          height: auto;
          display: none;
          left: 3%;
          right: 3%
      }
  
      .fs-cc-banner2_container {
          grid-row-gap: 1.5rem
      }
  
      .fs-cc-banner2_text {
          font-size: 12px
      }
  
      .fs-cc-banner2_buttons-wrapper {
          width: 100%;
          grid-row-gap: 2rem;
          flex-flow: row;
          justify-content: center
      }
  
      .fs-cc-banner_text-link {
          font-size: 14px
      }
  
      .fs-cc-banner2_button {
          min-width: 4.5rem;
          margin-left: auto;
          margin-right: auto;
          padding-left: .5rem;
          padding-right: .5rem;
          font-size: 14px
      }
  
      .fs-cc-banner2_button.fs-cc-button-alt {
          min-width: 4rem;
          padding-left: .5rem;
          padding-right: .5rem
      }
  
      .fs-cc-prefs_component {
          z-index: 1000;
          padding: 1.5rem;
          display: none
      }
  
      .fs-cc-prefs_content {
          padding: 1.5rem
      }
  
      .fs-cc-prefs_title {
          font-weight: 500
      }
  
      .fs-cc-prefs_space-medium {
          grid-row-gap: .5rem;
          flex-direction: column;
          display: flex
      }
  
      .fs-cc-prefs_button {
          width: 100%;
          text-align: center;
          margin-bottom: .5rem;
          margin-right: 0;
          padding-left: 1rem;
          padding-right: 1rem;
          font-family: Generalsans,sans-serif;
          font-weight: 400
      }
  
      .fs-cc-prefs_buttons-wrapper {
          width: 100%;
          flex-direction: row;
          justify-content: flex-start;
          margin-right: 0
      }
  
      .text-span-20,.text-span-21,.text-span-22,.text-span-23 {
          color: #fff
      }
  }
  
  #w-node-fc472468-3e1d-6df9-e2a0-778fc09b5b44-45a291a3 {
      justify-self: center
  }
  
  #w-node-_23d99870-a857-978e-fc3a-c4f947a2bba0-45a291a3 {
      align-self: start
  }
  
  #w-node-_86c80847-090f-be2a-7bc4-567e1c087dad-45a291a3,#w-node-c41528ae-e7a8-b02a-3181-20bcb705bbd1-45a291a3 {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-_77fdf264-8876-fedd-9845-82c69dedcf5d-45a291a3 {
      justify-self: center
  }
  
  #w-node-_77fdf264-8876-fedd-9845-82c69dedcf60-45a291a3 {
      align-self: start
  }
  
  #w-node-_77fdf264-8876-fedd-9845-82c69dedcf75-45a291a3 {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-_77fdf264-8876-fedd-9845-82c69dedcf77-45a291a3 {
      align-self: start
  }
  
  #w-node-_77fdf264-8876-fedd-9845-82c69dedcf8e-45a291a3 {
      justify-self: center
  }
  
  #w-node-_77fdf264-8876-fedd-9845-82c69dedcf91-45a291a3 {
      align-self: start
  }
  
  #w-node-_77fdf264-8876-fedd-9845-82c69dedcfa6-45a291a3 {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-_77fdf264-8876-fedd-9845-82c69dedcfa8-45a291a3 {
      align-self: start
  }
  
  #w-node-_77fdf264-8876-fedd-9845-82c69dedcfbf-45a291a3 {
      justify-self: center
  }
  
  #w-node-_77fdf264-8876-fedd-9845-82c69dedcfc2-45a291a3 {
      align-self: start
  }
  
  #w-node-_77fdf264-8876-fedd-9845-82c69dedcfd7-45a291a3 {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-_77fdf264-8876-fedd-9845-82c69dedcfd9-45a291a3 {
      align-self: start
  }
  
  #w-node-_7c3cee37-3fbd-0c7f-1f14-de1b1c3123e2-45a291a3,#w-node-_00f3c34e-c30d-fd2d-b161-4dd5ec7c2da6-ec7c2d89,#w-node-_00f3c34e-c30d-fd2d-b161-4dd5ec7c2df8-ec7c2d89,#w-node-feed3f58-455f-1671-61fb-dc2c0a9f0ac0-e6a291aa {
      justify-self: center
  }
  
  #w-node-feed3f58-455f-1671-61fb-dc2c0a9f0ad7-e6a291aa {
      align-self: start
  }
  
  #w-node-feed3f58-455f-1671-61fb-dc2c0a9f0ad5-e6a291aa {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-_0242e89a-d197-60f9-7df1-ac4ac06355e9-e6a291aa {
      align-self: start
  }
  
  #w-node-_2af0ce0a-2dc8-baa8-2666-097e02299c12-e6a291aa {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-_04174c97-f3bb-5f13-90f9-6694665c0df3-e6a291aa,#w-node-d94fe5ba-9186-cb3b-ebb5-b7c442f3cf5c-1ed1d9af {
      justify-self: center
  }
  
  #w-node-d94fe5ba-9186-cb3b-ebb5-b7c442f3cf5f-1ed1d9af {
      align-self: start
  }
  
  #w-node-d94fe5ba-9186-cb3b-ebb5-b7c442f3cf74-1ed1d9af {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-d94fe5ba-9186-cb3b-ebb5-b7c442f3cf75-1ed1d9af {
      align-self: center
  }
  
  #w-node-d94fe5ba-9186-cb3b-ebb5-b7c442f3cf76-1ed1d9af {
      align-self: start
  }
  
  #w-node-b189ff12-f737-b5a0-6b4b-71d4b9416ac7-1ed1d9af {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-bb29d59d-79ee-4308-b155-3023bdb0fdfd-1ed1d9af {
      align-self: end
  }
  
  #w-node-bb29d59d-79ee-4308-b155-3023bdb0fdfe-1ed1d9af {
      align-self: start
  }
  
  #w-node-_10f87619-fd25-058c-e270-a1f41fe96a2d-1ed1d9af {
      justify-self: center
  }
  
  #w-node-_10f87619-fd25-058c-e270-a1f41fe96a2f-1ed1d9af {
      align-self: end
  }
  
  #w-node-_10f87619-fd25-058c-e270-a1f41fe96a30-1ed1d9af {
      align-self: start
  }
  
  #w-node-_10f87619-fd25-058c-e270-a1f41fe96a45-1ed1d9af {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-_10f87619-fd25-058c-e270-a1f41fe96a46-1ed1d9af {
      align-self: center
  }
  
  #w-node-_10f87619-fd25-058c-e270-a1f41fe96a47-1ed1d9af {
      align-self: start
  }
  
  #w-node-f6a61413-acc0-3ba8-1a77-cd8c5171031b-1ed1d9af {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-_9c03905e-1adc-2186-a37a-72d88463df3a-1ed1d9af,#w-node-_9c03905e-1adc-2186-a37a-72d88463df3b-1ed1d9af {
      align-self: start
  }
  
  #w-node-_3c572920-1fb2-9823-0ae6-d824453f82a0-1ed1d9af {
      justify-self: center
  }
  
  #w-node-_3c572920-1fb2-9823-0ae6-d824453f82a2-1ed1d9af,#w-node-_3c572920-1fb2-9823-0ae6-d824453f82a3-1ed1d9af {
      align-self: start
  }
  
  #w-node-_3c572920-1fb2-9823-0ae6-d824453f82b8-1ed1d9af {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-_3c572920-1fb2-9823-0ae6-d824453f82b9-1ed1d9af {
      align-self: center
  }
  
  #w-node-_3c572920-1fb2-9823-0ae6-d824453f82ba-1ed1d9af {
      align-self: start
  }
  
  #w-node-_94a94724-1dd7-d8a6-98fe-989f236e8ec3-1ed1d9af {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-_43695764-dd34-d59c-664c-98b7907e26a8-1ed1d9af {
      align-self: end
  }
  
  #w-node-_43695764-dd34-d59c-664c-98b7907e26a9-1ed1d9af,#w-node-b6c2e90a-5a85-24d3-1e23-4e4ca924999f-16ae02d1 {
      align-self: start
  }
  
  #w-node-c27ea7cf-1259-352e-a4aa-b1d6bb21348e-16ae02d1,#w-node-_960af0f3-9022-9f53-e1c2-f9f50aa1b849-16ae02d1 {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-e0c46a3e-34d0-7d8f-6c47-b1dddcd298de-16ae02d1,#w-node-e0c46a3e-34d0-7d8f-6c47-b1dddcd298e3-16ae02d1 {
      align-self: start
  }
  
  #w-node-a8f04089-aafe-0feb-6173-2b5b071dd98f-16ae02d1,#w-node-_797d4d05-4e30-6ba5-167a-ddc5ec5b0234-16ae02d1 {
      justify-self: center
  }
  
  #w-node-c30f9c2a-266f-3c38-7398-b5eeed4cd5c7-0a745c56 {
      align-self: start
  }
  
  #w-node-_930ec677-7835-f475-540b-45fa5d59a921-0a745c56 {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-_930ec677-7835-f475-540b-45fa5d59a922-0a745c56 {
      align-self: start
  }
  
  #w-node-b42db442-665a-795f-6980-e0fd8d3c4714-0a745c56 {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28a-0a745c56 {
      justify-self: center
  }
  
  #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28d-0a745c56 {
      align-self: start
  }
  
  #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb2a2-0a745c56 {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb2a4-0a745c56 {
      align-self: start
  }
  
  #w-node-fc472468-3e1d-6df9-e2a0-778fc09b5b44-5e275613 {
      justify-self: center
  }
  
  #w-node-_23d99870-a857-978e-fc3a-c4f947a2bba0-5e275613 {
      align-self: start
  }
  
  #w-node-_86c80847-090f-be2a-7bc4-567e1c087dad-5e275613,#w-node-c41528ae-e7a8-b02a-3181-20bcb705bbd1-5e275613 {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-_77fdf264-8876-fedd-9845-82c69dedcf5d-5e275613 {
      justify-self: center
  }
  
  #w-node-_77fdf264-8876-fedd-9845-82c69dedcf60-5e275613 {
      align-self: start
  }
  
  #w-node-_77fdf264-8876-fedd-9845-82c69dedcf75-5e275613 {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-_77fdf264-8876-fedd-9845-82c69dedcf77-5e275613 {
      align-self: start
  }
  
  #w-node-_77fdf264-8876-fedd-9845-82c69dedcf8e-5e275613 {
      justify-self: center
  }
  
  #w-node-_77fdf264-8876-fedd-9845-82c69dedcf91-5e275613 {
      align-self: start
  }
  
  #w-node-_77fdf264-8876-fedd-9845-82c69dedcfa6-5e275613 {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-_77fdf264-8876-fedd-9845-82c69dedcfa8-5e275613 {
      align-self: start
  }
  
  #w-node-_77fdf264-8876-fedd-9845-82c69dedcfbf-5e275613 {
      justify-self: center
  }
  
  #w-node-_77fdf264-8876-fedd-9845-82c69dedcfc2-5e275613 {
      align-self: start
  }
  
  #w-node-_77fdf264-8876-fedd-9845-82c69dedcfd7-5e275613 {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-_77fdf264-8876-fedd-9845-82c69dedcfd9-5e275613 {
      align-self: start
  }
  
  #w-node-_7c3cee37-3fbd-0c7f-1f14-de1b1c3123e2-5e275613,#w-node-_0c5dacbd-1938-b526-c3eb-9b7b1846f00b-1846efee,#w-node-_0c5dacbd-1938-b526-c3eb-9b7b1846f060-1846efee,#w-node-feed3f58-455f-1671-61fb-dc2c0a9f0ac0-4b0c909b {
      justify-self: center
  }
  
  #w-node-feed3f58-455f-1671-61fb-dc2c0a9f0ad7-4b0c909b {
      align-self: start
  }
  
  #w-node-feed3f58-455f-1671-61fb-dc2c0a9f0ad5-4b0c909b {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-_0242e89a-d197-60f9-7df1-ac4ac06355e9-4b0c909b {
      align-self: start
  }
  
  #w-node-_2af0ce0a-2dc8-baa8-2666-097e02299c12-4b0c909b {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-_10cbc9bf-4275-2a57-ce5a-e368d1173725-4b0c909b,#w-node-d94fe5ba-9186-cb3b-ebb5-b7c442f3cf5c-5bed08fc {
      justify-self: center
  }
  
  #w-node-d94fe5ba-9186-cb3b-ebb5-b7c442f3cf5f-5bed08fc {
      align-self: start
  }
  
  #w-node-d94fe5ba-9186-cb3b-ebb5-b7c442f3cf74-5bed08fc {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-d94fe5ba-9186-cb3b-ebb5-b7c442f3cf75-5bed08fc {
      align-self: center
  }
  
  #w-node-d94fe5ba-9186-cb3b-ebb5-b7c442f3cf76-5bed08fc {
      align-self: start
  }
  
  #w-node-b189ff12-f737-b5a0-6b4b-71d4b9416ac7-5bed08fc {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-bb29d59d-79ee-4308-b155-3023bdb0fdfd-5bed08fc {
      align-self: end
  }
  
  #w-node-bb29d59d-79ee-4308-b155-3023bdb0fdfe-5bed08fc {
      align-self: start
  }
  
  #w-node-_10f87619-fd25-058c-e270-a1f41fe96a2d-5bed08fc {
      justify-self: center
  }
  
  #w-node-_10f87619-fd25-058c-e270-a1f41fe96a2f-5bed08fc {
      align-self: end
  }
  
  #w-node-_10f87619-fd25-058c-e270-a1f41fe96a30-5bed08fc {
      align-self: start
  }
  
  #w-node-_10f87619-fd25-058c-e270-a1f41fe96a45-5bed08fc {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-_10f87619-fd25-058c-e270-a1f41fe96a46-5bed08fc {
      align-self: center
  }
  
  #w-node-_10f87619-fd25-058c-e270-a1f41fe96a47-5bed08fc {
      align-self: start
  }
  
  #w-node-f6a61413-acc0-3ba8-1a77-cd8c5171031b-5bed08fc {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-_9c03905e-1adc-2186-a37a-72d88463df3a-5bed08fc,#w-node-_9c03905e-1adc-2186-a37a-72d88463df3b-5bed08fc {
      align-self: start
  }
  
  #w-node-_3c572920-1fb2-9823-0ae6-d824453f82a0-5bed08fc {
      justify-self: center
  }
  
  #w-node-_3c572920-1fb2-9823-0ae6-d824453f82a2-5bed08fc,#w-node-_3c572920-1fb2-9823-0ae6-d824453f82a3-5bed08fc {
      align-self: start
  }
  
  #w-node-_3c572920-1fb2-9823-0ae6-d824453f82b8-5bed08fc {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-_3c572920-1fb2-9823-0ae6-d824453f82b9-5bed08fc {
      align-self: center
  }
  
  #w-node-_3c572920-1fb2-9823-0ae6-d824453f82ba-5bed08fc {
      align-self: start
  }
  
  #w-node-_94a94724-1dd7-d8a6-98fe-989f236e8ec3-5bed08fc {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-_43695764-dd34-d59c-664c-98b7907e26a8-5bed08fc {
      align-self: end
  }
  
  #w-node-_43695764-dd34-d59c-664c-98b7907e26a9-5bed08fc,#w-node-b6c2e90a-5a85-24d3-1e23-4e4ca924999f-49f77b30 {
      align-self: start
  }
  
  #w-node-c27ea7cf-1259-352e-a4aa-b1d6bb21348e-49f77b30,#w-node-_960af0f3-9022-9f53-e1c2-f9f50aa1b849-49f77b30 {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-e0c46a3e-34d0-7d8f-6c47-b1dddcd298de-49f77b30,#w-node-e0c46a3e-34d0-7d8f-6c47-b1dddcd298e3-49f77b30 {
      align-self: start
  }
  
  #w-node-_17779785-c3c9-ba66-b694-d4156dcbd9af-49f77b30,#w-node-_17779785-c3c9-ba66-b694-d4156dcbda04-49f77b30 {
      justify-self: center
  }
  
  #w-node-c30f9c2a-266f-3c38-7398-b5eeed4cd5c7-1f6964f4 {
      align-self: start
  }
  
  #w-node-_930ec677-7835-f475-540b-45fa5d59a921-1f6964f4 {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-_930ec677-7835-f475-540b-45fa5d59a922-1f6964f4 {
      align-self: start
  }
  
  #w-node-b42db442-665a-795f-6980-e0fd8d3c4714-1f6964f4 {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28a-1f6964f4 {
      justify-self: center
  }
  
  #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28d-1f6964f4 {
      align-self: start
  }
  
  #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb2a2-1f6964f4 {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb2a4-1f6964f4,#w-node-c30f9c2a-266f-3c38-7398-b5eeed4cd5c7-79d23788 {
      align-self: start
  }
  
  #w-node-_930ec677-7835-f475-540b-45fa5d59a921-79d23788 {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-_930ec677-7835-f475-540b-45fa5d59a922-79d23788 {
      align-self: start
  }
  
  #w-node-b42db442-665a-795f-6980-e0fd8d3c4714-79d23788 {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28a-79d23788 {
      justify-self: center
  }
  
  #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28d-79d23788 {
      align-self: start
  }
  
  #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb2a2-79d23788 {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb2a4-79d23788,#w-node-c30f9c2a-266f-3c38-7398-b5eeed4cd5c7-bed23826 {
      align-self: start
  }
  
  #w-node-_930ec677-7835-f475-540b-45fa5d59a921-bed23826 {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-_930ec677-7835-f475-540b-45fa5d59a922-bed23826 {
      align-self: start
  }
  
  #w-node-b42db442-665a-795f-6980-e0fd8d3c4714-bed23826 {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28a-bed23826 {
      justify-self: center
  }
  
  #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28d-bed23826 {
      align-self: start
  }
  
  #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb2a2-bed23826 {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb2a4-bed23826,#w-node-c30f9c2a-266f-3c38-7398-b5eeed4cd5c7-9fcb4fcc {
      align-self: start
  }
  
  #w-node-_930ec677-7835-f475-540b-45fa5d59a921-9fcb4fcc {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-_930ec677-7835-f475-540b-45fa5d59a922-9fcb4fcc {
      align-self: start
  }
  
  #w-node-b42db442-665a-795f-6980-e0fd8d3c4714-9fcb4fcc {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28a-9fcb4fcc {
      justify-self: center
  }
  
  #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28d-9fcb4fcc {
      align-self: start
  }
  
  #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb2a2-9fcb4fcc {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb2a4-9fcb4fcc,#w-node-c30f9c2a-266f-3c38-7398-b5eeed4cd5c7-8154dc5a {
      align-self: start
  }
  
  #w-node-_930ec677-7835-f475-540b-45fa5d59a921-8154dc5a {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-_930ec677-7835-f475-540b-45fa5d59a922-8154dc5a {
      align-self: start
  }
  
  #w-node-b42db442-665a-795f-6980-e0fd8d3c4714-8154dc5a {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28a-8154dc5a {
      justify-self: center
  }
  
  #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28d-8154dc5a {
      align-self: start
  }
  
  #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb2a2-8154dc5a {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb2a4-8154dc5a,#w-node-c30f9c2a-266f-3c38-7398-b5eeed4cd5c7-8a7761f1 {
      align-self: start
  }
  
  #w-node-_930ec677-7835-f475-540b-45fa5d59a921-8a7761f1 {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-_930ec677-7835-f475-540b-45fa5d59a922-8a7761f1 {
      align-self: start
  }
  
  #w-node-b42db442-665a-795f-6980-e0fd8d3c4714-8a7761f1 {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28a-8a7761f1 {
      justify-self: center
  }
  
  #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28d-8a7761f1 {
      align-self: start
  }
  
  #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb2a2-8a7761f1 {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb2a4-8a7761f1,#w-node-c30f9c2a-266f-3c38-7398-b5eeed4cd5c7-3a40589d {
      align-self: start
  }
  
  #w-node-_930ec677-7835-f475-540b-45fa5d59a921-3a40589d {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-_930ec677-7835-f475-540b-45fa5d59a922-3a40589d {
      align-self: start
  }
  
  #w-node-b42db442-665a-795f-6980-e0fd8d3c4714-3a40589d {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28a-3a40589d {
      justify-self: center
  }
  
  #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28d-3a40589d {
      align-self: start
  }
  
  #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb2a2-3a40589d {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb2a4-3a40589d,#w-node-c30f9c2a-266f-3c38-7398-b5eeed4cd5c7-44e6322c {
      align-self: start
  }
  
  #w-node-_930ec677-7835-f475-540b-45fa5d59a921-44e6322c {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-_930ec677-7835-f475-540b-45fa5d59a922-44e6322c {
      align-self: start
  }
  
  #w-node-b42db442-665a-795f-6980-e0fd8d3c4714-44e6322c {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28a-44e6322c {
      justify-self: center
  }
  
  #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28d-44e6322c {
      align-self: start
  }
  
  #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb2a2-44e6322c {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb2a4-44e6322c,#w-node-c30f9c2a-266f-3c38-7398-b5eeed4cd5c7-79aa73fb {
      align-self: start
  }
  
  #w-node-_930ec677-7835-f475-540b-45fa5d59a921-79aa73fb {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-_930ec677-7835-f475-540b-45fa5d59a922-79aa73fb {
      align-self: start
  }
  
  #w-node-b42db442-665a-795f-6980-e0fd8d3c4714-79aa73fb {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28a-79aa73fb {
      justify-self: center
  }
  
  #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28d-79aa73fb {
      align-self: start
  }
  
  #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb2a2-79aa73fb {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb2a4-79aa73fb,#w-node-c30f9c2a-266f-3c38-7398-b5eeed4cd5c7-d12993f7 {
      align-self: start
  }
  
  #w-node-_930ec677-7835-f475-540b-45fa5d59a921-d12993f7 {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-_930ec677-7835-f475-540b-45fa5d59a922-d12993f7 {
      align-self: start
  }
  
  #w-node-b42db442-665a-795f-6980-e0fd8d3c4714-d12993f7 {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28a-d12993f7 {
      justify-self: center
  }
  
  #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28d-d12993f7 {
      align-self: start
  }
  
  #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb2a2-d12993f7 {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb2a4-d12993f7,#w-node-c30f9c2a-266f-3c38-7398-b5eeed4cd5c7-cf7ad0a4 {
      align-self: start
  }
  
  #w-node-_930ec677-7835-f475-540b-45fa5d59a921-cf7ad0a4 {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-_930ec677-7835-f475-540b-45fa5d59a922-cf7ad0a4 {
      align-self: start
  }
  
  #w-node-b42db442-665a-795f-6980-e0fd8d3c4714-cf7ad0a4 {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28a-cf7ad0a4 {
      justify-self: center
  }
  
  #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28d-cf7ad0a4 {
      align-self: start
  }
  
  #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb2a2-cf7ad0a4 {
      grid-area: span 1/span 1/span 1/span 1
  }
  
  #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb2a4-cf7ad0a4 {
      align-self: start
  }
  
  @media screen and (max-width: 991px) {
      #w-node-_23d99870-a857-978e-fc3a-c4f947a2bb9f-45a291a3 {
          grid-area:1/3/2/4
      }
  
      #w-node-c41528ae-e7a8-b02a-3181-20bcb705bbd1-45a291a3 {
          grid-area: 1/1/2/2
      }
  
      #w-node-_00f3c34e-c30d-fd2d-b161-4dd5ec7c2dfe-ec7c2d89 {
          grid-area: span 1/span 1/span 1/span 1;
          justify-self: auto
      }
  
      #w-node-feed3f58-455f-1671-61fb-dc2c0a9f0ad6-e6a291aa {
          grid-area: 1/3/2/4
      }
  
      #w-node-_0242e89a-d197-60f9-7df1-ac4ac06355e8-e6a291aa {
          grid-area: 1/1/2/2
      }
  
      #w-node-d94fe5ba-9186-cb3b-ebb5-b7c442f3cf5e-1ed1d9af {
          grid-area: 1/1/2/4
      }
  
      #w-node-bb29d59d-79ee-4308-b155-3023bdb0fdfd-1ed1d9af {
          grid-area: 2/1/3/2
      }
  
      #w-node-_10f87619-fd25-058c-e270-a1f41fe96a2f-1ed1d9af {
          grid-area: 1/1/2/4
      }
  
      #w-node-_10f87619-fd25-058c-e270-a1f41fe96a46-1ed1d9af {
          grid-area: 2/1/3/2;
          align-self: auto
      }
  
      #w-node-_9c03905e-1adc-2186-a37a-72d88463df3a-1ed1d9af {
          grid-area: 2/3/3/4
      }
  
      #w-node-_3c572920-1fb2-9823-0ae6-d824453f82a2-1ed1d9af {
          grid-area: 1/1/2/4
      }
  
      #w-node-_3c572920-1fb2-9823-0ae6-d824453f82b9-1ed1d9af {
          grid-area: 2/3/3/4
      }
  
      #w-node-_43695764-dd34-d59c-664c-98b7907e26a8-1ed1d9af {
          grid-area: 2/1/3/2;
          align-self: auto
      }
  
      #w-node-e0c46a3e-34d0-7d8f-6c47-b1dddcd298dd-16ae02d1 {
          grid-area: span 1/span 1/span 1/span 1
      }
  
      #w-node-e0c46a3e-34d0-7d8f-6c47-b1dddcd298e2-16ae02d1 {
          grid-area: 1/1/2/2
      }
  
      #w-node-_797d4d05-4e30-6ba5-167a-ddc5ec5b023a-16ae02d1 {
          grid-area: span 1/span 1/span 1/span 1;
          justify-self: auto
      }
  
      #w-node-_23d99870-a857-978e-fc3a-c4f947a2bb9f-5e275613 {
          grid-area: 1/3/2/4
      }
  
      #w-node-c41528ae-e7a8-b02a-3181-20bcb705bbd1-5e275613 {
          grid-area: 1/1/2/2
      }
  
      #w-node-_0c5dacbd-1938-b526-c3eb-9b7b1846f066-1846efee {
          grid-area: span 1/span 1/span 1/span 1;
          justify-self: auto
      }
  
      #w-node-feed3f58-455f-1671-61fb-dc2c0a9f0ad6-4b0c909b {
          grid-area: 1/3/2/4
      }
  
      #w-node-_0242e89a-d197-60f9-7df1-ac4ac06355e8-4b0c909b {
          grid-area: 1/1/2/2
      }
  
      #w-node-d94fe5ba-9186-cb3b-ebb5-b7c442f3cf5e-5bed08fc {
          grid-area: 1/1/2/4
      }
  
      #w-node-bb29d59d-79ee-4308-b155-3023bdb0fdfd-5bed08fc {
          grid-area: 2/1/3/2
      }
  
      #w-node-_10f87619-fd25-058c-e270-a1f41fe96a2f-5bed08fc {
          grid-area: 1/1/2/4
      }
  
      #w-node-_10f87619-fd25-058c-e270-a1f41fe96a46-5bed08fc {
          grid-area: 2/1/3/2;
          align-self: auto
      }
  
      #w-node-_9c03905e-1adc-2186-a37a-72d88463df3a-5bed08fc {
          grid-area: 2/3/3/4
      }
  
      #w-node-_3c572920-1fb2-9823-0ae6-d824453f82a2-5bed08fc {
          grid-area: 1/1/2/4
      }
  
      #w-node-_3c572920-1fb2-9823-0ae6-d824453f82b9-5bed08fc {
          grid-area: 2/3/3/4
      }
  
      #w-node-_43695764-dd34-d59c-664c-98b7907e26a8-5bed08fc {
          grid-area: 2/1/3/2;
          align-self: auto
      }
  
      #w-node-e0c46a3e-34d0-7d8f-6c47-b1dddcd298dd-49f77b30 {
          grid-area: span 1/span 1/span 1/span 1
      }
  
      #w-node-e0c46a3e-34d0-7d8f-6c47-b1dddcd298e2-49f77b30 {
          grid-area: 1/1/2/2
      }
  
      #w-node-_17779785-c3c9-ba66-b694-d4156dcbda0a-49f77b30 {
          grid-area: span 1/span 1/span 1/span 1;
          justify-self: auto
      }
  }
  
  @media screen and (max-width: 767px) {
      #w-node-_23d99870-a857-978e-fc3a-c4f947a2bb9f-45a291a3,#w-node-feed3f58-455f-1671-61fb-dc2c0a9f0ad6-e6a291aa {
          grid-area:2/1/3/2
      }
  
      #w-node-_0242e89a-d197-60f9-7df1-ac4ac06355e8-e6a291aa {
          grid-area: 1/1/2/2
      }
  
      #w-node-d94fe5ba-9186-cb3b-ebb5-b7c442f3cf75-1ed1d9af {
          grid-area: 2/1/3/4
      }
  
      #w-node-bb29d59d-79ee-4308-b155-3023bdb0fdfd-1ed1d9af {
          grid-row: 3/4;
          grid-column-end: 4
      }
  
      #w-node-_10f87619-fd25-058c-e270-a1f41fe96a46-1ed1d9af {
          grid-column-end: 4
      }
  
      #w-node-_9c03905e-1adc-2186-a37a-72d88463df3a-1ed1d9af {
          grid-row: 3/4;
          grid-column-start: 1
      }
  
      #w-node-_3c572920-1fb2-9823-0ae6-d824453f82b9-1ed1d9af {
          grid-column-start: 1
      }
  
      #w-node-_43695764-dd34-d59c-664c-98b7907e26a8-1ed1d9af {
          grid-row: 3/4;
          grid-column-end: 4
      }
  
      #w-node-e0c46a3e-34d0-7d8f-6c47-b1dddcd298dd-16ae02d1 {
          grid-area: span 1/span 1/span 1/span 1
      }
  
      #w-node-e0c46a3e-34d0-7d8f-6c47-b1dddcd298e2-16ae02d1 {
          grid-area: 1/1/2/2
      }
  
      #w-node-_23d99870-a857-978e-fc3a-c4f947a2bb9f-5e275613,#w-node-feed3f58-455f-1671-61fb-dc2c0a9f0ad6-4b0c909b {
          grid-area: 2/1/3/2
      }
  
      #w-node-_0242e89a-d197-60f9-7df1-ac4ac06355e8-4b0c909b {
          grid-area: 1/1/2/2
      }
  
      #w-node-d94fe5ba-9186-cb3b-ebb5-b7c442f3cf75-5bed08fc {
          grid-area: 2/1/3/4
      }
  
      #w-node-bb29d59d-79ee-4308-b155-3023bdb0fdfd-5bed08fc {
          grid-row: 3/4;
          grid-column-end: 4
      }
  
      #w-node-_10f87619-fd25-058c-e270-a1f41fe96a46-5bed08fc {
          grid-column-end: 4
      }
  
      #w-node-_9c03905e-1adc-2186-a37a-72d88463df3a-5bed08fc {
          grid-row: 3/4;
          grid-column-start: 1
      }
  
      #w-node-_3c572920-1fb2-9823-0ae6-d824453f82b9-5bed08fc {
          grid-column-start: 1
      }
  
      #w-node-_43695764-dd34-d59c-664c-98b7907e26a8-5bed08fc {
          grid-row: 3/4;
          grid-column-end: 4
      }
  
      #w-node-e0c46a3e-34d0-7d8f-6c47-b1dddcd298dd-49f77b30 {
          grid-area: span 1/span 1/span 1/span 1
      }
  
      #w-node-e0c46a3e-34d0-7d8f-6c47-b1dddcd298e2-49f77b30 {
          grid-area: 1/1/2/2
      }
  }
  
  @media screen and (max-width: 479px) {
      #w-node-fc472468-3e1d-6df9-e2a0-778fc09b5b44-45a291a3 {
          justify-self:auto
      }
  
      #w-node-_23d99870-a857-978e-fc3a-c4f947a2bb9f-45a291a3 {
          grid-area: 2/1/3/2
      }
  
      #w-node-_77fdf264-8876-fedd-9845-82c69dedcf5d-45a291a3 {
          justify-self: auto
      }
  
      #w-node-_77fdf264-8876-fedd-9845-82c69dedcfa6-45a291a3 {
          grid-area: 2/1/3/2
      }
  
      #w-node-_7c3cee37-3fbd-0c7f-1f14-de1b1c3123e2-45a291a3,#w-node-_00f3c34e-c30d-fd2d-b161-4dd5ec7c2da6-ec7c2d89,#w-node-_00f3c34e-c30d-fd2d-b161-4dd5ec7c2df8-ec7c2d89,#w-node-feed3f58-455f-1671-61fb-dc2c0a9f0ac0-e6a291aa {
          justify-self: auto
      }
  
      #w-node-feed3f58-455f-1671-61fb-dc2c0a9f0ad6-e6a291aa {
          grid-area: 2/1/3/2
      }
  
      #w-node-_0242e89a-d197-60f9-7df1-ac4ac06355e8-e6a291aa {
          grid-area: 1/1/2/2
      }
  
      #w-node-_04174c97-f3bb-5f13-90f9-6694665c0df3-e6a291aa,#w-node-d94fe5ba-9186-cb3b-ebb5-b7c442f3cf5c-1ed1d9af,#w-node-_10f87619-fd25-058c-e270-a1f41fe96a2d-1ed1d9af,#w-node-_3c572920-1fb2-9823-0ae6-d824453f82a0-1ed1d9af {
          justify-self: auto
      }
  
      #w-node-e0c46a3e-34d0-7d8f-6c47-b1dddcd298dd-16ae02d1 {
          grid-area: span 1/span 1/span 1/span 1
      }
  
      #w-node-e0c46a3e-34d0-7d8f-6c47-b1dddcd298e2-16ae02d1 {
          grid-area: 1/1/2/2
      }
  
      #w-node-a8f04089-aafe-0feb-6173-2b5b071dd98f-16ae02d1,#w-node-_797d4d05-4e30-6ba5-167a-ddc5ec5b0234-16ae02d1,#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28a-0a745c56,#w-node-fc472468-3e1d-6df9-e2a0-778fc09b5b44-5e275613 {
          justify-self: auto
      }
  
      #w-node-_23d99870-a857-978e-fc3a-c4f947a2bb9f-5e275613 {
          grid-area: 2/1/3/2
      }
  
      #w-node-_77fdf264-8876-fedd-9845-82c69dedcf5d-5e275613 {
          justify-self: auto
      }
  
      #w-node-_77fdf264-8876-fedd-9845-82c69dedcfa6-5e275613 {
          grid-area: 2/1/3/2
      }
  
      #w-node-_7c3cee37-3fbd-0c7f-1f14-de1b1c3123e2-5e275613,#w-node-_0c5dacbd-1938-b526-c3eb-9b7b1846f00b-1846efee,#w-node-_0c5dacbd-1938-b526-c3eb-9b7b1846f060-1846efee,#w-node-feed3f58-455f-1671-61fb-dc2c0a9f0ac0-4b0c909b {
          justify-self: auto
      }
  
      #w-node-feed3f58-455f-1671-61fb-dc2c0a9f0ad6-4b0c909b {
          grid-area: 2/1/3/2
      }
  
      #w-node-_0242e89a-d197-60f9-7df1-ac4ac06355e8-4b0c909b {
          grid-area: 1/1/2/2
      }
  
      #w-node-_10cbc9bf-4275-2a57-ce5a-e368d1173725-4b0c909b,#w-node-d94fe5ba-9186-cb3b-ebb5-b7c442f3cf5c-5bed08fc,#w-node-_10f87619-fd25-058c-e270-a1f41fe96a2d-5bed08fc,#w-node-_3c572920-1fb2-9823-0ae6-d824453f82a0-5bed08fc {
          justify-self: auto
      }
  
      #w-node-e0c46a3e-34d0-7d8f-6c47-b1dddcd298dd-49f77b30 {
          grid-area: span 1/span 1/span 1/span 1
      }
  
      #w-node-e0c46a3e-34d0-7d8f-6c47-b1dddcd298e2-49f77b30 {
          grid-area: 1/1/2/2
      }
  
      #w-node-_17779785-c3c9-ba66-b694-d4156dcbd9af-49f77b30,#w-node-_17779785-c3c9-ba66-b694-d4156dcbda04-49f77b30,#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28a-1f6964f4,#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28a-79d23788,#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28a-bed23826,#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28a-9fcb4fcc,#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28a-8154dc5a,#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28a-8a7761f1,#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28a-3a40589d,#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28a-44e6322c,#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28a-79aa73fb,#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28a-d12993f7,#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28a-cf7ad0a4 {
          justify-self: auto
      }
  }
  
  @font-face {
      font-family: 'Satoshi';
      src: url('https://assets.website-files.com/63cc467bfbf4f5ebbd16107a/63cc4a55b9a0713f9579b702_Satoshi-Italic.woff2') format('woff2');
      font-weight: 400;
      font-style: italic;
      font-display: swap;
  }
  
  @font-face {
      font-family: 'Satoshi';
      src: url('https://assets.website-files.com/63cc467bfbf4f5ebbd16107a/63cc4a55d802df39367aa87f_Satoshi-Regular.woff2') format('woff2');
      font-weight: 400;
      font-style: normal;
      font-display: swap;
  }
  
  @font-face {
      font-family: 'Satoshi';
      src: url('https://assets.website-files.com/63cc467bfbf4f5ebbd16107a/63cc4a5557b94ef726d334a5_Satoshi-Bold.woff2') format('woff2');
      font-weight: 700;
      font-style: normal;
      font-display: swap;
  }
  
  @font-face {
      font-family: 'Satoshi';
      src: url('https://assets.website-files.com/63cc467bfbf4f5ebbd16107a/63cc4a5515116f77d55ecdbf_Satoshi-Medium.woff2') format('woff2');
      font-weight: 500;
      font-style: normal;
      font-display: swap;
  }
  
  @font-face {
      font-family: 'Generalsans';
      src: url('https://assets.website-files.com/63cc467bfbf4f5ebbd16107a/63cec3ea4ad20b7a202982e5_GeneralSans-Light.woff2') format('woff2');
      font-weight: 300;
      font-style: normal;
      font-display: swap;
  }
  
  @font-face {
      font-family: 'Generalsans';
      src: url('https://assets.website-files.com/63cc467bfbf4f5ebbd16107a/63cec3f5c5fda35e6c00eedb_GeneralSans-Regular.woff2') format('woff2');
      font-weight: 400;
      font-style: normal;
      font-display: swap;
  }
  
  @font-face {
      font-family: 'Gambetta';
      src: url('https://assets.website-files.com/63cc467bfbf4f5ebbd16107a/63cc5f89763a9e4773479b73_Gambetta-Italic.ttf') format('truetype');
      font-weight: 400;
      font-style: italic;
      font-display: swap;
  }
  
        /* </style> */


        /* <style type="text/css" id="server-side-jss"> */
        .button-0-3-39 {
          color: currentColor;
          border: 1px solid var(--foreground);
          cursor: pointer;
          margin: 0;
          display: inline-flex;
          padding: 7.5px 15px;
          overflow: hidden;
          position: relative;
          font-size: 10px;
          background: transparent;
          transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
          align-items: center;
          line-height: 1.6;
          border-radius: 80px;
          letter-spacing: 0.5px;
          text-transform: uppercase;
          justify-content: center;
          text-decoration: none;
        }
        .button-0-3-39:disabled {
          color: currentColor;
          cursor: inherit;
          opacity: 1.5;
          background: transparent;
        }
        .clone-0-3-40 {
          top: 0;
          left: 0;
          color: var(--background);
          right: 0;
          bottom: 0;
          overflow: hidden;
          position: absolute;
          transform: translateY(110%);
          background-color: var(--foreground);
        }
        .button-0-3-39.selected .clone-0-3-40 {
          transform: translateY(0%);
        }
        .cloneContent-0-3-41 {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: inline-flex;
          padding: 7.5px 15px;
          position: absolute;
          transform: translateY(-110%);
          align-items: center;
          justify-content: center;
        }
        .button-0-3-39.selected .cloneContent-0-3-41 {
          transform: translateY(0%);
        }
        .icon-0-3-42 {
          width: 18px;
          display: block;
        }
        .icon-0-3-42.backArrow {
          transform: rotate(180deg);
        }
        .iconContainer-0-3-43 {
          display: block;
          overflow: hidden;
          margin-top: 1px;
          margin-left: 5px;
        }
        .iconContainer-0-3-43.backArrow {
          margin-left: 0;
          margin-right: 5px;
        }
        .Layout-section-0-3-33.gutter {
          margin-left: 15px;
          margin-right: 15px;
        }
        .Layout-section-0-3-33.bottomMargin {
          margin-bottom: 75px;
        }
        .Layout-section-0-3-33.grid {
          gap: 15px;
          display: grid;
          grid-template-columns: repeat(4, 1fr);
        }
        @media (min-width: 1024px) {
          .Layout-section-0-3-33.grid {
            gap: 25px;
            grid-template-columns: repeat(7, 1fr);
            /* margin-left: 1rem; */
          }
        }
        @media (min-width: 1024px) {
          .Layout-section-0-3-33.bottomMargin {
            /* margin-bottom: 100px; */
          }
        }
        @media (min-width: 1024px) {
          .Layout-section-0-3-33.gutter {
            margin-left: 25px;
            margin-right: 25px;
            /* margin-top: 4.6rem; */
          }
        }
        .caption-0-3-237 {
          font-size: 10px;
          font-family: "ABCMonumentGroteskMono", "Lucida Sans", Tahoma, sans-serif;
          line-height: 1.2;
          white-space: pre-wrap;
          text-transform: uppercase;
        }
        .imageContainer-0-3-92 {
          width: 100%;
          display: block;
          position: relative;
        }
        .imageContainer-0-3-92 picture::before {
          display: block;
        }
        @media (min-width: 1024px) {
          .imageContainer-0-3-92 {
          }
          .imageContainer-0-3-92 picture::before {
          }
        }
        .imageContainer-0-3-92.overflowHidden {
          overflow: hidden;
        }
        .picture-0-3-93 img{
            height: 800px;
            width: 800px;
        }
        .image-0-3-94 {
          top: 0;
          left: 0;
          right: 0;
          width: 100%;
          bottom: 0;
          height: 100%;
          position: absolute;
          object-fit: cover;
        }
        .image-0-3-94.fadeIn {
          opacity: 1;
        }
        .caption-0-3-95 {
          top: calc(100% + 10px);
          right: 0;
          position: absolute;
          font-size: 10px;
          font-family: "ABCMonumentGroteskMono", "Lucida Sans", Tahoma, sans-serif;
        }
        .imageContainer-d0-0-3-96 {
        }
        .imageContainer-d0-0-3-96 picture::before {
          content: "";
          padding-top: 66.74082313681869%;
        }
        @media (min-width: 1024px) {
          .imageContainer-d0-0-3-96 {
          }
          .imageContainer-d0-0-3-96 picture::before {
            padding-top: 66.74082313681869%;
          }
        }
        .image-d2-0-3-97 {
          object-position: 50% 50%;
        }
        .imageContainer-d3-0-3-241 {
        }
        .imageContainer-d3-0-3-241 picture::before {
          content: "";
          padding-top: 122.66666666666667%;
        }
        @media (min-width: 1024px) {
          .imageContainer-d3-0-3-241 {
          }
          .imageContainer-d3-0-3-241 picture::before {
            padding-top: 56.25%;
          }
        }
        .image-d5-0-3-242 {
          object-position: 50% 50%;
        }
        .imageContainer-d6-0-3-281 {
        }
        .imageContainer-d6-0-3-281 picture::before {
          /* content: ""; */
          padding-top: 100%;
        }
        @media (min-width: 1024px) {
          .imageContainer-d6-0-3-281 {
          }
          .imageContainer-d6-0-3-281 picture::before {
            padding-top: 100%;
          }
        }
        .image-d8-0-3-282 {
          object-position: 50% 50%;
        }
        .imageContainer-d9-0-3-287 {
        }
        .imageContainer-d9-0-3-287 picture::before {
          content: "";
          padding-top: 100%;
        }
        @media (min-width: 1024px) {
          .imageContainer-d9-0-3-287 {
          }
          .imageContainer-d9-0-3-287 picture::before {
            padding-top: 100%;
          }
        }
        .image-d11-0-3-288 {
          object-position: 50% 50%;
        }
        .imageContainer-d12-0-3-290 {
        }
        .imageContainer-d12-0-3-290 picture::before {
          content: "";
          padding-top: 157.14285714285714%;
        }
        @media (min-width: 1024px) {
          .imageContainer-d12-0-3-290 {
          }
          .imageContainer-d12-0-3-290 picture::before {
            padding-top: 157.14285714285714%;
          }
        }
        .image-d14-0-3-291 {
          object-position: 50% 50%;
        }
        .imageContainer-d15-0-3-293 {
        }
        .imageContainer-d15-0-3-293 picture::before {
          content: "";
          padding-top: 157.14285714285714%;
        }
        @media (min-width: 1024px) {
          .imageContainer-d15-0-3-293 {
          }
          .imageContainer-d15-0-3-293 picture::before {
            padding-top: 157.14285714285714%;
          }
        }
        .image-d17-0-3-294 {
          object-position: 50% 50%;
        }
        .imageContainer-d18-0-3-308 {
        }
        .imageContainer-d18-0-3-308 picture::before {
          content: "";
          padding-top: 100%;
        }
        @media (min-width: 1024px) {
          .imageContainer-d18-0-3-308 {
          }
          .imageContainer-d18-0-3-308 picture::before {
            padding-top: 100%;
          }
        }
        .image-d20-0-3-309 {
          object-position: 50% 50%;
        }
        .imageContainer-d21-0-3-311 {
        }
        .imageContainer-d21-0-3-311 picture::before {
          content: "";
          padding-top: 75.86206896551724%;
        }
        @media (min-width: 1024px) {
          .imageContainer-d21-0-3-311 {
          }
          .imageContainer-d21-0-3-311 picture::before {
            padding-top: 75.86206896551724%;
          }
        }
        .image-d23-0-3-312 {
          object-position: 50% 50%;
        }
        .imageContainer-d24-0-3-314 {
        }
        .imageContainer-d24-0-3-314 picture::before {
          content: "";
          padding-top: 157.14285714285714%;
        }
        @media (min-width: 1024px) {
          .imageContainer-d24-0-3-314 {
          }
          .imageContainer-d24-0-3-314 picture::before {
            padding-top: 157.14285714285714%;
          }
        }
        .image-d26-0-3-315 {
          object-position: 50% 50%;
        }
        .imageContainer-d27-0-3-317 {
        }
        .imageContainer-d27-0-3-317 picture::before {
          content: "";
          padding-top: 100%;
        }
        @media (min-width: 1024px) {
          .imageContainer-d27-0-3-317 {
          }
          .imageContainer-d27-0-3-317 picture::before {
            padding-top: 100%;
          }
        }
        .image-d29-0-3-318 {
          object-position: 50% 50%;
        }
        .imageContainer-d30-0-3-320 {
        }
        .imageContainer-d30-0-3-320 picture::before {
          content: "";
          padding-top: 100%;
        }
        @media (min-width: 1024px) {
          .imageContainer-d30-0-3-320 {
          }
          .imageContainer-d30-0-3-320 picture::before {
            padding-top: 100%;
          }
        }
        .image-d32-0-3-321 {
          object-position: 50% 50%;
        }
        .imageContainer-d33-0-3-335 {
        }
        .imageContainer-d33-0-3-335 picture::before {
          content: "";
          padding-top: 100%;
        }
        @media (min-width: 1024px) {
          .imageContainer-d33-0-3-335 {
          }
          .imageContainer-d33-0-3-335 picture::before {
            padding-top: 100%;
          }
        }
        .image-d35-0-3-336 {
          object-position: 50% 50%;
        }
        .imageContainer-d36-0-3-338 {
        }
        .imageContainer-d36-0-3-338 picture::before {
          content: "";
          padding-top: 157.14285714285714%;
        }
        @media (min-width: 1024px) {
          .imageContainer-d36-0-3-338 {
          }
          .imageContainer-d36-0-3-338 picture::before {
            padding-top: 157.14285714285714%;
          }
        }
        .image-d38-0-3-339 {
          object-position: 50% 50%;
        }
        .imageContainer-d39-0-3-341 {
        }
        .imageContainer-d39-0-3-341 picture::before {
          content: "";
          padding-top: 157.14285714285714%;
        }
        @media (min-width: 1024px) {
          .imageContainer-d39-0-3-341 {
          }
          .imageContainer-d39-0-3-341 picture::before {
            padding-top: 157.14285714285714%;
          }
        }
        .image-d41-0-3-342 {
          object-position: 50% 50%;
        }
        .imageContainer-d42-0-3-344 {
        }
        .imageContainer-d42-0-3-344 picture::before {
          content: "";
          padding-top: 75.86206896551724%;
        }
        @media (min-width: 1024px) {
          .imageContainer-d42-0-3-344 {
          }
          .imageContainer-d42-0-3-344 picture::before {
            padding-top: 75.86206896551724%;
          }
        }
        .image-d44-0-3-345 {
          object-position: 50% 50%;
        }
        .imageContainer-d45-0-3-376 {
        }
        .imageContainer-d45-0-3-376 picture::before {
          content: "";
          padding-top: 74.12790697674419%;
        }
        @media (min-width: 1024px) {
          .imageContainer-d45-0-3-376 {
          }
          .imageContainer-d45-0-3-376 picture::before {
            padding-top: 80.82191780821917%;
          }
        }
        .image-d47-0-3-377 {
          object-position: 50% 50%;
        }
        .imageContainer-d48-0-3-381 {
        }
        .imageContainer-d48-0-3-381 picture::before {
          content: "";
          padding-top: 74.12790697674419%;
        }
        @media (min-width: 1024px) {
          .imageContainer-d48-0-3-381 {
          }
          .imageContainer-d48-0-3-381 picture::before {
            padding-top: 124.21052631578948%;
          }
        }
        .image-d50-0-3-382 {
          object-position: 50% 50%;
        }
        .imageContainer-d51-0-3-386 {
        }
        .imageContainer-d51-0-3-386 picture::before {
          content: "";
          padding-top: 74.12790697674419%;
        }
        @media (min-width: 1024px) {
          .imageContainer-d51-0-3-386 {
          }
          .imageContainer-d51-0-3-386 picture::before {
            padding-top: 124.21052631578948%;
          }
        }
        .image-d53-0-3-387 {
          object-position: 50% 50%;
        }
        .imageContainer-d54-0-3-391 {
        }
        .imageContainer-d54-0-3-391 picture::before {
          content: "";
          padding-top: 74.12790697674419%;
        }
        @media (min-width: 1024px) {
          .imageContainer-d54-0-3-391 {
          }
          .imageContainer-d54-0-3-391 picture::before {
            padding-top: 80.82191780821917%;
          }
        }
        .image-d56-0-3-392 {
          object-position: 50% 50%;
        }
        .imageContainer-d57-0-3-396 {
        }
        .imageContainer-d57-0-3-396 picture::before {
          content: "";
          padding-top: 74.12790697674419%;
        }
        @media (min-width: 1024px) {
          .imageContainer-d57-0-3-396 {
          }
          .imageContainer-d57-0-3-396 picture::before {
            padding-top: 124.21052631578948%;
          }
        }
        .image-d59-0-3-397 {
          object-position: 50% 50%;
        }
        .imageContainer-d60-0-3-401 {
        }
        .imageContainer-d60-0-3-401 picture::before {
          content: "";
          padding-top: 74.12790697674419%;
        }
        @media (min-width: 1024px) {
          .imageContainer-d60-0-3-401 {
          }
          .imageContainer-d60-0-3-401 picture::before {
            padding-top: 124.21052631578948%;
          }
        }
        .image-d62-0-3-402 {
          object-position: 50% 50%;
        }
        .imageContainer-d63-0-3-420 {
        }
        .imageContainer-d63-0-3-420 picture::before {
          content: "";
          padding-top: 55.26315789473684%;
        }
        @media (min-width: 1024px) {
          .imageContainer-d63-0-3-420 {
          }
          .imageContainer-d63-0-3-420 picture::before {
            padding-top: 55.26315789473684%;
          }
        }
        .image-d65-0-3-421 {
          object-position: 50% 50%;
        }
        .imageContainer-d66-0-3-426 {
        }
        .imageContainer-d66-0-3-426 picture::before {
          content: "";
          padding-top: 55.26315789473684%;
        }
        @media (min-width: 1024px) {
          .imageContainer-d66-0-3-426 {
          }
          .imageContainer-d66-0-3-426 picture::before {
            padding-top: 55.26315789473684%;
          }
        }
        .image-d68-0-3-427 {
          object-position: 50% 50%;
        }
        .imageContainer-d69-0-3-432 {
        }
        .imageContainer-d69-0-3-432 picture::before {
          content: "";
          padding-top: 55.26315789473684%;
        }
        @media (min-width: 1024px) {
          .imageContainer-d69-0-3-432 {
          }
          .imageContainer-d69-0-3-432 picture::before {
            padding-top: 55.26315789473684%;
          }
        }
        .image-d71-0-3-433 {
          object-position: 50% 50%;
        }
        .imageContainer-d72-0-3-492 {
        }
        .imageContainer-d72-0-3-492 picture::before {
          content: "";
          padding-top: 100%;
        }
        @media (min-width: 1024px) {
          .imageContainer-d72-0-3-492 {
          }
          .imageContainer-d72-0-3-492 picture::before {
            padding-top: 100%;
          }
        }
        .image-d74-0-3-493 {
          object-position: 50% 50%;
        }
        .imageContainer-d75-0-3-494 {
        }
        .imageContainer-d75-0-3-494 picture::before {
          content: "";
          padding-top: 100%;
        }
        @media (min-width: 1024px) {
          .imageContainer-d75-0-3-494 {
          }
          .imageContainer-d75-0-3-494 picture::before {
            padding-top: 100%;
          }
        }
        .image-d77-0-3-495 {
          object-position: 50% 50%;
        }
        .imageContainer-d78-0-3-496 {
        }
        .imageContainer-d78-0-3-496 picture::before {
          content: "";
          padding-top: 100%;
        }
        @media (min-width: 1024px) {
          .imageContainer-d78-0-3-496 {
          }
          .imageContainer-d78-0-3-496 picture::before {
            padding-top: 100%;
          }
        }
        .image-d80-0-3-497 {
          object-position: 50% 50%;
        }
        .imageContainer-d81-0-3-498 {
        }
        .imageContainer-d81-0-3-498 picture::before {
          content: "";
          padding-top: 100%;
        }
        @media (min-width: 1024px) {
          .imageContainer-d81-0-3-498 {
          }
          .imageContainer-d81-0-3-498 picture::before {
            padding-top: 100%;
          }
        }
        .image-d83-0-3-499 {
          object-position: 50% 50%;
        }
        .imageContainer-d84-0-3-500 {
        }
        .imageContainer-d84-0-3-500 picture::before {
          content: "";
          padding-top: 100%;
        }
        @media (min-width: 1024px) {
          .imageContainer-d84-0-3-500 {
          }
          .imageContainer-d84-0-3-500 picture::before {
            padding-top: 100%;
          }
        }
        .image-d86-0-3-501 {
          object-position: 50% 50%;
        }
        .imageContainer-d87-0-3-502 {
        }
        .imageContainer-d87-0-3-502 picture::before {
          /*  /* content: ""; */
          padding-top: 100%;
        }
        @media (min-width: 1024px) {
          .imageContainer-d87-0-3-502 {
          }
          .imageContainer-d87-0-3-502 picture::before {
            padding-top: 100%;
          }
        }
        .image-d89-0-3-503 {
          object-position: 50% 50%;
        }
        .splitWords-0-3-235 {
          visibility: hidden;
        }
        .splitWords-0-3-235 a {
          text-decoration: none;
        }
        .lines-0-3-238 {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: grid;
          z-index: -1;
          position: absolute;
        }
        .verticalLine-0-3-239 {
          width: 1px;
          opacity: 1.15;
          transform: scaleY(0);
          justify-self: end;
          background-color: var(--border);
        }
        .verticalLine-0-3-239.desktopOnly {
          display: block;
        }
        @media (min-width: 1024px) {
          .verticalLine-0-3-239.desktopOnly {
            display: block;
          }
        }
        .horizontalLine-0-3-240 {
          left: -15px;
          right: -15px;
          bottom: 0;
          height: 1px;
          opacity: 1.15;
          position: absolute;
          transform: scaleX(0);
          background-color: var(--border);
        }
        @media (min-width: 1024px) {
          .horizontalLine-0-3-240 {
            left: -25px;
            right: -25px;
          }
        }
        .horizontalLine-0-3-240.top {
          top: 0;
          bottom: auto;
        }
        .horizontalLine-0-3-240.bottom {
          opacity: 1;
        }
        .section-0-3-224 {
          margin-bottom: 0px;
        }
        .content-0-3-225 {
          height: calc(100vh - 250px);
          position: relative;
          align-items: end;
          padding-bottom: 25px;
          grid-template-rows: auto min-content min-content;
        }
        @media (min-width: 1024px) {
          .content-0-3-225 {
            height: auto;
            align-items: start;
            padding-top: 15vw;
            padding-bottom: 50px;
            grid-template-rows: auto;
          }
        }
        .title-0-3-226 {
          /* font-size: 42px; */
          max-width: 600px;
          /* grid-column: 1 / span 4; */
          line-height: 0.9;
          margin-bottom: 35px;
          font-size: 25px;
        }
        @media (min-width: 1024px) {
          .title-0-3-226 {
            font-size: 50px;
            max-width: 100%;
            /* grid-column: 1 / span 7; */
            margin-bottom: 50px;
          }
        }
        .copy-0-3-227 {
          opacity: 1;
          max-width: 360px;
          grid-column: 1 / span 4;
          text-indent: 25px;
          margin-bottom: 0;
        }
        @media (min-width: 1024px) {
          .copy-0-3-227 {
            grid-column: 2 / span 2;
          }
        }
        .captions-0-3-228 {
          display: block;
          opacity: 1;
        }
        @media (min-width: 1024px) {
          .captions-0-3-228 {
            gap: 24px;
            display: grid;
            grid-column: 5 / span 3;
            grid-template-columns: 1fr 1fr 1fr;
          }
        }
        .button-0-3-229 {
          opacity: 1;
          grid-column: 1 / span 4;
        }
        @media (min-width: 1024px) {
          .button-0-3-229 {
            grid-column: 2 / span 2;
          }
        }
        .image-0-3-230 {
          opacity: 1;
        }
        .imageCaptions-0-3-231 {
          top: 0;
          left: 0;
          color: #fff;
          right: 0;
          position: absolute;
          margin-top: 30px;
        }
        @media (min-width: 1024px) {
          .imageCaptions-0-3-231 {
            margin-top: 50px;
          }
        }
        .imageCaption-0-3-232 {
          grid-column: span 2;
        }
        @media (min-width: 1024px) {
          .imageCaption-0-3-232 {
            grid-column: span 1;
          }
          .imageCaption-0-3-232:first-child {
            grid-column: 5;
          }
        }
        .line-0-3-233 {
          width: 100%;
          height: 1px;
          opacity: 1;
          background-color: var(--border);
        }
        .logo-0-3-234 {
          max-width: 80px;
          margin-top: -7px;
        }
        .line-0-3-107 {
          background-color: #5C5C5C;
          /* stroke: #5C5C5C; */
opacity: 0.600000011920929;
        }
        .horizontal-0-3-108 {
          left: 0;
          right: 0;
          height: 1px;
          position: absolute;
          /* transform: scaleX(0); */
        }
        .horizontal-0-3-108.extend {
          left: -15px;
          right: -15px;
        }
        @media (min-width: 1024px) {
          .horizontal-0-3-108.extend {
            left: -25px;
            right: -25px;
          }
        }
        .vertical-0-3-109 {
          top: 0;
          width: 1px;
          bottom: 0;
          position: absolute;
          /* right: 0; */
          /* transform: scaley(0); */
        }
        .vertical-0-3-109.extend {
          top: -15px;
          bottom: -15px;
        }
        @media (min-width: 1024px) {
          .vertical-0-3-109.extend {
            top: -25px;
            bottom: -25px;
          }
        }
        .horizontal-d1-0-3-110 {
          transform-origin: 50% 50%;
        }
        .vertical-d2-0-3-111 {
          transform-origin: 50% 50%;
        }
        .horizontal-d3-0-3-112 {
          transform-origin: 50% 50%;
        }
        .vertical-d4-0-3-113 {
          transform-origin: 50% 50%;
        }
        .horizontal-d5-0-3-114 {
          transform-origin: 50% 50%;
        }
        .vertical-d6-0-3-115 {
          transform-origin: 50% 50%;
        }
        .horizontal-d7-0-3-116 {
          transform-origin: 50% 50%;
        }
        .vertical-d8-0-3-117 {
          transform-origin: 50% 50%;
        }
        .horizontal-d9-0-3-118 {
          transform-origin: 50% 50%;
        }
        .vertical-d10-0-3-119 {
          transform-origin: 50% 50%;
        }
        .horizontal-d11-0-3-120 {
          transform-origin: 50% 50%;
        }
        .vertical-d12-0-3-121 {
          transform-origin: 50% 50%;
        }
        .horizontal-d13-0-3-122 {
          transform-origin: 50% 50%;
        }
        .vertical-d14-0-3-123 {
          transform-origin: 50% 50%;
        }
        .horizontal-d15-0-3-124 {
          transform-origin: 50% 50%;
        }
        .vertical-d16-0-3-125 {
          transform-origin: 50% 50%;
        }
        .horizontal-d17-0-3-126 {
          transform-origin: 50% 50%;
        }
        .vertical-d18-0-3-127 {
          transform-origin: 50% 50%;
        }
        .horizontal-d19-0-3-128 {
          transform-origin: 50% 50%;
        }
        .vertical-d20-0-3-129 {
          transform-origin: 50% 50%;
        }
        .horizontal-d21-0-3-130 {
          transform-origin: 50% 50%;
        }
        .vertical-d22-0-3-131 {
          transform-origin: 50% 50%;
        }
        .horizontal-d23-0-3-132 {
          transform-origin: 50% 50%;
        }
        .vertical-d24-0-3-133 {
          transform-origin: 50% 50%;
        }
        .horizontal-d25-0-3-134 {
          transform-origin: 50% 50%;
        }
        .vertical-d26-0-3-135 {
          transform-origin: 50% 50%;
        }
        .horizontal-d27-0-3-136 {
          transform-origin: 50% 50%;
        }
        .vertical-d28-0-3-137 {
          transform-origin: 50% 50%;
        }
        .horizontal-d29-0-3-138 {
          transform-origin: 50% 50%;
        }
        .vertical-d30-0-3-139 {
          transform-origin: 50% 50%;
        }
        .horizontal-d31-0-3-140 {
          transform-origin: 50% 50%;
        }
        .vertical-d32-0-3-141 {
          transform-origin: 50% 50%;
        }
        .horizontal-d33-0-3-142 {
          transform-origin: 50% 50%;
        }
        .vertical-d34-0-3-143 {
          transform-origin: 50% 50%;
        }
        .horizontal-d35-0-3-144 {
          transform-origin: 50% 50%;
        }
        .vertical-d36-0-3-145 {
          transform-origin: 50% 50%;
        }
        .horizontal-d37-0-3-146 {
          transform-origin: 50% 50%;
        }
        .vertical-d38-0-3-147 {
          transform-origin: 50% 50%;
        }
        .horizontal-d39-0-3-148 {
          transform-origin: 50% 50%;
        }
        .vertical-d40-0-3-149 {
          transform-origin: 50% 50%;
        }
        .horizontal-d41-0-3-179 {
          transform-origin: 50% 50%;
        }
        .vertical-d42-0-3-180 {
          transform-origin: 50% 50%;
        }
        .horizontal-d43-0-3-181 {
          transform-origin: 50% 50%;
        }
        .vertical-d44-0-3-182 {
          transform-origin: 50% 50%;
        }
        .horizontal-d45-0-3-183 {
          transform-origin: 50% 50%;
        }
        .vertical-d46-0-3-184 {
          transform-origin: 50% 50%;
        }
        .horizontal-d47-0-3-185 {
          transform-origin: 50% 50%;
        }
        .vertical-d48-0-3-186 {
          transform-origin: 50% 50%;
        }
        .horizontal-d49-0-3-187 {
          transform-origin: 50% 50%;
        }
        .vertical-d50-0-3-188 {
          transform-origin: 50% 50%;
        }
        .horizontal-d51-0-3-189 {
          transform-origin: 50% 50%;
        }
        .vertical-d52-0-3-190 {
          transform-origin: 50% 50%;
        }
        .horizontal-d53-0-3-191 {
          transform-origin: 50% 50%;
        }
        .vertical-d54-0-3-192 {
          transform-origin: 50% 50%;
        }
        .horizontal-d55-0-3-193 {
          transform-origin: 50% 50%;
        }
        .vertical-d56-0-3-194 {
          transform-origin: 50% 50%;
        }
        .horizontal-d57-0-3-195 {
          transform-origin: 50% 50%;
        }
        .vertical-d58-0-3-196 {
          transform-origin: 50% 50%;
        }
        .horizontal-d59-0-3-197 {
          transform-origin: 50% 50%;
        }
        .vertical-d60-0-3-198 {
          transform-origin: 50% 50%;
        }
        .horizontal-d61-0-3-199 {
          transform-origin: 50% 50%;
        }
        .vertical-d62-0-3-200 {
          transform-origin: 50% 50%;
        }
        .horizontal-d63-0-3-201 {
          transform-origin: 50% 50%;
        }
        .vertical-d64-0-3-202 {
          transform-origin: 50% 50%;
        }
        .horizontal-d65-0-3-203 {
          transform-origin: 50% 50%;
        }
        .vertical-d66-0-3-204 {
          transform-origin: 50% 50%;
        }
        .horizontal-d67-0-3-205 {
          transform-origin: 50% 50%;
        }
        .vertical-d68-0-3-206 {
          transform-origin: 50% 50%;
        }
        .horizontal-d69-0-3-207 {
          transform-origin: 50% 50%;
        }
        .vertical-d70-0-3-208 {
          transform-origin: 50% 50%;
        }
        .horizontal-d71-0-3-209 {
          transform-origin: 50% 50%;
        }
        .vertical-d72-0-3-210 {
          transform-origin: 50% 50%;
        }
        .horizontal-d73-0-3-211 {
          transform-origin: 50% 50%;
        }
        .vertical-d74-0-3-212 {
          transform-origin: 50% 50%;
        }
        .horizontal-d75-0-3-213 {
          transform-origin: 50% 50%;
        }
        .vertical-d76-0-3-214 {
          transform-origin: 50% 50%;
        }
        .horizontal-d77-0-3-261 {
          transform-origin: 28% 50%;
        }
        .vertical-d78-0-3-262 {
          transform-origin: 50% 28%;
        }
        .horizontal-d79-0-3-263 {
          transform-origin: 50% 50%;
        }
        .vertical-d80-0-3-264 {
          transform-origin: 50% 50%;
        }
        .horizontal-d81-0-3-273 {
          transform-origin: 0% 50%;
        }
        .vertical-d82-0-3-274 {
          transform-origin: 50% 0%;
        }
        .horizontal-d83-0-3-275 {
          transform-origin: 50% 50%;
        }
        .vertical-d84-0-3-276 {
          transform-origin: 50% 50%;
        }
        .horizontal-d85-0-3-295 {
          transform-origin: 28% 50%;
        }
        .vertical-d86-0-3-296 {
          transform-origin: 50% 28%;
        }
        .horizontal-d87-0-3-297 {
          transform-origin: 50% 50%;
        }
        .vertical-d88-0-3-298 {
          transform-origin: 50% 50%;
        }
        .horizontal-d89-0-3-303 {
          transform-origin: 0% 50%;
        }
        .vertical-d90-0-3-304 {
          transform-origin: 50% 0%;
        }
        .horizontal-d91-0-3-305 {
          transform-origin: 50% 50%;
        }
        .vertical-d92-0-3-306 {
          transform-origin: 50% 50%;
        }
        .horizontal-d93-0-3-322 {
          transform-origin: 28% 50%;
        }
        .vertical-d94-0-3-323 {
          transform-origin: 50% 28%;
        }
        .horizontal-d95-0-3-324 {
          transform-origin: 50% 50%;
        }
        .vertical-d96-0-3-325 {
          transform-origin: 50% 50%;
        }
        .horizontal-d97-0-3-330 {
          transform-origin: 0% 50%;
        }
        .vertical-d98-0-3-331 {
          transform-origin: 50% 0%;
        }
        .horizontal-d99-0-3-332 {
          transform-origin: 50% 50%;
        }
        .vertical-d100-0-3-333 {
          transform-origin: 50% 50%;
        }
        .horizontal-d101-0-3-346 {
          transform-origin: 28% 50%;
        }
        .vertical-d102-0-3-347 {
          transform-origin: 50% 28%;
        }
        .horizontal-d103-0-3-348 {
          transform-origin: 50% 50%;
        }
        .vertical-d104-0-3-349 {
          transform-origin: 50% 50%;
        }
        .horizontal-d105-0-3-406 {
          transform-origin: 50% 50%;
        }
        .vertical-d106-0-3-407 {
          transform-origin: 50% 50%;
        }
        .horizontal-d107-0-3-408 {
          transform-origin: 50% 50%;
        }
        .vertical-d108-0-3-409 {
          transform-origin: 50% 50%;
        }
        .horizontal-d109-0-3-422 {
          transform-origin: 50% 50%;
        }
        .vertical-d110-0-3-423 {
          transform-origin: 50% 50%;
        }
        .horizontal-d111-0-3-424 {
          transform-origin: 50% 50%;
        }
        .vertical-d112-0-3-425 {
          transform-origin: 50% 50%;
        }
        .horizontal-d113-0-3-428 {
          transform-origin: 50% 50%;
        }
        .vertical-d114-0-3-429 {
          transform-origin: 50% 50%;
        }
        .horizontal-d115-0-3-430 {
          transform-origin: 50% 50%;
        }
        .vertical-d116-0-3-431 {
          transform-origin: 50% 50%;
        }
        .horizontal-d117-0-3-434 {
          transform-origin: 50% 50%;
        }
        .vertical-d118-0-3-435 {
          transform-origin: 50% 50%;
        }
        .horizontal-d119-0-3-436 {
          transform-origin: 50% 50%;
        }
        .vertical-d120-0-3-437 {
          transform-origin: 50% 50%;
        }
        .horizontal-d121-0-3-464 {
          transform-origin: 50% 50%;
        }
        .vertical-d122-0-3-465 {
          transform-origin: 50% 50%;
        }
        .horizontal-d123-0-3-466 {
          transform-origin: 50% 50%;
        }
        .vertical-d124-0-3-467 {
          transform-origin: 50% 50%;
        }
        .horizontal-d125-0-3-468 {
          transform-origin: 0 50%;
        }
        .vertical-d126-0-3-469 {
          transform-origin: 50% 0;
        }
        .horizontal-d127-0-3-470 {
          transform-origin: 50% 50%;
        }
        .vertical-d128-0-3-471 {
          transform-origin: 50% 50%;
        }
        .horizontal-d129-0-3-472 {
          transform-origin: 0 50%;
        }
        .vertical-d130-0-3-473 {
          transform-origin: 50% 0;
        }
        .horizontal-d131-0-3-474 {
          transform-origin: 50% 50%;
        }
        .vertical-d132-0-3-475 {
          transform-origin: 50% 50%;
        }
        .horizontal-d133-0-3-476 {
          transform-origin: 50% 50%;
        }
        .vertical-d134-0-3-477 {
          transform-origin: 50% 50%;
        }
        .horizontal-d135-0-3-478 {
          transform-origin: 50% 50%;
        }
        .vertical-d136-0-3-479 {
          transform-origin: 50% 50%;
        }
        .horizontal-d137-0-3-480 {
          transform-origin: 50% 50%;
        }
        .vertical-d138-0-3-481 {
          transform-origin: 50% 50%;
        }
        .horizontal-d139-0-3-482 {
          transform-origin: 50% 50%;
        }
        .vertical-d140-0-3-483 {
          transform-origin: 50% 50%;
        }
        .curtain-0-3-283 {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;
          overflow: hidden;
          position: absolute;
          pointer-events: none;
        }
        .leftCurtain-0-3-284 {
          width: 50%;
          /* background-color: var(--background); */
        }
        .leftCurtain-0-3-284.topDown {
          width: 100%;
        }
        .rightCurtain-0-3-285 {
          width: 50%;
          /* background-color: var(--background); */
        }
        .rightCurtain-0-3-285.topDown {
          display: block;
        }
        .Slider-flickity-0-3-278 {
          display: flex;
          outline: none;
          position: relative;
          white-space: nowrap;
        }
        .Slider-flickity-0-3-278 > * {
          flex-shrink: 0;
        }
        .Slider-flickity-0-3-278 .flickity-slider {
          height: 100%;
        }
        .container-0-3-277 {
          margin: 0 -15px;
          padding: 0 15px;
          overflow: hidden;
        }
        @media (min-width: 1024px) {
          .container-0-3-277 {
            margin: 0 -25px;
            padding: 0 25px;
          }
        }
        .image-0-3-279 {
          cursor: pointer;
          height: calc((100vw / 375) * 300);
          flex-shrink: 0;
          margin-right: 15px;
        }
        @media (max-width: 504px) {
        .picture-0-3-93 img{
            height: 100%;
            width: 800px;
            /* object-fit: fill; */
        }
    }
        @media (min-width: 1024px) {
          .image-0-3-279 {
            height: calc((100vw / 1440) * 600);
            margin-right: 25px;
          }
        }
        .image-d0-0-3-280 {
          /* width: 75%; */
        }
        @media (min-width: 1024px) {
          .image-d0-0-3-280 {
            width: calc(((100vw - 200px) / 7) * 3 + (25px * 2) + 0px);
          }
        }
        .image-d2-0-3-286 {
          width: 75%;
        }
        @media (min-width: 1024px) {
          .image-d2-0-3-286 {
            width: calc(((100vw - 200px) / 7) * 3 + (25px * 2) + 0px);
          }
        }
        .image-d4-0-3-289 {
          width: 50%;
        }
        @media (min-width: 1024px) {
          .image-d4-0-3-289 {
            width: calc(((100vw - 200px) / 7) * 2 + (25px * 1) + 0px);
          }
        }
        .image-d6-0-3-292 {
          width: 50%;
        }
        @media (min-width: 1024px) {
          .image-d6-0-3-292 {
            width: calc(((100vw - 200px) / 7) * 2 + (25px * 1) + 0px);
          }
        }
        .image-d8-0-3-307 {
          width: 75%;
        }
        @media (min-width: 1024px) {
          .image-d8-0-3-307 {
            width: calc(((100vw - 200px) / 7) * 3 + (25px * 2) + 0px);
          }
        }
        .image-d10-0-3-310 {
          width: 100%;
        }
        @media (min-width: 1024px) {
          .image-d10-0-3-310 {
            width: calc(((100vw - 200px) / 7) * 4 + (25px * 3) + 0px);
          }
        }
        .image-d12-0-3-313 {
          width: 50%;
        }
        @media (min-width: 1024px) {
          .image-d12-0-3-313 {
            width: calc(((100vw - 200px) / 7) * 2 + (25px * 1) + 0px);
          }
        }
        .image-d14-0-3-316 {
          width: 75%;
        }
        @media (min-width: 1024px) {
          .image-d14-0-3-316 {
            width: calc(((100vw - 200px) / 7) * 3 + (25px * 2) + 0px);
          }
        }
        .image-d16-0-3-319 {
          width: 75%;
        }
        @media (min-width: 1024px) {
          .image-d16-0-3-319 {
            width: calc(((100vw - 200px) / 7) * 3 + (25px * 2) + 0px);
          }
        }
        .image-d18-0-3-334 {
          width: 75%;
        }
        @media (min-width: 1024px) {
          .image-d18-0-3-334 {
            width: calc(((100vw - 200px) / 7) * 3 + (25px * 2) + 0px);
          }
        }
        .image-d20-0-3-337 {
          width: 50%;
        }
        @media (min-width: 1024px) {
          .image-d20-0-3-337 {
            width: calc(((100vw - 200px) / 7) * 2 + (25px * 1) + 0px);
          }
        }
        .image-d22-0-3-340 {
          width: 50%;
        }
        @media (min-width: 1024px) {
          .image-d22-0-3-340 {
            width: calc(((100vw - 200px) / 7) * 2 + (25px * 1) + 0px);
          }
        }
        .image-d24-0-3-343 {
          width: 100%;
        }
        @media (min-width: 1024px) {
          .image-d24-0-3-343 {
            width: calc(((100vw - 200px) / 7) * 4 + (25px * 3) + 0px);
          }
        }
        .text-0-3-265 {
          display: block;
        }
        .label-0-3-266 {
          display: block;
          opacity: 1.5;
          color: rgba(0, 94, 140, 0.60);;
        }
        .text-d0-0-3-267 {
          font-size: 12px;
        }
        .text-d1-0-3-269 {
          font-size: 12px;
        }
        .text-d2-0-3-270 {
          font-size: 12px;
        }
        .text-d3-0-3-271 {
          font-size: 12px;
        }
        .text-d4-0-3-299 {
          font-size: 12px;
        }
        .text-d5-0-3-300 {
          font-size: 12px;
        }
        .text-d6-0-3-301 {
          font-size: 12px;
        }
        .text-d7-0-3-302 {
          font-size: 12px;
        }
        .text-d8-0-3-326 {
          font-size: 12px;
        }
        .text-d9-0-3-327 {
          font-size: 12px;
        }
        .text-d10-0-3-328 {
          font-size: 12px;
        }
        .text-d11-0-3-329 {
          font-size: 12px;
        }
        .text-d12-0-3-378 {
          font-size: 10px;
        }
        .text-d13-0-3-379 {
          font-size: 10px;
        }
        .text-d14-0-3-380 {
          font-size: 10px;
        }
        .text-d15-0-3-383 {
          font-size: 10px;
        }
        .text-d16-0-3-384 {
          font-size: 10px;
        }
        .text-d17-0-3-385 {
          font-size: 10px;
        }
        .text-d18-0-3-388 {
          font-size: 10px;
        }
        .text-d19-0-3-389 {
          font-size: 10px;
        }
        .text-d20-0-3-390 {
          font-size: 10px;
        }
        .text-d21-0-3-393 {
          font-size: 10px;
        }
        .text-d22-0-3-394 {
          font-size: 10px;
        }
        .text-d23-0-3-395 {
          font-size: 10px;
        }
        .text-d24-0-3-398 {
          font-size: 10px;
        }
        .text-d25-0-3-399 {
          font-size: 10px;
        }
        .text-d26-0-3-400 {
          font-size: 10px;
        }
        .text-d27-0-3-403 {
          font-size: 10px;
        }
        .text-d28-0-3-404 {
          font-size: 10px;
        }
        .text-d29-0-3-405 {
          font-size: 10px;
        }
        .paginationButton-0-3-272 {
          padding: 4px 8px;
          min-width: 42px;
          margin-top: 8px;
        }
        .paginationButton-0-3-272:first-child {
          margin-right: 8px;
        }
        .paginationButton-0-3-272 span {
          margin: 0;
        }
        .paginationButton-0-3-272 > div > span {
          padding: 4px 8px;
        }
        .projects-0-3-251 {
          position: relative;
        }
        .project-0-3-252 {
          padding: 0 15px;
          position: relative;
        }
        @media (min-width: 1024px) {
          .project-0-3-252 {
            padding: 0 25px;
          }
        }
        .content-0-3-253 {
          gap: 15px;
          display: grid;
          padding: 15px  0;
          position: relative;
          grid-column: 1 / span 4;
          grid-template-columns: 1fr 1fr 1fr 1fr;
        }
        @media (min-width: 1024px) {
          .content-0-3-253 {
            display: flex;
            padding: 25px 25px 25px 0;
            grid-column: 1 / span 2;
            flex-direction: column;
          }
        }
        .images-0-3-254 {
          padding: 0 0 15px;
          grid-column: 1 / span 4;
        }
        @media (min-width: 1024px) {
          .images-0-3-254 {
            padding: 25px 0;
            grid-column: 4 / span 5;
          }
        }
        .verticalLine-0-3-255 {
          display: block;
          z-index: 1;
        }
        @media (min-width: 1024px) {
          .verticalLine-0-3-255 {
            display: block;
          }
        }
        .onlyDesktop-0-3-256 {
          display: block;
        }
        @media (min-width: 1024px) {
          .onlyDesktop-0-3-256 {
            display: block;
          }
        }
        .titleField-0-3-257 {
          opacity: 1;
          flex-grow: 1;
          flex-shrink: 1;
          grid-column: 1 / span 2;
        }
        .title-0-3-258 {
          font-size: 12px;
          text-decoration: none;
        }
        @media (min-width: 1024px) {
          .title-0-3-258 {
            font-size: 24px;
          }
        }
        .detailsContainer-0-3-259 {
          gap: 15px;
          display: grid;
          grid-template-columns: 1fr 1fr;
          /* position: absolute;
          right: 0; */
        }
        @media (min-width: 1024px) {
          .detailsContainer-0-3-259 {
            gap: 25px;
          }
        }
        .detailsContainer-0-3-259 > * {
          opacity: 1;
        }
        .paginationButton-0-3-260 {
          padding: 6px 8px;
          margin-top: 8px;
        }
        .paginationButton-0-3-260:first-child {
          margin-right: 8px;
        }
        .paginationButton-0-3-260 svg {
          margin: 0;
        }
        .RichText-container-0-3-363 > div > p:last-child,
        .RichText-container-0-3-363 > p:last-child {
          margin-bottom: 0;
        }
        .RichText-list-0-3-364 {
          margin: 0;
          padding: 0;
          position: relative;
          list-style: none;
        }
        .RichText-linkListLine-0-3-365 {
          opacity: 1.3;
        }
        .RichText-linkListLink-0-3-366 {
          display: flex;
          padding: 15px 0;
          position: relative;
          text-decoration: none;
        }
        .RichText-linkListLink-0-3-366:hover .RichText-linkListItemLine-0-3-367 {
          opacity: 1;
        }
        .RichText-linkListItemLine-0-3-367 {
          opacity: 1.3;
          transition: opacity 0.15s ease-in-out;
        }
        .RichText-linkListTitle-0-3-368 {
          display: flex;
          flex-direction: column;
        }
        .RichText-linkListImage-0-3-369 {
          width: 48px;
          height: 48px;
          margin-right: 20px;
        }
        .header-0-3-246 {
          margin-bottom: 75px;
        }
        @media (min-width: 1024px) {
          .header-0-3-246 {
            margin-bottom: 100px;
          }
        }
        .title-0-3-247 {
          opacity: 1;
          grid-column: 1 / span 4;
        }
        @media (min-width: 1024px) {
          .title-0-3-247 {
            margin: 0;
            grid-column: 1 / span 3;
          }
        }
        @media (min-width: 1440px) {
          .title-0-3-247 {
            grid-column: 1 / span 2;
          }
        }
        .copyContainer-0-3-248 {
          opacity: 1;
          grid-column: 1 / span 4;
        }
        @media (min-width: 1024px) {
          .copyContainer-0-3-248 {
            max-width: 400px;
            grid-column: 5 / span 2;
          }
        }
        .copyContainer-0-3-248.end {
          align-self: end;
        }
        .copy-0-3-249 {
          margin: 0 0 25px;
        }
        .buttonLabel-0-3-250 {
          opacity: 1.5;
        }
        .section-0-3-243 {
          margin-bottom: 75px;
        }
        @media (min-width: 1024px) {
          .section-0-3-243 {
            margin-bottom: 100px;
          }
        }
        .header-0-3-244 {
          margin-bottom: 30px;
        }
        @media (min-width: 1024px) {
          .header-0-3-244 {
            margin-bottom: 100px;
          }
        }
        .title-0-3-245 {
          margin-bottom: 100px;
        }
        @media (min-width: 1024px) {
          .title-0-3-245 {
            margin: 0;
          }
        }
        .section-0-3-410 {
          margin-bottom: 100px;
        }
        .article-0-3-411 {
          display: block;
          position: relative;
          margin-bottom: 15px;
          padding-bottom: 15px;
          text-decoration: none;
        }
        @media (min-width: 1024px) {
          .article-0-3-411 {
            margin-bottom: 25px;
            padding-bottom: 25px;
          }
        }
        .article-0-3-411 .category-0-3-418,
        .article-0-3-411 .date-0-3-417,
        .article-0-3-411 .line-0-3-419 {
          transition: opacity 0.15s ease-in-out;
        }
        .article-0-3-411:hover .category-0-3-418,
        .article-0-3-411:hover .date-0-3-417,
        .article-0-3-411:hover .line-0-3-419 {
          opacity: 1;
        }
        .image-0-3-413 {
          opacity: 1;
          align-self: start;
          grid-column: 1 / span 4;
        }
        @media (min-width: 1024px) {
          .image-0-3-413 {
            grid-column: 1 / span 2;
          }
        }
        .content-0-3-414 {
          gap: 15px;
          display: grid;
          opacity: 1;
          grid-column: 1 / span 4;
          grid-template-columns: repeat(4, 1fr);
        }
        @media (min-width: 1024px) {
          .content-0-3-414 {
            gap: 25px;
            grid-column: 3 / span 5;
            grid-template-rows: auto min-content;
            grid-template-columns: repeat(5, 1fr);
          }
        }
        .title-0-3-415 {
          margin: 0;
          font-size: 18px;
          grid-column: 1 / span 4;
          line-height: 1.4;
        }
        @media (min-width: 1024px) {
          .title-0-3-415 {
            font-size: 20px;
            grid-column: 1 / span 2;
          }
        }
        .summary-0-3-416 {
          margin: 0 0 15px;
          font-size: 12px;
          grid-column: 1 / span 4;
        }
        @media (min-width: 1024px) {
          .summary-0-3-416 {
            margin: 0;
            font-size: 15px;
            max-width: 400px;
            grid-column: 3 / span 2;
          }
        }
        .date-0-3-417 {
          margin: 0;
          opacity: 1.5;
          grid-row: 3;
          grid-column: 3 / span 2;
        }
        @media (min-width: 1024px) {
          .date-0-3-417 {
            grid-row: 2;
            grid-column: 2;
          }
        }
        .category-0-3-418 {
          margin: 0;
          opacity: 1.5;
          grid-row: 3;
          grid-column: 1 / span 2;
        }
        @media (min-width: 1024px) {
          .category-0-3-418 {
            grid-row: 2;
            grid-column: 1;
          }
        }
        .line-0-3-419 {
          bottom: 0;
          opacity: 1.1;
        }
        .section-0-3-370 {
          overflow: hidden;
          position: relative;
          margin-bottom: 75px;
          padding-bottom: 75px;
        }
        @media (min-width: 1024px) {
          .section-0-3-370 {
            margin-bottom: 100px;
            padding-bottom: 100px;
          }
        }
        .product-0-3-371 {
          overflow: hidden;
          grid-column: 1 / span 4;
          padding-bottom: 0;
          text-decoration: none;
        }
        @media (min-width: 1024px) {
          .product-0-3-371 {
            grid-column: span 2;
            padding-bottom: 25px;
          }
          .product-0-3-371.large {
            grid-column: span 3;
          }
        }
        .product-0-3-371 label {
          transition: opacity 0.15s ease-in-out;
        }
        .product-0-3-371:hover label {
          opacity: 1;
        }
        .image-0-3-372 {
          margin-bottom: 15px;
        }
        .title-0-3-373 {
          display: block;
          margin-bottom: 5px;
        }
        @media (min-width: 1024px) {
          .title-0-3-373 {
            margin-bottom: 50px;
          }
        }
        .fieldContainer-0-3-374 {
          margin: 0;
          display: grid;
          grid-gap: 15px;
          text-transform: uppercase;
          grid-template-columns: 1fr 1fr;
        }
        @media (min-width: 1024px) {
          .fieldContainer-0-3-374 {
            margin: 0 -15px;
            display: flex;
          }
          .fieldContainer-0-3-374 > * {
            margin: 0 15px;
          }
        }
        @media (min-width: 1440px) {
          .fieldContainer-0-3-374 {
            margin: 0 -30px;
          }
          .fieldContainer-0-3-374 > * {
            margin: 0 30px;
          }
        }
        .fireGroup-0-3-375 {
          display: block;
        }
        @media (min-width: 1024px) {
          .fireGroup-0-3-375 {
            display: block;
          }
        }
        .chartContainer-0-3-350 {
          display: block;
          position: relative;
          padding-top: 27.77777777777778%;
        }
        .title-0-3-351 {
          max-width: 540px;
          grid-column: 1 / span 4;
        }
        @media (min-width: 1024px) {
          .title-0-3-351 {
            grid-column: 1 / span 4;
          }
        }
        .copy-0-3-352 {
          display: flex;
          align-items: start;
          grid-column: 1 / span 4;
          flex-direction: column;
        }
        @media (min-width: 1024px) {
          .copy-0-3-352 {
            max-width: 300px;
            grid-column: span 3;
          }
        }
        .copy-0-3-352 > *:not(:last-child) {
          margin-bottom: 15px;
        }
        .actionLabel-0-3-353 {
          opacity: 1.5;
          margin-left: 5px;
        }
        .chart-0-3-354 {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          position: absolute;
        }
        .chart-0-3-354 svg > g > g > path {
          fill: var(--foreground);
        }
        .chart-0-3-354 svg > g > g:nth-child(6) > g > path,
        .chart-0-3-354 svg > g > g:nth-child(7) > g > path,
        .chart-0-3-354 svg > g > g:nth-child(8) > g > path,
        .chart-0-3-354 svg > g > g:nth-child(9) > g > path {
          fill: var(--foreground);
        }
        .labels-0-3-355 {
          top: 8%;
          left: 0;
          right: 0;
          position: absolute;
        }
        .labels-0-3-355 > * {
          opacity: 1;
        }
        .chartTitle-0-3-356 {
          left: 15px;
          display: block;
          position: absolute;
        }
        @media (min-width: 1024px) {
          .chartTitle-0-3-356 {
            left: 25px;
            display: block;
          }
        }
        .label-0-3-357 {
          display: flex;
          position: absolute;
        }
        .soundProfileLabel-0-3-358 {
          display: block;
          opacity: 1.5;
          margin-left: 15px;
        }
        @media (min-width: 1024px) {
          .soundProfileLabel-0-3-358 {
            display: block;
          }
        }
        .chartLabel1-0-3-359 {
          left: 17%;
        }
        .chartLabel2-0-3-360 {
          left: 31%;
        }
        .chartLabel3-0-3-361 {
          left: 59%;
        }
        .chartLabel4-0-3-362 {
          left: 87%;
        }
        .title-0-3-485 {
          display: block;
          opacity: 1.5;
          font-size: 12px;
          margin-bottom: 5px;
        }
        .input-0-3-486 {
          width: 100%;
          border: none;
          margin: -1px 0 0 0;
          outline: none;
          padding: 10px 30px 10px 0;
          font-size: 12px;
          appearance: none;
          border-top: 1px solid var(--border);
          box-shadow: none;
          border-bottom: 1px solid var(--border);
          border-radius: 0;
          background-color: transparent;
        }
        .inputContainer-0-3-487 {
          position: relative;
        }
        .button-0-3-488 {
          top: 50%;
          right: 0;
          width: 18px;
          border: none;
          cursor: pointer;
          height: 30px;
          margin: 0;
          display: flex;
          padding: 0;
          overflow: hidden;
          position: absolute;
          transform: translate(0, -50%);
          align-items: center;
          justify-content: flex-end;
          background-color: transparent;
        }
        .button-0-3-488:hover > .arrow-0-3-489 {
          transform: translate(200%, 0);
        }
        .button-0-3-488:hover > .arrow2-0-3-490 {
          transform: translate(200%, 0);
        }
        .arrow-0-3-489 {
          width: 18px;
          transition: transform 0.35s cubic-bezier(0.61, 1, 0.88, 1);
          flex-shrink: 0;
        }
        .arrow2-0-3-490 {
          right: 200%;
          width: 18px;
          position: absolute;
          transition: transform 0.35s cubic-bezier(0.61, 1, 0.88, 1);
        }
        .error-0-3-491 {
          color: #aa0000;
          margin-top: 5px;
        }
        .toolbarContainer-0-3-438 {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: block;
          z-index: 900;
          overflow: hidden;
          position: fixed;
          pointer-events: none;
        }
        @media (min-width: 1024px) {
          .toolbarContainer-0-3-438 {
            position: absolute;
          }
        }
        .toolbar-0-3-439 {
          left: 0;
          right: 0;
          bottom: 0;
          height: 50px;
          display: flex;
          position: absolute;
          transform: translate(0, 100%);
          border-top: 1px solid var(--border);
          align-items: center;
          pointer-events: all;
          justify-content: flex-end;
          background-color: var(--background);
        }
        .titleItem-0-3-440 {
          min-width: 220px;
        }
        .hideOnMobile-0-3-441 {
          display: block;
        }
        @media (min-width: 1024px) {
          .hideOnMobile-0-3-441 {
            display: flex;
          }
        }
        .footer-0-3-442 {
          position: relative;
          font-size: 12px;
        }
        .section-0-3-443 {
          padding: 50px 0;
          row-gap: 50px !important;
        }
        @media (min-width: 1024px) {
          .section-0-3-443 {
            padding: 25px 0;
            row-gap: 25px !important;
          }
        }
        @media (min-width: 1024px) {
          .section-0-3-443.extraToolbarPadding {
            padding: 25px 0 75px;
          }
        }
        .logoNewsletterContainer-0-3-444 {
          grid-row: 1;
          grid-column: 1 / span 4;
        }
        @media (min-width: 1024px) {
          .logoNewsletterContainer-0-3-444 {
            grid-column: 1 / span 2;
            padding-right: 25px;
          }
        }
        .acknowledgeContainer-0-3-445 {
          margin: 0;
          grid-row: 7;
          font-size: 12px;
          text-align: center;
          grid-column: 1 / span 4;
          margin-bottom: 60px;
        }
        @media (min-width: 1024px) {
          .acknowledgeContainer-0-3-445 {
            grid-row: 2;
            margin-top: 280px;
            text-align: left;
            grid-column: 1 / span 2;
            margin-bottom: 0;
            padding-right: 50px;
          }
        }
        .socialLinksContainer-0-3-446 {
          grid-row: 5;
          grid-column: 1 / span 2;
        }
        @media (min-width: 1024px) {
          .socialLinksContainer-0-3-446 {
            grid-row: 1;
            grid-column: 3 / span 2;
          }
        }
        .copyrightContainer-0-3-447 {
          grid-row: 5;
          grid-column: 3 / span 2;
        }
        @media (min-width: 1024px) {
          .copyrightContainer-0-3-447 {
            grid-row: 2;
            align-self: end;
            grid-column: 3 / span 2;
          }
        }
        .productsContainer-0-3-448 {
          grid-row: 2;
          text-align: right;
          grid-column: 3 / span 2;
        }
        @media (min-width: 1024px) {
          .productsContainer-0-3-448 {
            grid-row: 1;
            text-align: left;
            grid-column: 5 / span 2;
          }
        }
        .projectsContainer-0-3-449 {
          grid-row: 2;
          grid-column: 1 / span 2;
        }
        @media (min-width: 1024px) {
          .projectsContainer-0-3-449 {
            grid-row: 1;
            text-align: right;
            grid-column: 7;
          }
        }
        .contactsContainer-0-3-450 {
          display: flex;
          grid-row: 3;
          grid-column: 1 / span 2;
          flex-direction: column;
        }
        @media (min-width: 1024px) {
          .contactsContainer-0-3-450 {
            grid-row: 2;
            align-self: end;
            grid-column: 5 / span 2;
          }
        }
        .resourcesContainer-0-3-451 {
          grid-row: 3;
          text-align: right;
          grid-column: 3 / span 2;
        }
        @media (min-width: 1024px) {
          .resourcesContainer-0-3-451 {
            grid-row: 2;
            align-self: end;
            grid-column: 7;
          }
        }
        .logo-0-3-452 {
          width: 64px;
          height: 64px;
          margin-bottom: 50px;
        }
        .link-0-3-453 {
          line-height: 1.8;
          text-decoration: none;
        }
        .link-0-3-453:hover {
          text-decoration: underline;
        }
        .caption-0-3-454 {
          display: block;
          opacity: 1.5;
          margin-bottom: 15px;
        }
        .partnerLogos-0-3-455 {
          margin: 0 -5px 15px;
          display: flex;
          flex-wrap: wrap;
          max-width: 20em;
        }
        @media (min-width: 1024px) {
          .partnerLogos-0-3-455 {
            margin: 0 -5px 30px;
          }
        }
        .partnerLogo-0-3-456 {
          width: 100%;
          margin: 5px 5px;
          max-width: 48px;
        }
        .partnerLogo-0-3-456 img {
          object-fit: contain;
        }
        .partnerLogo-0-3-456 > div {
          max-height: 100%;
        }
        .linkList-0-3-457 {
          margin: 0;
          padding: 0;
          list-style-type: none;
        }
        .verticalLineContainer-0-3-458 {
          display: block;
          position: relative;
          pointer-events: none;
        }
        @media (min-width: 1024px) {
          .verticalLineContainer-0-3-458 {
            margin: -25px 0;
            display: block;
            grid-row: 1 / span 2;
          }
        }
        @media (min-width: 1024px) {
          .firstLine-0-3-459 {
            grid-column: 2;
          }
        }
        @media (min-width: 1024px) {
          .secondLine-0-3-460 {
            grid-column: 4;
          }
        }
        .horizontalLineContainer-0-3-461 {
          margin: 0 -15px;
          display: block;
          position: relative;
          grid-column: 1 / span 4;
          pointer-events: none;
        }
        @media (min-width: 1024px) {
          .horizontalLineContainer-0-3-461 {
            display: block;
          }
        }
        .firstMobileLine-0-3-462 {
          grid-row: 4;
        }
        .secondMobileLine-0-3-463 {
          grid-row: 6;
        }
        .link-0-3-268 {
          display: inline-flex;
          position: relative;
          /* color: #005E8C; */
        }
        .link-0-3-268:after {
          left: 0;
          right: 0;
          bottom: 0;
          height: 1px;
          content: "";
          display: block;
          position: absolute;
          transform: scaleX(0);
          transition: transform 0.15s ease-in-out;
          background-color: currentColor;
          transform-origin: 0 0;
        }
        .link-0-3-268:hover:after,
        .link-0-3-268.active:after {
          transform: scaleX(1);
        }
        .button-0-3-18 {
          color: currentColor;
          border: none;
          cursor: pointer;
          display: inline=block;
          padding: 0;
          position: relative;
          font-size: 12px;
          background: transparent;
          min-height: 42px;
          align-items: center;
          font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
          line-height: 1.2;
          border-radius: 0;
          letter-spacing: 0.5px;
          text-transform: uppercase;
          text-decoration: none;
        }
        .labelContainer-0-3-19 {
          height: 1.2em;
          display: block;
          overflow: hidden;
          position: relative;
        }
        .menuLabel-0-3-20 {
          display: block;
          position: relative;
        }
        .closeLabel-0-3-21 {
          right: 0;
          display: block;
        }
        .count-0-3-17 {
          width: 20px;
          border: 1px solid currentColor;
          height: 20px;
          display: inline-flex;
          font-size: 9px;
          align-items: center;
          font-family: "ABCMonumentGroteskMono", "Lucida Sans", Tahoma, sans-serif;
          border-radius: 50%;
          justify-content: center;
        }
        @media (min-width: 1024px) {
          .count-0-3-17 {
            width: 24px;
            height: 24px;
            font-size: 10px;
          }
        }
        .count-0-3-16 {
          margin-left: 5px;
        }
        .menuItem-0-3-14 {
          display: block;
        }
        @media (min-width: 1024px) {
          .menuItem-0-3-14 {
            display: flex;
            align-items: center;
          }
        }
        .link-0-3-15 {
          color: currentColor;
          border: none;
          cursor: pointer;
          display: inline-flex;
          padding: 0;
          overflow: hidden;
          position: relative;
          font-size: 10px;
          background: transparent;
          min-height: 42px;
          align-items: center;
          font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
          line-height: 1.2;
          border-radius: 0;
          letter-spacing: 0.5px;
          text-transform: uppercase;
          text-decoration: none;
        }
        .link-0-3-15:after {
          top: 50%;
          left: 0;
          right: 0;
          height: 1px;
          content: "";
          display: block;
          position: absolute;
          transform: translate(-110%, 1.2em);
          transition: transform 0.15s ease-in-out;
          background-color: currentColor;
          transform-origin: 0% 50%;
        }
        .link-0-3-15:hover:after,
        .link-0-3-15.active:after,
        .link-0-3-15.selected:after {
          transform: translate(0%, 1.2em);
        }
        .header-0-3-3 {
          top: 0;
          left: 0;
          right: 0;
          display: block;
          z-index: 2002;
          position: fixed;
          /* background-color: var(--background); */
background-color: white;          color: white;
          opacity: 1;
        }
        .line-0-3-4 {
          left: 0;
          right: 0;
          bottom: 0;
          height: 1px;
          opacity: 1;
          position: absolute;
          background-color: var(--border);
        }
        .nav-0-3-5 {
          padding: 12px 15px;
          /* height: 100px; */
          background: linear-gradient(90deg, rgba(0, 94, 140, 0.00) 24.79%, rgba(0, 94, 140, 0.80) 57.84%);

        }
        @media (min-width: 1024px) {
          .nav-0-3-5 {
            padding: 12px 25px;
            
          }
        }
        .list-0-3-6 {
          margin: 0 -7.5px;
          display: flex;
          padding: 0;
          list-style: none;
          justify-content: space-between;
        }
        @media (min-width: 1024px) {
          .list-0-3-6 {
            margin: 0 -12.5px;
          }
        }
        .list-0-3-6 li {
          width: calc(100% / 7);
          margin: 0 7.5px;
          opacity: 1;
        }
        @media (min-width: 1024px) {
          .list-0-3-6 li {
            margin: 0 12.5px;
          }
        }
        .menuItem-0-3-7 {
          display: block;
        }
        @media (min-width: 1024px) {
          .menuItem-0-3-7 {
            display: flex;
            align-items: center;
          }
        }
        .mobileMenu-0-3-8 {
          display: flex;
          justify-content: flex-end;
        }
        @media (min-width: 1024px) {
          .mobileMenu-0-3-8 {
            display: block;
          }
        }
        .logoLink-0-3-9 {
          color: currentColor;
          display: inline-flex;
          min-height: 50px;
          align-items: center;
          text-decoration: none;
        }
        .logo-0-3-10 {
          width: 105px;
          height: 26px;
        }
        .link-0-3-11 {
          color: currentColor;
          border: none;
          cursor: pointer;
          display: inline-flex;
          padding: 0;
          overflow: hidden;
          position: relative;
          font-size: 10px;
          background: transparent;
          min-height: 42px;
          align-items: center;
          font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
          line-height: 1.2;
          border-radius: 0;
          letter-spacing: 0.5px;
          text-transform: uppercase;
          text-decoration: none;
        }
        .link-0-3-11:after {
          top: 50%;
          left: 0;
          right: 0;
          height: 1px;
          content: "";
          display: block;
          position: absolute;
          transform: translate(-110%, 1.2em);
          transition: transform 0.15s ease-in-out;
          background-color: currentColor;
          transform-origin: 0% 50%;
        }
        .link-0-3-11:hover:after,
        .link-0-3-11.active:after {
          transform: translate(0%, 1.2em);
        }
        .cartLink-0-3-12:after {
          right: 22px;
        }
        @media (min-width: 1024px) {
          .cartCount-0-3-13 {
            width: 20px;
            height: 20px;
          }
        }
        .MobileMenu-container-0-3-22 {
          top: 0;
          left: 0;
          color: currentColor;
          width: 100%;
          height: 100vh;
          z-index: 1000;
          position: fixed;
          transform: translate(100%, 0);
          pointer-events: none;
          background-color: var(--background);
        }
        .MobileMenu-container-0-3-22.open {
          pointer-events: all;
        }
        .MobileMenu-inner-0-3-23 {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          height: 100%;
          display: flex;
          position: absolute;
          padding-top: 42px;
          flex-direction: column;
        }
        .MobileMenu-header-0-3-24 {
          padding: 30px 15px;
        }
        .MobileMenu-breadcrumb-0-3-25 {
          font-size: 12px;
          visibility: hidden;
          align-items: center;
          line-height: 1.4;
          margin-bottom: 30px;
          text-transform: uppercase;
        }
        .MobileMenu-breadcrumb-0-3-25.show {
          visibility: visible;
        }
        .MobileMenu-title-0-3-26 {
          font-size: 20px;
          line-height: 1.4;
        }
        .MobileMenu-menuItems-0-3-27 {
          margin: 0;
          display: block;
          padding: 0;
          overflow: hidden;
          list-style: none;
          padding-bottom: 1px;
        }
        .MobileMenu-menuItem-0-3-28 {
          display: block;
          border-top: 1px solid var(--border);
          border-bottom: 1px solid var(--border);
          margin-bottom: -1px;
        }
        .MobileMenu-link-0-3-29 {
          width: 100%;
          border: none;
          cursor: pointer;
          display: flex;
          padding: 15px;
          font-size: 15px;
          background: transparent;
          text-align: left;
          align-items: center;
          font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
          letter-spacing: 0.03em;
          text-transform: none;
          justify-content: space-between;
          text-decoration: none;
        }
        .MobileMenu-count-0-3-30 {
          font-size: 9px;
        }
        .panel-0-3-44 {
          height: 100vh;
          display: block;
          overflow: auto;
          position: relative;
          transform: translate(110%, 0);
          background-color: var(--background);
        }
        @media (min-width: 1024px) {
          .panel-0-3-44 {
            transform: translate(-110%, 0);
          }
        }
        .panel-0-3-44.padding {
          padding: 20px 15px 15px;
        }
        .panel-0-3-44.flex {
          display: flex;
          flex-direction: column;
        }
        .panel-0-3-44::-webkit-scrollbar {
          width: 5px;
        }
        .panel-0-3-44::-webkit-scrollbar-track {
          background: transparent;
        }
        .panel-0-3-44::-webkit-scrollbar-thumb {
          /* border-radius: 2px; */
          /* background-color: var(--border); */
        }
        @media (min-width: 1024px) {
          .panel-0-3-44.padding {
            padding: 30px 25px 25px;
          }
        }
        .breadcrumb-0-3-46 {
          cursor: pointer;
          display: flex;
          font-size: 12px;
          min-height: 42px;
          visibility: hidden;
          align-items: center;
          line-height: 1.4;
          margin-bottom: 30px;
          text-transform: uppercase;
        }
        @media (max-width: 1023.98px) {
          .breadcrumb-0-3-46.show {
            visibility: visible;
          }
        }
        .title-0-3-47 {
          display: flex;
          font-size: 20px;
          align-items: center;
          line-height: 1.4;
          margin-bottom: 30px;
        }
        @media (min-width: 1024px) {
          .title-0-3-47 {
            margin-bottom: 50px;
          }
        }
        .titleLink-0-3-48 {
          transition: opacity 0.15s ease-in-out;
          text-decoration: none;
        }
        .titleLink-0-3-48:hover {
          opacity: 1.5;
        }
        .count-0-3-49 {
          margin-left: 5px;
        }
        .closeButton-0-3-50 {
          top: 24px;
          right: 15px;
          width: 26px;
          border: none;
          cursor: pointer;
          height: 26px;
          margin: 0;
          display: flex;
          opacity: 1;
          padding: 0;
          z-index: 1;
          position: absolute;
          transition: opacity 0.5s ease-in-out;
          align-items: center;
          border-radius: 50%;
          pointer-events: none;
          justify-content: center;
          background-color: transparent;
        }
        @media (min-width: 1024px) {
          .closeButton-0-3-50 {
            top: 24px;
            right: 24px;
            display: flex;
          }
        }
        .closeButton-0-3-50:hover,
        .closeButton-0-3-50.fill {
          background-color: #f2f2f2;
        }
        .closeButton-0-3-50.show {
          opacity: 1;
          pointer-events: all;
        }
        .backIcon-0-3-51 {
          width: 24px;
          height: 24px;
          margin-right: 5px;
        }
        .container-0-3-52 {
          height: 100%;
          display: flex;
          font-size: 12px;
          flex-direction: column;
        }
        .imageContainer-0-3-53 {
          width: 100%;
          position: relative;
          flex-grow: 1;
          flex-shrink: 1;
        }
        .image-0-3-54 {
          top: 0;
          left: 0;
          right: 0;
          width: 100%;
          bottom: 0;
          height: 100%;
          position: absolute;
          object-fit: cover;
        }
        .product-0-3-55 {
          display: flex;
          padding: 15px;
          flex-grow: 0;
          min-height: 280px;
          flex-shrink: 0;
          flex-direction: column;
          justify-content: space-between;
        }
        @media (min-width: 1024px) {
          .product-0-3-55 {
            padding: 25px;
          }
        }
        .title-0-3-56 {
          display: block;
          font-size: 15px;
          margin-bottom: 25px;
        }
        .productDetails-0-3-57 {
          display: flex;
          margin-bottom: 25px;
        }
        .productDetails-0-3-57 > * {
          width: 50%;
        }
        .locationDetails-0-3-58 {
          margin-bottom: 25px;
        }
        .caption-0-3-59 {
          display: block;
          opacity: 1.5;
        }
        .detailsPanel-0-3-60 {
          overflow: hidden;
        }
        .container-0-3-215 {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1003;
          overflow: hidden;
          position: fixed;
          pointer-events: none;
        }
        .overlay-0-3-216 {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: 1;
          position: absolute;
          /* background-color: #000; */
        }
        .drawerWrapper-0-3-217 {
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          position: absolute;
          transform: translate(-100%, 0);
          background-color: var(--background);
        }
        @media (min-width: 1024px) {
          .drawerWrapper-0-3-217 {
            width: 480px;
            bottom: 0;
            height: auto;
          }
        }
        .drawerContainer-0-3-218 {
          height: 100%;
          display: flex;
          position: relative;
          flex-direction: column;
        }
        .header-0-3-219 {
          margin: 25px 15px 0;
          display: flex;
          position: relative;
          flex-grow: 0;
          flex-shrink: 0;
          flex-direction: column;
          justify-content: space-between;
        }
        @media (min-width: 1024px) {
          .header-0-3-219 {
            margin: 25px 25px 0;
          }
        }
        .header-0-3-219:before {
          left: 0;
          right: 0;
          bottom: 0;
          height: 1px;
          /*  /* content: ""; */
          opacity: 1.3;
          position: absolute;
          /* background-color: var(--border); */
        }
        .headerTop-0-3-220 {
          display: flex;
          align-items: start;
          margin-bottom: 25px;
          justify-content: space-between;
        }
        @media (min-width: 1024px) {
          .headerTop-0-3-220 {
            margin-bottom: 25px;
          }
        }
        .drawerBody-0-3-221 {
          display: flex;
          padding: 15px;
          overflow: auto;
          position: relative;
          flex-grow: 1;
          margin-top: -8px;
          flex-shrink: 1;
          padding-top: 0;
          flex-direction: column;
        }
        .drawerBody-0-3-221.empty:after {
          display: block;
        }
        @media (min-width: 1024px) {
          .drawerBody-0-3-221 {
            padding: 25px;
            padding-top: 0;
          }
        }
        .title-0-3-222 {
          display: flex;
          font-size: 20px;
          align-items: center;
          font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
          text-transform: none;
        }
        .closeButton-0-3-223 {
          border: none;
          cursor: pointer;
          margin: 0;
          padding: 0;
          margin-left: auto;
          border-radius: 0;
          background-color: transparent;
        }
        .container-0-3-34 {
          display: block;
          padding: 15px;
          position: relative;
          transform: translateX(-110%);
          grid-column: 1 / span 4;
          border-right: 1px solid var(--border);
          justify-content: space-between;
          background-color: var(--background);
        }
        @media (min-width: 1024px) {
          .container-0-3-34 {
            display: flex;
            padding: 23px 25px 25px;
            z-index: 4;
            grid-column: 1;
            margin-left: -25px;
            flex-direction: column;
          }
        }
        .list-0-3-35 {
          margin: 40px 0 0;
          padding: 0;
          list-style: none;
        }
        .item-0-3-36 {
          opacity: 1.5;
          font-size: 15px;
          transition: opacity 0.25s ease-in-out;
          font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
          text-transform: none;
        }
        .item-0-3-36:after {
          top: 1.2em;
        }
        .item-0-3-36:hover,
        .item-0-3-36.selected {
          opacity: 1;
        }
        .button-0-3-37 {
          align-self: start;
        }
        .buttonLabel-0-3-38 {
          opacity: 1.5;
          margin-left: 5px;
          white-space: nowrap;
        }
        .container-0-3-31 {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          padding: 0;
          z-index: 1003;
          overflow: hidden;
          position: fixed;
          pointer-events: none;
        }
        @media (min-width: 1024px) {
          .container-0-3-31 {
            padding: 0 25px;
          }
        }
        .container-0-3-31 > .panel1 {
          grid-row: 1;
          grid-column: 1 / span 4;
        }
        .container-0-3-31 > .panel2 {
          grid-row: 1;
          grid-column: 1 / span 4;
        }
        .container-0-3-31 > .panel3 {
          grid-row: 1;
          grid-column: 1 / span 4;
          margin-right: 15px;
        }
        @media (min-width: 1024px) {
          .container-0-3-31 > .panel3 {
            z-index: 1;
            grid-column: 6 / span 2;
            margin-left: -25px;
            border-right: 1px solid var(--border);
            margin-right: -26px;
          }
        }
        @media (min-width: 1024px) {
          .container-0-3-31 > .panel2 {
            z-index: 2;
            grid-column: 4 / span 2;
            margin-left: -25px;
            border-right: 1px solid var(--border);
          }
        }
        @media (min-width: 1024px) {
          .container-0-3-31 > .panel1 {
            z-index: 3;
            grid-column: 2 / span 2;
            margin-left: -25px;
            border-right: 1px solid var(--border);
          }
        }
        .overlay-0-3-32 {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: 1;
          position: absolute;
          /* background-color: #000; */
        }
        .formContainer-0-3-98 {
          padding-bottom: 50px;
        }
        @media (min-width: 1024px) {
          .formContainer-0-3-98 {
            padding-bottom: 50px;
          }
        }
        .form-0-3-99 {
          margin-bottom: 25px;
        }
        @media (min-width: 1024px) {
          .form-0-3-99 {
            margin-bottom: 25px;
          }
        }
        .errors-0-3-100 {
          display: flex;
          align-items: center;
          flex-direction: column;
        }
        .error-0-3-101 {
          color: #cf6c4f;
          font-size: 10px;
          text-align: center;
          font-family: "ABCMonumentGroteskMono", "Lucida Sans", Tahoma, sans-serif;
          line-height: 1;
          margin-bottom: 25px;
          text-transform: uppercase;
        }
        @media (min-width: 1024px) {
          .error-0-3-101 {
            margin-bottom: 25px;
          }
        }
        .error-0-3-101:last-child {
          margin-bottom: 0;
        }
        .inputContainer-0-3-102 {
          height: 50px;
          display: flex;
          position: relative;
          align-items: center;
        }
        .inputContainer-0-3-102.hasError {
          color: #cf6c4f;
        }
        .inputContainer-0-3-102:focus-within .bottomBorder {
          opacity: 1;
        }
        .inputContainer-0-3-102.hasError > label {
          opacity: 1;
        }
        .inputLabel-0-3-103 {
          flex: 1;
          opacity: 1.5;
          font-size: 10px;
          font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
          line-height: 1;
          text-transform: uppercase;
        }
        .input-0-3-104 {
          flex: 2;
          color: currentColor;
          border: none;
          outline: none;
          padding: 16px 0;
          font-size: 12px;
          font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
          line-height: 1;
          border-radius: 0;
          background-color: transparent;
        }
        .input-0-3-104:-webkit-autofill,
        .input-0-3-104:-webkit-autofill:hover,
        .input-0-3-104:-webkit-autofill:focus .input-0-3-104:-webkit-autofill,
        .input-0-3-104:-webkit-autofill:hover
          .input-0-3-104:-webkit-autofill:focus {
          box-shadow: 0 0 0px 1000px transparent inset;
          transition: background-color 5000s ease-in-out 0s;
          caret-color: currentColor;
        }
        .bottomBorder-0-3-105 {
          opacity: 1.2;
          transition: opacity 0.25s ease-in-out;
        }
        .hiddenButton-0-3-106 {
          display: block;
        }
        .container-0-3-86 {
          margin-top: 0;
        }
        @media (min-width: 1024px) {
          .container-0-3-86 {
            margin-top: 75px;
          }
        }
        .image-0-3-87 {
          margin-bottom: 20px;
        }
        @media (min-width: 1024px) {
          .image-0-3-87 {
            margin-bottom: 35px;
          }
        }
        .content-0-3-88 {
          width: 250px;
          margin: auto;
          text-align: center;
        }
        .title-0-3-89 {
          font-size: 15px;
          margin-bottom: 10px;
        }
        @media (min-width: 1024px) {
          .title-0-3-89 {
            margin-bottom: 20px;
          }
        }
        .copy-0-3-90 {
          opacity: 1.5;
          font-size: 12px;
          white-space: pre-wrap;
          margin-bottom: 10px;
        }
        @media (min-width: 1024px) {
          .copy-0-3-90 {
            margin-bottom: 20px;
          }
        }
        .linkLabel-0-3-91 {
          opacity: 1.5;
        }
        .container-0-3-61 {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1003;
          overflow: hidden;
          position: fixed;
          pointer-events: none;
        }
        .overlay-0-3-62 {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: 1;
          position: absolute;
          /* background-color: #000; */
        }
        .cart-0-3-63 {
          top: 0;
          right: 0;
          width: 100%;
          height: 100%;
          padding: 25px 15px 15px;
          position: absolute;
          transform: translate(100%, 0);
          background-color: var(--background);
        }
        @media (min-width: 1024px) {
          .cart-0-3-63 {
            width: 480px;
            bottom: 0;
            height: auto;
            padding: 25px;
          }
        }
        .cartContainer-0-3-64 {
          height: 100%;
          display: flex;
          position: relative;
          flex-direction: column;
        }
        .header-0-3-65 {
          display: flex;
          position: relative;
          flex-grow: 0;
          flex-shrink: 0;
          flex-direction: column;
          justify-content: space-between;
        }
        .headerTop-0-3-66 {
          display: flex;
          align-items: start;
          margin-bottom: 25px;
          justify-content: space-between;
        }
        @media (min-width: 1024px) {
          .headerTop-0-3-66 {
            margin-bottom: 25px;
          }
        }
        .headerBottom-0-3-67 {
          margin-bottom: 45px;
        }
        @media (min-width: 1024px) {
          .headerBottom-0-3-67 {
            margin-bottom: 45px;
          }
        }
        .backToCartButton-0-3-68 {
          visibility: hidden;
        }
        .backToCartButton-0-3-68.show {
          visibility: visible;
        }
        .content-0-3-69 {
          overflow: auto;
          position: relative;
          flex-grow: 1;
          flex-shrink: 1;
        }
        .content-0-3-69:before {
          top: 0;
          left: 0;
          right: 0;
          height: 1px;
          /*  /* content: ""; */
          opacity: 1.3;
          position: absolute;
          /* background-color: var(--border); */
        }
        .content-0-3-69.empty:after {
          display: block;
        }
        .innerContent-0-3-70 {
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          position: absolute;
          overflow-y: auto;
        }
        .formContent-0-3-71 {
          opacity: 1;
        }
        .footer-0-3-72 {
          flex-grow: 0;
          flex-shrink: 0;
        }
        .title-0-3-73 {
          display: flex;
          font-size: 20px;
          align-items: center;
          font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
          text-transform: none;
        }
        .count-0-3-74 {
          margin-left: 5px;
        }
        .copy-0-3-75 {
          font-size: 10px;
          text-align: center;
          white-space: pre-wrap;
          margin-bottom: 15px;
        }
        .emptyCopy-0-3-76 {
          font-size: 15px;
          margin-top: 15px;
        }
        .deliveryCopy-0-3-77 {
          opacity: 1.5;
        }
        .successCopy-0-3-78 {
          color: #219653;
        }
        .errorCopy-0-3-79 {
          color: #eb5757;
        }
        .submitButton-0-3-80 {
          width: 100%;
          padding: 16px 32px;
        }
        .closeButton-0-3-81 {
          border: none;
          cursor: pointer;
          margin: 0;
          padding: 0;
          border-radius: 0;
          background-color: transparent;
        }
        .successMessage-0-3-82 {
          top: 50%;
          left: 50%;
          width: 100%;
          display: flex;
          position: absolute;
          max-width: 340px;
          transform: translateX(-50%) translateY(-50%);
          text-align: center;
          align-items: center;
          flex-direction: column;
          justify-content: center;
        }
        .successTitle-0-3-83 {
          font-size: 15px;
        }
        .successSubtitle-0-3-84 {
          opacity: 1.5;
          font-size: 12px;
        }
        .form-0-3-169 {
          height: 100%;
          display: flex;
          flex-direction: column;
        }
        .errors-0-3-170 {
          display: flex;
          align-items: center;
          flex-direction: column;
        }
        .error-0-3-171 {
          color: #cf6c4f;
          font-size: 10px;
          margin-top: 10px;
          text-align: center;
          font-family: "ABCMonumentGroteskMono", "Lucida Sans", Tahoma, sans-serif;
          line-height: 1;
          margin-bottom: 25px;
          text-transform: uppercase;
        }
        @media (min-width: 1024px) {
          .error-0-3-171 {
            margin-bottom: 25px;
          }
        }
        .error-0-3-171:last-child {
          margin-bottom: 0;
        }
        .formFieldContainer-0-3-172 {
          display: flex;
          position: relative;
        }
        .formFieldContainer-0-3-172.hasError {
          color: #cf6c4f;
        }
        .formFieldContainer-0-3-172:focus-within .bottomBorder {
          opacity: 1;
        }
        .formFieldContainer-0-3-172.hasError > label {
          opacity: 1;
        }
        .inputContainer-0-3-173 {
          height: 50px;
          align-items: center;
        }
        .textareaContainer-0-3-174 {
          padding: 20px 0 0;
          flex-grow: 2;
          flex-direction: column;
        }
        .textareaContainer-0-3-174 label {
          flex: 0;
        }
        .textareaContainer-0-3-174 textarea {
          flex: auto;
          resize: none;
          font-size: 12px;
          max-width: 100%;
        }
        .textareaContainer-0-3-174 textarea::placeholder {
          opacity: 1.3;
        }
        .inputLabel-0-3-175 {
          flex: 1;
          opacity: 1.5;
          font-size: 10px;
          font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
          line-height: 1;
          text-transform: uppercase;
        }
        .input-0-3-176 {
          flex: 2;
          color: currentColor;
          border: none;
          outline: none;
          padding: 16px 0;
          font-size: 12px;
          font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
          line-height: 1;
          border-radius: 0;
          background-color: transparent;
        }
        .input-0-3-176:-webkit-autofill,
        .input-0-3-176:-webkit-autofill:hover,
        .input-0-3-176:-webkit-autofill:focus .input-0-3-176:-webkit-autofill,
        .input-0-3-176:-webkit-autofill:hover
          .input-0-3-176:-webkit-autofill:focus {
          box-shadow: 0 0 0px 1000px transparent inset;
          transition: background-color 5000s ease-in-out 0s;
          caret-color: currentColor;
        }
        .bottomBorder-0-3-177 {
          opacity: 1.2;
          transition: opacity 0.25s ease-in-out;
        }
        .hiddenButton-0-3-178 {
          display: block;
        }
        .container-0-3-150 {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1003;
          overflow: hidden;
          position: fixed;
          pointer-events: none;
        }
        .overlay-0-3-151 {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: 1;
          position: absolute;
          /* background-color: #000; */
        }
        .quoteForm-0-3-152 {
          top: 0;
          right: 0;
          width: 100%;
          height: 100%;
          position: absolute;
          transform: translate(100%, 0);
          background-color: var(--background);
        }
        @media (min-width: 1024px) {
          .quoteForm-0-3-152 {
            width: 480px;
            bottom: 0;
            height: auto;
          }
        }
        .quoteFormContainer-0-3-153 {
          height: 100%;
          display: flex;
          position: relative;
          flex-direction: column;
        }
        .header-0-3-154 {
          margin: 25px 15px 0;
          display: flex;
          position: relative;
          flex-grow: 0;
          flex-shrink: 0;
          flex-direction: column;
          justify-content: space-between;
        }
        @media (min-width: 1024px) {
          .header-0-3-154 {
            margin: 25px 25px 0;
          }
        }
        .header-0-3-154:before {
          left: 0;
          right: 0;
          bottom: 0;
          height: 1px;
          content: "";
          opacity: 1.3;
          position: absolute;
          background-color: var(--border);
        }
        .headerTop-0-3-155 {
          display: flex;
          align-items: start;
          margin-bottom: 25px;
          justify-content: space-between;
        }
        @media (min-width: 1024px) {
          .headerTop-0-3-155 {
            margin-bottom: 25px;
          }
        }
        .content-0-3-156 {
          display: flex;
          padding: 0 15px;
          overflow: auto;
          position: relative;
          flex-grow: 1;
          flex-shrink: 1;
          flex-direction: column;
        }
        .content-0-3-156.empty:after {
          display: block;
        }
        @media (min-width: 1024px) {
          .content-0-3-156 {
            padding: 0 25px;
          }
        }
        .quoteProcessCopy-0-3-157 {
          margin: 0;
          padding: 40px 0;
          position: relative;
          font-size: 15px;
        }
        .quoteProcessCopy-0-3-157:before {
          left: 0;
          right: 0;
          bottom: 0;
          height: 1px;
          content: "";
          opacity: 1.3;
          position: absolute;
          background-color: var(--border);
        }
        .quoteSubmissionCopy-0-3-158 {
          margin: 15px auto 0;
          font-size: 10px;
          max-width: 260px;
        }
        .formContent-0-3-159 {
          opacity: 1;
          flex-grow: 2;
        }
        .footer-0-3-160 {
          padding: 0 15px 15px;
          flex-grow: 0;
          flex-shrink: 0;
        }
        @media (min-width: 1024px) {
          .footer-0-3-160 {
            padding: 0 25px 25px;
          }
        }
        .title-0-3-161 {
          display: flex;
          font-size: 20px;
          align-items: center;
          font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
          text-transform: none;
        }
        .count-0-3-162 {
          margin-left: 5px;
        }
        .copy-0-3-163 {
          font-size: 10px;
          text-align: center;
          white-space: pre-wrap;
          margin-bottom: 15px;
        }
        .submitButton-0-3-164 {
          width: 100%;
          padding: 16px 32px;
        }
        .closeButton-0-3-165 {
          border: none;
          cursor: pointer;
          margin: 0;
          padding: 0;
          border-radius: 0;
          background-color: transparent;
        }
        .successMessage-0-3-166 {
          top: 50%;
          left: 50%;
          width: 100%;
          display: flex;
          position: absolute;
          max-width: 340px;
          transform: translateX(-50%) translateY(-50%);
          text-align: center;
          align-items: center;
          flex-direction: column;
          justify-content: center;
        }
        .successTitle-0-3-167 {
          margin: 0 0 20px;
          font-size: 15px;
        }
        .successSubtitle-0-3-168 {
          margin: 0 0 20px;
          opacity: 1.5;
          font-size: 12px;
        }
        *,
        *::before,
        *::after {
          box-sizing: border-box;
          -webkit-tap-highlight-color: transparent;
        }
        :root {
          --border: #808080;
          --background: #ffffff;
          --foreground: #3a3a1f;
          --scrollbar-width: 0px;
        }
        html {
          line-height: 1.5;
        }
        body {
          color: var(--foreground);
          width: 100%;
          margin: 0;
          padding: 0;
          font-size: 16px;
          font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
          font-weight: 400;
          line-height: 1.5;
          background-color: var(--background);
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          margin-top: 0;
          /* font-family: "NHaasGroteskDSPro", "Lucida Sans", Tahoma, sans-serif; */
          font-weight: 400;
          margin-bottom: 0.4em;
          text-transform: uppercase;
        }
        h1 {
          font-size: 45px;
          line-height: 0.95;
        }
        @media (min-width: 1024px) {
          h1 {
            font-size: 65px;
          }
        }
        h2 {
          font-size: 35px;
          line-height: 0.9;
          letter-spacing: 0.65px;
        }
        @media (min-width: 1024px) {
          h2 {
            /* font-size: 65px; */
          }
        }
        h3 {
          font-size: 48px;
        }
        h4 {
          font-size: 32px;
        }
        h5 {
          font-size: 20px;
          margin-bottom: 25px;
          text-transform: none;
        }
        h6 {
          font-size: 15px;
          text-transform: none;
        }
        p {
          margin: 0 0 15px;
          /* font-size: 1.2rem; */
        }
        .srOnly {
          clip: rect(1px, 1px, 1px, 1px) !important;
          width: 1px !important;
          border: 0 !important;
          height: 1px !important;
          margin: -1px !important;
          padding: 0 !important;
          overflow: hidden !important;
          position: absolute !important;
          clip-path: inset(50%) !important;
          white-space: nowrap !important;
        }
        a {
          color: currentColor;
          /* text-decoration: underline; */
        }
        a:hover {
          /* text-decoration: none; */
        }
        button {
          color: currentColor;
          font-size: 10px;
          font-family: "ABCMonumentGroteskMono", "Lucida Sans", Tahoma, sans-serif;
          font-weight: 400;
          text-transform: uppercase;
        }
        .scrollbar-track.scrollbar-track-y {
          z-index: 2000;
        }
        main .scrollbar-track {
          /* width: 5px;
          border-radius: 2px;
          background-color: transparent; */
        }
        main .scrollbar-thumb.scrollbar-thumb-y {
          /* width: 3px;
          border-radius: 2px; */
        }
        .chatlio-title-bar {
          display: block;
        }
        .chatlio-widget-body {
          bottom: 10px !important;
        }
        .verticalLine-0-3-255{
            display: none;
        }
        @media (min-width: 1024px) {
          .chatlio-widget-body {
            right: 10px !important;
            bottom: 60px !important;
          }
          .verticalLine-0-3-255{
              display: block;
          }
        }
        .Layout-main-0-3-1 {
          width: 100%;
          /* height: 100vh; */
          overflow-x: hidden;
          overflow-y: hidden;
          /* overflow-y: auto; */
        }
        .Layout-inner-0-3-2 {
          padding-top: 72px;
        }
      /* </style> */

      .swiper-container {
        display: flex;
        justify-content: flex-end;
      }
      .swiper-container img{
        width: 450px;
      }
      .grid--logos {
        display: inline-block;
      }
      
      .grid__row {
        display: flex;
        flex-wrap: nowrap;
        margin-left:17px;
      
        @media (min-width: sm) {
          margin-left: -20px;
        }
      
        > div {
          width: 24.5vw;
          flex-shrink: 0;
          display: block;
          padding-left: 10px;
          margin-bottom: 10px;
      
          @media (min-width: sm) {
           
            padding-left: 20px;
            margin-bottom: 20px;
          }
        }
      }
      
      .section--logos {
        overflow: hidden;
        /* padding: 57% 0 0; */
      
        @media (min-width: 1600px) {
          padding: 57px 0 0;
        }
      
        .section__intro {
          /* margin: 0 auto {(146 / 1800) * 100}vw; */
          text-align: center;
      
          @media (min-width: 1800px) {
            margin-bottom: 146px;
          }
        }
      }
      .block__image img{
        /* width: ; */
        height: -1%;
        width: 94%;
        
        /* width: 100%; */
      }


     
      @media (min-width: 900px) {
      .layout_row.home{
        height: 25vw
      }
      .layout_image.home{
        width: 70%;
        height: 60%;
        margin-bottom: 18rem;
      }
    }

    .section_process.home{
        /* margin-bottom: 5rem; */
        /* margin-top: -5rem; */
    }

    .image-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(15vw, 1fr)); /* Responsive grid with a minimum column width of 300px */
        gap: 30px; /* Adjust the gap between images as needed */
        margin: 0 5px 80px 40px;
        align-items: center;
      }
      
      .image-grid img {
        /* width: 15rem; */
        width: 50%;
        height: auto;
      }
      @media (max-width: 900px) {
        .image-grid {
          grid-template-columns: repeat(4, 1fr); /* Display 2 images per row on smaller screens */
          margin: 0; /* Remove margin to fit better in smaller screens */
          margin: 0 30px;
        }
      }

      @media (max-width: 500px) {
        .image-grid {
          grid-template-columns: repeat(3, 1fr); /* Display 2 images per row on smaller screens */
          margin: 0; /* Remove margin to fit better in smaller screens */
          margin: 0 30px;
          gap: 10px;
          /* margin-top: -; */
          /* padding-top: 1rem; */
          /* padding-bottom: 5rem; */
          margin-top: -2rem;
        }
        .image-grid img{
            width: 70%;
        }
        .komefi{
            margin-top: -2rem;
        }
        .solarkontl{
            /* padding-bottom: 5rem; */
            margin-top: -0.5rem;
        }
      }
      .homewealso{
        color: #005E8C;

font-size: 25px;
font-style: normal;
font-weight: 500;
text-align: left;
line-height: normal;
margin-left: 2.5rem;
letter-spacing: 2.24px;
text-decoration-line: underline;
/* text-transform: capitalize; */
text-transform: none;
      }
      @media (max-width: 950px) {
        .header-0-3-3 LI A{
          font-size: 15.5px;
          text-decoration: none;
          color: #5C5C5C;
        }
        .header-0-3-3{
          background: white;
        }
      }

      .homeaboutb{
        border-radius: 6px;
border: 1.5px solid rgba(92, 92, 92, 0.60);
background: #FFF;
width: 105px;
height: 35px;
/* color: ; */
flex-shrink: 0;
color: rgba(92, 92, 92, 0.60);
margin-top: 1rem;

font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
      }
      @media (max-width: 950px) {
        .sectionsliderhp{
            /* margin-top: -4rem; */
        }
        .sectionsliderrhpp{
            margin-top: -4rem;
        }
      .newaboust {
        margin-top: -5rem;
        /* padding-bottom: 5rem; */
      }
      .detailsContainer-0-3-259{
        position: absolute;
        bottom: 0;
        /* margin: 0px -10px; */
      }
      .titleField-0-3-257{
        width: 100%;
        grid-column: 1 / span 4;
      }
      .newaboust h3,p,.button{
        /* margin: 0px -10px; */
      }
     
      .tradinginm{
        margin: 0px 5px 0px ;
        /* width: 8rem; */
      }
      .label-0-3-266{
        /* margin: 0px -10px; */
      }
      .text-size-small.text-style-allcaps.text-colour-grey{
        font-size: 16px;
      }
      .link-0-3-268:after{
        /* background-color: white; */
      }
      .nav-0-3-5{
        height: 66px;
      }
      .text-size-medium.max-width-medium.text-weight-light.full-width-respomsive:nth-child(2){
        /* margin-top: -1.4rem; */
        margin-bottom: -1rem;
      }
      .image-d0-0-3-280 {
        /* width: 75%; */
        /* height: 100%; */
      }
        .white-line.not-visible-mobile.about-line {
            /* margin-top: 12rem; */
        }
        .firstMobileLine-0-3-387{
            /* display: none; */
            margin-top: -3rem;
        }
        .socialLinksContainer-0-3-299{
            margin-top: -5rem;
        }
        .socialLinksContainer-0-3-299 ul li a{
            margin-top: -6rem;
            font-size: 15px;
        }
        .homelastmoclin{
            display: none;
        }
        .fotermobujer{
margin-top: -1rem;
        }

    }
    @media (max-width: 750px) {
        .socialLinksContainer-0-3-299 ul li a{
            margin-top: -6rem;
            font-size: 12px;
        }
    }