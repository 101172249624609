* {
  font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
}
.solar01 {
  position: absolute;
  width: 100%;
  height: 0px;
  top: 11%;
  opacity: 0.8;
  border-bottom: 1px solid rgba(58, 58, 31, 0.8);
  animation: 2.5s cubic-bezier(0.25, 1, 0.3, 1) wipe-in-right both;
}
.solar-body {
  padding-top: 7.5rem;
  color: rgba(58, 58, 31, 0.8);
}

.sflex-container {
  display: flex;
  flex-direction: row;
  font-size: 30px;
  text-align: center;
}
.solarfmain a{
  text-decoration: none;
}
.sflex-item-left {
  padding: 10px;
  flex: 50%;
}

.sflex-item-right {
  padding: 10px;
  flex: 50%;
}
.solar-01 {
  padding-left: 8rem;
}
.solar-head {
  font-size: 1.5rem;
  text-decoration: underline;
  padding-left: 4.5rem;
  padding-top: 1rem;
}
.solarlogo {
  position: absolute;
  width: 4rem;
  padding-top: 1.3rem;

  left: 3%;
}
.solarpr {
  position: absolute;
  padding-top: 3rem;
  text-decoration: underline;
  padding-left: 4rem;
}
.solarimg {
  height: 80%;
  /* border-radius: 10px; */
  /* position: absolute; */
  right: 4%;
  width: 80%;
  margin-top: 8rem;

  /* top:52%; */
  animation: 2.5s cubic-bezier(0.25, 1, 0.3, 1) wipe-in-down both;
}
.solarproducts-deails {
  position: absolute;

  padding-left: 4rem;
  padding-right: 4rem;
  padding-top: 8rem;
  font-size: 5rem;
}
.solarproducts-deails p {
  font-size: 1.2rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 85%;
  text-align: justify;
  padding-top: 2rem;
  font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
}
.solarproducts-deails h1 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  /* padding-left: 5rem; */
  /* font-size: 5rem; */
  text-align: center;
}
.solarproducts-deails span {
  color: orange;
}
.solarpr{
  text-align: center;
}
.solarproducts-deails img {
  width: 45%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  padding-top: 1.5rem;
}
 .solarone table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 50%;
  border: 2px solid #000000;

  left: 25%;

  margin-top: 4rem;
  position: absolute;
}
th {
  font-size: 18px;
  /* font-family: "Amaranth"; */
  font-style: normal;
  font-weight: 700;
  /* font-size: 28px; */
  line-height: 136.7%;

  /* color: #000000; */
  text-align: justify;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  background: rgba(0, 94, 140, 0.80);;
}
td {
  padding-top: 45px 85px 78px;
}
th,
td {
  text-align: left;
  padding: 8px 34px;

  border-bottom: 1px solid rgba(92, 92, 92, 0.70);
  /* background: rgba(92, 92, 92, 0.70); */
  /* border: none; */
  /* font-size: 1.2vw; */
}
.solarptable td {
}
.stable-striped {
  /* width: 50%; */

  font-size: 20px;
}
.solarfmain table {
  border-collapse: collapse;
}
 .solarproducts-deails .stable-striped td,
th {
  border: 1px solid black;
}
 
.ssolarph1{
  text-align: center;
}
.ssolarphimg{
  width: 50%;
  height: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 2rem;
}
.ssolarpprd{
  width: 85%;
  font-size: 1.2rem;
  text-align: justify;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 2rem;
}
.solarproducts-3{
  padding-top: 65rem;
}
.solarproducts-deails2 {
  /* position: absolute; */
  padding-top: 110rem;
  position: relative;

  /* top: 350%; */
}
.solar-body .tab:nth-child(-n+16){
  border-bottom: 1px solid rgba(58, 58, 31, 0.7);
}

/* media */
@media (max-width: 1800px) {
  .solarproducts-deails{
 margin-top: 20rem;
  }
}
@media (max-width: 1012px) {
  .sflex-container {
    flex-direction: column;
  }
}
@media (max-width: 1012px) {
  .solar-body {
    padding-top: 12rem;
  }
  .solarimg {
  margin-top: 80rem;
  }
  .solarproducts-deails {
    /* padding-top: 155rem; */
    margin-top: 1rem;
  }
  .solarproducts-deails2{
    /* padding-top: 255rem; */
    margin-top: 1rem;
  }
 
}
@media (max-width: 960px) {
  .solarimg {
    margin-top: 120rem;
    }
    .solarlogo{
      margin-top: -1rem;
      margin-left: 1.5rem;
    }
}
@media (max-width: 650px) {
  .solar-body {
    padding-top: 12rem;
  }
  .solarimg {
 
  }
  .solarpr {
    font-size: 8vw;
    left: 1%;
  }
  .solarproducts-deails h1 {
    width: 100%;
   
  }
  .stable-striped {
    left: 20%;
  }
}
@media (max-width: 380px) {
  .solarimg {
 margin-top: 135rem;
  }
}
@media (max-width: 270px) {
  .solarimg {
 margin-top: 150rem;
  }
}
@media (max-width: 330px) {
  .solar-body {
    padding-top: 12rem;
  }
  .solarimg {

  }
  .solarpr {
    font-size: 2vw;
    left: 1%;
  }
  .solarproducts-deails {
  }
}


.solarfmain{
  /* margin-bottom: 1045px; */
}
/* CSS to center the table */
.table-container {
  display: flex;
  justify-content: center;
}
.asolarproducts-deailsa{
  padding: 2rem;
}
.asolarproducts-deailsa h1{
  text-align: center;
  font-size: 2vw;

}
.asolarproducts-deailsa img{
  width: 30rem;
  height: 10rem;
  /* justify-content: center; */
}/* CSS to align the image to the center */
.image-container {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
.asolarproducts-deailsa p{
  /* margin-left: 2rem; */
  /* margin-right: 2rem; */
  text-align: justify;
  color: #5C5C5C;
  margin-top: 2rem;
}
.asolarproducts-deailsa .stable-striped {
  width: 50%;
  margin-left: 2rem;
  margin-right: 2rem;
  font-size: 10px;
  border: 1px solid #000;
}
.asolarproducts-deailsa .table td, .table th{
  border: 1px solid #000;
  color: #000000;
}
.table thead th{
  border: 1px solid #000;
}
@media (max-width: 500px) {
.asolarproducts-deailsa .stable-striped{
width: 100%;
}
}

.elmexlomps{
  width: 7rem;
}
.elmexlompsp{
  margin-top: 1rem;
  color: #005E8C;
  /* text-decoration: underline; */
}
@media (max-width: 550px) {
  .elmexlomps{
    /* width: 9rem; */
    margin-left: 0rem;
  }
  .asolarproducts-deailsa h1{
    font-size: 3.9vw;
  }
  .asolarproducts-deailsa{
    padding: 1rem;
  }
}