.terminal-body {
  padding-left: 4rem;
  padding-right: 4rem;
  padding-top: 6rem;
  color: rgba(58, 58, 31, 0.8);
}
.terminal-01 {
  padding-left: 4rem;
  font-size: 2vw;
}
.flex-container {
  display: flex;
  flex-direction: row;
  font-size: 30px;
  text-align: center;
}
.terminal-body .terminal-head {
  font-size: 1.5rem;
  text-decoration: underline;
}
.elmexlogo {
  position: absolute;
  width: 4rem;
  /* padding-top: -1.3rem; */
  margin-top: -1rem;
}
.terminal01 {
  position: absolute;
  width: 100%;
  height: 0px;

  top: 11%;

  opacity: 0.8;
  border-bottom: 1px solid rgba(58, 58, 31, 0.8);
 
  animation: 2.5s cubic-bezier(0.25, 1, 0.3, 1) wipe-in-right both;
}
@keyframes wipe-in-right {
  from {
    clip-path: inset(0 100% 0 0);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

.terminal-desc {
  font-size: 15px;
  width: 32%;
  padding-top: 2rem;
  text-align: justify;
}
.flex-item-left {
  padding: 10px;
  flex: 50%;
}

.tflex-item-right {
  padding: 10px;
  flex: 50%;
}
.terminalimg {
  /* width: 3rem; */
  height: 90%;
  border-radius: 10px;
  position: absolute;
  right: 4%;
  /* margin-top: 4rem; */
  top: 18%;
  /* width: 30%; */
  animation: 2.5s cubic-bezier(0.25, 1, 0.3, 1) wipe-in-down both;
}
@keyframes wipe-in-down {
  from {
    clip-path: inset(0 0 100% 0);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

.terminalaccordian {
  width: 45%;
  left: 1.9%;
  position: absolute;
  /* font-size: 1px; */
  padding-top: 9rem;
  /* height: 45%; */
}
.terminalaccordian .tab-label {
  /* color: red; */
  /* font-size: 1rem; */
  font-weight: 100;
  font-size: 12px;
  margin-top: 4px;
}
.terminalpr {
  position: absolute;
  padding-top: 3rem;
  /* margin-left: 1rem; */
  
  text-decoration: underline;
 
}
.terminalaccordian input{
  display: none;
}

.terminalcard-details {

  padding-left: 2rem;
  /* display: inline-block; */

  padding-right: 2rem;
  
  /* display: flex; */
  /* flex-direction: column-reverse; */
 
}
.ricontainer{
  /* display: inline-block; */
}

.terminaldetmain {
  /* display: inline-block; */

}
.cterminaldesc{
  /* margin-top: 30rem; */
  /* display: inline-block; */
}

/* media query start */
@media (max-width: 800px) {
  .flex-container {
    flex-direction: column;
  }
}



@media (max-width: 1320px) {
 .terminal-desc{
  width: 45%;
 }
 .terminalimg {

height: 75%;

}
}
@media (max-width: 1100px) {
  .terminal-body {
    padding-left: 4rem;
    padding-right: 4rem;
    padding-top: 3rem;
  }
  .terminalpr {
    font-size: 3vw;

  }
  .terminalaccordian {
    width: 90%;
    margin-top: 1%;
    left: 1%;
   
  }
  .terminalimg {
    left: 15%;

    
    margin-top: 48rem;
    height: 105%;
    width: 70%;
 
 
  }
 .terminalaccordian  .tab-label{
    /* font-size: 54px; */
  }
  .terminal-desc {

    width: 100%;
    font-size: 2vw;
  }
  .cterminaldesc{
    /* margin-top: 85rem; */
  }
.rititle-heading{
  font-size: 25px;
}
.terminalpr{
  margin-top: 1%;
}

}

@media (max-width: 970px) {
  .terminal-body {
    padding-left: 4rem;
    padding-right: 4rem;
    padding-top: 10rem;
  }
  .terminalimg {
    left: 9%;

    
    margin-top: 55rem;
    height: 50%;
    width: 80%;
 
 
  }
  .cterminaldesc{
    /* margin-top: 65rem; */
  }
}

@media (max-width: 380px) {
  .terminal-body {
    padding-left: 4rem;
    padding-right: 4rem;
    padding-top: 8rem;
  }
  .terminalimg {
    left: 9%;

    
    margin-top: 40rem;
    height: 50%;
    width: 80%;
 
 
  }
  .terminalaccordian {
    /* width: 80%;
    margin-top: 1%; */
    margin-left: -1%;
  }
  .elmexlogo{
    margin-top: -1.7rem;
  }
.ricard-body{
  /* margin-bottom: 450px; */
  /* border: 5px solid red; */
}
.ricard{
  /* margin-top: 4px; */
}
}


.terminalfmain{
  margin-bottom: 245px;
  margin-top: 4%;
}
.ricard{
  /* display: inline-block; */
}
@media (max-width: 900px) {
  .terminalfmain{
    margin-bottom: 595px;
  }
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(12rem, 16rem));
  gap: 2rem;
  justify-content: center;
}
/* .card {
  overflow: hidden;
  box-shadow: 0 2px 20px #e1e5ee;
  border-radius: 0.2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: transform 200ms ease-in;
}
.card__image {
  height: 12rem;
  width: 100%;
  object-fit: cover;
}
.card__title {
  padding: 1rem;
}
.card__description {
  padding: 0 1rem;
} */

.ricontainer{
  display: grid;
  grid-template-columns: repeat(1,1fr);
}

.terminalfmain a{
  text-decoration: none;
}

@media (max-width: 970px){
.cterminaldesc {
    margin-top: -3rem;
}
.ricard-title{
  font-size: 16px;
}
.rititle-heading {
  font-size: 20px;
  text-align: center;
  margin: 11% 0px 0%;
  text-transform: capitalize;
  color: #005E8C;
}
}