.crompton-body {
  padding-top: 7.5rem;
  padding-left: 4rem;
  padding-right: 4rem;
  color: rgba(58, 58, 31, 0.8);
}
.chead {
  padding-left: 5rem;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  font-size: 2.5rem;
}
.cromlogo {
  width: 2vw;

  position: absolute;
  padding-top: 0.1rem;
  left: 5rem;
}
.cromptonfmain a{
  text-decoration: none;
}
.crom-content01 {
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  line-height: 0%;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}
.crom-content2 {
  /* position: absolute; */
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  text-align: left;

  padding-top: 5rem;
  font-weight: 400;
  /* left: 1px; */
  width: 40%;
  /* padding-left: 0rem; */
  /* margin-left: -6rem; */
  /* text-align: left; */
}
.cromptonlogo{
  width: 2rem;
  position: absolute;
  margin-left: 1.5%;
  margin-top: 5px;
}
.crom-content3 {
  font-size: 1.1rem;

  width: 40%;
  display: flex;
  align-items: center;
  text-align: justify;
  padding-top: 2rem;
}
.cromflex-container {
  display: flex;
  flex-direction: row;
  font-size: 30px;
  text-align: center;
}

.cromflex-item-left {
  padding: 10px;
  flex: 50%;
}

.cromflex-item-right {
  padding: 10px;
  flex: 50%;
}
.crom-mainimg {
  position: absolute;
  top: 8rem;
  right: 4rem;
  width: 40%;
  height: 90%;
}
@media (max-width: 1100px) {
  .cromflex-container {
    flex-direction: column;
  }
}

@media (max-width: 1400px) {
  .crom-mainimg {
    position: absolute;
    /* top: 10rem; */
    right: 2rem;

    width: 32rem;
    height: 80%;
  }
  .crom-content3 {
    font-size: 1vw;
  }
  .cromlogo {
    margin-top: 0.3rem;
  }
  .cromflex-item-left .tab-label {
    font-size: 8px;
  }
  .cromflex-item-left .terminalaccordian {
    width: 40%;
    margin-left: 1.4%;
    margin-top: -4rem;
  }
  .cromflex-item-left .terminalpr {
    font-size: 2vw;
    margin-top: -2rem;
  }
}

@media (max-width: 1100px) {
  .crom-mainimg {
    position: absolute;
    top: 60rem;

    margin-right: 25%;
    height: 55%;

    width: 32rem;
  }
  .cromptonlogo{
    width: 2rem;
    position: absolute;
    margin-left: 29.5%;
    margin-top: 15px;
  }
  .crom-content3 {
    width: 100%;
    text-align: center;
    margin-left: 2rem;
    margin-right: 2rem;
    font-size: 2vw;
  }
  .cromflex-item-left .terminalaccordian {
    width: 80%;
  }
  /* .terminalaccordian {
    width: 90%;
  } */
  .crom-content2 {
    width: 100%;
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: -4rem;
  }
  .crom-content01 {
    font-size: 2.4vw;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .cromlogo {
    /* left: 6rem; */
    /* margin-top: -0.1rem; */
    margin-top: 1.4rem;
    /* margin-left: 30rem; */
  }
  .chead {
    font-size: 5vw;

    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .cromflex-item-left {
    margin-top: 48rem;
  }
  .cromflex-item-right .crom-mainimg {
    /* top: 58%; */
    margin-top: -28rem;
    width: 40vw;
  }
  .cromflex-item-left {
    margin-top: 40rem;
  }
}

@media (max-width: 900px) {
  .cromptonfmain{
    margin-top: 4rem;
  }
  .crom-mainimg {
    /* position: absolute; */
    top: 64rem;
    margin-right: 25%;
    width: 32%;
    height: 30%;
  }
  .cromflex-item-left{
    margin-top: 48rem;
  }
}
@media (min-width: 175px) and (max-width: 500px) {
  .cromflex-item-right .crom-mainimg {
    /* top: 58%; */
    margin-top: -35rem;
    width: 40vw;
  }
  .cromflex-item-left {
    margin-top: 23rem;
  }
}
@media (max-width: 500px) {
  .crom-mainimg {
    /* position: absolute; */
    top: 68rem;
    margin-right: 25%;
    width: 32%;
    height: 22%;
  }
  .cromflex-item-left {
    margin-top:18rem;
  }
  .cromptoncardsdeta{
    /* margin-top: 45rem; */
  }
}

/* @media (max-width: 970px) {
  .crom-mainimg {
    position: absolute;
    top: 64rem;
    margin-right: 25%;
    width: 32rem;
  }
}
@media (max-width: 500px) {
  .crom-mainimg {
    position: absolute;
    top: 64rem;
    margin-right: 17%;
    width: 32rem;
  }
}
@media (max-width: 400px) {
  .crom-mainimg {
    position: absolute;
    top: 65%;
    margin-right: 6%;
    width: 78%;
  }
} */

.cromptoncardsdeta {
  margin-top: 8rem;
  /* position: absolute; */
  position: relative;
}
.cromcard{
  /* border: 1px solid #ddd; */
}
.cromcards {
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: row;
  /* margin-top: 25%; */
  /* border: 1px solid #ddd; */
  margin-bottom: 1%;
}
@media (max-width: 900px) {
  .cromcards {
    /* display: flex; */
    /* flex-wrap: wrap; */
    flex-direction: column;
    /* margin-top: 25%;
    margin-bottom: 1%; */
  }
}
.cromrow2 {
  /* margin-left: 0;
  margin-top: 58rem;

  position: absolute;
  width: 90%;
  left: 4rem; */
}
.third {
  display: flex;
  float: right;
  margin-top: -48rem;
}
.cromrow3 {
  margin-left: 0;
  margin-top: 54rem;

  position: absolute;
  width: 60%;
  left: 68rem;
}
.cromcards a:hover {
  color: yellow;
  text-decoration: none;
}
.cromcard h1 {
  font-size: 35px;
}
.cromptoncardsdeta input {
  display: none;
}
.cromcards-item {
  display: flex;
  padding: 2rem;
}
.cromcards-item {
  margin-left: 1rem;
}
.cromcards-item.second {
  margin-top: -1rem;
}

@media (min-width: 40rem) {
  .cromcards-item {
    width: 100%;
  }
}

@media (min-width: 56rem) {
  .cromcards-item {
  }
  .cromcards-item {
    display: block;
  }
}

.cromcard {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: white;
  border-radius: 0.25rem;
}

.cromcard-image {
  width: 100%;
}
.cromcard-image img {
  width: 50%;
  display: block;

  margin-right: auto;
}

.cromcard h1 {
  font-weight: 400;
  font-size: 20px;

  text-transform: uppercase;

  /* color: rgba(58, 58, 31, 0.8); */
}
.cromcard-title {
  display: block;

  margin-right: auto;
}
.cromcard-image1 {
  background-image: url(https://www.crompton.co.in/wp-content/uploads/2022/06/batten-light-thumbnails_1-600x600.jpg);
}

.cromcard-content {
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
}

.cromcard-title {
  color: #005E8C;
  font-size: 1.25rem;
  font-weight: 500;
  padding-bottom: 1rem;
  text-transform: uppercase;
}

.cromcard-text {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 1.25rem;
  color: #777777;
}

.cromcards-button {
  background-color: white;
  border: 1px solid #cccccc;
  color: #696969;
  padding: 0.5rem;
  text-align: center;
}

.button-block {
  display: block;
  width: 100%;
}

.cromptonfmain {
  margin-bottom: 205px;
}


@media (max-width: 900px) {
.cromlogo{
margin-left: 26rem;
margin-top: 0.4rem;


}
.cromptonlogo{
  width: 2rem;
  position: absolute;
  margin-left: 32.5%;
  margin-top: 5px;
}
.chead{
  font-size: 3rem;
  /* margin-left: 1rem; */
  padding-left: 0rem;
}
}

@media (max-width: 500px) {
  .cromlogo{
  margin-left: 11rem;
  margin-top: 0.6rem;
  
  
  }
  .cromptonlogo{
    width: 2rem;
    position: absolute;
    margin-left:24.5%;
    margin-top: 5px;
  }
  .chead{
    font-size: 3rem;
    /* margin-left: 1rem; */
    padding-left: 0rem;
  }
  }

  @media (max-width: 350px) {
   
    .cromptonlogo{
      width: 2rem;
      position: absolute;
      margin-left:10.5%;
      margin-top: 5px;
    }
  }

  @media (max-width: 500px) {

    .cromptoncardsdeta{
      /* margin-top: 20rem; */
      margin-top: -4rem;
    }
    .cromcards-item {
      /* display: flex; */
      padding: 0rem;
      border-bottom: 1px solid #5c5c5c;
      margin-top: 2rem;
    }
    .cromcard h1{
      padding-bottom: 1rem;
    }

  }
  .mainaccp{
    margin-top: 3rem;
    position: sticky;
    /* bottom: 0; */
    /* position: sticky; */
    /* position: static; */
    /* top: 23%; */
    /* top: 100%; */
    /* bottom: -/8rem; */
    /* position: -webkit-sticky;
    position: sticky;
    top: 50%; */

  }

  .elmexlompsc{
    width: 4rem;
    /* margin-top: -1rem; */
    /* margin-left: 6rem; */
    padding: 0.3rem;
  }

  .elmexlompsccp{
    /* margin-left: 1rem; */
  }
  .margin-bottom h2{
    left: 0;
  }
  @media (max-width: 500px) {
    .elmexlompsc{
      width: 2rem;
      /* margin-top: -1rem; */
      margin-left: 0rem;
      padding: 0.3rem;
    }
    .elmexlompsccp{
      margin-left: 0rem;
      margin-top: 0rem;
    }
  }#profile-description {
    max-width: 400px; 
    /* margin-top: 50px;  */
    position:relative;
  }
  #profile-description .text {
  /*   width: 660px;  */
    margin-bottom: 5px; 
    color: #777; 
    padding: 0 15px; 
    position:relative; 
    font-family: Arial; 
    font-size: 14px; 
    display: block;
  }
  #profile-description .show-more {
  /*   width: 690px;  */
    color: #777; 
    position:relative; 
    font-size: 18px; 
    padding-top: 5px; 
    height: 20px; 
    text-align: left; padding: 1em;
    /* background: #f1f1f1;  */
    cursor: pointer;
  }
  #profile-description .show-more:hover { 
      color: #1779dd;
  }
  #profile-description .show-more-height { 
    height: 180px; 
    overflow:hidden; 
  }
  .profile-descriptionhh{
    font-size: 16px;
  }
  @media (max-width: 500px) {

  #profile-description .show-more-height{
height: 90px;
  }
  .profile-descriptionhh{
    font-size: 15px;
  }
  .mainaccp{
    margin-top: 2rem;
  }
  .footer-0-3-367{
    margin-top: 2rem;
  }
}
@media (max-width: 300px) {
#profile-description .show-more-height {
  height: 98px;
}
}.text {
  overflow: hidden;
  transition: height 0.9s ease;
}

.show-more {
  cursor: pointer;
  transition: transform 0.9s ease;
}

.show-more-height {
  height: 200px; /* Set the height to your desired value */
}

.show-less-transition {
  transform: translateY(-30px); /* Set the translateY value to create a smooth upward transition */
}
.cromcard-image{
  margin-top: 1.6rem;
}