.panelflex-container {
  display: flex;
  flex-direction: row;
  font-size: 30px;
  text-align: center;
  color: rgba(58, 58, 31, 0.8);
}

.panelhaed {
  font-size: 2vw;
  padding-left: 4rem;
  padding-right: 4rem;
  padding-top: 3.7rem;
  font-weight: 400;
  color: rgba(58, 58, 31, 0.8);
}
.paneldesc {
  width: 49%;
  padding-left: 4rem;
  padding-right: 4rem;
  padding-top: 2rem;
}
.tab-label {
  font-size: 0.6vw;
}


.panelflex-item-left {
  padding: 10px;
  flex: 50%;
}
@media (max-width: 1340px) {
  .tab-label {
    font-size: 7px;
   
  }
  .panelflex-item-left {
    margin-top: -10px;
   
  }
}

.panelflex-item-right {
  padding: 10px;
  flex: 50%;
}
.paneldeschead {
  font-size: 2.2rem;
  text-align: left;
  padding-left: 2rem;
}
.panelrightimg {
 
  /* width: 80%; */
  height: 98%;
  margin-top: -185px;

}

@media (max-width: 1340px) {
  .panelrightimg {
 
    height: 160%;
    width: 100%;
    margin-top: -16rem;
  }
 
}
@media (max-width: 1100px) {
  .panelrightimg {
   
    height: 50%;
    width: 80%;
    margin-top: 35rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 450px) {
  .panelrightimg {
   
    height: 50%;
    width: 80%;
    margin-top: 35rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 330px) {
  .panelrightimg {
   
    height: 50%;
    width: 58%;
    margin-top: 175vw;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 1256px) {
  .panelflex-container {
    flex-direction: column;
  }
 
  .paneldesc {
    width: 100%;
  }
  .panelmianbody {
    padding-top: 5rem;
  }

  .panelflex-container {
    margin-top: -5rem;
  }
  .terminalaccordian {
    padding-left: 1rem;
  }
}

.panelcards-01 {
  width: 90%;
  margin: 5rem auto;
  display: grid;
  gap: 2.75rem;
  grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
  object-fit: fill;
}


.panelcard {
  width: 100%;
  cursor: default;
  padding: 1.25rem;
  border-radius: 0.25rem;
  background-color: var(--sdcolor);
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  /* height: 450px; */
  /* width: 450px; */
}
.panelcardheonew .panelcard{
  height: 600px;
  object-fit: cover;
}

.panelcard-img {
  width: 100%;
  height: 100%;
  /* height: 15rem; */
  overflow: hidden;
  position: relative;
  border-radius: 0.25rem;
}

.panelcard-img img {
  /* width: 100%; */
  height: 100%;
  display: block;
  object-fit: cover;
  object-position: center;
}

.panelcard-img figcaption {
  background-color: var(--ttcolor);
  color: var(--sdcolor);
  font-size: 0.85rem;
  padding: 0.5rem;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.panelcard-title {
  text-transform: capitalize;
  margin: 0.75rem 0;

  /* text-align: center; */
  font-size: 20px;
  text-decoration: none;
  font-weight: 400;

}




@media (max-width: 1066px) {
 
  .panelhaed {
  
    font-size: 5vw;
  }
  .paneldeschead {
    text-align: left;
    padding-left: 3rem;
    margin-left: -18px;
  }
  .terminalaccordian {
    margin-top: 55%;
  }
 
  .tab-label {
    font-size: 12px;
  }
}

@media (max-width: 500px) {
  .panelrightimg {
    /* margin-top: 34rem; */
    /* margin-top: 5rem; */
  }
  .terminalaccordian {
    /* font-size: 52px; */
    /* margin-top: 34rem; */
  }
  .tab-label {
    /* font-size: 10px; */
  }
  .paneldeschead {
    font-size: 25px;
    padding-left: 3.5rem;
    margin-left: 4px;
  }
  .panelcardheonew .panelcard{
    height: 400px;

    /* width: 450px; */
    object-fit: cover;
  }
  
}

@media (max-width: 410px) {
  .panelrightimg {
    /* margin-top: 15rem; */
  }
  .tab-label {
    /* font-size: 8px; */
  }
  .terminalaccordian {
    /* font-size: 52px; */
    margin-top: 29rem;
  }
}

@media (max-width: 320px) {
  .panelrightimg {
    /* margin-top: 88%; */
  }
  .terminalaccordian {
    margin-top: 22rem;
  }
}

.panelcard {
  cursor: pointer;
}

.panelfmain {
  margin-bottom: 85px;
  margin-top: 5%;
}
@media (max-width: 900px) {
  .panelfmain {
    /* margin-bottom: 595px; */
    margin-top: 10%;
  }
  .panelrightimg {
    /* margin-top: 40rem;
    width: 50%;
    height: 40%; */
  }
  .panelcardsmainsect {
    /* margin-top: 54rem; */
    /* margin-bottom: 145rem; */
    /* margin-top: -10rem; */
  }
  .panelcard-title{
    font-size: 1.2rem;
    text-decoration: none;
  }
}
@media (max-width: 500px) {
  .panelrightimg {
    /* margin-top: 70rem;
    width: 50%;
    height: 32%; */
  }
  .panelcardsmainsect {
    /* margin-top: 65rem; */
  }
}

.panelcard-title{
  text-decoration: none;
}
.panelcard-title:hover{
  text-decoration: none;
}
.panelcard-title a:hover{
  text-decoration: none;
  border: none;
}
a:hover{
  border: none;
}
a{
  text-decoration: none;
}
h2{
  text-decoration: none;
  border-bottom: none;
}
.process-layout_content-left .row input{
  opacity: 0;
}@media (max-width: 800px) {
  .panelcards-01 {
    grid-template-columns: repeat(1, 1fr);
  }
  .panelcardsmainsect {
    margin-top: -12rem;
}
}
.panelcard-title{
  /* font-size: 1.5rem; */
}
.panelcard h2{
  /* color: red; */
  text-decoration:dotted;
  text-align: center;
}
a{
  text-decoration: none;
  border: none;
}

h2{
  text-decoration: none;
  /* border-bottom: 1px solid red; */
}
.panelcard-title{
  border-bottom: none;
}
a{
  border: none;
}

.mainaccp{
  margin-top: 5rem;
}


@media (max-width: 800px) {
  /* .panelcards-01 {
    grid-template-columns: repeat(1, 1fr);
  } */
  .panelcardsmainsect {
    /* margin-top: -11rem; */
}
.panelcardsmainsect{
  margin-top: -5rem;
}

}

