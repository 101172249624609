.panelddflex-container {
  display: flex;
  flex-direction: row;
  font-size: 30px;
  text-align: center;
}

.panelddflex-item-left {
  padding: 10px;
  flex: 50%;
  margin-top: 5%;
  padding-left: 5%;
}
.paneldd01 {
  padding-top: 4rem;
  /* color: red; */
}

.panelddflex-item-right {
  padding: 10px;
  flex: 50%;
}
.paneldescline01{
  position: absolute;
width: 100%;
height: 0px;
left: 0px;
top: 105px;

border-bottom: 1px solid rgba(58, 58, 31, 0.8);
/* transform: rotate(-0.08deg); */
}
.paneldd01 h1 {
  background: #005E8C;
  color: white;
  width: 75%;
  /* color: red; */
padding-right: 2rem;
line-height: 2;
  text-align: right;
  font-size: 1.5rem;
}
.paneldd01 p {
  text-align: justify;
  border-bottom: 0.5px solid rgba(58, 58, 31, 0.6);
  /* background: rgba(92, 92, 92, 0.60); */
  height: 40px;

  margin-top: 4%;
  padding-bottom: 2rem;
  color: rgba(92, 92, 92, 0.80);
}
.panelddflex-item-right img {
  width: 60%;
  height: 500px;
  margin-top: 2.5rem;
}

@media (max-width: 800px) {
  .panelddflex-container {
    flex-direction: column;
  }
}
@media (max-width: 1692px) {
  .paneldd01 p {
   
    border-bottom: 0.5px solid rgba(58, 58, 31, 0.6);
 
    padding-bottom: 4rem;
  }
  .panelddflex-item-right img {
    width: 80%;
    height: 97%;
    margin-top: 2.5rem;
}
}
@media (max-width: 959px) {
  .paneldd01 p {
   
    border-bottom: 0.5px solid rgba(58, 58, 31, 0.6);
 
    padding-bottom: 7rem;
  }
  .panelddflex-item-right img {
    width: 80%;
    height: 520px;
    margin-top: 2.5rem;
}
.paneldescline01{
  /* position: absolute; */
  top: 75px;
}
}
@media (max-width: 799px) {
  .paneldd01 p {
   
    border-bottom: 0.5px solid rgba(58, 58, 31, 0.6);
 
    padding-bottom: 7rem;
  }
  .panelddflex-item-right img {
    width: 80%;
    height: 520px;
    margin-top: 2.5rem;
}
.panelddflex-item-left p{
  font-size: 15px;
  padding-bottom: 5rem;
}
}

@media (max-width: 413px) {
  .paneldd01 p {
   
    border-bottom: 0.5px solid rgba(58, 58, 31, 0.6);
 
    /* padding-bottom: 8rem; */
  }
  .panelddflex-item-right img {
    width: 80%;
    height:auto;
    margin-top: 2.5rem;
}
.paneldd01 h1 {
  /* color: red; */
  font-size: 20px;
}
}
.gvksolarpanel{
  margin-top: 6rem;
}
.gvksolarpanel .layout_image-wrapper{
  margin-top: 2rem;
}
.gvksolarpanel .sideline{
  width: 0.45em;
    height: 10%;
    background-color: #005E8C;
    position: absolute;
    left: 2em;
    margin-top: 2rem;
}
.gvksolarpanel .layout_component{
  margin-left: 3rem;
}
/* .gvksolarpanel .layout_image.home{
  width: 100%;
  border: none;
  height: 1000px;
  object-fit: cover;
} */

.gvksolarpanel .padding-xhuge{
  padding: 0rem;
}
.gvksolarpanel p{
  line-height: 135%;
  font-size: 18px;
}