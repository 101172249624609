.tfitmainbody {
  padding-top: 8rem;
  padding-left: 2rem;
  padding-right: 2rem;
}
.tfitmainbody .stable-striped td,
th {
  /* border: 1px solid inherit; */
}
.tfitflex-container {
  display: flex;
  flex-direction: row;
  font-size: 30px;
  text-align: center;
  /* margin-top: -4rem; */
}
.tfithead {
 
  font-size: 30px;
  font-weight: 500;
  text-transform: capitalize;
  text-align: center;
}
.tfittable-striped th {
  border: none;
}
.tfitmimage {
  width: 100%;
  height: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 3rem;
}
.tfitc01 {
  
  text-transform: capitalize;
  text-align: center;
  padding-top: 2rem;
}
.tfitc02 {
  
  color: rgba(92, 92, 92, 0.70);
  /* width: 55%; */
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  font-size: 20px;
  padding-top: 2rem;
}

.tfitflex-item-left {
  /* background-color: #f1f1f1; */
  padding: 10px;
  flex: 50%;
}

.tfitflex-item-right {
  /* background-color: dodgerblue; */
  padding: 10px;
  flex: 50%;
}

.tfitccontainer {
  display: flex;

  width: 100%;
  justify-content: space-evenly;

  flex-wrap: wrap;
  padding-top: 2rem;
}
.tfitccontainer .card {
  margin: 10px;
  background-color: #fff;

  overflow: hidden;
  width: 500px;
  border-bottom: none;
}
.tfitccontainer .card-header {
  border-bottom: none;
  background-color: inherit;
}
.tfitccontainer .card-header img {
  width: 100%;
  /* height: 400px; */
  height: auto;
  object-fit: cover;
}
.tfitccontainer .card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  min-height: 250px;
}

.tfitccontainer .tag {
  background: #cccccc;
  border-radius: 50px;
  font-size: 12px;
  margin: 0;
  color: #fff;
  padding: 2px 10px;
  text-transform: uppercase;
  cursor: pointer;
}

.tfitccontainer ul li {
  color: rgba(58, 58, 31, 0.7);
}

.tfitccontainer .user {
  display: flex;
  margin-top: auto;
  text-align: left;
  flex-direction: row;
  left: 48%;
  position: absolute;
  top: 75%;
}

.tfitccontainer .user img {
  width: 240px;
  height: 240px;
  margin-right: 10px;
  margin-top: 2rem;
  text-align: right;
  align-items: center;
}
.user-info h5 {
  margin: 0;
}
.user-info small {
  color: #545d7a;
}
@media (max-width: 500px) {
  .user img {
    display: none;
  }
}
.sepline-01 {
  position: absolute;

  width: 100%;
  height: 0px;
  left: 0;
  opacity: 0.8;
  border-bottom: 1px solid rgba(58, 58, 31, 0.8);
  transform: rotate(-180deg);
  margin-top: 2rem;
}

.tfitcleandesc01 {
  position: absolute;
  width: 100%;
  height: 6vw;
  left: 0;
  margin-top: 3rem;
  /* background: linear-gradient(90deg, #ffffff 0%, #f18400 100%); */
  background: linear-gradient(90deg, #FFF 0%, #005E8C 100%);

}
.tfitcleanhead-01 {
  color: white;
  text-align: right;
  padding-top: 1.8%;
  font-size: 2vw;
  padding-right: 2.5rem;
}
.tfitcleanhead-02 {
  /* background-color: red; */
  font-size: 2vw;
  text-align: left;
  color: rgba(0, 94, 140, 0.70);;
  position: absolute;
  top: 13%;
  text-transform: uppercase;
  left: 2%;
}
.tfitcleanhead-02 span {
  font-size: 1vw;
}
.tfitclean-01img {
  width: 30vw;
  /* position: absolute; */
  text-align: left;
  display: block;

  margin-left: 4rem;
  /* left: 10%; */
  /* padding-top: 12rem; */

  /* height: 45%; */
}
.tfitcleantable-01 {
  /* position: absolute; */
  /* width: 861px;
height: 84px;
left: 523px; */
  /* top: 15px; */
  width: 45%;
  height: 84px;
  text-align: right;
  display: block;
  margin-left: auto;
  /* padding-top: 45rem; */
  margin-top: -19%;
  /* margin-right: auto; */

  background: rgba(241, 132, 0, 0.8);
}
.tfittable-striped th {
  background: rgba(241, 132, 0, 0.8);
  height: 145px;
  border-left: inherit;
  color: white;
  /* height: 489px; */
}
.tfittable-striped {
  /* border: 5px solid green; */
  border-left: navajowhite;
}
.tfittable-striped td {
  color: rgba(58, 58, 31, 0.7);
  font-size: 1rem;
  padding-top: 1.5rem;
  /* border-bottom: 1px  solid rgba(58, 58, 31, 0.7);; */
}
.tfittable-striped th:nth-child(even) {
  border: none;
}
.tfittable-striped {
  display: block;

  left: 38%;
  position: absolute;
  top: 92%;
  /* height: 4845px; */
  width: 60%;
  /* padding: 8rem; */
  border: none;
}

/* hygine */

.tfithygflex-container {
  display: flex;
  flex-direction: row;
  font-size: 30px;
  text-align: center;
  /* position: absolute; */
  /* padding-top: 73rem; */
  margin-top: 53rem;
}

.tfithygflex-item-left {
  /* background-color: #f1f1f1; */
  padding: 10px;
  flex: 50%;
  /* padding-top: 50rem; */
  /* margin-top: 50rem; */
}

.tfithygflex-item-right {
  /* background-color: dodgerblue; */
  padding: 10px;
  flex: 50%;
  margin-top: -50rem;
}
.tfithygdesc01 {
  /* position: absolute; */
  width: 100%;
  height: 166px;
  left: 0;
  /* margin-top: 6rem; */
  /* background: linear-gradient(90deg, #ffffff 0%, #f18400 100%); */
  background: linear-gradient(90deg, #FFF 0%, #005E8C 100%);
}
.tfithyghead-01 {
  color: white;
  text-align: right;
  padding-top: 2.5rem;
  padding-right: 2.5rem;
  position: absolute;
  font-size: 3rem;
  /* left: 0; */
  right: 0;
}
.tfithyghead-02 {
  font-size: 2vw;
  text-align: left;
  /* color: rgba(58, 58, 31, 0.8); */
  color: rgba(0, 94, 140, 0.80);

  top: 12%;
  position: absolute;
  left: 2%;
  text-transform: uppercase;
}
.tfithyghead-02 span {
  font-size: 1vw;
}
@media (max-width: 1024px) {
  .tfitflex-container {
    flex-direction: column;
  }
  .tfitflex-item-left .tfitclean-01img {
    /* display: none; */
    width: 80%;
    margin-top: 8rem;
    /* margin-left: 4rem; */
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .tfithygflex-container .tfitclean-01img {
    width: 80%;
    /* margin-top: 8rem; */
    /* margin-left: 4rem; */
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .tfittable-striped {
    margin-top: 80rem;
    left: 0%;
    top: 0;
    width: 100%;
  }
  .tfitclean-01img {
    /* display: none; */
  }
  .tfithygflex-container {
    /* margin-top: 95rem; */
  }
}
@media (max-width: 1024px) {
  .tfithygflex-container {
    flex-direction: column;
  }
  .table {
    /* width: 5%; */
  }
  
  .tfittable-striped td {
    color: rgba(58, 58, 31, 0.7);
    font-size: 1.5rem;
    padding-top: 1.5rem;
  }
  .tfittable-striped th {
    height: auto;
    font-size: 28px;
    /* margin-left: 142rem; */
  }
  .tfithygdesc01 {
    /* width: 179%; */
  }
  .tfitcleandesc01 {
    /* width: 109%; */
  }
}
@media (max-width: 928px) {
  
 
  .tfittable-striped {
    margin-top: 125%;
    left: 0%;
    top: 0;
    width: 100%;
  }
  .tfitclean-01img {
    /* display: none; */
  }
  .tfithygflex-container {
    /* margin-top: 155%; */
  }
}

@media (max-width: 770px) {
  
 
  .tfittable-striped {
    margin-top: 150%;
    left: 0%;
    top: 0;
    width: 100%;
  }
  .tfitclean-01img {
    /* display: none; */
  }
  .tfithygflex-container {
    /* margin-top: 185%; */
  }
  
}
@media (max-width: 660px) {
  
 
  .tfittable-striped {
    margin-top: 150%;
    left: 0%;
    top: 0;
    width: 100%;
  }
  .tfitclean-01img {
    /* display: none; */
  }
  .tfithygflex-container {
    /* margin-top: 245%; */
  }
}

@media (max-width: 350px) {
  
 
  .tfittable-striped {
    margin-top: 180%;
    left: 0%;
    top: 0;
    width: 100%;
  }
  .tfitclean-01img {
    /* display: none; */
  }
  .tfithygflex-container {
    /* margin-top: 295%; */
  }
}
@media (max-width: 290px) {
  
 
  .tfittable-striped {
    margin-top: 190%;
    left: 0%;
    top: 0;
    width: 100%;
  }
  .tfitclean-01img {
    /* display: none; */
  }
  .tfithygflex-container {
    margin-top: 335%;
  }
}

.tfitmainbody{
  margin-bottom: 800px;
}

@media (max-width: 1030px) {
  .tfitmainbody{
    margin-bottom: 3445px;
  }
}
@media (max-width: 940px) {
  .tfitmainbody{
    margin-bottom: 100rem;
  }
}
@media (max-width: 600px) {
  .tfitmainbody{
    margin-bottom: 100rem;
  }
}
@media (max-width: 355px) {
  .tfitmainbody{
    margin-bottom: 86rem;
  }
}

.t-fit-wrapper {
  display: flex;
  flex-direction: column;
  font-family: "Open Sans", sans-serif;
  padding-top: 8%;
  margin-bottom: 2%;
  padding-left: 5%;
  padding-right: 5%;
}
.t-fit-wrapper .t-fit {
  display: flex;
  flex-direction: row;
  margin-bottom: 8%;
  box-sizing: border-box;
  margin-left: 5%;
  margin-right: 5%;
}
.t-fit-wrapper .t-fit .left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  box-sizing: border-box;
  padding-right: 5%;
}
.t-fit-wrapper .t-fit .left h2 {
  font-weight: 400;
  font-size: 38px;
  margin-top: 2%;
  margin-bottom: 2%;
  line-height: 1.4;
}
.t-fit-wrapper .t-fit .left p {
  margin-top: 2%;
  margin-bottom: 2%;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.8;
}
.t-fit-wrapper .t-fit .right {
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
}
.t-fit-wrapper .t-fit .right img {
  width: 80%;
}
.t-fit-wrapper .product-range {
  display: flex;
  flex-direction: row;
  margin-top: 0%;
}
.t-fit-wrapper .product-range .product-selector {
  display: flex;
  flex-direction: column;
  width: 20%;
}
.t-fit-wrapper .product-range .product-selector span {
  font-size: 14px;
  font-weight: 400;
  padding-top: 3%;
  padding-bottom: 3%;
  padding-left: 10%;
  padding-right: 10%;
  letter-spacing: 1px;
  cursor: pointer;
}
.t-fit-wrapper .product-range .product-selector .header {
  background-color: #343a3f;
  color: white;
}
.t-fit-wrapper .product-range .product-type {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 2%;
}
.t-fit-wrapper .product-range .product-type .product-detail .product-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 2%;
  padding-bottom: 2%;
  background-image: -webkit-linear-gradient(-30deg, #ececec 60%, #f18400 40%);
}
.t-fit-wrapper .product-range .product-type .product-detail .product-header h1 {
  margin-bottom: 0;
  margin-top: 0;
}
.t-fit-wrapper .product-range .product-type .product-detail .product-header h2 {
  margin-top: 0;
}
.t-fit-wrapper .product-range .product-type .product-detail .product-header .header-left {
  color: rgba(0, 0, 0, 0.7);
}
.t-fit-wrapper .product-range .product-type .product-detail .product-header .header-right {
  color: white;
}
.t-fit-wrapper .product-range .product-type .product-detail .scroll-container {
  display: flex;
  flex-direction: column;
  height: 70vh;
  overflow-y: scroll;
}
.t-fit-wrapper .product-range .product-type .product-detail .scroll-container p {
  font-weight: 300;
  line-height: 1.7;
}
.t-fit-wrapper .product-range .product-type .product-detail .scroll-container .product-description {
  display: flex;
  flex-direction: row;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 3%;
  margin-bottom: 3%;
}
.t-fit-wrapper .product-range .product-type .product-detail .scroll-container .product-description .left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
}
.t-fit-wrapper .product-range .product-type .product-detail .scroll-container .product-description .left h3 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 400;
}
.t-fit-wrapper .product-range .product-type .product-detail .scroll-container .product-description .right {
  width: 50%;
}
.t-fit-wrapper .product-range .product-type .product-detail .scroll-container .product-description .right img {
  width: 100%;
}
 .product-info {
  display: flex;
  flex-direction: column;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 3%;
  margin-bottom: 3%;
}
.product-info table th {
  background-color: #f18400;
  color: white;
  padding-top: 2%;
  padding-bottom: 2%;
  padding-left: 1%;
  padding-right: 1%;
  text-align: left;
}
 .product-info table td {
  border-bottom: thin solid;
}
.t-fit-wrapper .product-range .product-type .product-detail .scroll-container .product-details {
  display: flex;
  flex-direction: column;
  margin-left: 1%;
  margin-right: 1%;
  margin-top: 3%;
  margin-bottom: 3%;
}
.t-fit-wrapper .product-range .product-type .product-detail .scroll-container .product-details table th {
  background-color: #f18400;
  color: white;
  padding-top: 1%;
  padding-bottom: 1%;
  padding-left: 1%;
  padding-right: 1%;
  text-align: left;
}
.t-fit-wrapper .product-range .product-type .product-detail .scroll-container .product-details table td {
  font-weight: 300;
  font-size: 16px;
}
@media only screen and (max-width: 1400px) and (min-width: 1024px) {
  .t-fit-wrapper {
    padding-top: 8%;
    margin-bottom: 2%;
    /* background-color: yellow; */
    padding-left: 3%;
    padding-right: 3%;
 }
  .t-fit-wrapper .t-fit {
    display: flex;
    flex-direction: row;
    margin-bottom: 8%;
    box-sizing: border-box;
    margin-left: 5%;
    margin-right: 5%;
 }
  .t-fit-wrapper .t-fit .left h2 {
    font-size: 30px;
    line-height: 1.4;
 }
  .t-fit-wrapper .t-fit .left p {
    font-size: 16px;
    line-height: 1.8;
 }
  .t-fit-wrapper .product-range .product-selector {
    display: flex;
    flex-direction: column;
    width: 25%;
 }
  .t-fit-wrapper .product-range .product-type .product-detail .product-header h1 {
    margin-bottom: 0;
    margin-top: 0;
    font-size: 30px;
 }
  .t-fit-wrapper .product-range .product-type .product-detail .product-header h2 {
    margin-top: 0;
    font-size: 22px;
 }
}
@media only screen and (max-width: 1023px) and (min-width: 990px) {
  .t-fit-wrapper {
    padding-top: 8%;
    margin-bottom: 2%;
    padding-left: 3%;
    padding-right: 3%;
 }
  .t-fit-wrapper .t-fit {
    display: flex;
    flex-direction: row;
    margin-bottom: 8%;
    box-sizing: border-box;
    margin-left: 5%;
    margin-right: 5%;
 }
  .t-fit-wrapper .t-fit .left h2 {
    font-size: 30px;
    line-height: 1.4;
 }
  .t-fit-wrapper .t-fit .left p {
    font-size: 16px;
    line-height: 1.8;
 }
  .t-fit-wrapper .product-range .product-selector {
    display: flex;
    flex-direction: column;
    width: 25%;
 }
  .t-fit-wrapper .product-range .product-type .product-detail p {
    font-size: 14px;
 }
  .t-fit-wrapper .product-range .product-type .product-detail .product-header h1 {
    margin-bottom: 0;
    margin-top: 0;
    font-size: 24px;
 }
  .t-fit-wrapper .product-range .product-type .product-detail .product-header h2 {
    margin-top: 0;
    font-size: 20px;
 }
  .t-fit-wrapper .product-range .product-type .product-detail .scroll-container table th {
    font-size: 14px;
 }
  .t-fit-wrapper .product-range .product-type .product-detail .scroll-container table td {
    font-weight: 300;
    font-size: 14px;
 }
  .t-fit-wrapper .product-range .product-type .product-detail .scroll-container .product-details table th {
    font-size: 14px;
 }
  .t-fit-wrapper .product-range .product-type .product-detail .scroll-container .product-details table td {
    font-weight: 300;
    font-size: 14px;
 }
}
@media only screen and (max-width: 990px) {
  .t-fit-wrapper .t-fit {
    display: flex;
    flex-direction: column;
 }
  .t-fit-wrapper .t-fit .left {
    width: 100%;
    padding-right: 0;
 }
  .t-fit-wrapper .t-fit .left h2 {
    font-size: 30px;
    line-height: 1.4;
    text-align: center;
 }
  .t-fit-wrapper .t-fit .left p {
    font-size: 16px;
    line-height: 1.8;
    text-align: center;
 }
  .t-fit-wrapper .t-fit .right {
    width: 100%;
    margin-top: 4%;
    margin-bottom: 4%;
 }
  .t-fit-wrapper .t-fit .right img {
    width: 100%;
 }
  .t-fit-wrapper .product-range {
    display: flex;
    flex-direction: column;
    margin-top: 4%;
 }
  .t-fit-wrapper .product-range .product-selector {
    display: flex;
    flex-direction: column;
    margin-bottom: 4%;
    width: 100%;
 }
  .t-fit-wrapper .product-range .product-selector span {
    font-size: 12px;
 }
  .t-fit-wrapper .product-range .product-type {
    width: 100%;
    margin-top: 4%;
 }
  .t-fit-wrapper .product-range .product-type .product-detail .product-header {
    background-image: none;
    background-color: #ececec;
 }
  .t-fit-wrapper .product-range .product-type .product-detail .product-header h1 {
    margin-bottom: 0;
    margin-top: 0;
    font-size: 22px;
 }
  .t-fit-wrapper .product-range .product-type .product-detail .product-header h2 {
    margin-top: 0;
    font-size: 18px;
    margin-bottom: 0;
 }
  .t-fit-wrapper .product-range .product-type .product-detail .product-header .header-right {
    display: none;
 }
  .t-fit-wrapper .product-range .product-type .product-detail .scroll-container {
    display: flex;
    flex-direction: column;
    height: 70vh;
    overflow-y: scroll;
 }
  .t-fit-wrapper .product-range .product-type .product-detail .scroll-container p {
    font-weight: 300;
    line-height: 1.7;
 }
  .t-fit-wrapper .product-range .product-type .product-detail .scroll-container .product-description {
    flex-direction: column;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 3%;
    margin-bottom: 3%;
 }
  .t-fit-wrapper .product-range .product-type .product-detail .scroll-container .product-description .left {
    width: 100%;
 }
  .t-fit-wrapper .product-range .product-type .product-detail .scroll-container .product-description .left h3 {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 400;
    font-size: 22px;
 }
  .t-fit-wrapper .product-range .product-type .product-detail .scroll-container .product-description .left p {
    font-size: 14px;
 }
  .t-fit-wrapper .product-range .product-type .product-detail .scroll-container .product-description .right {
    width: 100%;
 }
  .t-fit-wrapper .product-range .product-type .product-detail .scroll-container .product-description .right img {
    width: 100%;
 }
  .t-fit-wrapper .product-range .product-type .product-detail .scroll-container table th {
    font-size: 14px;
 }
  .t-fit-wrapper .product-range .product-type .product-detail .scroll-container table td {
    font-weight: 300;
    font-size: 14px;
 }
  .t-fit-wrapper .product-range .product-type .product-detail .scroll-container .product-details table th {
    font-size: 14px;
 }
  .t-fit-wrapper .product-range .product-type .product-detail .scroll-container .product-details table td {
    font-weight: 300;
    font-size: 14px;
 }
}
td{
  font-size: 15px;
}
@keyframes FadeIn {
  0% {
    opacity: 0;
    transform: translateY(100%);
 }
  85% {
    opacity: 1;
    transform: translateY(15%);
 }
  100% {
    transform: translateY(0%);
 }
}



.t-fit-wrapper {
  display: flex;
  flex-direction: column;
  font-family: 'Open Sans', sans-serif;
  padding-top: 8%;
  margin-bottom: 2%;
  padding-left: 5%;
  padding-right: 5%;
}
.t-fit-wrapper .t-fit {
  display: flex;
  flex-direction: row;
  margin-bottom: 8%;
  box-sizing: border-box;
  margin-left: 5%;
  margin-right: 5%;
}
.t-fit-wrapper .t-fit .left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  box-sizing: border-box;
  padding-right: 5%;
}
.t-fit-wrapper .t-fit .left h2 {
  font-weight: 400;
  font-size: 38px;
  margin-top: 2%;
  margin-bottom: 2%;
  line-height: 1.4;
}
.t-fit-wrapper .t-fit .left p {
  margin-top: 2%;
  margin-bottom: 2%;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.8;
}
.t-fit-wrapper .t-fit .right {
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
}
.t-fit-wrapper .t-fit .right img {
  width: 80%;
}
.t-fit-wrapper .product-range {
  display: flex;
  flex-direction: row;
  margin-top: 0%;
}
.t-fit-wrapper .product-range .product-selector {
  display: flex;
  flex-direction: column;
  width: 20%;
}
.t-fit-wrapper .product-range .product-selector span {
  font-size: 14px;
  font-weight: 400;
  padding-top: 3%;
  padding-bottom: 3%;
  padding-left: 10%;
  padding-right: 10%;
  letter-spacing: 1px;
  cursor: pointer;
}
.t-fit-wrapper .product-range .product-selector .header {
  background-color: #343a3f;
  color: white;
}
.t-fit-wrapper .product-range .product-type {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 2%;
}
.t-fit-wrapper .product-range .product-type .product-detail .product-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 2%;
  padding-bottom: 2%;
  background-image: -webkit-linear-gradient(-30deg, #ececec 60%, #f18400 40%);
}
.t-fit-wrapper .product-range .product-type .product-detail .product-header h1 {
  margin-bottom: 0;
  margin-top: 0;
}
.t-fit-wrapper .product-range .product-type .product-detail .product-header h2 {
  margin-top: 0;
}
.t-fit-wrapper .product-range .product-type .product-detail .product-header .header-left {
  color: rgba(0, 0, 0, .7);
}
.t-fit-wrapper .product-range .product-type .product-detail .product-header .header-right {
  color: white;
}
.t-fit-wrapper .product-range .product-type .product-detail .scroll-container {
  display: flex;
  flex-direction: column;
  height: 70vh;
  /* overflow-y: scroll; */
}
.t-fit-wrapper .product-range .product-type .product-detail .scroll-container p {
  font-weight: 300;
  line-height: 1.7;
}
.t-fit-wrapper .product-range .product-type .product-detail .scroll-container .product-description {
  display: flex;
  flex-direction: row;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 3%;
  margin-bottom: 3%;
}
.t-fit-wrapper .product-range .product-type .product-detail .scroll-container .product-description .left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
}
.t-fit-wrapper .product-range .product-type .product-detail .scroll-container .product-description .left h3 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 400;
}
.t-fit-wrapper .product-range .product-type .product-detail .scroll-container .product-description .right {
  width: 50%;
}
.t-fit-wrapper .product-range .product-type .product-detail .scroll-container .product-description .right img {
  width: 100%;
}
.t-fit-wrapper .product-range .product-type .product-detail .scroll-container .product-info {
  display: flex;
  flex-direction: column;
  margin-left: 5%;
  margin-right: 5%;
  /* margin-top:18%; */
  margin-bottom: 3%;
}
.t-fit-wrapper .product-range .product-type .product-detail .scroll-container .product-info table th {
  background-color: #f18400;
  color: white;
  padding-top: 2%;
  padding-bottom: 2%;
  padding-left: 1%;
  padding-right: 1%;
  text-align: left;
  border: none;
}
.t-fit-wrapper .product-range .product-type .product-detail .scroll-container .product-info table td {
  border-bottom: thin solid;
}
.t-fit-wrapper .product-range .product-type .product-detail .scroll-container .product-info table tr:nth-child(1) {
  /* border-bottom: thin solid; */
  /* background-color: pink; */
}

.t-fit-wrapper .product-range .product-type .product-detail .scroll-container .product-details {
  display: flex;
  flex-direction: column;
  margin-left: 1%;
  margin-right: 1%;
  margin-top: 3%;
  margin-bottom: 3%;
}
.t-fit-wrapper .product-range .product-type .product-detail .scroll-container .product-details table th {
  background-color: #f18400;
  color: white;
  padding-top: 1%;
  padding-bottom: 1%;
  padding-left: 1%;
  padding-right: 1%;
  text-align: left;
}
.t-fit-wrapper .product-range .product-type .product-detail .scroll-container .product-details table td {
  font-weight: 300;
  font-size: 16px;
}
@media only screen and (max-width: 1400px) and (min-width: 1024px) {
  .t-fit-wrapper {
    padding-top: 8%;
    margin-bottom: 2%;
    padding-left: 3%;
    padding-right: 3%;
 }
  .t-fit-wrapper .t-fit {
    display: flex;
    flex-direction: row;
    margin-bottom: 8%;
    box-sizing: border-box;
    margin-left: 5%;
    margin-right: 5%;
 }
  .t-fit-wrapper .t-fit .left h2 {
    font-size: 30px;
    line-height: 1.4;
 }
  .t-fit-wrapper .t-fit .left p {
    font-size: 16px;
    line-height: 1.8;
 }
  .t-fit-wrapper .product-range .product-selector {
    display: flex;
    flex-direction: column;
    width: 25%;
 }
  .t-fit-wrapper .product-range .product-type .product-detail .product-header h1 {
    margin-bottom: 0;
    margin-top: 0;
    font-size: 30px;
 }
  .t-fit-wrapper .product-range .product-type .product-detail .product-header h2 {
    margin-top: 0;
    font-size: 22px;
 }
}
@media only screen and (max-width: 1023px) and (min-width: 990px) {
  .t-fit-wrapper {
    padding-top: 8%;
    margin-bottom: 2%;
    padding-left: 3%;
    padding-right: 3%;
 }
  .t-fit-wrapper .t-fit {
    display: flex;
    flex-direction: row;
    margin-bottom: 8%;
    box-sizing: border-box;
    margin-left: 5%;
    margin-right: 5%;
 }
  .t-fit-wrapper .t-fit .left h2 {
    font-size: 30px;
    line-height: 1.4;
 }
  .t-fit-wrapper .t-fit .left p {
    font-size: 16px;
    line-height: 1.8;
 }
  .t-fit-wrapper .product-range .product-selector {
    display: flex;
    flex-direction: column;
    width: 25%;
 }
  .t-fit-wrapper .product-range .product-type .product-detail p {
    font-size: 14px;
 }
  .t-fit-wrapper .product-range .product-type .product-detail .product-header h1 {
    margin-bottom: 0;
    margin-top: 0;
    font-size: 24px;
 }
  .t-fit-wrapper .product-range .product-type .product-detail .product-header h2 {
    margin-top: 0;
    font-size: 20px;
 }
  .t-fit-wrapper .product-range .product-type .product-detail .scroll-container table th {
    font-size: 14px;
 }
  .t-fit-wrapper .product-range .product-type .product-detail .scroll-container table td {
    font-weight: 300;
    font-size: 14px;
 }
  .t-fit-wrapper .product-range .product-type .product-detail .scroll-container .product-details table th {
    font-size: 14px;
 }
  .t-fit-wrapper .product-range .product-type .product-detail .scroll-container .product-details table td {
    font-weight: 300;
    font-size: 14px;
 }
}
@media only screen and (max-width: 990px) {
  .t-fit-wrapper .t-fit {
    display: flex;
    flex-direction: column;
 }
  .t-fit-wrapper .t-fit .left {
    width: 100%;
    padding-right: 0;
 }
  .t-fit-wrapper .t-fit .left h2 {
    font-size: 30px;
    line-height: 1.4;
    text-align: center;
 }
  .t-fit-wrapper .t-fit .left p {
    font-size: 16px;
    line-height: 1.8;
    text-align: center;
 }
  .t-fit-wrapper .t-fit .right {
    width: 100%;
    margin-top: 4%;
    margin-bottom: 4%;
 }
  .t-fit-wrapper .t-fit .right img {
    width: 100%;
 }
  .t-fit-wrapper .product-range {
    display: flex;
    flex-direction: column;
    margin-top: 74%;
    height: 100vh;
 }
  .t-fit-wrapper .product-range .product-selector {
    display: flex;
    flex-direction: column;
    margin-bottom: 4%;
    width: 100%;
 }
  .t-fit-wrapper .product-range .product-selector span {
    font-size: 12px;
 }
  .t-fit-wrapper .product-range .product-type {
    width: 100%;
    margin-top: 4%;
 }
  .t-fit-wrapper .product-range .product-type .product-detail .product-header {
    background-image: none;
    background-color: #ececec;
 }
  .t-fit-wrapper .product-range .product-type .product-detail .product-header h1 {
    margin-bottom: 0;
    margin-top: 0;
    font-size: 22px;
 }
  .t-fit-wrapper .product-range .product-type .product-detail .product-header h2 {
    margin-top: 0;
    font-size: 18px;
    margin-bottom: 0;
 }
  .t-fit-wrapper .product-range .product-type .product-detail .product-header .header-right {
    display: none;
 }
  .t-fit-wrapper .product-range .product-type .product-detail .scroll-container {
    display: flex;
    flex-direction: column;
    height: 280vh;
    overflow-y: scroll;
 }
  .t-fit-wrapper .product-range .product-type .product-detail .scroll-container p {
    font-weight: 300;
    line-height: 1.7;
 }
  .t-fit-wrapper .product-range .product-type .product-detail .scroll-container .product-description {
    flex-direction: column;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 3%;
    margin-bottom: 3%;
 }
  .t-fit-wrapper .product-range .product-type .product-detail .scroll-container .product-description .left {
    width: 100%;
 }
  .t-fit-wrapper .product-range .product-type .product-detail .scroll-container .product-description .left h3 {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 400;
    font-size: 22px;
 }
  .t-fit-wrapper .product-range .product-type .product-detail .scroll-container .product-description .left p {
    font-size: 14px;
 }
  .t-fit-wrapper .product-range .product-type .product-detail .scroll-container .product-description .right {
    width: 100%;
 }
  .t-fit-wrapper .product-range .product-type .product-detail .scroll-container .product-description .right img {
    width: 100%;
 }
  .t-fit-wrapper .product-range .product-type .product-detail .scroll-container table th {
    font-size: 14px;
 }
 .t-fit-wrapper .product-range .product-type .product-detail .scroll-container table {
  /* font-size: 14px; */
  height: 40vh;
}
  .t-fit-wrapper .product-range .product-type .product-detail .scroll-container table td {
    font-weight: 300;
    font-size: 14px;
 }
  .t-fit-wrapper .product-range .product-type .product-detail .scroll-container .product-details table th {
    font-size: 14px;
 }
  .t-fit-wrapper .product-range .product-type .product-detail .scroll-container .product-details table td {
    font-weight: 300;
    font-size: 14px;
 }
 .tfitflex-item-left{
  margin-top: -10rem;
}
}
@keyframes FadeIn {
  0% {
    opacity: 0;
    transform: translateY(100%);
 }
  85% {
    opacity: 1;
    transform: translateY(15%);
 }
  100% {
    transform: translateY(0%);
 }
}

table{
  /* width: 484px; */
  margin-right: 0px;
  /* position: absolute; */
  right: 404px;
}
@media screen and (min-width: 1024px)  {
  body{
    /* background-color: pink; */
  }
  .product-info table{
    width: 55%;

    /* margin-left: 65%; */
    /* margin-right: 11%; */
    /* right: -180px; */
    /* margin-left: 45%; */
    /* margin-top: 25%; */
    position: absolute;

    left: 38%;
    /* margin-top: 15%; */

    /* margin-top: 45%; */
    /* margin-top: 45%; */
    border: none;
    /* overflow: hidden; */
    /* border: 5px solid red; */
    /* margin-top: -14px; */
  }
  .tfithygflex-container .product-info table{
    margin-top: 10%;
  }
  .product-info table td{
    font-size: 14px;
  }
 
}
@media screen and (max-width: 1504px)  and (min-width:1000px){
  .tfithygflex-container .product-info table{
    margin-top: 23%;
  }
    .tfitflex-container .product-info table{
      margin-top: 15%;
    }
  
}
@media screen and (max-width: 1024px)  {
  .tfithygflex-item-right{
    /* margin-top: 1rem; */
    width: 100%;
  }
  .product-info table{
    /* width: 55%; */

    /* margin-left: 15%; */
    /* margin-top: -14px; */
    margin-top: 45%;
    /* overflow-y: scroll; */
  }
}
@media screen and (max-width: 600px)  {
  .tfithygflex-item-right{
    /* margin-top: 1rem; */
    width: 100%;
  }
  .product-info table{
    /* width: 55%; */

    /* margin-left: 15%; */
    /* margin-top: -14px; */
    /* margin-top: 45%; */
    /* position: absolute; */
    left: 3%;
    position: relative;
    margin-top: 205%;
    /* width: 15p; */
    /* overflow-y: scroll; */
  }
 
}

 .container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    margin: 1em 0;
  }

  .image-container {
    flex: 1 1 100%;
    max-width: 100%;
    margin-right: 1em;
  }

  .image-container img {
    max-width: 100%;
    height: auto;
  }

  .table-container {
    flex: 1 1 100%;
    max-width: 100%;
    overflow-x: auto;
  }

  .responsive-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 1rem;
  }

  .responsive-table th,
  .responsive-table td {
    padding: 0.5em;
    border: 1px solid #ddd;
  }

  /* adjust for smaller screens */
  @media only screen and (max-width: 600px) {
    .container {
      flex-direction: column;
    }
    .image-container,
    .table-container {
      flex-basis: 100%;
      margin: 0;
    }
    .table-container {
      overflow-x: auto;
    }
    .responsive-table {
      font-size: 0.8rem;
    }
    .responsive-table th,
    .responsive-table td {
      padding: 0.3em;
    }
  }

  /* adjust for even smaller screens */
  @media only screen and (max-width: 400px) {
    .responsive-table {
      font-size: 0.7rem;
    }
    .responsive-table th,
    .responsive-table td {
      padding: 0.2em;
    }
  }
  .tfitccontainer .card{
    height: auto;
  }
  .tfitccontainer .card{
  flex-direction: column;
  }

  /* Styles for the image when the minimum width is 1025px */
@media screen and (min-width: 1025px) {
  .atfitclean-01img {
    float: left;
    width: 50%;
  }
 
}

/* Styles for the image when the maximum width is 900px */
@media screen and (max-width: 900px) {
  .atfitclean-01img {
    display: block;
    margin: 0 auto; /* Center the image horizontally */
    margin-top: -3rem;
  }
  .tfitbox .downlaod-solapnel{
    /* margin-top: 2rem; */
  }
}
.tfithygflex-container .section_process{
  /* margin-top: 8rem; */
}
tbody tr{
  color: rgba(92, 92, 92, 0.70);
}
thead tr{
  color: white;
}
@media screen and (max-width: 900px) {
.secondshe{
  margin-top: 98rem;
}
.tfitcleandesc01 {
  margin-top: 0rem;
}
}
.tfitmainbody td{
  font-size: 18px;
}
@media screen and (max-width: 1325px) {
  .tfitmainbody td{
    font-size: 15px;
  }
}
@media screen and (max-width: 505px) {

.secondshe{
  /* margin-top: 90rem; */
}
.tfithead{
  font-size: 20px;
}
.tfitc02{
  font-size: 16px;
}
.tfitmainbody{
  padding-top: 5rem;
}
.tfitccontainer{
  padding-top: 0px;
}
.tfitccontainer .card{
  margin-top: -1rem;
}
.atfitclean-01img{
  width: 270px;
}
}
@media screen and (max-width: 500px){
.secondshe {
    margin-top: 81rem;
}
}
@media screen and (max-width: 1500px){
.tfitmainbody td{
  font-size: 16px;
}
}

@media screen and (max-width: 500px){
  .tfitmainbody td{
    font-size: 15px;
  }
  .tfitmainbody {
    margin-bottom: 80rem;
  }
  }

  @media screen and (max-width: 300px){
    .tfitmainbody td{
      font-size: 14px;
    }
    .tfitmainbody {
      margin-bottom: 90rem;
    }
    }
  

    @media screen and (max-width: 400px){
      .secondshe {
          margin-top: 90rem;
      }
      .tfitmainbody{
        padding-left: 0.8rem;
        padding-right: 0.8rem;
      }
      .tfitmainbody {
        margin-bottom: 91rem;
      }
      }